import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  private businessLogoSrc = new BehaviorSubject('');
  currentSrc = this.businessLogoSrc.asObservable();

  private urlSrc = new BehaviorSubject('/dashboard');
  currentURLSrc = this.urlSrc.asObservable();

  private bussinessLogo = new BehaviorSubject ((localStorage.getItem("image")));
  businessImg= this.bussinessLogo.asObservable();

  // private authenticateTaskDetails = new BehaviorSubject('');
  // currentTaskDetails = this.authenticateTaskDetails.asObservable();
  
  constructor() { }

  changeUrl(message: string) {
    this.businessLogoSrc.next(message)
  }

  urlChange(message: string){
    this.urlSrc.next(message);
  }

  imageChanged (data: any) {
    this.bussinessLogo.next(data);
    console.log(this.bussinessLogo)
  }

  // getTaskDetails(message: any) {
  //   this.authenticateTaskDetails.next(message)
  // }
}
