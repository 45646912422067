<p-toast [style]="{ marginTop: '80px' }" [baseZIndex]="16980" position="top-right"></p-toast>
<p-toast position="center" key="c" [modal]="true" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div style="text-align: center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
            <h3>{{ message.summary }}</h3>
            <p><a href="/#/payments">Click Here </a>{{ message.detail }}</p>
        </div>
    </ng-template>
</p-toast>
<button class="btn btn-sm taketourbtn" *ngIf="isAuthToken == true" (click)="showTakeTourDialog()">
    <img src="../../../assets/img/tour_1.png" style="width: 14px" />{{
    "takeATour" | translate
    }}
</button>

<div class="updatedDateLabel row m-0" *ngIf="isAuthToken == true">

    {{ "last" | translate }}
    <b style="text-decoration: underline; cursor: pointer; margin-left: 2px" (click)="refresh()">{{ "update" | translate
        }}
    </b>
    <span style="margin-left: 3px">{{ currentDate }}</span>
    <app-tasks [addTaskId]="addTaskId" (closeEventAdd)="closeAddDataTask($event)"></app-tasks>
    <!-- <ng-container *ngIf="isUserAuthorised">
        <app-tasks [addTaskId]="addTaskId" (closeEventAdd)="closeAddDataTask($event)"></app-tasks>
    </ng-container> -->
</div>


<div *ngIf="viewDialogueBox">
    <app-tasks [viewDialogueBox]="viewDialogueBox" [viewTaskId]="viewTaskId" [viewDialogueBoxData]="viewDialogueBoxData"
        (closeEventView)="closeAddTaskData($event)" (closeIconView)="closeIconClick($event)"></app-tasks>
</div>

<div class="container-fluid dashboard-main-container" *ngIf="isAuthToken == true">
    <div class="main-container">
        <div class="dashboard-container">
            <p-panel class="dashboard-Panel" [toggleable]="true" #dashPanel *ngIf="toggle" expandIcon="pi-angle-down"
                [showHeader]="false" collapseIcon="pi-angle-up" (onAfterToggle)="minimizeDiv()">
                <div class="row dashboardContainer" *ngIf="!advancedSearch">
                    <div class="col-sm-10">
                        <div class="dashboard-count-container">
                            <!-- <p>buble count goes here</p> -->
                            <div class="row counterRow">
                                <div class="myCount">
                                    <p class="mytitle" translate>my</p>
                                    <p class="teamtitle" translate>team</p>
                                </div>
                                <div class="counterDiv">
                                    <p class="title" translate>active</p>
                                    <div class="border-right">
                                        <p class="digits light-blue pointer"
                                            [class.active]="selectedTab == 'My Active Task'" (click)="
                        myCount?.myActive > 0 &&
                          onTaskCardClick('My Active Task');
                        changePageToFirst($event)
                      ">
                                            {{ myCount?.myActive == null ? "0" : myCount?.myActive }}
                                        </p>
                                        <p class="digits light-blue businessCards pointer" (click)="
                        teamCount?.teamActive > 0 &&
                          onTaskCardClick('Team Active Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'Team Active Task'">
                                            {{
                                            teamCount?.teamActive == null
                                            ? "0"
                                            : teamCount?.teamActive
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="counterDiv">
                                    <p class="title" translate>created</p>
                                    <div class="border-right">
                                        <p class="digits light-lightBlue pointer" (click)="
                        myCount?.myCreated > 0 &&
                          onTaskCardClick('My Created Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'My Created Task'">
                                            {{
                                            myCount?.myCreated == null ? "0" : myCount?.myCreated
                                            }}
                                        </p>
                                        <p class="digits light-lightBlue businessCards pointer" (click)="
                        teamCount?.teamCreated > 0 &&
                          onTaskCardClick('Team Created Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'Team Created Task'">
                                            {{
                                            teamCount?.teamCreated == null
                                            ? "0"
                                            : teamCount?.teamCreated
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="counterDiv">
                                    <p class="title" translate>overdue</p>
                                    <div class="border-right">
                                        <p class="digits light-pink overdueActive pointer" (click)="
                        myCount?.myOverdue > 0 &&
                          onTaskCardClick('My Overdue Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'My Overdue Task'">
                                            {{
                                            myCount?.myOverdue == null || myCount?.myOverdue == ""
                                            ? "0"
                                            : myCount?.myOverdue
                                            }}
                                        </p>
                                        <p class="digits light-pink businessCards overdueActive pointer" (click)="
                        teamCount?.teamOverdue > 0 &&
                          onTaskCardClick('Team Overdue Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'Team Overdue Task'">
                                            {{
                                            teamCount?.teamOverdue == null
                                            ? "0"
                                            : teamCount?.teamOverdue
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="counterDiv">
                                    <p class="title" translate>atRisk</p>
                                    <div class="border-right">
                                        <p class="digits light-brown pointer" (click)="
                        myCount?.myAtRisk > 0 &&
                          onTaskCardClick('My At Risk Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'My At Risk Task'">
                                            {{
                                            myCount?.myAtRisk == null || myCount?.myAtRisk == ""
                                            ? "0"
                                            : myCount?.myAtRisk
                                            }}
                                        </p>
                                        <p class="digits light-brown businessCards pointer" (click)="
                        teamCount?.teamAtRisk > 0 &&
                          onTaskCardClick('Team At Risk Task');
                        changePageToFirst($event)
                      " [class.active]="selectedTab == 'Team At Risk Task'">
                                            {{
                                            teamCount?.teamAtRisk == null
                                            ? "0"
                                            : teamCount?.teamAtRisk
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="counterDiv">
                                    <p class="title" translate>allTasks</p>
                                    <p class="digits light-darkBlue pointer" (click)="
                      myCount?.myAllTask > 0 && onTaskCardClick('My All Task');
                      changePageToFirst($event)
                    " [class.active]="selectedTab == 'My All Task'">
                                        {{ myCount?.myAllTask == null ? "0" : myCount?.myAllTask }}
                                    </p>
                                    <p class="digits light-darkBlue businessCards pointer" (click)="
                      teamCount?.teamAllTask > 0 &&
                        onTaskCardClick('Team All Task');
                      changePageToFirst($event)
                    " [class.active]="selectedTab == 'Team All Task'">
                                        {{
                                        teamCount?.teamAllTask == null
                                        ? "0"
                                        : teamCount?.teamAllTask
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 mt-4 p-0">
                        <label class="graphLabelStatus mb-0" *ngIf="myAllTasks && atriskTasks == true">{{ "atRisk" |
                            translate }} |
                            {{ "byPriority" | translate }}</label>
                        <label class="graphLabelStatus mb-0" *ngIf="myAllTasks && atriskTasks == false">{{ "allTasks" |
                            translate }} |
                            {{ "byPriority" | translate }}</label>
                        <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions2" *ngIf="myAllTasks"
                            class="highchartnewcsspie graph-container graph-containeripad">
                        </highcharts-chart>

                        <div class="graph-container graph-containeripad" *ngIf="!myAllTasks">

                            <label *ngIf="!myAllTasks" class="graphLabelPriority mb-0">{{ "taskAssigned" | translate }}
                                |
                                {{ "byPriority" | translate }}</label>
                            <div class="priorityChart">
                                <figure class="highcharts-figure" *ngIf="!myAllTasks">
                                    <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions1"
                                        style="height: 200px">
                                    </highcharts-chart>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row dashboardContainer" *ngIf="advancedSearch">
                    <div class="col p-0 mt-auto">
                        <span class="tableHeader" style="font-size: 14px; color: #495057; font-weight: 600">
                            {{ "advanceSearch" | translate }}
                        </span>
                    </div>
                    <div class="SearchPanel" style="
              margin-top: 0.2% !important;
              width: 100%;
              margin: 0 auto;
              box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
              border: 1px solid rgba(0, 0, 0, 0.050980392156862744);
              height: auto;
            ">
                        <!-- (ngSubmit)="advanceSearchList(advanceSearchForm.value,0,15)" -->
                        <form [formGroup]="advanceSearchForm" class="searchForm" style="height: 100%">
                            <div class="col-md-12" style="height: 100%">
                                <div class="row" style="height: 100%">
                                    <div class="col-4 partDiv">
                                        <div class="form-group row firstDiv">
                                            <label for="staticEmail" class="col-lg-3 col-md-3 col-sm-3 col-form-label"
                                                style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "product" |
                                                translate }}</label>
                                            <div class="col-lg-9 col-md-9 col-sm-9"
                                                style="padding-left:0px;padding-top: 8px;">
                                                <p-multiSelect class="dropdownCss" [options]="product"
                                                    [maxSelectedLabels]="1" (onChange)="onSelectProduct($event)"
                                                    filter="true" formControlName="product"
                                                    defaultLabel="{{ 'choose' | translate }}" name="product"
                                                    optionLabel="name"></p-multiSelect>
                                            </div>
                                            <div class="col-sm-1"></div>
                                        </div>

                                        <div class="form-group row firstDiv">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "project" |
                                                translate }}</label>
                                            <div class="col-sm-9" style="padding-left:0px;padding-top: 8px;">
                                                <p-multiSelect class="dropdownCss" [options]="projects" filter="true"
                                                    [maxSelectedLabels]="1" (onChange)="onSelectProject($event)"
                                                    defaultLabel="{{ 'choose' | translate }}" formControlName="project"
                                                    name="project" optionLabel="name"></p-multiSelect>
                                            </div>
                                            <div class="col-sm-1"></div>
                                        </div>

                                        <div class="form-group row firstDiv">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "createdBy" |
                                                translate }}</label>
                                            <div class="col-sm-9" style="padding-left:0px;padding-top: 8px;">
                                                <p-multiSelect [options]="createdByList" class="dropdownCss"
                                                    filter="true" [maxSelectedLabels]="1"
                                                    (onChange)="onSelectCreatedUser($event)" formControlName="createdBy"
                                                    defaultLabel="{{ 'choose' | translate }}" name="createdBy"
                                                    optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <!-- <div class="col-sm-1"></div> -->
                                        </div>
                                    </div>

                                    <div class="col-4 partDiv">



                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "assignedTo" |
                                                translate }}</label>
                                            <div class="col-sm-9" style="padding-left:0px;padding-top: 8px;">
                                                <p-dropdown (onChange)="onSelectAssignTo($event)"
                                                    *ngIf="!isManager && !isAdmin"
                                                    class="dropdownCss AssignedToDropdown"
                                                    placeholder="{{ 'choose' | translate }}"
                                                    formControlName="assignedTo" name="assignedTo"
                                                    [options]="assignedToArr" optionLabel="name">
                                                </p-dropdown>
                                                <p-multiSelect *ngIf="isManager && isAdmin"
                                                    class="dropdownCss AssignedTo" [options]="assignedToArr"
                                                    [maxSelectedLabels]="1" (onChange)="onSelectUser($event)"
                                                    formControlName="assignedTo" name="assignedTo"
                                                    defaultLabel="{{ 'choose' | translate }}" optionLabel="name"
                                                    [panelStyle]="{ minWidth: '19.5em' }">
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "priority" |
                                                translate }}</label>
                                            <div class="col-sm-3"
                                                style="padding-left:0px;padding-top: 8px;padding-right: 0px;">
                                                <p-multiSelect [options]="priorityArr"
                                                    class="dropdownCss dropdownCss1 priorityStatus"
                                                    (onChange)="onSelectPriority($event)" filter="true"
                                                    [maxSelectedLabels]="1" defaultLabel="{{ 'choose' | translate }}"
                                                    formControlName="priority" name="status" optionLabel="name">
                                                </p-multiSelect>
                                            </div>
                                            <label for="staticEmail" class="col-sm-2 col-form-label" style="padding-right: 0px;
                                            text-align: center;">{{ "status" |
                                                translate }}</label>
                                            <div class="col-sm-4"
                                                style="padding-left:0px;padding-top: 8px;padding-right: 0px;">
                                                <p-multiSelect class="dropdownCss dropdownCss1 status"
                                                    [options]="statuses" filter="true" [maxSelectedLabels]="1"
                                                    defaultLabel="{{ 'choose' | translate }}"
                                                    (onChange)="onSelectStatus($event)" formControlName="status"
                                                    name="status" optionLabel="name"></p-multiSelect>
                                            </div>
                                        </div>
                                        <div class="form-group row customDateRange">
                                            <!-- {{"assignedTo" |translate }} -->
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">
                                                {{'dateRange' | translate}}
                                            </label>
                                            <div class="col-sm-3" style="padding: 0px;padding-top: 8px;">
                                                <input type="text" ngxDaterangepickerMd [(ngModel)]="selected"
                                                    [ngModelOptions]="{ standalone: true }" startKey="start"
                                                    endKey="end" style=" font-size: 12px;width:100%;"
                                                    [linkedCalendars]="true" [alwaysShowCalendars]="true"
                                                    class="pointer" (change)="selectDate($event)"
                                                    placeholder="{{'pleaseSelect' | translate}}" />
                                            </div>
                                            <div class="col-sm-6"
                                                style="padding-left:0px;padding-top: 8px;padding-right: 0px;">



                                                <div style="display: inline-block; vertical-align: top;width: 100%;
                                              margin-left: 5px !important;">
                                                    <button class="button commonCss" id="todaytask"
                                                        pTooltip="{{'Today' | translate}}" tooltipPosition="top"
                                                        id="button0" (click)="btnClickNew(0)">
                                                        {{ 'Today' | translate}}
                                                    </button>
                                                    <button class="button commonCss" id="todaytask"
                                                        pTooltip="{{'wtd' | translate}}" tooltipPosition="top"
                                                        id="button0" (click)="btnClickNew(1)">
                                                        {{ 'wtd' | translate}}
                                                    </button>
                                                    <button class="button commonCss" id="todaytask"
                                                        pTooltip="{{'mtd' | translate}}" tooltipPosition="top"
                                                        id="button0" (click)="btnClickNew(2)">
                                                        {{ 'mtd' | translate}}
                                                    </button>
                                                    <button class="button commonCss" id="todaytask"
                                                        pTooltip="{{'ytd' | translate}}" tooltipPosition="top"
                                                        id="button0" (click)="btnClickNew(3)">
                                                        {{ 'ytd' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-4 lastDiv">


                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "taskName" |
                                                translate }}</label>
                                            <div class="col-sm-9" style="padding-left:0px;padding-top: 8px;">
                                                <input type="text" placeholder="{{ 'enterTaskName' | translate }}"
                                                    (keydown)="onKeydown()" class="enterTaskName pointer"
                                                    formControlName="taskName" name="taskName" id="taskNameReport" />

                                            </div>
                                        </div>


                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-3 col-form-label" style="padding-right:0px;padding-top: 10px;
                                            padding-bottom: 0px;">{{ "taskId" |
                                                translate }}</label>
                                            <div class="col-sm-9" style="padding-left:0px;padding-top: 8px;">
                                                <input type="text" placeholder="{{ 'enterTaskId' | translate }}"
                                                    (keydown)="onKeydown()" class="enterTaskID pointer"
                                                    formControlName="taskId" name="taskId" id="taskIdReport" />

                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <!-- <label for="staticEmail" class="col-sm-3 col-form-label"  style="padding-right:0px;">{{ "taskId" |
                                                translate }}</label> -->
                                            <div class="col-lg-2 col-md-2 col-sm-2"></div>
                                            <div class="col-lg-10 col-md-10 col-sm-10"
                                                style="padding-left:0px;padding-top: 8px;text-align: right;">
                                                <button type="button" class="btn btn-primary btnCssReset"
                                                    (click)="cancelAdvanceSearch()">
                                                    {{'Cancel' | translate}}
                                                </button>
                                                <button type="button" class="btn btn-primary btnCssReset" [ngClass]="{
                        disabled: advanceSearchFormClear == false
                      }" (click)="advanceSearchFormReset()">
                                                    {{ "reset" | translate }}
                                                </button>

                                                <button type="submit" (click)="
                        advanceSearchList(advanceSearchForm.value, 0, 15)
                      " [ngClass]="{
                        disabled: advanceSearchFormSubmit == false
                      }" class="btn btn-primary btnCssSearch">
                                                    {{ "search" | translate }}
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </p-panel>
            <div class="row ecIconRow">
                <div class="col-12">
                    <!-- <i class="pi pi-angle-double-down"
                        [ngClass]="toggle ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down'"
                        style="margin-right: 8px; font-size: 20px;" (click)="togglePanel()"></i> -->
                </div>
            </div>
        </div>

        <div class="table-container mt-4" [ngClass]="{ 'table-containertoggle': toggle == false }">
            <!-- <p>table goes here</p> -->
            <div class="add-task1" style="z-index: 1">
                <div class="ui-inputgroup" style="z-index: 1">
                    <span [ngClass]="toggle ? 'toggleSearch1' : 'toggleSearch2'" class="ui-inputgroup-addon"
                        *ngIf="!watchlistImageNew">
                        <img src="../../assets/img/view.png" style="line-height: 1.25; width: 14px; cursor: pointer"
                            (click)="submitWatchlistDefault()" />
                    </span>

                    <span [ngClass]="toggle ? 'toggleSearch1' : 'toggleSearch2'" class="ui-inputgroup-addon"
                        *ngIf="watchlistImageNew" style="background-color: #fc7242 !important">
                        <img src="../../assets/img/view.png" style="line-height: 1.25; width: 14px; cursor: pointer"
                            (click)="submitWatchlistDefault()" />
                    </span>
                    <span class="searchSpan">


                        <p-multiSelect [(ngModel)]="dtWatchlist" (onChange)="onChangeWathlist($event)"
                            [options]="watchlistDropdown" defaultLabel="{{ 'watchlist' | translate }}" [ngClass]="{
                'highlight-watchlist': watchlistImageNew == true,
                'remove-watchlist-highlight': watchlistImageNew == false
              }" styleClass="widthdropdownProjectNNEWW ">
                            <ng-template let-watchlist pTemplate="item">
                                <span style="
                    width: 84px;
                    margin-right: 15px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  " pTooltip="{{ watchlist.label }}">{{ watchlist.label }}</span>
                                <span style="
                    cursor: pointer;
                    font-size: 9px;
                    cursor: pointer;
                    line-height: 0.9;
                  " *ngIf="watchlist.value.defaultWatchlist"><b translate>default</b></span>
                                <span style="margin-left: 5px; cursor: pointer; float: right" (click)="
                    openWatchlistDefault(
                      watchlist.label,
                      multiselectWa,
                      watchlist
                    )
                  "><i class="pi pi-pencil icon" style="cursor: pointer; margin-top: 4px"></i></span>
                            </ng-template>

                            <p-footer>
                                <span (click)="submitWatchlist()" style="
                    position: absolute;
                    bottom: -21px;
                    height: 20px;
                    left: 0px;
                    text-align: center;
                    width: 101%;
                    background-color: #304254;
                    color: white;
                  " *ngIf="!addWatchlistDropdown" (click)="submitWatchlist()">Apply</span>
                                <span (click)="openWatchlist()" style="
                    position: absolute;
                    bottom: -21px;
                    height: 20px;
                    left: 0px;
                    text-align: center;
                    width: 101%;
                    background-color: #304254;
                    color: white;
                  " *ngIf="addWatchlistDropdown" translate>addWatchlist</span>
                            </p-footer>
                        </p-multiSelect>

                    </span>

                    <span *ngIf="dashboardGlobalSearch" [ngClass]="toggle ? 'toggleSearch1' : 'toggleSearch2'"
                        class="ui-inputgroup-addon"><i class="pi pi-search" style="line-height: 1.25"></i></span>
                    <span class="searchSpan" *ngIf="dashboardGlobalSearch">
                        <!-- <i class="pi pi-search"></i> -->
                        <input placeholder="{{ 'search' | translate }}" class="searchBar" type="text"
                            [(ngModel)]="searchtext" (change)="
                globalFilter($event.target.value, 0, 15);
                changePageToFirst($event);
              " *ngIf="showSearch1 == false" />
                        <input placeholder="{{ 'search' | translate }}" class="searchBar" type="text"
                            [(ngModel)]="searchtext" (change)="
                globalFilter($event.target.value, 0, 15);
                changePageToFirst($event);
              " *ngIf="showSearch1 == true" />
                    </span>
                </div>
                <button *ngIf="!isAdvanceSearch" style="
            background-color: #4f595f;
            color: white;
            border: 1px solid #4f595f;
            border-radius: 12px;
            font-size: 14px;
            font-weight: 500;
            width: 125px;
            height: 26px;
            cursor: pointer;
            z-index: 1;
            margin-left: 5px;
          " (click)="advanceSearch()">
                    {{ "advanceSearch" | translate }}
                </button>
                <button *ngIf="isAdvanceSearch" style="
                background-color: #4f595f;
                color: white;
                border: 1px solid #4f595f;
                border-radius: 12px;
                font-size: 14px;
                font-weight: 500;
                width: 125px;
                height: 26px;
                opacity: 0.5;
                cursor: not-allowed;
                z-index: 1;
                margin-left: 5px;">
                    {{ "advanceSearch" | translate }}
                </button>

                <a class="pointer export addtaskA displayExportImg" pTooltip="{{ 'resetFilter' | translate }}"
                    *ngIf="!advanceSearchExport && !displayflicker " tooltipPosition="top">
                    <img class="exportImg" src="../../assets/img/Filter.png" (click)="clearState()" />
                </a>
                <a class="pointer export addtaskA hiddenExportImgdisabled" pTooltip="{{ 'resetFilter' | translate }}"
                    *ngIf="!advanceSearchExport && displayflicker" tooltipPosition="top">
                    <img class="exportImg" src="../../assets/img/Filter.png" />
                </a>
                <a class="pointer export addtaskA displayExportImg" pTooltip="{{ 'resetFilter' | translate }}"
                    *ngIf="advanceSearchExport && !displayflicker" tooltipPosition="top">
                    <img class="exportImg" src="../../assets/img/Filter.png" (click)="clearStateadvanceSearch()" />
                </a>



                <a class="pointer export addtaskA displayExportImg" pTooltip="{{ 'export' | translate }}"
                    *ngIf="!advanceSearchExport && !displayflicker" tooltipPosition="top">
                    <img class="exportImg" src="../../assets/img/download.png"
                        (click)="getExcelFromApi('dashboardExport')" />
                </a>
                <a class="pointer export addtaskA hiddenExportImgdisabled" pTooltip="{{ 'export' | translate }}"
                    *ngIf="!advanceSearchExport && displayflicker" tooltipPosition="top">
                    <img src="../../assets/img/download.png" />
                </a>
                <a class="pointer export addtaskA displayExportImg" pTooltip="{{ 'export' | translate }}"
                    *ngIf="advanceSearchExport" tooltipPosition="top">
                    <img style="height:22px" class="exportImg" src="../../assets/img/download.png"
                        (click)="getExcelFromApi('advanceExport')" />
                </a>

                <a class="pointer setting addtaskA" *ngIf="showSearch1 == false">
                    <img class="exportImg" src="../../assets/img/settings.png" *ngIf="settingPresent == false" />
                    <img class="exportImg" src="../../assets/img/settings_default.png" *ngIf="settingPresent == true" />
                </a>

                <div class="ecIconRow">
                    <i class="pi pi-angle-double-down" [ngClass]="
              toggle ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down'
            " style="font-size: 30px; cursor: pointer" (click)="togglePanel()"></i>
                </div>
            </div>
        </div>
        <div class="taskViewContainer">
            <!-- *ngIf="advanced_Search_Div" -->
            <p-tabView [ngStyle]="{'display': advanced_Search_Div ? 'block' : 'none'}">
                <div style="
            text-align: center;
            margin-top: 8%;
            font-size: 36px;
            color: #969696;
          ">
                    {{ "advanceSearch" | translate }}
                </div>
            </p-tabView>
            <!-- *ngIf="!advanced_Search_Div" -->
            <p-tabView [ngStyle]="{'display': !advanced_Search_Div ? 'block' : 'none'}"
                (onChange)="onChangeTabs($event)">
                <p-tabPanel leftIcon="pi pi-bars" [selected]="isListView" tooltip="{{ 'listView' | translate }}"
                    tooltipPosition="top">
                    <!-- Tabular View Code -->
                    <p-table class="dashboardNewTbl tableViewDash" #dt [columns]="selectedColumns" [value]="tasks"
                        [(first)]="first" [scrollable]="true" scrollHeight="40vh" [paginator]="true" [rows]="noOfRows"
                        (onPage)="onPageChange($event)" [ngClass]="{
              height1: isMinimized == true,
              height2: isMinimized == false
            }" stateKey="localvalue" (onFilter)="getFilteredData(dt.filteredValue)">
                        <ng-template pTemplate="caption">
                            <div class="settings">
                                <!-- [(ngModel)]="selectedColumns" -->
                                <p-multiSelect class="settingdd" [options]="cols" optionLabel="header"
                                    selectedItemsLabel="{0} columns selected" [style]="{ minWidth: '200px' }">
                                    <p-header>
                                        <div class="makeDefaultBtn">
                                            <button class="btn btn-sm makedefault"
                                                (click)="onClickMakeDefaultSettings()" translate>
                                                makeDefault
                                            </button>
                                        </div>
                                    </p-header>
                                </p-multiSelect>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th [class]="col.class" *ngFor="let col of columns" [pSortableColumn]="col.field"
                                    id="myDIV" [ngClass]="{
                    highlight: col.isHilighted,
                    MultiLangThTd: lang != 'en'
                  }">
                                    <span *ngIf="col.header == 'Task ID'">{{
                                        "taskId" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Task Name'">{{
                                        "taskName" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Task Type'">{{
                                        "taskType" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Product'">{{
                                        "product" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Project Name'">{{
                                        "projectName" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Assigned To'">{{
                                        "assignedTo" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Created By'">{{
                                        "createdBy" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Priority'">{{
                                        "priority" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Status'">{{
                                        "status" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Start Date'">{{
                                        "startDate" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Due Date'">{{
                                        "dueDate" | translate
                                        }}</span>
                                    <span *ngIf="col.header == 'Last Updated'">{{
                                        "lastUpdated" | translate
                                        }}</span>
                                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                                        style="background-color: unset"
                                        ariaLabelDesc="Activate to sort in descending order"
                                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                                </th>
                            </tr>
                            <tr [ngClass]="{
                  toggleTrue: toggle == true,
                  toggleFalse: toggle == false
                }">
                                <th *ngFor="let col of columns; let i = index" [ngSwitch]="col.field"
                                    [ngClass]="{ MultiLangThTd: lang != 'en' }">
                                    <p-multiSelect #taskId *ngSwitchCase="'taskId'" [options]="tableTaskIdData"
                                        class="taskId" [(ngModel)]="dt22" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #taskName *ngSwitchCase="'taskName'" [options]="tableTaskNameData"
                                        class="taskName" [(ngModel)]="dt23" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #taskType *ngSwitchCase="'taskType'" [options]="tableTaskTypeData"
                                        class="taskType" [(ngModel)]="dt24" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #productName *ngSwitchCase="'productName'"
                                        [options]="tableProductList" class="productName" [(ngModel)]="dt25"
                                        defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #projectName *ngSwitchCase="'projectName'"
                                        [options]="tableProjectList" class="projectName" [(ngModel)]="dt26"
                                        defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #userName *ngSwitchCase="'userName'" [options]="tableAssignToList"
                                        class="userName" [(ngModel)]="dt27" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #createdByName *ngSwitchCase="'createdByName'"
                                        [options]="tableCreatedByList" class="createdBy" [(ngModel)]="dt28"
                                        defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #priority *ngSwitchCase="'priority'" [options]="tablePriorityList"
                                        class="priority" [(ngModel)]="dt29" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #status *ngSwitchCase="'status'" [options]="tableStatusList"
                                        class="status" [(ngModel)]="dt30" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #createdDate *ngSwitchCase="'createdDate'"
                                        [options]="tableCreatedDate" class="createdDate" [(ngModel)]="dt31"
                                        defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #dueDate *ngSwitchCase="'dueDate'" [options]="tableDueDate"
                                        class="dueDate" [(ngModel)]="dt32" defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>

                                    <p-multiSelect #updatedDate *ngSwitchCase="'updatedDate'"
                                        [options]="tableUpdatedDate" class="updatedDate" [(ngModel)]="dt33"
                                        defaultLabel="All" (onChange)="
                      dt2.filter($event.value, col.field, 'in');
                      dt.filter($event.value, col.field, 'in');
                      checkClass1(i, col, $event);
                      onChangeTableFilter(col)
                    " dropdownIcon="pi-caret-down">
                                    </p-multiSelect>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-tasks let-columns="columns">
                            <tr [class.overdueRow]="overdue(tasks.dueDate, tasks.status)" class="pointer"
                                [pSelectableRow]="tasks">
                                <td *ngFor="let col of columns" (click)="openViewTaskForm(tasks.taskId)"
                                    [ngClass]="{ MultiLangThTd: lang != 'en' }" [class]="col.class">
                                    <ng-container class="tooltipId" *ngIf="col.field == 'taskId'">
                                        <!-- <div class="top">
                                            <p>Overdue</p>
                                        </div> -->
                                    </ng-container>
                                    <ng-container *ngIf="
                      col.field == 'taskName' && col.field != 'createdDate'
                    ">
                                        <p tooltipPosition="top" pTooltip="{{ tasks[col.field] }}">
                                            {{ tasks[col.field] }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="
                      col.field == 'projectName' && col.field != 'createdDate'
                    ">
                                        <p tooltipPosition="top" pTooltip="{{ tasks[col.field] }}">
                                            {{ tasks[col.field] }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="
                      col.field == 'productName' && col.field != 'createdDate'
                    ">
                                        <p tooltipPosition="top" pTooltip="{{ tasks[col.field] }}">
                                            {{ tasks[col.field] }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="col.field == 'createdDate' || col.field == 'dueDate'">
                                        <p tooltipPosition="top" pTooltip="{{ tasks[col.field] | date: 'MM/dd/yyyy' }}">
                                            {{ tasks[col.field] | date: "MM/dd/yyyy" }}
                                        </p>
                                    </ng-container>
                                    <ng-container *ngIf="
                      col.field == 'updatedDate' && col.field != 'createdDate'
                    ">
                                        {{ tasks[col.field] | date: "MM/dd/yyyy" }}
                                        <i style="float: right" class="pi pi-pencil icon"></i>
                                    </ng-container>
                                    <ng-container *ngIf="
                      col.field !== 'taskName' &&
                      col.field !== 'dueDate' &&
                      col.field !== 'updatedDate' &&
                      col.field != 'createdDate' &&
                      col.field != 'productName' &&
                      col.field != 'projectName' &&
                      col.field != 'priority'
                    ">
                                        {{ tasks[col.field] }}
                                    </ng-container>
                                    <ng-container *ngIf="col.field == 'priority'">
                                        <p tooltipPosition="top" pTooltip="{{ tasks[col.field] }}">
                                            {{ tasks[col.field] }}
                                        </p>
                                    </ng-container>
                                </td>

                            </tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage" let-columns>
                            <tr *ngIf="isTasksNotPresent == true">
                                <td [attr.colspan]="columns.length">No records found</td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="paginatorleft" let-tasks>
                            <p *ngIf="!pageClicked" style="
                  font-size: small;
                  color: #304254;
                  font-weight: 900;
                  margin-left: 140px !important;
                ">
                                {{ "displaying" | translate }}
                                {{ totalRecords < 1 ? 0 : tasks.first + 1 }} {{ "to" | translate }} {{ totalRecords> 0
                                    ? tasks.rows + tasks.first <= totalRecords ? tasks.rows + tasks.first : totalRecords
                                        : 0 }} {{ "of" | translate }} {{ totalRecords ? totalRecords : 0 }} {{ "records"
                                        | translate }} </p>
                        </ng-template>
                    </p-table>
                    <p *ngIf="pageClicked" style="
              font-size: small;
              color: #304254;
              font-weight: 900;
              bottom: 17px;
              position: fixed;
            ">
                        {{ "displaying" | translate }}
                        {{ totalRecords < 1 ? 0 : totalTask + 1 }} {{ "to" | translate }} {{ totalRecords> 0
                            ? noOfRows + totalTask <= totalRecords ? noOfRows + totalTask : totalRecords : 0 }} {{ "of"
                                | translate }} {{ totalRecords ? totalRecords : 0 }} {{ "records" | translate }} </p>
                                <p-paginator class="customePaginator" [rows]="noOfRows" #p [totalRecords]="totalRecords"
                                    (onPageChange)="loadLazy($event)"></p-paginator>
                </p-tabPanel>
                <p-tabPanel leftIcon="pi pi-table" [selected]="isCardView" tooltip="{{ 'cardView' | translate }}"
                    tooltipPosition="top">
                    <!-- Card View Code -->
                    <div>
                        <div class="cardView-container">
                            <p-table class="dashboardNewTbl" #dt2 [columns]="cols" [value]="tasks"
                                [(first)]="firstCardView" (onPage)="onPageChangeCardView($event)" [scrollable]="true"
                                scrollHeight="40vh" [paginator]="true" [rows]="noOfCards" [ngClass]="{
                  height1: isMinimized == true,
                  height2: isMinimized == false
                }" stateKey="localvalue" (onFilter)="getFilteredData(dt2.filteredValue)">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th [class]="col.class" *ngFor="let col of columns; let i = index"
                                            [pSortableColumn]="col.field" id="myDIV"
                                            [ngClass]="{ highlight: col.isHilighted }"
                                            [ngClass]="{ 'd-none': col.field == 'action' }">
                                            <!-- {{col.header}} -->
                                            <span *ngIf="col.header == 'Task ID'">{{
                                                "taskId" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Task Name'">{{
                                                "taskName" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Task Type'">{{
                                                "taskType" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Product'">{{
                                                "product" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Project Name'">{{
                                                "projectName" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Assigned To'">{{
                                                "assignedTo" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Created By'">{{
                                                "createdBy" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Priority'">{{
                                                "priority" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Status'">{{
                                                "status" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Start Date'">{{
                                                "startDate" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Due Date'">{{
                                                "dueDate" | translate
                                                }}</span>
                                            <span *ngIf="col.header == 'Last Updated'">{{
                                                "lastUpdated" | translate
                                                }}</span>

                                    <tr [ngClass]="{
                          toggleTrue: toggle == true,
                          toggleFalse: toggle == false
                        }">
                                        <!-- (click)="$event.stopPropagation()" -->
                                        <th [ngSwitch]="col.field" (click)="$event.stopPropagation()">
                                            <p-multiSelect #taskId *ngSwitchCase="'taskId'" [options]="tableTaskIdData"
                                                class="taskId" selectedItemsLabel="{0} selected" [(ngModel)]="dt22"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #taskName *ngSwitchCase="'taskName'"
                                                [options]="tableTaskNameData" class="taskName"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt23"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #taskType *ngSwitchCase="'taskType'"
                                                [options]="tableTaskTypeData" class="taskType"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt24"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #productName *ngSwitchCase="'productName'"
                                                [options]="tableProductList" class="productName"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt25"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #projectName *ngSwitchCase="'projectName'"
                                                [options]="tableProjectList" class="projectName"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt26"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #userName *ngSwitchCase="'userName'"
                                                [options]="tableAssignToList" class="userName"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt27"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #createdByName *ngSwitchCase="'createdByName'"
                                                [options]="tableCreatedByList" class="createdBy"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt28"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #priority *ngSwitchCase="'priority'"
                                                [options]="tablePriorityList" class="priority"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt29"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #status *ngSwitchCase="'status'" [options]="tableStatusList"
                                                class="status" selectedItemsLabel="{0} selected" [(ngModel)]="dt30"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #createdDate *ngSwitchCase="'createdDate'"
                                                [options]="tableCreatedDate" class="createdDate"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt31"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #dueDate *ngSwitchCase="'dueDate'" [options]="tableDueDate"
                                                class="dueDate" selectedItemsLabel="{0} selected" [(ngModel)]="dt32"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>

                                            <p-multiSelect #updatedDate *ngSwitchCase="'updatedDate'"
                                                [options]="tableUpdatedDate" class="updatedDate"
                                                selectedItemsLabel="{0} selected" [(ngModel)]="dt33"
                                                maxSelectedLabels="0" defaultLabel="All" (onChange)="
                              dt.filter($event.value, col.field, 'in');
                              dt2.filter($event.value, col.field, 'in');
                              checkClass1(i, col, $event);
                              onChangeTableFilter(col)
                            " dropdownIcon="pi-caret-down">
                                            </p-multiSelect>
                                        </th>
                                    </tr>
                                    </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-tasks let-columns="columns">
                                    <div class="row" [ngStyle]="{ margin: !toggle ? '6px' : '0px' }">
                                        <tr [class.overdueRow]="overdue(tasks.dueDate, tasks.status)"
                                            [pSelectableRow]="tasks" class="col p-2">
                                            <p-card>
                                                <div class="card-container" (click)="openViewTaskForm(tasks.taskId)">
                                                    <!-- Task Name & Task Id-->
                                                    <td class="task-name">
                                                        <div class="row">
                                                            <div class="col-md-8 col-lg-8 col-sm-8 task-name-labelUpdated"
                                                                tooltipPosition="top" pTooltip="{{ tasks.taskName }}">
                                                                {{ tasks.taskName }}
                                                            </div>
                                                            <div
                                                                class="col-md-3 col-lg-3 col-sm-3 p-0 text-right taskId-label">
                                                                {{ tasks.taskId }}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <!-- Project Name & Priority -->
                                                    <td class="project-name">
                                                        <div class="row row m-0 p-0 col-lg-12 col-md-12 col-sm-12">
                                                            <div class="col-md-9 col-sm-9 col-lg-9 p-0 font-size task-name-label"
                                                                style="height: 18px" tooltipPosition="top"
                                                                pTooltip="{{ tasks.projectName }}">
                                                                {{ tasks.projectName }}
                                                            </div>
                                                            <div class="priority" tooltipPosition="top" pTooltip="{{
                                  !tasks.priority
                                    ? 'NONE'
                                    : (tasks.priority | uppercase)
                                }}" [ngClass]="{
                                  critical: tasks.priority == 'Critical',
                                  high: tasks.priority == 'High',
                                  medium: tasks.priority == 'Medium',
                                  low: tasks.priority == 'Low'
                                }">
                                                                {{
                                                                !tasks.priority
                                                                ? "NONE"
                                                                : (tasks.priority | uppercase)
                                                                }}
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <!-- Product Name , comment , history , attachment -->
                                                    <td class="product-name">
                                                        <div class="row m-0 p-0 col-lg-12 col-md-12 col-sm-12">
                                                            <div class="col-md-7 col-lg-7 col-sm-7 p-0 font-size task-name-label"
                                                                style="height: 18px" tooltipPosition="top"
                                                                pTooltip="{{ tasks.productName }}">
                                                                {{ tasks.productName }}
                                                            </div>
                                                            <div class="col-lg-5 col-sm-5 col-md-5 p-0 text-right"
                                                                style="
                                  position: absolute;
                                  right: 4px;
                                  bottom: 12px;
                                ">

                                                                <span class="mr-2" *ngIf="tasks.comments"><i
                                                                        class="fas fa-comment" [ngStyle]="{
                                      color: tasks?.comments
                                        ? '#848484'
                                        : '#848484'
                                    }"></i></span>


                                                                <span class="mr-2" *ngIf="
                                    tasks.attachments &&
                                    tasks.attachments.length != 0
                                  "><i class="fas fa-paperclip" [ngStyle]="{
                                      color: tasks?.attachments
                                        ? '#848484'
                                        : '#848484b8'
                                    }"></i></span>
                                                                <!-- Created by -->
                                                                <span class="created-by rounded-circle" pTooltip="{{ 'createdBy' | translate }} {{
                                    tasks.createdByName
                                  }}">{{
                                                                    writtenInitials(tasks.createdByName)
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <!-- Due Date , Assigned to & Status -->
                                                    <td class="assignedto-name" tooltipPosition="top">
                                                        <div class="row m-0">
                                                            <div
                                                                class="col-md-7 col-lg-7 col-sm-7 p-0 font-size task-name-label">
                                                            </div>
                                                            <div class="col-lg-5 col-sm-5 col-md-5 p-0 text-right"
                                                                style="bottom: 9px; right: 4px">
                                                                <span class="due-date" pTooltip="{{ tasks.dueDate }}"
                                                                    [ngClass]="{
                                    'task-due': overdue(
                                      tasks.dueDate,
                                      tasks.status
                                    )
                                  }"><i class="pi pi-calendar icon"></i>{{ tasks.dueDate | date: "MM/dd" }}</span>
                                                            </div>
                                                        </div>
                                                        <div style="margin-top: -11px">
                                                            <span class="assigned-to font-size"
                                                                pTooltip="{{ 'assignedTo' | translate }}">{{
                                                                tasks.assignedToName == null ||
                                                                tasks.assignedToName == ""
                                                                ? "--"
                                                                : tasks.assignedToName
                                                                }}</span>

                                                            <span class="task-status font-size">{{
                                                                tasks.status ? tasks.status : "--"
                                                                }}</span>
                                                        </div>
                                                    </td>
                                                </div>
                                            </p-card>
                                        </tr>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="emptymessage" let-columns>
                                    <tr *ngIf="isTasksNotPresent == true">
                                        <td class="no-records">No records found!</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="paginatorleft" let-tasks>
                                    <p *ngIf="!pageClicked" style="
                      font-size: small;
                      color: #304254;
                      font-weight: 900;
                      margin-left: 140px !important;
                    ">
                                        {{ "displaying" | translate }}
                                        {{ totalRecords < 1 ? 0 : tasks.first + 1 }} {{ "to" | translate }} {{
                                            totalRecords> 0
                                            ? tasks.rows + tasks.first <= totalRecords ? tasks.rows + tasks.first :
                                                totalRecords : 0 }} {{ "of" | translate }} {{ totalRecords ?
                                                totalRecords : 0 }} {{ "records" | translate }} </p>
                                </ng-template>
                            </p-table>
                            <p *ngIf="pageClicked" style="
                  font-size: small;
                  color: #304254;
                  font-weight: 900;
                  bottom: 17px;
                  position: fixed;
                ">
                                {{ "displaying" | translate }}
                                {{ totalRecords < 1 ? 0 : totalTask + 1 }} {{ "to" | translate }} {{ totalRecords> 0
                                    ? noOfRows + totalTask <= totalRecords ? noOfRows + totalTask : totalRecords : 0 }}
                                        {{ "of" | translate }} {{ totalRecords ? totalRecords : 0 }} {{ "records" |
                                        translate }} </p>
                                        <p-paginator class="customePaginator" [rows]="noOfRows" #p1
                                            [totalRecords]="totalRecords" (onPageChange)="loadLazy($event)">
                                        </p-paginator>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>

        <p-dialog header="Add Watchlist" [(visible)]="displayWatchlist" [modal]="true"
            styleClass="overlayDialogWatchlistDashboard" [style]="{ width: '400px', height: '218px' }"
            [draggable]="false">
            <!-- appendTo="body" -->
            <form [formGroup]="watchlistForm" (ngSubmit)="addWatchlist(watchlistForm.value)">
                <div style="margin-left: 0px; display: inline-block; margin-top: 20px">
                    <div class="ui-g-12 ui-md-12" style="display: inline-block; margin-bottom: 20px">
                        <p class="commonhead assignLabels widthTitle"
                            style="font-size: 14px; display: inline-block; width: 110px">
                            Watchlist Name
                        </p>
                        <div class="ui-inputgroup" style="display: inline-block">


                            <input type="text" pInputText placeholder="Add Watchlist Name"
                                style="height: 28px; width: 206px" formControlName="watchlistName"
                                (input)="checkIfNameExist(watchlistForm.value.watchlistName)" />
                        </div>
                        <span *ngIf="watchlistAlready" class="invalid-feedback"
                            style="font-size: 10px; margin-left: 111px">
                            ( Watchlist already Exist )
                        </span>
                    </div>
                    <div style="margin-top: 25px; margin-left: 7px">
                        <p class="commonhead assignLabels widthTitle"
                            style="font-size: 14px; display: inline-block; width: 110px">
                            Set as Default
                        </p>
                        <p-checkbox formControlName="default" name="privateTask" binary="true" class="watchCheckboc"
                            class="watchlistClass" styleClass="privateSpanOccurenceb"></p-checkbox>
                    </div>
                </div>
                <div style="margin-left: 172px">
                    <button type="button" class="btn btn-default submitBtns" (click)="closeWatchlist()"
                        style="position: absolute; right: 162px; bottom: 11px">
                        CANCEL
                    </button>

                    <input type="submit" class="btn btn-default submitBtn" value="ADD"
                        [disabled]="!watchlistForm.valid || watchlistAlready" style="
              margin-right: 15px;
              position: absolute;
              right: 48px;
              cursor: pointer;
              bottom: 11px;
            " />
                </div>
            </form>
        </p-dialog>

        <p-dialog header="Default Watchlist" [(visible)]="displayWatchlistDefault" [modal]="true"
            styleClass="overlayDialogWatchlistDashboard" [style]="{ width: '400px', height: '218px' }"
            [draggable]="false">
            <!-- appendTo="body" -->
            <form [formGroup]="watchlistFormDefault" (ngSubmit)="setAsDefault(watchlistFormDefault.value)">
                <div style="margin-left: 0px; display: inline-block; margin-top: 20px">
                    <div class="ui-g-12 ui-md-12" style="display: inline-block; margin-bottom: 20px">
                        <p class="commonhead assignLabels widthTitle"
                            style="font-size: 14px; display: inline-block; width: 110px">
                            Watchlist Name
                        </p>
                        <div class="ui-inputgroup" style="display: inline-block">
                            <input type="text" pInputText placeholder="Add Watchlist Name"
                                style="height: 28px; width: 206px" formControlName="watchlistName" readonly />
                        </div>
                    </div>
                    <div style="margin-top: 25px; margin-left: 7px">
                        <p class="commonhead assignLabels widthTitle"
                            style="font-size: 14px; display: inline-block; width: 110px">
                            Set as Default
                        </p>
                        <p-checkbox formControlName="default" name="privateTask" binary="true" class="watchCheckboc"
                            class="watchlistClass" styleClass="privateSpanOccurenceb"></p-checkbox>
                    </div>
                </div>
                <div style="margin-left: 172px">
                    <button type="button" class="btn btn-default submitBtns" (click)="closeWatchlistDefault()"
                        style="position: absolute; right: 261px; bottom: 11px">
                        CANCEL
                    </button>

                    <button type="button" class="btn btn-default submitBtns"
                        (click)="deleteWatchlist(watchlistFormDefault.value)"
                        style="position: absolute; right: 162px; bottom: 11px">
                        DELETE
                    </button>

                    <input type="submit" class="btn btn-default submitBtn" value="UPDATE" style="
              margin-right: 15px;
              position: absolute;
              right: 48px;
              cursor: pointer;
              bottom: 11px;
            " />
                </div>
            </form>
        </p-dialog>
    </div>
</div>

<p-sidebar class="taketoursidebar" [(visible)]="displayTakeTour" position="center" [ngClass]="{
    zIndex: displayTakeTour == true,
    zindex1: displayTakeTour == false
  }" [dismissible]="false">
    <div *ngIf="tourImages != null">
        <div class="carousel" *carousel="let url; from: tourImages; let ctrl = controller">
            <img class="imgs" id="{{ url }}" src="{{ url }}" />
            <label (click)="ctrl.prev()" class="prevLabel" *ngIf="
          !url.includes(tourImages[0]) &&
          !url.includes(tourImages[tourImages.length - 1])
        ">
                Back
            </label>
            <span class="nav-dots" *ngIf="!url.includes(tourImages[tourImages.length - 1])">
                <label *ngFor="let img of tourImages; let i = index">
                    <label class="nav-dot" id="{{ i }}" [ngClass]="{ checked: img == url }" *ngIf="i < 20"></label>
                </label>
            </span>
            <label class="nextLabel" (click)="nextImage(); ctrl.next()"
                *ngIf="!url.includes(tourImages[tourImages.length - 1])">Next></label>
            <label *ngIf="!url.includes(tourImages[tourImages.length - 1])" (click)="getStarted(); ctrl.end()"
                class="nextLabel" style="right: 2% !important">Skip>></label>
            <label *ngIf="url.includes(tourImages[tourImages.length - 1])" (click)="getStarted(); ctrl.end()"
                class="nextLabel" style="right: 2% !important">Get Started>></label>
            <p-checkbox class="dontshowRadio" [readonly]="isDontShowAgain" *ngIf="
          isDontShowAgain == false &&
          (url.includes(tourImages[0]) ||
            url.includes(tourImages[tourImages.length - 1]))
        " [(ngModel)]="dontshowagain" binary="true" label="Don't show me this again"
                (onChange)="onChangeDontShowTour(dontshowagain)"></p-checkbox>
        </div>
    </div>
</p-sidebar>



<p-dialog header="Export" [(visible)]="isExportData" [modal]="true" [closable]="false" styleClass="exportClass"
    [style]="{ width: '480px', height: '300px' }" [draggable]="false">
    <!-- appendTo="body" -->
    <div class="row" style="width:auto;margin: 0px;">
        <!-- <p-card class="plainExport">hii</p-card> -->

        <div>
            <div style="position: relative;display: inline-block;">
                <img src="assets/img/dynamicExport.svg" alt=""
                    style="filter:drop-shadow(1px 2px 2px gray);margin:10px 20px 10px 19px">
                <p-radioButton name="groupname" value="val1" [(ngModel)]="selectedValue"
                    style="position: absolute;top: 9px;right: 20px;"
                    (onClick)="onClickImg('additionalExport')"></p-radioButton>
            </div>
            <div style="position: relative;display: inline-block;">
                <img src="assets/img/plainExport.svg" alt=""
                    style="filter:drop-shadow(1px 2px 2px gray);margin:10px 10px 10px 10px">
                <p-radioButton name="groupname" value="val2" [(ngModel)]="selectedValue"
                    style="position: absolute;top: 9px;right: 10px;"
                    (onClick)="onClickImg('plainExport')"></p-radioButton>
            </div>

        </div>


        <div class="col-md-12" *ngIf="displayDropdown"><p-multiSelect class="exportDropdown" [options]="templateList"
                [maxSelectedLabels]="1" (onChange)="onChangeTaskType($event)" [(ngModel)]="templateListSelected"
                filter="true" optionLabel="name"></p-multiSelect></div>

        <div class="col-md-12" *ngIf="!displayDropdown" style="display: block;height: 26px;"></div>

        <div class="col-md-12 exportBtnCss">
            <button type="button" class="btn btn-primary btnCssExport" *ngIf="!advanceExportData"
                (click)="dashboardExportExcelDownload()" [disabled]="!selectedDropdown">
                {{ "Export" | translate }}
            </button>
            <button type="button" *ngIf="advanceExportData" class="btn btn-primary btnCssExport"
                (click)="advanceExportExcelDownload()" [disabled]="!selectedDropdown">
                {{ "Export" | translate }}
            </button>
            <button type="button" class="btn btn-primary btnCssReset" (click)="ExportCancel()">
                {{'Cancel' | translate}}
            </button>

        </div>

    </div>
</p-dialog>