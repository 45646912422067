import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'my-taskEasy';
  showHead: boolean=false;
  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
  
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          if (event['url'].includes('/login') || event['url']=='/' || event['url'].includes('/#/login') || event['url'].includes('/verify') || event['url'].includes('/password-expired') || event['url'].includes('/reset-password')) {
            this.showHead = false;
           localStorage.setItem('showHeader','false')
          } else {
            this.showHead = true;
            localStorage.setItem('showHeader','true')
          }
        }
      });
    }
}
