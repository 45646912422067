import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  fetchUnitAmount(req){
    return this.http.get<any>(environment.paymentUrl+'customer/fetchPerSubscriptionAmount?subcription='+req);
  }

  makePayment(req){
    return this.http.post<any>(environment.paymentUrl+"customer/createCustomerSubscription",req);
  }

  fetchInvoicesInfoByCustId(req){
    return this.http.get<any>(environment.paymentUrl+'invoice/fetchInvoicesInfoByCustId?custId='+req);
  }

  fetchInvoiceAndRecByCustdId(req){
    return this.http.get<any>(environment.paymentUrl+'invoice/getInvoicesAndReceiptInfoByCustId?custId='+req);
  }

  fetchCustomerById(req){
    return this.http.get<any>(environment.paymentUrl+'customer/fetchCustomerById?customerId='+req);
  }

  fetchCustomerIdByBusiness(req){
    return this.http.get<any>(environment.paymentUrl+'customer/fetchCustomerIdByBusinessId?businessId='+req);
  }

  updatePayment(req){
    return this.http.post<any>(environment.paymentUrl+'customer/updateSubscriptionQtyAndPayment',req);
  }

  findPromocodeById(req){
    return  this.http.get<any>(environment.northstar_Loginurl+'promoCode/findPromoCodeByBusinessId?businessId='+req)
  }

  findPromocodeByIdV1(req){
    return  this.http.get<any>(environment.northstar_Loginurl+'promoCode/findPromoCodeByBusinessIdV1?businessId='+btoa(req))
  }

  getScreteKey(){
    return this.http.get<any>(environment.paymentUrl+'config/getSecretKey');
  }

  retriveUpcomingInvoice(req){
     return this.http.post<any>(environment.paymentUrl+'customer/retriveUpcomingInvoice',req);
   }
  
   reduceSubscription(req) {
    return this.http.post<any>(environment.paymentUrl+'customer/reduceSubscription',req);
  }

  findPromocodeDetailsByBusinessId(req){
    return this.http.get<any>(environment.northstar_Loginurl+"promoCode/findPromoCodeDetailsByBusinessId?businessId="+req);
  }
}
