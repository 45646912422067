import { Component, OnInit, ViewChild, PipeTransform, Pipe,HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LoginService } from '../../../services/login.service';
import { environment } from '../../../../environments/environment';
import { RatingModule } from 'primeng/rating';
import { SharedService } from '../../../services/shared.service';
import { OverlayPanel } from 'primeng/overlaypanel/public_api';
import { TaskDataService } from '../../../services/task-data.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDeviceService } from 'src/app/services/user-device.service';
import { UserIdleService } from 'angular-user-idle';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  todayDate = new Date();
  displayFP = false;
  displayFPS = false;
  displayFeedback = false;
  forgetPassForm: any;
  isFileType: boolean;
  fileSize: boolean;
  profileImg: any;
  profileSrc: any;
  msg = "";
  inputText = "string";
  msgCheck = false;
  userData: any;
  email:any;
  helpText: any;
  feedbackValue: number;
  userPData: any;
  breadCrumb: any;
  interactionUrl: any;
  bussinessData: any;
  bussinessLogo: string;
  index: number = -1;
  feedbackForm: any;
  feedbackRatePoint = "";
  displayFeedbackThanks = false;
  iframeArray: HTMLIFrameElement[];
  displayWhatNew: any;
  tabSelected: any=true;
  tabSelected4: boolean=false;
  tabSelected3: boolean=false;;
  tabSelected2: boolean=false;;
  adminNinedot = false;
  @ViewChild('op1') op: OverlayPanel;
  notificaitonList = [];
  helpMenuData = [];
  arrayNotEmpty: boolean;
  arrayNotEmptyNot: boolean;
  displayAccount = false;
  displayAbout: boolean;
  notificaitonCount: any;
  notifcationCountCondition = false;
  notificaitonLastSeen: any;
  displayContactSupport: boolean;
  userEmailId: boolean;
  userDataCheck = false;
  isadmin: any;
  notificaitonListdata = false;
  userDatas: any;
  position: string;
  createTicketSidebar: boolean = false;
  createTicketForm: FormGroup;
  createForm: FormGroup;
  priority: { name: string; code: string; }[];
  supportContactNo: any;
  supportMailId: any;
  files: any = [];
  /* help */
  newArrayHelp:any=[];
  faqResponse:any=[];
  faqResponseNew:any=[];
  guideResponseNew:any=[];
  CopyfaqResponse:any=[];
  guideResponse:any=[];
  videoResponse:any=[];
  highlightCheck="";
  highlightCheckHelp="";
  showDetails: any;
  showDetailsHelp:any;
  checkValue=false;
  checkValueHelp=false;
  isLoaded: boolean=false;
  displaydetailHelp: boolean;
  newValueCheck: any;
  CopyguideResponse:any=[];
  CopyvideoResponse:any=[];
  CopyfaqResponseNew:any=[];
  CopyGuideResNew:any=[];
  detailformDataImg:any;
  detailformData: any;
  moduleNameData:any;
  textNameData: any;
  sameLenghtfaq=false;
  videoLink: any;
  unsafeLink:any;
  videoNameData: any;
  videoModalHelp: boolean;
  expandIcon="pi pi-plus-circle";
  collapseIcons="pi pi-minus-circle";
  videoResponseNew:any=[];
  CopyVideoResNew:any=[];
  guideResponseNewData:any=[];
  highlightCheckHelpFinal:any;
  highlightCheckHelpHeading: string;
 highlightCheckHelpHeadingNew:any;
 showDetailsHelpHeadingFinal:any;
 showDetailsHelpHeadingNew:any;
 showDetailsHelpHeading: number;
 tabindex:any=0;
 lang:any;
 selectedLanguage:any;
 //for storing login details
 deviceInfo: any=[];
  constructor(
    private TaskService: TaskDataService,
    private router: Router,
    private fb: FormBuilder,
    private base: LoginService,
    public messageService: MessageService,
    private sanitizer: DomSanitizer,
    private ngxService: NgxUiLoaderService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private data: DataSharingService,
    private translateservice: TranslateService,
    public deviceService:UserDeviceService,
    private loginService:LoginService,
    private userIdle: UserIdleService,
    private ActivatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    if(localStorage.getItem('showHeader')=='true' && localStorage.getItem('auth_token')){
      this.languageDropdown=[];
      if(localStorage.getItem('languageDropdown')==null && localStorage.getItem('languageImage') == null){
        this.getAllLanguages();
      }
      else{
        this.languageDropdown=[];
        this.languageDropdown=JSON.parse(localStorage.getItem('languageDropdown'));
        this.languageImage=JSON.parse(localStorage.getItem('languageImage'));
        if(localStorage.getItem('selectedLanguage')==null){
          this.lang='en';
          localStorage.setItem('selectedLanguage','en');
          this.selectedLanguage={name:'English', code:'en'};
          this.getLabels();
        }
        else{
          this.lang=localStorage.getItem('selectedLanguage');
          if(this.lang=='en'){
            this.selectedLanguage={name:'English', code:'en'};
            if(localStorage.getItem('englishLabels')==null){
              this.getLabels();
            }
            else{
              this.translateservice.setTranslation(this.lang,JSON.parse(localStorage.getItem('englishLabels')));
              this.translateservice.use('en');
            }
          }
          else if(this.lang=='es'){
            this.selectedLanguage={name:'Spanish',code:'es'};
            if(localStorage.getItem('SpanishLabels')==null){
              this.getLabels();
            }
            else{
              this.translateservice.setTranslation(this.lang,JSON.parse(localStorage.getItem('SpanishLabels')));
              this.translateservice.use('es');
            }
          }
          else if(this.lang=='pr'){
            this.selectedLanguage={name:'Portuguese',code:'pr'};
            if(localStorage.getItem('portugueseLabels')==null){
              this.getLabels();
            }
            else{
              this.translateservice.setTranslation('pr',JSON.parse(localStorage.getItem('portugueseLabels')));
              this.translateservice.setDefaultLang('pr');
            }
          }
          else if(this.lang=='hi'){
            this.selectedLanguage={name:'Hindi',code:'hi'};
            if(localStorage.getItem('hindiLabels')==null){
              this.getLabels();
            }
            else{
              this.translateservice.setTranslation('hi',JSON.parse(localStorage.getItem('hindiLabels')));
              this.translateservice.setDefaultLang('hi');
            }
          }
          else if(this.lang=='fr'){
            this.selectedLanguage={name:'French',code:'fr'};
            if(localStorage.getItem('frenchLabels')==null){
              this.getLabels();
            }
            else{
              this.translateservice.setTranslation('fr',JSON.parse(localStorage.getItem('frenchLabels')));
              this.translateservice.setDefaultLang('fr');
            }
          }
        }
      }
      
      //fetch device login info starts
      this.deviceService.storeIP();
      this.deviceService.epicFunction();
      this.deviceService.shareDeviceDetails.subscribe(res=> {
        this.deviceInfo = res;
      })
      //fetch device login info ends

      this.sharedService.sharedMessage.subscribe(message => this.breadCrumb = message);
      this.TaskService.getUserDetails().subscribe(res => {
          if (res.result == "Success") {
            this.userData = res.responseData;
            localStorage.setItem("userData",JSON.stringify(this.userData));
            this.userDatas = JSON.parse(localStorage.getItem('userData'));
            if (this.userDatas) {
              this.isadmin = this.userDatas.admin;
            }
            this.email=this.userData.emailId;
            this.userDataCheck = true;
            this.isadmin = this.userData.admin;
            this.getAllNotificationCount();
            this.getProductTourImages();
            this.getAllNotificationLastseen();
          }
        });
      let home;
      this.translateservice.get('home').subscribe(txt=>{
        home=txt;
      })
      if (this.router.url.indexOf('/dashboard') > -1) {
        this.breadCrumb = [{ "label": home, "url": environment.mytaskeasy_url + 'dashboard' }];
      }
      
    

      if (this.router.url.indexOf('/products') > -1) {
        this.adminNinedot = true;
      } 
      else if (this.router.url.indexOf('/projects') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } 
        else {
          this.adminNinedot = false;
        }
      } 
      else if (this.router.url.indexOf('/users') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } 
        else {
          this.adminNinedot = false;
        }
      } 
      else if (this.router.url.indexOf('/task-status') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } 
        else {
          this.adminNinedot = false;
        }
      } 
      else if (this.router.url.indexOf('/admin-list') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } 
        else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/task-type') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/profile') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/user-group') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/user-role') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/configuration') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      }
      else if (this.router.url.indexOf('/audit-log') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      } 
      else if (this.router.url.indexOf('/payments') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      } 
      else if (this.router.url.indexOf('/invoices') > -1) {
        if (this.isadmin) {
          this.adminNinedot = true;
        } else {
          this.adminNinedot = false;
        }
      } 
      else {
        this.adminNinedot = false;
      }
      this.userPData = JSON.parse(localStorage.getItem('userPData'));
      setTimeout(() => {
        localStorage.setItem('breadCrumb', JSON.stringify(this.breadCrumb));
      }, 1000);
    this.base.getBussinessData().subscribe(
      res => {
        if (res.status.statusMsg == 'SUCCESS') {
          this.bussinessData = res['data'];
          if (this.bussinessData['userImage']) {
            this.profileSrc = "data:image/png;base64," + this.bussinessData['userImage'];
          }
          if (this.bussinessData['businessImage']) {
            this.bussinessLogo = "data:image/png;base64," + this.bussinessData['businessImage'];
          }
          this.data.businessImg.subscribe(res=>{
            if((res!=null) && res.includes('data:image/png;base64')){
              this.bussinessLogo=res;
            }
          })
          localStorage.setItem('businessId',this.bussinessData['businessId']);
          localStorage.setItem('role',this.bussinessData['userRole']);
          this.createTicketForm.patchValue({
            businessId: this.bussinessData['businessId'],
            businessName: this.bussinessData['businessName'],
            emailId: this.userData['emailId']
          });
        }
      }
    );

    this.base.getSupportToken()
      .subscribe(res => {
        if (res['status']['statusMsg'] == 'SUCCESS') {
          localStorage.setItem('supportToken', res['data']['token']);
          this.supportContactNo = res['data']['phone'];
          this.supportMailId = res['data']['email'];
        }
      }),
      err => {
      }
      
      this.getTextValues();
      this.tabSelected=true;
      this.tabSelected2=false;
      this.tabSelected3=false;
      this.tabSelected4=false;
     }
     else if(this.ActivatedRoute.snapshot.queryParams && this.ActivatedRoute.snapshot.queryParams['taskId']){
      localStorage.setItem('taskId', this.ActivatedRoute.snapshot.queryParams['taskId']);
      if(!localStorage.getItem('auth_token')){
        this.router.navigateByUrl('/');
      }
    }
    this.forgetPassForm = this.fb.group({
      file: ['', [Validators.required]]
    });
    this.createTicketForm = new FormGroup({
      businessId: new FormControl('', [Validators.required]),
      businessName: new FormControl('', [Validators.required]),
      emailId: new FormControl(''),
      subject: new FormControl(''),
      product: new FormControl(''),
      category: new FormControl(''),
      priority: new FormControl(''),
      description: new FormControl('', [Validators.required]),
      file: new FormControl(''),
    });
    this.priority = [
      { name: 'Critical', code: 'Critical' },
      { name: 'High', code: 'High' },
      { name: 'Low', code: 'Low' },
      { name: 'Medium', code: 'Medium' }
    ];
    this.feedbackForm = this.fb.group({
      feedbackText: ['', [Validators.required]],
      feedbackRate: ['', [Validators.required]]
    });

     //Start watching for user inactivity.
     this.userIdle.resetTimer();
     this.userIdle.startWatching();
   
     // Start watching when user idle is starting.
     this.userIdle.onTimerStart().subscribe(/* count => console.log(count) */);
    
     // Start watch when time is up.
     this.userIdle.onTimeout().subscribe(() => this.logOut());
  }

  getLabels(){
    this.TaskService.getMultiLangLabels().subscribe(response=>{
      let languageLabels:any=[];
      let englishLabels:any;
      let SpanishLabels:any;
      let hindiLabels:any;
      let frenchLabels:any;
      let portugueseLabels:any;
      languageLabels=response.responseData;
          for(let i=0;i<languageLabels.length;i++){
            if(languageLabels[i].language=='English'){
              englishLabels= languageLabels[i].labels;
              localStorage.setItem('englishLabels',JSON.stringify(englishLabels));
            }
            if(languageLabels[i].language=='Spanish'){
              SpanishLabels= languageLabels[i].labels;
              localStorage.setItem('SpanishLabels',JSON.stringify(SpanishLabels));
            }
            if(languageLabels[i].language=='Hindi'){
              hindiLabels= languageLabels[i].labels;
              localStorage.setItem('hindiLabels',JSON.stringify(hindiLabels));
            }
            if(languageLabels[i].language=='French'){
              frenchLabels= languageLabels[i].labels;
              localStorage.setItem('frenchLabels',JSON.stringify(frenchLabels));
            }
            if(languageLabels[i].language=='Portuguese'){
              portugueseLabels= languageLabels[i].labels;
              localStorage.setItem('portugueseLabels',JSON.stringify(portugueseLabels));
            }
          }

          if(this.lang=='en'){
            this.translateservice.currentLang='en';
            this.translateservice.setTranslation('en', englishLabels);
            this.translateservice.setDefaultLang('en');
          }
          else if(this.lang=='es'){
            this.translateservice.currentLang='es';
            this.translateservice.setTranslation('es', SpanishLabels);
            this.translateservice.setDefaultLang('es');
          }
          else if(this.lang=='pr'){
            this.translateservice.currentLang='pr';
            this.translateservice.setTranslation('pr',portugueseLabels);
            this.translateservice.setDefaultLang('pr');
          }
          else if(this.lang=='hi'){
            this.translateservice.currentLang='hi';
            this.translateservice.setTranslation('hi',hindiLabels);
            this.translateservice.setDefaultLang('hi');
          }
          else if(this.lang=='fr'){
            this.translateservice.currentLang='fr';
            this.translateservice.setTranslation('fr',frenchLabels);
            this.translateservice.setDefaultLang('fr');
          }
    })
  }

  createTicket(data) {
    this.ngxService.start();

    this.base.getSupportToken()
      .subscribe(res => {
        if (res['status']['statusMsg'] == 'SUCCESS') {
          localStorage.setItem('supportToken', res['data']['token']);
          const formData = new FormData();
          let userFullName = this.userPData.firstname + " " + this.userPData.lastname;

          if (data.priority = '' || data.priority == null || data.priority.name == null || data.priority.code == null) {
            data.priority = '';
          }


          let req = {
            "ticketName": data.subject,
            "ticketDescription": data.description,
            "createdBy": userFullName,
            "createdById": null,
            "createdByName": userFullName,
            "userName": null,
            // "categoryId": data.category.code,
            // "productId": data.product.code,
            // "productName": data.product.name,
            // "categoryName": data.category.name,
            "userId": null,
            "dueDate": null,
            "statusName": "New",
            "priorityId": data.priority.code,
            "priorityName": data.priority.name,
            "ticketType": "Support",
            "internalUser": false,
            "customerDetails": {
              "customerId": this.bussinessData['businessId'],
              "firstName": this.bussinessData['businessName'],
              "lastName": "",
              "email": this.userData.emailId,
              "mobileNo": this.userPData.contact,
              "customerType": "Business"
            },
            "customerType": "Business",
            "customerName": this.bussinessData['businessName'],
            "customerId": this.bussinessData['businessId']
          }
          formData.append("ticketDetails", JSON.stringify(req));
          if (this.files.length) {
            for (let file of this.files) {
              formData.append("file", file);
            }
          }
          else {
            this.files = [];
            formData.append("file", this.files);
          }
          formData.append("file", data.file);
          this.base.createTicket(formData)
            .subscribe(res => {
              this.ngxService.stop();
              if (res.result == "Success") {
                this.createTicketSidebar = false;
                this.createTicketForm.reset();
                let msg1;
                this.translateservice.get('ticketCreated').subscribe(txt=>{
                  msg1=txt;
                })
                this.messageService.add({ severity: 'success', summary: "ID - " + res['responseData'] + ' '+msg1 });
              }
            }),
            err => {
             // console.log(err);
              this.ngxService.stop();
            }
        }
      }),
      err => {
       // console.log(err)
      }


  }

  handleRate(event) {
    this.msgCheck = true;
    this.feedbackRatePoint = event.value;

    if (event.value == 1) {
      this.msg = "Poor";
    } else if (event.value == 2) {
      this.msg = "Average";
    } else if (event.value == 3) {
      this.msg = "Good";
    } else if (event.value == 4) {
      this.msg = "Very Good";
    } else if (event.value == 5) {
      this.msg = "Excellent";
    }
  }
  handleCancel(event) {

  }

  onTabClose(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Closed', detail: 'Index: ' + event.index })
  }

  onTabOpen(event) {
    this.messageService.add({ severity: 'info', summary: 'Tab Expanded', detail: 'Index: ' + event.index });
  }

  openNext() {
    this.index = (this.index === 3) ? 0 : this.index + 1;
  }

  openPrev() {
    this.index = (this.index <= 0) ? 3 : this.index - 1;
  }

  openFPForm() {
    this.forgetPassForm.reset();
    this.displayFP = !this.displayFP;
    this.getBusinessData();
  }

  opendisplayWhatNew() {
    this.displayWhatNew = !this.displayWhatNew;
  }

  getBusinessData() {
    this.base.getBussinessData().subscribe(
      res => {
        if (res['status']['statusMsg'] == 'SUCCESS') {
          this.bussinessData = res['data'];
          this.createTicketForm.patchValue({
            businessId: this.bussinessData['businessId'],
            businessName: this.bussinessData['businessName'],
            emailId: this.userData['emailId']
          });
          if (this.bussinessData['userImage']) {
            this.profileSrc = "data:image/png;base64," + this.bussinessData['userImage'];
          }
          if (this.bussinessData['businessImage']) {
            this.bussinessLogo = "data:image/png;base64," + this.bussinessData['businessImage'];
          }
          localStorage.setItem('image',this.bussinessData['businessImage']);
        }
      }

    );
  }
  fileName;
  openFPFormFeedback() {
    this.msg = "";
    this.feedbackForm.reset();
    this.displayFeedback = !this.displayFeedback;
    this.isValidFeedback=false;
  }
  opendisplayAccount() {
    this.displayAccount = true;
  }

  onCancelAccount() {
    this.displayAccount = false;
  }

  opendisplayContactSupport() {
    this.displayContactSupport = true;
    this.getBusinessData();
    this.files = [];
  }

  onCancelContact() {
    this.displayContactSupport = false;
  }

  opendisplayAbout() {
    this.displayAbout = true;
  }

  onCancelAbout() {
    this.displayAbout = false;
  }

  openFPFormFeedbackThanks() {
    this.msg = "";
    this.feedbackForm.reset();
    this.displayFeedbackThanks = !this.displayFeedbackThanks;
  }

  openFormFeedback() {
    this.ngxService.start();
    const req = {
      feedBackNameId: this.feedbackRatePoint,
      description: this.feedbackForm.value['feedbackText'],
      feedBackName: this.msg,
    }


    this.base.sendFeedback(req)
      .subscribe(res => {
        this.ngxService.stop();
        let msg1;
        /* this.translateservice.get('feedbackSend').subscribe(txt=>{
          msg1=txt;
        }) */
        //this.messageService.add({ severity: 'success', summary: msg1 });
        this.displayFeedback = !this.displayFeedback;
        this.displayFeedbackThanks = !this.displayFeedbackThanks;
        this.isValidFeedback=false;
        // if(Type == "svg+xml")
        //   window.location.reload();
      },
        err => {

          this.ngxService.stop();
          let msg1;
          this.translateservice.get('feedbackNotsend').subscribe(txt=>{
            msg1=txt;
          })
          this.messageService.add({ severity: 'warn', summary: msg1 });
          this.isValidFeedback=false;
        })
  }

  onhidehelp() {
    this.inputText = "string";
  }
  getAllNotificationCount() {
    const req = {
      'userId': this.userData.userId,
    };
    this.TaskService.getAllNotificationCount(req)
      .subscribe(res => {
        this.notificaitonCount = res.responseData;
        if (this.notificaitonCount.Total > 0) {
          this.notifcationCountCondition = true;
        }

      },
        error => {
          this.ngxService.stop();
        });
  }

  clearallNotification() {
    this.ngxService.start();
    const req = {
      'userId': this.userData.userId,
    };
    this.TaskService.getAllNotificationClear(req)
      .subscribe(res => {
       // this.getNotification();
        // this.ngxService.stop();
        this.notificaitonCount = res.responseData;
        this.notifcationCountCondition = false;
        this.notificaitonList=[];
      },
        error => {
          this.ngxService.stop();
        });
  }


  // getNotification() {
  //   const req = {
  //     'userId': this.userData.userId,
  //   };
  //   this.TaskService.getAllNotification(req)
  //     .subscribe(res => {
  //       this.notifcationCountCondition = false;
  //       this.notificaitonList = res.responseData;
  //       if (!this.notificaitonList) {
  //         this.notificaitonListdata = false;
  //       } else {
  //         this.notificaitonListdata = true;
  //       }
  //     },
  //       error => {
  //         this.ngxService.stop();
  //       });
  // }

  getAllNotificationLastseen() {
    const req = {
      'userId': this.userData.userId,
    };
    this.TaskService.getAllNotification(req)
      .subscribe(res => {
        // this.getNotification();
        this.notifcationCountCondition = false;
        this.notificaitonList = res.responseData;
        if (!this.notificaitonList) {
          this.notificaitonListdata = false;
        } else {
          this.notificaitonListdata = true;
        }

        // this.getAllNotificationCount();

        this.notifcationCountCondition = false;
        this.notificaitonLastSeen = res.responseData;
      },
        error => {
          this.ngxService.stop();
        });
  }

  onchangeHelpText(text) {
    this.helpMenuData = [];
    this.inputText = text;
    const json = {
      'title': text,
    };
   // console.log(this.inputText.length)
    
    this.TaskService.helpMenu(json)
      .subscribe(res => {
        this.helpMenuData = res.responseData;
        
        
        if(res.responseMessage=='help  found successfully '){
            this.arrayNotEmptyNot = true;
            this.arrayNotEmpty = false;/* else {
            this.arrayNotEmpty = true;
            this.arrayNotEmptyNot = false;
          } */
        }
        else if(res.result=='Failed' && res.responseMessage=='help  not found '){
          if(this.inputText.length>0){
            this.arrayNotEmpty = true;
            this.arrayNotEmptyNot = false;
            this.helpMenuData=[];
          }
          else{
            this.arrayNotEmpty = false;
            this.arrayNotEmptyNot = false;
            this.helpMenuData=[];
          }
        }
      },
        error => {
          this.ngxService.stop();
        });
  }
  changePassword() {
    sessionStorage.setItem('lastUrl', this.router.url);

    this.router.navigateByUrl("/change-password");

  }

  gotoReport() {

    this.router.navigateByUrl("/report");
  }

  gotoProduct() {

    this.router.navigateByUrl("/products");
  }
  gotoUsers() {

    this.router.navigateByUrl("/users");
  }

  gotoTaskStatus() {
    this.router.navigateByUrl("/taskStatus");

  }
  gotoTaskProjects() {
    this.router.navigateByUrl("/projects");

  }

  gotoActivityList() {
    this.router.navigateByUrl("/activityList");

  }


  gotoAdmin(event, element) {
    // element.hide(event);
    this.adminNinedot = true;
    // element.toggle(event);
    //  element.show(event);
    // element.hide(event);
  }

  gotoDashboard() {
    this.adminNinedot = false;
    this.router.navigateByUrl("/dashboard?token=" + localStorage.getItem('auth_token'));
    // this.router.navigateByUrl("/dashboard");
  }


  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      // this.files = event.target.files;
      for (let index = 0; index < event.target.files.length; index++) {
        let temp = event.target.files[index].name.split(".");
        let temptype = temp[temp.length - 1];

        event.target.files[index].customType = temptype;
        let b = event.target.files[index].size <= 1024 * 1024 * 5; //5242880 bytes = 5MB
        if (b == false) {
          this.fileSize = true;
        } else {
          this.files = [];
          this.files.push(event.target.files[index]);
        }
      }

    }

  }


  //old logout method
  logOut1() {
    localStorage.clear();
    this.router.navigateByUrl("/login")
  }

  //new logout method
  logOut() {
    this.ngxService.start();
    let taskId=localStorage.getItem('taskId');


    let req = {
      'userLogoutIp': this.deviceInfo.IP,
      'logoutBrowser': this.deviceInfo.deviceInfo.browser
    }
    console.log("last update time===",localStorage.getItem('currentDate'))
    this.loginService.logoutUser(req)
      .subscribe(res => {
        if (res['status']['statusMsg'] === 'Success') {
         
          localStorage.clear();
          if(taskId){
          localStorage.setItem('taskId',taskId);
          }
          this.router.navigateByUrl("/");
        }
      })
  }


  onProfilePicChange(e) {
    var Type="";
    if (e.target.files[0].size == 0) {
      let msg1;
      this.translateservice.get('nofileSelected').subscribe(txt=>{
        msg1=txt;
      })
      this.messageService.add({ severity: 'warn', summary: msg1 });
    }
    let validFileType = ["jpeg", "png", "gif", "svg+xml"];
    
    var filetype = e.target.files[0].type;
      if(filetype!=""){
          Type = filetype.split("/")[1].toLowerCase();
      }
      else{
        Type="";
      }
    // var mimeType = event.target.files[0].type;
    //const filesize = Math.round((file.size / 1024));
   
    if (!validFileType.includes(Type)) {
      this.forgetPassForm.reset();
      this.isFileType = false;
      let msg1;
      this.translateservice.get('filetypeInvalid').subscribe(txt=>{
        msg1=txt;
      })
      this.messageService.add({ severity: 'warn', summary: msg1 });
      return;
    }
    else
     if ((e.target.files[0].size / 1024) > 500) {
      this.forgetPassForm.reset();
      this.fileSize = false;
      let msg1;
      this.translateservice.get('filesizeGreater500kb').subscribe(txt=>{
        msg1=txt;
      })
      this.messageService.add({ severity: 'warn', summary: msg1 });
      return;
    }
    else {
   
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (e => {
          const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
          this.profileSrc = value;
          // this.data.changeUrl(this.businessLogoSrc);
        })

        reader.readAsDataURL(file);
      }

      this.profileImg = e.target.files[0];

    }
  }

  uplaodProfile() {

    let formData = new FormData;
    formData.append('file', this.profileImg);

    this.ngxService.start();

    this.base.uploadUserProfile(formData, this.userData.emailId)
      .subscribe(res => {
        this.ngxService.stop();
        let msg1;
        this.translateservice.get('profileimageUploaded').subscribe(txt=>{
          msg1=txt;
        })
        this.messageService.add({ severity: 'success', summary: msg1 });
        this.displayFP = !this.displayFP;
        this.getBusinessData();
        // if(Type == "svg+xml")
        //   window.location.reload();
      },
        err => {
          this.ngxService.stop();
          let msg1;
          this.translateservice.get('profileimageNotuploaded').subscribe(txt=>{
            msg1=txt;
          })
          this.messageService.add({ severity: 'warn', summary: msg1 });
        })
  }



  removeuserLogo(email){
   /*  let msg1;
    this.translateservice.get('removeThisProfilePicture').subscribe(txt=>{
      msg1=txt;
    }) */
    this.confirmationService.confirm({
      message: '<p class="msgDelete">Do you want to remove this Profile Picture?</p>',
      header: 'Remove User Logo',/* 
      icon: 'pi pi-info-circle', */
      accept: () => {
        
        this.base.deleteUserLogo(email).subscribe(
          res => {
            if(res["status"]["statusMsg"] == "Success"){
              let msg1;
              this.translateservice.get('userlogoRemoved').subscribe(txt=>{
                msg1=txt;
              })
              this.messageService.add({ severity: 'success', summary: msg1 });
              this.getBusinessData();
              this.displayFP=false;
            }
          }
        )
      },
      reject: () => {
       // console.log("rejected")
      }
  });
  }
  resetPassForm() {
    this.forgetPassForm.reset();
    this.getBusinessData();
  }

  cancelCreateTicket() {
    this.createTicketSidebar=false;
    this.createTicketForm.reset();
  }

  imageUrl:any;
  Imagelist:any=[];
  tourImages:any=[];
  getProductTourImages(){
   let req;
   if(this.isadmin==true){
    req='admin';
   }
   else{
     req='user';
   }
    this.TaskService.getProductTourImagesV1(req).subscribe(response=>{
      this.imageUrl=response.responseData.url;
      this.Imagelist=response.responseData.product;
      for(let i=0;i<this.Imagelist.length;i++){
        this.tourImages[i]=this.imageUrl+this.Imagelist[i].fileName;
      }
      this.tourImages=[...this.tourImages];
      localStorage.setItem('productTourImages',JSON.stringify(this.tourImages));
      localStorage.setItem('isImages','true')
    })
  }

  gotoConfiguration(){
    this.router.navigateByUrl("/configuration");
  }

  downloadQuickRefPdf() {
      var link = document.createElement('a');
      link.href = this.fileName;
      link.target='_blank';
      link.click();
  }

  closeNotification(i){
    let req={
      notificationId:this.notificaitonList[i].notificationId
    }
    this.notificaitonList.splice(i,1)
    this.TaskService.removeNotificationById(req).subscribe(response=>{
      if(response.result!='Failed'){
        this.getAllNotificationLastseen();
      }
    },
    error=>{
      console.log(error);
    })
  }

  
  openFPFormHelpOld(tab, position: string) {
    //console.log(tab);
    this.position = position;

    if (tab == "help") {
      this.tabSelected = true;
      // this.tabSelected4=false;
      // this.tabSelected3=false;
      this.tabSelected2 = false;
      this.tabSelected3 = false;
      this.tabSelected4 = false;
      this.TaskService.findQuickRefPdf().subscribe(response=>{
        this.fileName=response.responseData.url+response.responseData.imageName;
       })
    } else if (tab == "FAQ") {
      this.tabSelected = true;
      // this.tabSelected4=false;
      // this.tabSelected3=false;
      this.tabSelected2 = true;
      this.tabSelected3 = false;
      this.tabSelected4 = false;
    } else if (tab == "contact") {
      // this.tabSelected3=true;
      // this.tabSelected4=false;
      this.tabSelected = false;
      this.tabSelected2 = false;
      this.tabSelected3 = false;
      this.tabSelected4 = false;
    } else if (tab == "about") {
      // this.tabSelected4=true;
      this.tabSelected = false;
      // this.tabSelected3=false;
      this.tabSelected2 = false;
      this.tabSelected3 = false;
      this.tabSelected4 = false;
    }
    this.guideResponseNew=JSON.parse(localStorage.getItem('indexList'));
    this.videoResponseNew=JSON.parse(localStorage.getItem('helpVideosList'));
    this.faqResponseNew=JSON.parse(localStorage.getItem('helpFaqList'))
    // this.forgetPassForm.reset();
    this.displayFPS = !this.displayFPS;


    // this.forgetPassForm.reset();
  }

  openFPFormHelp(tab, position: string) {
    this.ngxService.start();
   // this.tabSelected=true;
   if(tab=='help'){
    this.tabindex=0;
    }
    else if(tab=='FAQ'){
      this.tabindex=1;
    }
    this.TaskService.findQuickRefPdf().subscribe(response=>{
      this.fileName=response.responseData.url+response.responseData.imageName;
     });
        this.position = position;
        this.guideResponseNew=JSON.parse(localStorage.getItem('indexList'));
    this.videoResponseNew=JSON.parse(localStorage.getItem('helpVideosList'));
    this.faqResponseNew=JSON.parse(localStorage.getItem('helpFaqList'))
        this.displayFPS = true;
        this.ngxService.stop();
      }
  /* new HELP */
  
  searchValues(text){
    //console.log(text)
    this.newArrayHelp=[];
    this.faqResponse=[];
    this.faqResponseNew=[];
    this.guideResponseNew=[];
    this.videoResponseNew=[];
    this.guideResponseNewData=[];
    if(text!=""){
      for(let i=0 ;i<this.CopyfaqResponse.length;i++){
        if(this.CopyfaqResponse[i].keyword!=null){
          if(this.CopyfaqResponse[i].keyword.toLowerCase().indexOf(text.toLowerCase()) >= 0){
            this.faqResponseNew.push(this.CopyfaqResponse[i]);
          }
        }
      }
      for(let i=0 ;i<this.CopyvideoResponse.length;i++){
        if(this.CopyvideoResponse[i].keyword!=null){
          if(this.CopyvideoResponse[i].keyword.toLowerCase().indexOf(text.toLowerCase()) >= 0){
            this.videoResponseNew.push(this.CopyvideoResponse[i]);
          }
        }
      }
      for(let i=0 ;i<this.CopyguideResponse.length;i++){
        for(let j=0 ;j<this.CopyguideResponse[i].helpTopics.length;j++){
       /*  for(let l=0 ;l<this.CopyguideResponse[i].subHeadings[j].helpTopics.length;l++){ */
          if(this.CopyguideResponse[i].helpTopics[j].keyword!=null){
          //  console.log(this.CopyguideResponse[i].helpTopics[j].keyword)
            if( this.CopyguideResponse[i].helpTopics[j].keyword.toLowerCase().indexOf(text.toLowerCase()) >= 0){
            this.guideResponseNewData.push((this.CopyguideResponse[i].helpTopics[j]));
            }
          /* } */
        }
      }
    }
    }
    else{
      this.guideResponseNew=this.CopyguideResponse;
      this.faqResponseNew=this.CopyfaqResponse;
      this.videoResponseNew=this.CopyvideoResponse
    }
  //console.log(this.guideResponseNewData)
    if(this.faqResponseNew.length>0){
      this.inputText="none";
      this.arrayNotEmpty=true;
    }else{
      this.inputText="string";
      this.arrayNotEmptyNot=true
    }
    if(text=""){
      this.inputText="string";
      this.arrayNotEmptyNot=true
      
    }
  }

  openDetailsHelpItem(json,Newjson,newvalue){
    //console.log("click")
     if(this.highlightCheckHelp == Newjson){
       this.highlightCheckHelp="";
       this.showDetailsHelp=-1;
     }else{
     
       this.highlightCheckHelp =Newjson;
       this.showDetailsHelp=json;
       this.newValueCheck=newvalue;
     }
     //console.log( this.showDetailsHelp);
    // console.log(json +Newjson);
     this.checkValueHelp=!this.checkValueHelp;
 
     
  }

  openDetails(json,Newjson){
    if(this.highlightCheck == Newjson){
      this.highlightCheck="";
      this.showDetails=-1;
    }else{
      this.highlightCheck=Newjson;
      this.showDetails=json;
    }
    //console.log(json +Newjson);
  }

  openFormDetails(json,name,Pnaame,imgArray){
   // console.log(JSON.stringify(json));
    this.detailformData=json;
    this.detailformDataImg=imgArray;
    this.moduleNameData=name;
    this.textNameData=Pnaame;
    this.displaydetailHelp=true;
}


  getTextValues() {
   // this.ngxService.start();
    let faqList=[];
    if(localStorage.getItem('helpFaqList')==null){
      this.TaskService.getHelpFAQ().subscribe(response=>{
        faqList=response.responseData.faqs;
        this.faqResponse=faqList;
        //this.CopyfaqResponse=faqList;
        for(let i=0 ;i<this.faqResponse.length;i++){
              this.faqResponseNew.push(this.faqResponse[i]);
        }
        localStorage.setItem('helpFaqList',JSON.stringify(this.faqResponseNew));
        this.CopyfaqResponse=JSON.parse(localStorage.getItem('helpFaqList'));
      });
    }
    else{
      this.faqResponse=JSON.parse(localStorage.getItem('helpFaqList'));
      this.CopyfaqResponse=JSON.parse(localStorage.getItem('helpFaqList'));
        for(let i=0 ;i<this.faqResponse.length;i++){
            this.faqResponseNew.push(this.faqResponse[i]);
        }
    }
    let videoList=[];
    if(localStorage.getItem('helpVideosList')==null){
      this.TaskService.getHelpVideo().subscribe(response=>{
       // console.log(response)
        videoList=response.responseData.videos;
        this.videoResponse=videoList;
        //this.CopyvideoResponse=videoList;
        for(let i=0 ;i<this.videoResponse.length;i++){
              this.videoResponseNew.push(this.videoResponse[i]);
        }
        localStorage.setItem('helpVideosList',JSON.stringify(this.videoResponseNew));
        this.CopyvideoResponse=JSON.parse(localStorage.getItem('helpVideosList'));
      });
    }
    else{
      this.videoResponse=JSON.parse(localStorage.getItem('helpVideosList'));
      this.CopyvideoResponse=JSON.parse(localStorage.getItem('helpVideosList'));
        for(let i=0 ;i<this.videoResponse.length;i++){
            this.videoResponseNew.push(this.videoResponse[i]);
        }
    }
    let indexList:any;
   // console.log(localStorage.getItem('indexList'))
    if(localStorage.getItem('indexList')==null){
      this.TaskService.getHelpIndex().subscribe(response=>{
       // console.log(response)
        indexList=response.responseData.indexData.headings[0];
        this.guideResponse=indexList.subHeadings;
        //this.CopyguideResponse=indexList.subHeadings;
        for(let i=0 ;i<this.guideResponse.length;i++){
            this.guideResponseNew.push(this.guideResponse[i]);
        }
        localStorage.setItem('indexList',JSON.stringify(this.guideResponseNew));
        this.CopyguideResponse=JSON.parse(localStorage.getItem('indexList'));
        //console.log("guide res====",localStorage.getItem('indexList'))
      });
    }
    else{
      this.guideResponse=JSON.parse(localStorage.getItem('indexList'));
      this.CopyguideResponse=JSON.parse(localStorage.getItem('indexList'));
        for(let i=0 ;i<this.guideResponse.length;i++){
            this.guideResponseNew.push(this.guideResponse[i]);
        }
        //console.log("guide session====",this.guideResponseNew)
    }
  }
 
  openDetailsHelpItemHeading(json,Newjson,newvalue){
      
    if(this.highlightCheckHelpHeading == Newjson){
      this.highlightCheckHelpHeading="";
      this.showDetailsHelpHeading=-1;
    }else{
    
      this.highlightCheckHelpHeading =Newjson;
      this.showDetailsHelpHeading=json;
    }
    this.checkValueHelp=!this.checkValueHelp;
 }
 
 openDetailsHelpItemHeadingNew(json,Newjson,newvalue){
   if(this.highlightCheckHelpHeadingNew == Newjson){
     this.highlightCheckHelpHeadingNew="";
     this.showDetailsHelpHeadingNew=-1;
   }else{
   
     this.highlightCheckHelpHeadingNew =Newjson;
     this.showDetailsHelpHeadingNew=json;
   }
   this.checkValueHelp=!this.checkValueHelp;
}

openDetailsHelpItemFinal(json,Newjson){
  if(this.highlightCheckHelpFinal == Newjson){
    this.highlightCheckHelpFinal="";
    this.showDetailsHelpHeadingFinal=-1;
  }else{
    this.highlightCheckHelpFinal =Newjson;
    this.showDetailsHelpHeadingFinal=json;
  }
  this.checkValueHelp=!this.checkValueHelp;
}

  checkChange(e:any){
    event.stopPropagation(); // component will have direct access to event here.
  }

  openVideo(link,name){
    //console.log(link);
    //this.ngxService.start();
    this.videoNameData=name;
    let linkLastStirng=link.substring(link.lastIndexOf('/') + 1);
    let userData:any=JSON.parse(localStorage.getItem('userData'));
    let userPData:any=JSON.parse(localStorage.getItem('userPData'));
    let req={
      "userEmail": userData.emailId,
      "userName":userData.userName,
      "business_name":userPData.businessName,
      "videoSubject":this.videoNameData     
    }
      this.unsafeLink=link;
      //this.videoLink = this.transform(link);
     // this.videoLink.changingThisBreaksApplicationSecurity+='?userdata=' + JSON.stringify(req);
      this.videoLink = link+"?userdata="+JSON.stringify(req)
      this.videoModalHelp=true;
  }

  goToHomeHelp(){
    this.helpText='';
    this.guideResponseNew=this.CopyguideResponse;
    this.faqResponseNew=this.CopyfaqResponse;
    this.videoResponseNew=this.CopyvideoResponse
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  removeHelp(){
    this.tabindex=0;
    this.helpText='';
    this.displayFPS=false;
    this.videoModalHelp=false;
    this.guideResponseNew=this.CopyguideResponse;
    this.faqResponseNew=this.CopyfaqResponse;
    this.videoResponseNew=this.CopyvideoResponse
  }
  
  onTabOpen1(e) {
    this.tabindex = e.index;
}

isValidFeedback:boolean;
ChangeFeedbackValue($event){
  if(this.feedbackForm.controls['feedbackText'].value.charCodeAt('0')==32 || this.feedbackForm.controls['feedbackText'].value.charCodeAt('0')==10){
    this.isValidFeedback=true;
  }
  else{
    this.isValidFeedback=false;
  }
}
languageDropdown:any=[];
languageImage:any=[];
getAllLanguages(){
  //this.ngxService.start();
  this.TaskService.getAllLanguages().subscribe(response=>{
  //  console.log(response);
    let list=response.responseData;
    for(let i=0;i<list.length;i++){
      this.languageDropdown.push({name:list[i].languageName,code:list[i].languageCode});
      this.languageImage.push("data:image/png;base64," + list[i].languageImage);
    }
    localStorage.setItem('languageDropdown',JSON.stringify(this.languageDropdown));
    localStorage.setItem('languageImage',JSON.stringify(this.languageImage));
    this.languageDropdown=[...this.languageDropdown];
    this.languageImage=[...this.languageImage]
    if(localStorage.getItem('selectedLanguage')==null){
      this.lang='en';
      localStorage.setItem('selectedLanguage','en');
      this.selectedLanguage={name:'English', code:'en'};
      this.getLabels();
    }
    else{
      this.lang=localStorage.getItem('selectedLanguage');
      if(this.lang=='en'){
        this.selectedLanguage={name:'English', code:'en'};
        if(localStorage.getItem('englishLabels')==null){
          this.getLabels();
        }
        else{
          this.translateservice.setTranslation(this.lang,JSON.parse(localStorage.getItem('englishLabels')));
          this.translateservice.use('en');
        }
      }
     else if(this.lang=='es'){
       this.selectedLanguage={name:'Spanish',code:'es'};
       if(localStorage.getItem('SpanishLabels')==null){
         this.getLabels();
       }
       else{
        this.translateservice.setTranslation(this.lang,JSON.parse(localStorage.getItem('SpanishLabels')));
        this.translateservice.use('es');
       }
     }
     else if(this.lang=='pr'){
      this.selectedLanguage={name:'Portuguese',code:'pr'};
      if(localStorage.getItem('portugueseLabels')==null){
        this.getLabels();
      }
      else{
        this.translateservice.setTranslation('pr',JSON.parse(localStorage.getItem('portugueseLabels')));
        this.translateservice.setDefaultLang('pr');
      }
    }
    else if(this.lang=='hi'){
      this.selectedLanguage={name:'Hindi',code:'hi'};
      if(localStorage.getItem('hindiLabels')==null){
        this.getLabels();
      }
      else{
        this.translateservice.setTranslation('hi',JSON.parse(localStorage.getItem('hindiLabels')));
        this.translateservice.setDefaultLang('hi');
      }
    }
    else if(this.lang=='fr'){
      this.selectedLanguage={name:'French',code:'fr'};
      if(localStorage.getItem('frenchLabels')==null){
        this.getLabels();
      }
      else{
        this.translateservice.setTranslation('fr',JSON.parse(localStorage.getItem('frenchLabels')));
        this.translateservice.setDefaultLang('fr');
      }
    }
    }
  })
}
updatePreferredLanguage(req){
  this.TaskService.updatePreferredLanguage(req).subscribe(response=>{
    if(req=='English'){
      this.translateservice.setDefaultLang('en');
      this.translateservice.use('en');
    }
    else if(req=='Spanish'){
      this.translateservice.setDefaultLang('es');
      this.translateservice.use('es');
    }
    else if(req=='Portuguese'){
      this.translateservice.setDefaultLang('pr');
      this.translateservice.use('pr');
    }
    else if(req=='Hindi'){
      this.translateservice.setDefaultLang('hi');
      this.translateservice.use('hi');
    }
    else if(req=='French'){
      this.translateservice.setDefaultLang('fr');
      this.translateservice.use('fr');
    }
    let m1;
    this.translateservice.get('Preflangupdatedsuccess').subscribe(res=>{
      m1=res;
    })
    this.ngxService.stop();
  })
}
changeLanguage(value){
  //this.ngxService.start();
  this.lang=value;
  localStorage.setItem('selectedLanguage',value);
  let browserLang=navigator.language;
 if(browserLang=='en'){

 }
 else{
  if(value=='en'){
   this.updatePreferredLanguage('English');
    if(localStorage.getItem('englishLabels')==null){
      this.TaskService.getMultiLangLabelsByLang('English').subscribe(response=>{
        let languageLabels:any=[];
        languageLabels=response.responseData
       let  englishLabels= languageLabels[0].labels;
        localStorage.setItem('englishLabels',JSON.stringify(englishLabels));
        this.translateservice.setTranslation('en', englishLabels);
        this.ngxService.stop();
      })
    }
    else{
      this.translateservice.setTranslation('en', JSON.parse(localStorage.getItem('englishLabels')));
      this.ngxService.stop();
    }
   
  }
  else if(value=='es'){
   this.updatePreferredLanguage('Spanish');
    if(localStorage.getItem('SpanishLabels')==null){
      this.TaskService.getMultiLangLabelsByLang('Spanish').subscribe(response=>{
        let languageLabels:any=[];
        languageLabels=response.responseData
       let  spanishLabels= languageLabels[0].labels;
        localStorage.setItem('SpanishLabels',JSON.stringify(spanishLabels));
        this.translateservice.setTranslation('es', spanishLabels);
        this.ngxService.stop();
      })
    }
    else{
      this.translateservice.setTranslation('es', JSON.parse(localStorage.getItem('SpanishLabels')));
      this.ngxService.stop();
    }
  }
  else if(value=='pr'){
    this.updatePreferredLanguage('Portuguese');
    if(localStorage.getItem('portugueseLabels')==null){
      this.TaskService.getMultiLangLabelsByLang('Portuguese').subscribe(response=>{
        let languageLabels:any=[];
        languageLabels=response.responseData
       let  portugueseLabels= languageLabels[0].labels;
        localStorage.setItem('portugueseLabels',JSON.stringify(portugueseLabels));
        this.translateservice.setTranslation('pr', portugueseLabels);
        this.ngxService.stop();
      })
    }
    else{
      this.translateservice.setTranslation('pr', JSON.parse(localStorage.getItem('portugueseLabels')));
      this.ngxService.stop();
    }
  }
  else if(value=='hi'){
    this.updatePreferredLanguage('Hindi');
    if(localStorage.getItem('hindiLabels')==null){
      this.TaskService.getMultiLangLabelsByLang('Hindi').subscribe(response=>{
        let languageLabels:any=[];
        languageLabels=response.responseData
       let  hindiLabels= languageLabels[0].labels;
        localStorage.setItem('hindiLabels',JSON.stringify(hindiLabels));
        this.translateservice.setTranslation('hi', hindiLabels);
        this.ngxService.stop();
      })
    }
    else{
      this.translateservice.setTranslation('hi', JSON.parse(localStorage.getItem('hindiLabels')));
      this.ngxService.stop();
    }
  }
  else if(value=='fr'){
    this.updatePreferredLanguage('French');
    if(localStorage.getItem('frenchLabels')==null){
      this.TaskService.getMultiLangLabelsByLang('French').subscribe(response=>{
        let languageLabels:any=[];
        languageLabels=response.responseData
       let  frenchLabels= languageLabels[0].labels;
        localStorage.setItem('frenchLabels',JSON.stringify(frenchLabels));
        this.translateservice.setTranslation('fr', frenchLabels);
        this.ngxService.stop();
      })
    }
    else{
      this.translateservice.setTranslation('fr', JSON.parse(localStorage.getItem('frenchLabels')));
      this.ngxService.stop();
    }
  }
 // this.translateservice.use(this.lang);
  this.translateservice.setDefaultLang(this.lang);
  setTimeout(()=>{
    window.location.reload();
  },1000)
 }
}



@HostListener('window:load',['$event'])

onPageLoad(event: Event) {
  // this.ngxLoader.start();
   console.log('loaded',event);

  setTimeout(()=>{

     this.ngxService.stop();
    this.isLoaded=true;
  },5000)

}
}

export class ReversePipe implements PipeTransform {
  transform(value) {
    return value.slice().reverse();
  }
}


