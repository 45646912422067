import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceService {
  ipAddress: any;
  deviceInfo: any;
  private userDevice = new BehaviorSubject([]);
  shareDeviceDetails = this.userDevice.asObservable();
  
  constructor(private deviceService: DeviceDetectorService,
    private http: HttpClient) {
    
   }

   getIPAddress(){
    return this.http.get("https://api.ipify.org/?format=json",{ headers: null });
  }

  storeIP(){
    this.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
      this.getDeviceDetails();
    }); 
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.deviceInfo.device = isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktopDevice ? 'Desktop' : '';
  }

  getDeviceDetails(){
    let userDevice: any = {
      "IP" : this.ipAddress,
      "deviceInfo" : this.deviceInfo
    }
    this.userDevice.next(userDevice);
  }
}
