<div class="default-theme">
    <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid p-0">
            <!-- Brand -->
            <div id="ctl_straviso_logo_div" class="brand_name_div tooltip_custom">
                <a class="navbar-brand">
                    <img src="../../../../assets/img/myTaskEasy_logo_updated.png" alt="Logo" class="head-logo" 
                        style="cursor: pointer;">
                </a>
            </div>
            <div class="container m-t-100" style="text-align:center; margin-top: 35%;">
                <img src="../../assets/img/warning.png" width="80px"> 
                <h1 style="text-align: center;margin-top: 15px;">verification link is expired or invalid.</h1>
            </div>
        </div>
    </nav>
</div>


<footer class="footer">
    <span>{{year}} Copyright@StraViso</span>
</footer>