import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  
  createUser(json) {
    return this.http.post<any>(environment.mainurl+"user/createWithProfile", json);
  }
  onuploadFile(requestJson) {
    return this.http.post<any>(environment.upload_url + "productUpload/importBulkProductData", requestJson);
  }
  onuploadFileProject(requestJson) {
    return this.http.post<any>(environment.upload_url + "projectBulkUpload/importprojectData", requestJson);
  }
  onuploadFileUser(requestJson) {
    return this.http.post<any>(environment.upload_url + "upload/importBulKUserData", requestJson);
  }


  createProject(json) {
    return this.http.post<any>(environment.mainurl+"project/create", json);
  }

  createProduct(json) {
    return this.http.post<any>(environment.mainurl+"product/create", json);
  }

  createTaskStatus(json) {
    return this.http.post<any>(environment.mainurl+"taskStatus/create", json);
  }

  getUserList() {
    return this.http.get<any>(environment.mainurl + "user/findAllActive");
  }

  getAllActiveAndEnabledUserList() {
    return this.http.get<any>(environment.mainurl + "user/findAllEnabledAndActive");
  }

  getProjectList() {
    return this.http.get<any>(environment.mainurl + "project/findAllActive");
  }

  getProjectListData(json) {
    return this.http.post<any>(environment.mainurl + "project/findProjects", json);
  }

  getProductListData(json) {
    return this.http.post<any>(environment.mainurl + "product/findProducts", json);
  }

  getUserListData(json) {
    return this.http.post<any>(environment.mainurl + "user/findUsers", json);
  }

  getTaskStatusListData(json) {
    return this.http.post<any>(environment.mainurl + "taskStatus/findStatus", json);
  }

  getProjectCount() {
    return this.http.get<any>(environment.mainurl + "project/findCounts");
  }

  getAdminListCount() {
    return this.http.get<any>(environment.mainurl + "product/totalListCounts");
  }

  getProductCount() {
    return this.http.get<any>(environment.mainurl + "product/findCounts");
  }

  getUserCount() {
    return this.http.get<any>(environment.mainurl + "user/findCounts");
  }

  getTaskStatusCount() {
    return this.http.get<any>(environment.mainurl + "taskStatus/findCounts");
  }

  getNonMangers(json) {
    return this.http.post<any>(environment.mainurl+"user/findNonManagers", json);
  }

  getExcelDownload() {
    
    return this.http.get<any>(environment.upload_url + "projectBulkUpload/projectDownloadExcel",{responseType: 'blob' as 'json'});
  }
  getExcelDownloadProduct() {
    
    return this.http.get<any>(environment.upload_url + "productUpload/productDownloadExcel",{responseType: 'blob' as 'json'});
  }
  getExcelDownloadUser() {
    
    return this.http.get<any>(environment.upload_url + "upload/userdownloadExcel",{responseType: 'blob' as 'json'});
  }


  getProductList() {
    return this.http.get<any>(environment.mainurl + "product/findAllActive");
  }

  getTaskStatusActiveList() {
    return this.http.get<any>(environment.mainurl + "taskStatus/findAllActive");
  }

  getTaskStatusList() {
    return this.http.get<any>(environment.mainurl + "taskStatus/findAll");
  }

  getWatchlist(requestJson) {
 
    return this.http.post<any>(environment.mainurl+"watchlist/findByUserId",requestJson);
  }

  getUserDetailById(requestJson) {
    return this.http.post<any>(environment.mainurl+"user/findByUserId",requestJson);
  }

  getProjectDetailById(requestJson) {
    return this.http.post<any>(environment.mainurl+"project/findByProjectId",requestJson);
  }

  getProductDetailById(requestJson) {
    return this.http.post<any>(environment.mainurl+"product/findByProductId",requestJson);
  }

  getTaskStatusDetailById(requestJson) {
    return this.http.post<any>(environment.mainurl+"taskStatus/findByTaskStatusId",requestJson);
  }

  onUpdateUser (requestJson) {
    return this.http.post<any>(environment.mainurl+"user/updateWithProfile",requestJson);
  }

  

  onUpdateProject (requestJson) {
    return this.http.post<any>(environment.mainurl+"project/update",requestJson);
  }

  onUpdateProduct (requestJson) {
    return this.http.post<any>(environment.mainurl+"product/update",requestJson);
  }

  onUpdateTaskStatus  (requestJson) {
    return this.http.post<any>(environment.mainurl+"taskStatus/update",requestJson);
  }

  deleteUserById (requestJson) {
    return this.http.post<any>(environment.mainurl+"user/delete",requestJson);
  }

  deleteProjectById (requestJson) {
    return this.http.post<any>(environment.mainurl+"project/delete",requestJson);
  }

  deleteProductById (requestJson) {
    return this.http.post<any>(environment.mainurl+"product/delete",requestJson);
  }

  deleteTaskStatusById (requestJson) {
    return this.http.post<any>(environment.mainurl+"taskStatus/delete",requestJson);
  }

  deActivateUserById (requestJson) {
    return this.http.post<any>(environment.mainurl+"user/deactivate",requestJson);
  }

  deActivateProjectById (requestJson) {
    return this.http.post<any>(environment.mainurl+"project/deactivate",requestJson);
  }

  deActivateProductById (requestJson) {
    return this.http.post<any>(environment.mainurl+"product/deactivate",requestJson);
  }

  deActivateTaskStatusById (requestJson) {
    return this.http.post<any>(environment.mainurl+"taskStatus/deactivate",requestJson);
  }

  getRoleList() {
    return this.http.get<any>(environment.mainurl + "role/findAll");
  }

  getUserGroupList() {
    return this.http.get<any>(environment.mainurl + "usergroup/findAll");
  }

  checkEmailExistsAlready(emailId) {
    return this.http.post<any>(environment.mainurl+"user/checkIfEmailExists", emailId);
  }
  checkIfProductNameExists(productName) {
    return this.http.post<any>(environment.mainurl+"product/checkIfProductNameExists", productName);
  }
  checkIfStatusNameExists(productName) {
    return this.http.post<any>(environment.mainurl+"taskStatus/checkIfStatusNameExists", productName);
  }
  checkIfProjectNameExists(productName) {
    return this.http.post<any>(environment.mainurl+"project/checkIfProjectNameExists", productName);
  }
  
  downloadProjectTemplate () {
    return this.http.get(`${environment.mainurl}projectBulkUpload/projectDownloadExcel`,{responseType: 'blob' as 'json'});
  }

  bulkUploadProjects (req) {
    return this.http.post<any>(`${environment.mainurl}projectBulkUpload/importprojectData`, req);
  }

  downloadProductTemplate () {
    return this.http.get(`${environment.mainurl}productUpload/productDownloadExcel`,{responseType: 'blob' as 'json'});
  }
  
  bulkUploadProduct (req) {
    return this.http.post<any>(`${environment.mainurl}productUpload/importBulkProductData`, req);
  }

  downloadUserTemplate () {
    return this.http.get(`${environment.mainurl}upload/userdownloadExcel`,{responseType: 'blob' as 'json'});
  }
  
  bulkUploadUser (req) {
    return this.http.post<any>(`${environment.mainurl}upload/importBulKUserData`, req);
  }

  uploadUserProfile(req, mailId) {
    return this.http.post<any>(environment.mainurl + 'profile/addUserLogo?userEmail='+ mailId, req);
  }
  getTaskType(req) {
    return this.http.post<any>(`${environment.mainurl}taskType/findType`, req);
  }
  getUserGroupListData(json) {
    return this.http.post<any>(environment.mainurl + "usergroup/findUserGroup", json);
  }
  ReInvite(json) {
    return this.http.post<any>(environment.mainurl + "user/sendReInvitation", json);
  }

  fetchProductMapping(req){
    return this.http.post<any>(environment.mainurl+'user/fetchProductMapping',req);
  }
  fetchProjectMapping(req){
    return this.http.post<any>(environment.mainurl+'user/fetchProjectMapping',req);
  }

  addProjectProductMapping(req){
    return this.http.post<any>(environment.mainurl+'user/updateMonitorMapping',req);
  }
}
