import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export interface CarouselContext {
  $implicit: string;
  controller: {
    next: () => void;
    prev: () => void;
    end: ()=> void;
  };
}

@Directive({
  selector: '[carousel]',
})
export class CarouselDirective implements OnInit {
  constructor(private tmpl: TemplateRef<CarouselContext>, private vcr: ViewContainerRef, public ngxLoader:NgxUiLoaderService) {}

  @Input('carouselFrom') images: string[];
  context: CarouselContext|null = null; 
  index: number = 0;

  ngOnInit(): void {
    this.context = {
      $implicit: this.images[0],
      controller: {
        next: () => this.next(),
        prev: () => this.prev(),
        end: () => this.end()
      },
    };
    this.vcr.createEmbeddedView(this.tmpl, this.context);
  }

  next(): void {
    this.ngxLoader.start();
    this.index++;
    if (this.index >= this.images.length) {
      this.index = 0;
    }
    this.context!.$implicit = this.images[this.index];
    this.ngxLoader.stop();
  }

  prev(): void {
    this.ngxLoader.start();
    this.index--;
    if (this.index < 0) {
      this.index = this.images.length - 1;
    }
    this.context!.$implicit = this.images[this.index];
    this.ngxLoader.stop();
  }

  end():void {
    this.ngxLoader.start();
    this.index=0;
    this.context!.$implicit = this.images[this.index];
    this.ngxLoader.stop();
  }
}