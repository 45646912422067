import { tap } from 'rxjs/operators';
import { HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class HttpIntercepter implements HttpInterceptor {
 

    constructor(private router: Router,
        private ngxLoader: NgxUiLoaderService,) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = new HttpHeaders();
        if (localStorage.getItem('auth_token')) {
            headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') });
        }

    //   let token=" eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5NzQ4IiwiZW1haWxJZCI6InNhdXJhYmgudG90ZXdhckBzdHJhdmlzby5jb20iLCJidXNpbmVzc0lkIjoiU1ZTMDAwNzk4IiwiZmlyc3ROYW1lIjoiU2F1cmFiaCIsImxhc3ROYW1lIjoiVG90ZXdhciIsImlhdCI6MTU5MjY1NDIyNiwiZXhwIjoxNTkzNTE4MjI2fQ.3VLEf3x9PSSP_Pkb7iTOK07JsPAZ92H2W4XdnH0DRQz63yHkNJg_kY5Bd5WW1d2PG3-VtN6r4Ph2zcB31zW_jQ";

    //     let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });

    if(req.url.includes('ticket/core/ticket/create/support') || req.url.includes('ticket/core/productService/findAllActive') || req.url.includes('ticket/core/category/findAllActive') || req.url.includes('ticket/core/priority/findAllActive')){
        headers = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem('supportToken')});
    }

    if(req.url.includes('api.ipify.org/?format=json')){
        headers=new HttpHeaders();
    }

 
    
        const newRequest = req.clone({
            headers: headers
        });

        return next.handle(newRequest).pipe(
            catchError((error: any) => {
                if (error.status == 401 || error.status == 0) {
                    if(req.url.includes('api.ipify.org')){
                        this.ngxLoader.stop();
                    }
                    else{
                        localStorage.clear();
                        this.router.navigate(['/login']);
                        this.ngxLoader.stop();
                    }
                } else {

                }
                this.ngxLoader.stop();
                return throwError(error);

            })
        );
    }
}
