// export   const   environment = {
//   production: true
// };


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
 
// **************** Dev URLs ****************************
//   const   base_url = 'https://mytaskeasy-api.straviso.net/RestServer/TaskEasyCore/';
//   const   report_url = 'https://mytaskeasy-api.straviso.net/RestServer/TaskEasyReports/';
//   const   redirect_url = "https://taskeasydev.straviso.net/_hash_/login/";
//   const   loginManager = "https://taskeasydev.straviso.net:9096/"
 
//  const   base_url = 'https://taskeasy-nsfarm.straviso.net:9094/TaskEasy/Core/';
//  const   report_url = 'https://taskeasy-nsfarm.straviso.net:9094/TaskEasy/Reports/';
// ******************* Localhost URL ************************

// const loginManager = "https://mytaskeasy-nsapi.straviso.net/AuthServer/"
// const signIn = "https://mytaskeasy-nsapi.straviso.net/AuthServer/";


const base_url = 'https://mytaskeasy.straviso.net/teg/TaskEasy/Core/';
const northstar_Loginurl = 'https://mytaskeasy.straviso.net/nsg/NorthStar/Core/';
const report_url = 'https://mytaskeasy.straviso.net/teg/TaskEasy/Reports/';
const img_url = 'https://nsfarm-images.straviso.net/';
const redirect_url = "https://mytaskeasy.straviso.net/_hash_/login/";
const loginManager = "https://mytaskeasy.straviso.net/nsauth/"
const home_url = "https://taskeasydev.straviso.net/#/dashboard";
const northstar_url = "https://mytaskeasy.straviso.net:30011/";
const logo_url = "https://northstar-portal.straviso.net/";
const uploadUrl = 'https://mytaskeasy.straviso.net/teg/TaskEasy/Core/';
const contactus_url = "https://straviso.com/#/contact";
const signUp = 'https://mytaskeasy.straviso.net/teg/TaskEasy/Core/';
const signIn = "https://mytaskeasy.straviso.net/nsauth/";
const logout_url = "https://northstar-portal.straviso.net/#/";
const mytaskeasy_url = "https://mytaskeasy.straviso.net/#/";
// const ticketDahs_url ="https://ticketing-nsfarm.straviso.net:9094/ticket/core/";
const ticketDahs_url= "https://nsfarm.straviso.net/tmg/ticket/core/"
// const ticketDahs_url ="https://nsdev.straviso.net/tmg/ticket/core/"
const northstarUrl = 'https://mytaskeasy.straviso.net/nsg/NorthStar/Core/';

 

// https://mytaskeasy-api.straviso.net/
const help_url = "https://mytaskeasy.straviso.net/nsg/NorthStar/Core/helpMenu/findAll";
const supportToken = 'https://mytaskeasy.straviso.net/nsg/NorthStar/Core/';
const configUrl = 'https://mytaskeasy.straviso.net/nsg/NorthStar/BCS/contextDetails/';
const CommunicationDetailBCS = 'https://mytaskeasy.straviso.net/nsg/NorthStar/BCS/contextDetails/addBusinessCommunicationDetails';
const paymentUrl="https://mytaskeasy.straviso.net/nsg/MyTaskEasy/Payment/";
const hotReadsUrl="https://hotreads.straviso.net/api/saveEmailFromHRVideo";

 


// https://mytaskeasy-api.straviso.net/

 

const logOutUrl='https://mytaskeasy.straviso.net/nsg/NorthStar/Core/';
const signInUrl='https://mytaskeasy.straviso.net/nsg/';
const deleteBusinessUrl='https://mytaskeasy-ops.straviso.net:9097/BDU/' ;
const trackUserLoginUrl='https://mytaskeasy.straviso.net/nsauth/auth/trackUserLogin';
const trackUserLoginUrlV1='https://mytaskeasy.straviso.net/nsauth/auth/trackUserLoginV1';
const updateUserProfileUrl='https://mytaskeasy.straviso.net/teg/TaskEasy/Core/user/';
//Template Creation urls
const templateMTE='https://mytaskeasy.straviso.net/task/task/';
//template Report
const templateReportMTE ='https://mytaskeasy.straviso.net/report/'
//template urls
const template_base_url = 'https://nsdev-ops.straviso.net/bop/NSBusinessOperations/Core/mte/';
export const environment = {
production:true,
mainurl:base_url,
help_url:help_url,
reporturl:report_url,
mytaskeasy_url:mytaskeasy_url,
logo_url:logo_url,
northstar_url:northstar_url,
northStarLogOut:"https://northstar-portal.straviso.net/#/home",
img_url:img_url,
loginurl:base_url + 'User/',
redirectURL:redirect_url,
upload_url:uploadUrl,
sign_up:signUp,
sign_in:signIn,
contactus_url:contactus_url,
azureuserloginurl:loginManager + 'oauth2/authorize/azure?redirect_uri=' + redirect_url,
googleuserloginurl:loginManager + 'oauth2/authorize/google?redirect_uri=' + redirect_url,
home_url:home_url,
northstar_Loginurl:northstar_Loginurl,
logout_url:logout_url,
ticketDahs_url: ticketDahs_url,
northstarUrl: northstarUrl,
supportToken: supportToken,
configUrl:configUrl,
bcsCommunicationDetail:CommunicationDetailBCS,
paymentUrl:paymentUrl,
hotReadsUrl:hotReadsUrl,
logOutUrl:logOutUrl,
signInUrl:signInUrl,
deleteBusinessUrl:deleteBusinessUrl,
trackUserLoginUrl:trackUserLoginUrl,
trackUserLoginUrlV1:trackUserLoginUrlV1,
updateUserProfileUrl:updateUserProfileUrl,
templateMTE:templateMTE,
templateReportMTE:templateReportMTE,
template_base_url:template_base_url
};

