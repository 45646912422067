import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { PasswordExpiredComponent } from './components/authentication/password-expired/password-expired.component';
import { VerifyComponent } from './components/authentication/verify/verify.component';
import { TasksComponent } from './components/dashboard/tasks/tasks.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'dashboard', loadChildren:() =>import('./components/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'dashboard', component: DashboardComponent },
   { path: 'login', loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'password-expired', component: PasswordExpiredComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'report', loadChildren: () => import('./components/report/report.module').then(m => m.ReportModule) },
  { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'task', component: TasksComponent },
  { path: 'products', loadChildren: () => import('./components/admin/products/products.module').then(m => m.ProductsModule) },
  { path: 'projects', loadChildren: () => import('./components/admin/projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'users', loadChildren: () => import('./components/admin/users/users.module').then(m => m.UsersModule) },
  { path: 'task-status', loadChildren: () => import('./components/admin/task-status/task-status.module').then(m => m.TaskStatusModule) },
  { path: 'admin-list', loadChildren: () => import('./components/admin/admin-list/admin-list.module').then(m => m.AdminListModule) },
  { path: 'task-type', loadChildren: () => import('./components/admin/task-type/task-type.module').then(m => m.TaskTypeModule) },
  { path: 'profile', loadChildren: () => import('./components/useraccount/useraccount.module').then(m => m.UseraccountModule) },
  { path: 'user-group', loadChildren: () => import('./components/admin/usergroup/usergroup.module').then(m => m.UsergroupModule) },
  { path: 'audit-log', loadChildren: () => import('./components/admin/auditlog/auditlog.module').then(m => m.AuditlogModule) },
  { path: 'configuration', loadChildren: () => import('./components/configuration/configuration.module').then(m => m.ConfigurationModule) },
  { path: 'user-role', loadChildren: () => import('./components/admin/user-role/user-role.module').then(m => m.UserRoleModule) },
  { path: 'invoices', loadChildren: () => import('./components/admin/invoices/invoices.module').then(m => m.InvoicesModule) },
  { path: 'payments', loadChildren: () => import('./components/admin/payments/payments.module').then(m => m.PaymentsModule) },
  { path: 'priority', loadChildren: () => import('./components/admin/priority/priority.module').then(m => m.PriorityModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
