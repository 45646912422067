import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  PipeTransform,
  Pipe,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  FormGroupDirective,
} from "@angular/forms";
import { MessageService } from "primeng/api";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LoginService } from "../../../services/login.service";
import { environment } from "../../../../environments/environment";
import { RatingModule } from "primeng/rating";
import { SharedService } from "../../../services/shared.service";
import { TaskDataService } from "../../../services/task-data.service";
import * as moment from "moment";
import * as fileSaver from "file-saver";
import { newArray } from "@angular/compiler/src/util";
import { UserService } from "../../../services/user.service";
import { debounce } from "rxjs/operators";
import { saveAs } from "file-saver";
import { ConfirmationService } from "primeng/api";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { element } from "protractor";

@Component({
  selector: "app-tasks",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.scss"],
  //  encapsulation: ViewEncapsulation.None
})
export class TasksComponent implements OnInit {
  @Input() showAddTaskData;
  @Input() addTaskId;
  @Input() viewDialogueBox;
  @Input() closeViewDialogBox;
  @Input() viewDialogueBoxData;
  @Input() viewTaskId;
  @Output() closeEventView = new EventEmitter();
  @Output() closeEventAdd = new EventEmitter();
  @Output() closeIconView = new EventEmitter();
  @ViewChild("testInput") testInput;

  displayBasic: boolean;
  displayFP = false;
  displayFPS = false;
  displayFPImage = false;
  displayFeedback = false;
  displayView = false;
  createForm: FormGroup;
  files: any = [];
  selectedBtn: string;
  currentDate: any;
  lastModified: string;
  userData: any;
  minDueDate: any;
  isinvalid: boolean;
  taskCount: any;
  taskType: string;
  ticketType: string;
  setAssigneeGroupFlag: boolean;
  selectedUserId: string;
  dtWatchlist: any;
  selectedUsername: string;
  userGroups: any;
  taskData: any;
  viewFormDisabled = true;
  headerNameData = "View Task";
  fileSize: boolean;
  createSidebar: boolean;
  setAssigneeFlag: boolean;
  product: any[];
  file: File[] = [];
  projects = [];
  dummydata = [];
  priority: { name: string; code: string }[];
  taskTypeData: { name: string; code: string }[];
  UserList: any[];
  UserListEmpEdit: any[];
  UserListEmp: any[];
  UserGroupList: any[];
  selectedBtns = "";
  setAssigneeGroupFlags: boolean;
  timeValue: string;
  viewForm: FormGroup;
  userIDNew: any;
  ArrayOfSelectedFile: any;
  urls = [];
  filesData = [];
  invoiceForm: any;
  checklistArray = [];
  checklistFinal: any[];
  isStatusSelected: boolean = false;
  dateUpdated: boolean;
  isAssigneeUpdated: boolean;
  isTaskUpdated: boolean;
  selectedTaskId: any;
  viewSidebar: boolean;
  comment: boolean = false;
  taskDataForView: any;
  fileUrl: any;
  taskDataForEdit: any;
  StatusList: any = [];
  taskNameData: any;
  dateError: boolean;
  ngPrimeTime: any;
  srcc: any;
  inputText: any;
  currVerifiedLoanOfficerPhoto: any;
  Status: any;
  checkRespo: any;
  invoiceForms: any;
  readonlyAtt = true;
  checklistFinals: any;
  taskIdData: any;
  displayComment: boolean = true;
  position: string;
  addedComment: string;
  displayHistory;
  positionHistory: string;
  displayHistoryData = false;
  HistoryData = [];
  commnetArray = [];
  displaytaskrecurrence: boolean;
  tableTaskIdData: any;
  tableTaskNameData: any;
  tableTaskTypeData: any;
  tableProjectList: any;
  tableProductList: any;
  tableAssignToList: {}[];
  tableCreatedByList: any;
  tablePriorityList: any;
  tableCreatedDate: any;
  tableStatusList: {}[];
  datepipe: any;
  tasks: any;
  tableDueDate: any;
  NewDatavalue: boolean;
  fileDetailImage: boolean;
  statusInput: any;
  checklist: boolean;
  parseUserData: number;
  parseUserDataEdit: number;
  privateTaskAllow: boolean;
  userDataNew: any;
  privateTaskFalse: boolean;
  isPrivateTaskData: boolean;
  onEditClickCheck = false;
  isValidatedManField: boolean = true;
  isValidatedNonManField: boolean = true;
  commnetArrays: any;
  displayWatchlist: boolean;
  watchlistForm: FormGroup;
  defaultData: any;
  selectedValue: any;
  createdByidElement: any;
  createdByName: any;
  watchlistNewData: any;
  watchListInput: any;
  map = new Map<String, any>();
  watchlistDropdown: any;
  watchlistSelected: any;
  watchlistChangeValueNew: any[];
  dtWatchlistNew = [];
  afile: any = "";
  unamePattern = "^[a-z0-9_-]{8,15}$";
  position1;
  /* recurrence */
  patternRadio;
  endRadio = "endby";
  recStartDate: Date;
  recEndDate: Date;
  dailyNumber;
  dailyRadio;
  noOfRec;
  weekno;
  selectedWeekday: string[] = [];
  monthlyRadio;
  monthNumber;
  monthNumber1;
  monthFirstddData = [
    { name: "First", code: "First" },
    { name: "Second", code: "Second" },
    { name: "Third", code: "Third" },
    { name: "Fourth", code: "Fourth" },
    { name: "Fifth", code: "Fifth" },
  ];
  monthdd;
  monthSecondddData = [
    { name: "Monday", code: "Monday" },
    { name: "Tuesday", code: "Tuesday" },
    { name: "Wednesday", code: "Wednesday" },
    { name: "Thursday", code: "Thursday" },
    { name: "Friday", code: "Friday" },
    { name: "Saturday", code: "Saturday" },
    { name: "Sunday", code: "Sunday" },
  ];
  monthdd1;
  monthNumber11;
  recurrenceReq: any;
  isNoEndDate: boolean = false;
  isEveryWeekDays: boolean = false;
  yearlyRadio;
  yearlyddData = [
    { name: "January", code: "January" },
    { name: "February", code: "February" },
    { name: "March", code: "March" },
    { name: "April", code: "April" },
    { name: "May", code: "May" },
    { name: "June", code: "June" },
    { name: "July", code: "July" },
    { name: "August", code: "August" },
    { name: "September", code: "September" },
    { name: "October", code: "October" },
    { name: "November", code: "November" },
    { name: "December ", code: "December " },
  ];
  yearDd1;
  yearDd2;
  yearDd3;
  yearDd4;
  yearNumber1;
  todayDate: Date = new Date();
  recurrenceData: any = [];
  _id;
  recurrenceMsg: string;
  isCreateRecurrence: boolean = false;
  isUpdateClick: boolean = false;
  lang: any;
  calendarValue: any;
  dynamicJson: any = [];
  dynamicJsonEdit: any = [];
  templateList: any = [];
  templateListArr: any = [];
  isValidated: boolean = false;
  multipleTemplateList: boolean = false;
  mandatoryArr: any = [];
  paramerterValue = "";
  TicketTypeDataSelected: any = "";
  selectedTempList: any = "";
  showError: boolean = false;
  textDescription: any;
  displayDialogue: boolean = false;
  displayDialogue2: boolean = false;
  displayDialogue1: boolean = false;
  textDescription2: any;
  textDescription1: any;
  dropDownArr: any = [];
  dropDownArrEdit: any = [];
  selectedValueMan: any = "";
  selectedValueManEdit: any = "";
  validationText: any = "";
  validationTextEdit: any = "";
  isLoaded: boolean = false;
  noBusinessAssigned: boolean=true;
  constructor(
    private TaskService: TaskDataService,
    private router: Router,
    private messageService: MessageService,
    private ngxLoader: NgxUiLoaderService,
    private _fb: FormBuilder,
    private User: UserService,
    private sanitizer: DomSanitizer,
    private domSanitizer: DomSanitizer,
    public _sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // this.unamePattern="[a-zA-Z ]*";
    this.ngxLoader.start();
    this.dynamicJson = [];
    this.paramerterValue = "";
    if (localStorage.getItem("auth_token")) {
      if (!this.viewTaskId) {
        this.userData=JSON.parse(localStorage.getItem("userData"));
        if(!this.userData){
          this.TaskService.getUserDetails()
          .subscribe(res => {
            if (res.result == "Success") {
              // this.userData = res.responseData;
              localStorage.setItem("userData", JSON.stringify(res.responseData));
              this.userData = JSON.parse(localStorage.getItem("userData"));
              console.log("this.userData****",this.userData);
              if (this.userData) {
                this.getProduct();
                this.getProject();
                this.gettasktype();
                this.getUser();
                this.getWatchlist();
                this.getUserGroupList();
                this.getAllPriority();
                this.getStatusList();
                this.getTemplateList();
              }
            }
          })
        }else{
        this.TaskService.getUserDetails().subscribe((res) => {
          if (res.result == "Success") {
            localStorage.setItem("userData", JSON.stringify(res.responseData));
          this.userData = JSON.parse(localStorage.getItem("userData"));
          console.log("this.userData****",this.userData);
          this.getProduct();
          this.getProject();
          this.gettasktype();
          this.getUser();
          this.getWatchlist();
          this.getUserGroupList();
          this.getAllPriority();
          this.getStatusList();
          this.getTemplateList();
      }
        });
      }
    } 
      else {
        this.product = JSON.parse(localStorage.getItem("productList"));
        this.projects = JSON.parse(localStorage.getItem("projectList"));
        this.taskTypeData = JSON.parse(localStorage.getItem("taskTypeList"));
        this.UserList = JSON.parse(localStorage.getItem("userList"));
        this.watchlistDropdown = JSON.parse(
          localStorage.getItem("watchlistDropdown")
        );
        this.UserGroupList = JSON.parse(localStorage.getItem("userGroupList"));
        this.priority = JSON.parse(localStorage.getItem("priorityList"));
        this.StatusList = JSON.parse(localStorage.getItem("statusList"));
        this.mainStatusList = JSON.parse(
          localStorage.getItem("mainStatusList")
        );
        this.userGroups = JSON.parse(localStorage.getItem("userGroups"));
      }
    }
    this.calendarValue = {
      firstDayOfWeek: 1,
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Tue", "Fri", "Sat"],
      dayNamesMin: ["S", "M", "T", "W ", "T", "F ", "S"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthNamesShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Today",
      clear: "Clear",
    };
    this.lang = localStorage.getItem("selectedLanguage");
    if (localStorage.getItem("selectedLanguage") == "en") {
      this.translateService.setTranslation(
        "en",
        JSON.parse(localStorage.getItem("englishLabels"))
      );
      this.translateService.use("en");
      this.calendarValue = {
        firstDayOfWeek: 1,
        dayNames: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Tue", "Fri", "Sat"],
        dayNamesMin: ["S", "M", "T", "W ", "T", "F ", "S"],
        monthNames: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        monthNamesShort: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        today: "Today",
        clear: "Clear",
      };
    } else if (localStorage.getItem("selectedLanguage") == "es") {
      this.translateService.setTranslation(
        "es",
        JSON.parse(localStorage.getItem("SpanishLabels"))
      );
      this.translateService.use("es");
      this.calendarValue = {
        firstDayOfWeek: 1,
        dayNames: [
          "domingo",
          "lunes",
          "martes",
          "miércoles",
          "jueves",
          "viernes",
          "sábado",
        ],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["Do", "Lu", "Ma", "mi", "Ju", "Vi", "Sá"],
        monthNames: [
          "enero",
          "febrero",
          "marzo",
          "abril",
          "mayo",
          "junio",
          "julio",
          "agosto",
          "septiembre",
          "octubre",
          "noviembre",
          "diciembre",
        ],
        monthNamesShort: [
          "ene",
          "feb",
          "mar",
          "abr",
          "may",
          "jun",
          "jul",
          "ago",
          "sep",
          "oct",
          "nov",
          "dic",
        ],
        today: "Hoy",
        clear: "Borrar",
      };
    } else if (localStorage.getItem("selectedLanguage") == "pr") {
      this.translateService.setTranslation(
        "pr",
        JSON.parse(localStorage.getItem("portugueseLabels"))
      );
      this.translateService.use("pr");
      this.calendarValue = {
        firstDayOfWeek: 1,
        dayNames: [
          "Domingo",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinta-feira",
          "Sexta-feira",
          "Sábado",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "sexta ", "Sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "sexta", "Sáb"],
        monthNames: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        monthNamesShort: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        today: "Today",
        clear: "Clear",
      };
    } else if (localStorage.getItem("selectedLanguage") == "hi") {
      this.translateService.setTranslation(
        "hi",
        JSON.parse(localStorage.getItem("hindiLabels"))
      );
      this.translateService.use("hi");
      this.calendarValue = {
        firstDayOfWeek: 1,
        dayNames: [
          "रविवार",
          "सोमवार",
          "मंगलवार",
          "बुधवार",
          "गुरुवार",
          "शुक्रवार",
          "शनिवार",
        ],
        dayNamesShort: ["रवि", "सोम", "मंगल", "बुध", "गुरु", "शुक्र", "शनि"],
        dayNamesMin: ["रवि", "सोम", "मंगल", "बुध", "गुरु", "शुक्र", "शनि"],
        monthNames: [
          "जनवरी",
          "फ़रवरी",
          "मार्च",
          "अप्रैल",
          "मई",
          "जून",
          "जुलाई",
          "अगस्त",
          "सितम्बर",
          "अक्टूबर",
          "नवम्बर",
          "दिसम्बर",
        ],
        monthNamesShort: [
          "जनवरी",
          "फ़रवरी",
          "मार्च",
          "अप्रैल",
          "मई",
          "जून",
          "जुलाई",
          "अगस्त",
          "सितम्बर",
          "अक्टूबर",
          "नवम्बर",
          "दिसम्बर",
        ],
        today: "Today",
        clear: "Clear",
      };
    } else if (localStorage.getItem("selectedLanguage") == "fr") {
      this.translateService.setTranslation(
        "fr",
        JSON.parse(localStorage.getItem("frenchLabels"))
      );
      this.translateService.use("fr");
      this.calendarValue = {
        firstDayOfWeek: 1,
        dayNames: [
          "Dimanche",
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
        ],
        dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
        monthNames: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        monthNamesShort: [
          "Jan",
          "Fév",
          "Mar",
          "Avr",
          "Mai",
          "Jun",
          "Jul",
          "Aoû",
          "Sep",
          "Oct",
          "Nov",
          "Déc",
        ],
        today: "Hoy",
        clear: "Borrar",
      };
    }
    let moment = require("moment");
    moment().format();

    this.invoiceForm = this._fb.group({
      checklist: this._fb.array([this.initItemRows()]),
    });

    this.invoiceForms = this._fb.group({
      checklist: this._fb.array([this.initItemRows()]),
    });

    const title = "hii";
    const dte = new Date();
    this.currentDate = moment(new Date()).format("MM/DD/YYYY, h:mm A");
    dte.setDate(dte.getDate() - 2);
    this.lastModified = dte.toString();
    this.taskType = localStorage.getItem("taskType");
    this.minDueDate = moment(new Date())._i;
    localStorage.removeItem("taskType");
    localStorage.removeItem("selectedTask");
    if (this.viewTaskId) {
      this.openViewDialogue(this.viewTaskId);
    }

    this.selectedValue = null;
    this.watchlistForm = new FormGroup({
      watchlistName: new FormControl(null, [
        Validators.required,
        Validators.pattern("^(?! ).*[a-zA-Z\\s]*"),
      ]),
      default: new FormControl(""),
    });

    this.createForm = new FormGroup({
      taskName: new FormControl(null, [
        Validators.required,
        Validators.pattern("^(?! ).*[a-zA-Z\\s]*"),
      ]),
      selectedProduct: new FormControl(null, [Validators.required]),
      selectedProject: new FormControl(null, [Validators.required]),
      selectWatchlist: new FormControl(""),
      selectedPriority: new FormControl(null, [Validators.required]),
      description: new FormControl("", Validators.required),
      dueDate: new FormControl(""),
      dueTime: new FormControl(""),
      selectedAssignTo: new FormControl(""),
      selectedAssignGroup: new FormControl(""),
      taskType: new FormControl("", Validators.required),
      privateTask: new FormControl(""),
      ticketType: new FormControl(""),
    });
    const formattedDate = new Date(0);
    this.createForm.controls.dueTime.setValue(formattedDate);

    this.viewForm = new FormGroup({
      taskName: new FormControl(null, [
        Validators.required,
        Validators.pattern("^(?! ).*[a-zA-Z\\s]*"),
      ]) /*  ^(?! )[a-zA-Z0-9:\\s]*$*/,
      selectedProduct: new FormControl(""),
      selectedProject: new FormControl(""),
      selectWatchlists: new FormControl(""),
      selectedPriority: new FormControl(""),
      description: new FormControl("", Validators.required),
      dueDate: new FormControl(""),
      dueTime: new FormControl(""),
      selectedAssignTo: new FormControl(""),
      selectedAssignGroup: new FormControl(""),
      taskType: new FormControl(""),
      privateTask: new FormControl(""),
      Status: new FormControl(""),
      ticketType: new FormControl(""),
    });
  }

  ngAfterViewInit() {}
  keysrt(key) {
    return function (a, b) {
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      return 0;
    };
  }

  showBasicDialog() {
    this.displayBasic = true;
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      for (let index = 0; index < event.target.files.length; index++) {
        let temp = event.target.files[index].name.split(".");
        let temptype = temp[temp.length - 1];
        event.target.files[index].customType = temptype;
        let b = event.target.files[index].size <= 1024 * 1024 * 5;
        if (b == false) {
          this.fileSize = true;
          let msg1;
          this.translateService.get("fileSizegreaterMsg").subscribe((txt) => {
            msg1 = txt;
          });
          this.messageService.add({ severity: "warn", summary: msg1 });
        } else {
          this.ArrayOfSelectedFile = event.target.files;
          if (this.ArrayOfSelectedFile) {
            for (const files of this.ArrayOfSelectedFile) {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                let objKey = {};
                event.target.files[index].imgSrc = e.target.result;
              };
              reader.readAsDataURL(files);
            }
          }
          this.files.push(event.target.files[index]);
        }
      }
    }
  }

  onFileChange2(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      for (let index = 0; index < event.target.files.length; index++) {
        let temp = event.target.files[index].name.split(".");
        let temptype = temp[temp.length - 1];
        event.target.files[index].contentType = temptype;
        event.target.files[index].fileName = event.target.files[index].name;
        let b = event.target.files[index].size <= 1024 * 1024 * 5; //5242880 bytes = 5MB
        if (b == false) {
          this.fileSize = true;
          let msg1;
          this.translateService.get("fileSizegreaterMsg").subscribe((txt) => {
            msg1 = txt;
          });
          this.messageService.add({ severity: "warn", summary: msg1 });
        } else {
          this.ArrayOfSelectedFile = event.target.files;
          if (this.ArrayOfSelectedFile) {
            for (const files of this.ArrayOfSelectedFile) {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                let objKey = {};
                event.target.files[index].imgSrc = e.target.result;
              };
              reader.readAsDataURL(files);
            }
          }
          this.files.push(event.target.files[index]);
        }
      }
    }
  }

  get formArr() {
    return this.invoiceForm.get("checklist") as FormArray;
  }

  get formArrs() {
    return this.invoiceForms.get("checklist") as FormArray;
  }

  initItemRows() {
    return this._fb.group({
      criteria: [""],
      checked: [false],
    });
  }

  saveComment() {
    this.isStatusSelected = false;
    this.ngxLoader.start();
    if (this.addedComment == "" || this.addedComment == undefined) {
      this.ngxLoader.stop();
      this.messageService.add({
        severity: "warn",
        summary: "Please Add Comment !",
      });
      return 0;
    }
    let reqJson = {
      taskId: this.selectedTaskId,
      comments: [
        {
          comment: this.addedComment,
          commentedBy: this.userData.userName,
        },
      ],
    };
    this.TaskService.addComment(reqJson).subscribe(
      (res) => {
        this.addedComment = "";
        let reqjson = {
          taskId: this.selectedTaskId,
        };
        this.TaskService.getTaskDetailById(reqjson).subscribe((res) => {
          this.taskDataForView = res.responseData;
          this.taskDataForEdit = res.responseData;
          // this.dynamicJson = res.responseData["AdditionalField"];
          // console.log("view template JSON---",this.dynamicJson);
          this.commnetArrays = this.taskDataForEdit.comments;
          if (
            typeof this.commnetArrays !== "undefined" &&
            this.commnetArrays !== null
          ) {
            this.commnetArray = this.commnetArrays.reverse();
          } else {
            this.commnetArray = this.taskDataForEdit.comments;
          }

          for (let i = 0; i < this.taskDataForEdit.comments.length; i++) {
            if (this.taskDataForEdit.comments[i].comment) {
              this.taskDataForEdit.comments[i].comment =
                this.sanitizer.bypassSecurityTrustHtml(
                  this.taskDataForEdit.comments[i].comment
                );
            }
          }
          this.isValidComment = false;
          this.isValidComment1 = false;
          this.ngxLoader.stop();
          let msg1;
          this.translateService
            .get("commentSuccessfullyAdded")
            .subscribe((txt) => {
              msg1 = txt;
            });
          this.messageService.add({ severity: "success", summary: msg1 });
        });
      },
      (error) => {}
    );
  }

  initItemRowss() {
    return this._fb.group({
      criteria: [""],
      checked: [false],
    });
  }

  addNewRow() {
    this.formArr.push(this.initItemRows());
  }
  addNewRows() {
    this.formArrs.push(this.initItemRowss());
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }

  deleteRowss(index: number) {
    this.formArrs.removeAt(index);
  }

  removeSelectedFile(index) {
    console.log(this.files);
    this.files.splice(index, 1);
  }

  fileRemoveArray: any = [];
  removedAttachmentId: any = [];
  removeSelectedFileEdit(index) {
    let req = {
      taskId: this.selectedTaskId,
      attachments: [
        {
          _id: this.files[index]._id,
        },
      ],
    };
    this.TaskService.removeAttachment(req).subscribe((res) => {
      this.fileRemoveArray.push(this.files[index]);
      this.files.splice(index, 1);
    });
  }

  setAssignee(value) {
    if (value.name == "-- None --") {
      this.setAssigneeFlag = false;
      this.selectedUserId = "";
      this.setAssigneeGroupFlags = false;
      this.selectedUsername = "";
    } else {
      this.setAssigneeGroupFlags = true;
      this.setAssigneeFlag = true;
      this.selectedUserId = value.code;
      this.selectedUsername = value.name;
    }
  }

  getUserGroupList() {
    this.TaskService.getUserGroupListActiveAndEnabled().subscribe(
      (res) => {
        this.UserGroupList = [];
        this.userGroups = res.responseData;
        localStorage.setItem("userGroups", JSON.stringify(this.userGroups));
        const temp1: any = {
          name: "-- None --",
          code: "Null",
        };
        this.UserGroupList.push(temp1);
        res.responseData.forEach((ele) => {
          const temp: any = {
            name: ele.userGroupName,
            code: ele.userGroupId ? ele.userGroupId : "Null",
          };
          this.UserGroupList.push(temp);
        });
        this.UserGroupList.sort(this.keysrt("name"));
        localStorage.setItem(
          "userGroupList",
          JSON.stringify(this.UserGroupList)
        );
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  getUser() {
    this.TaskService.getUserList().subscribe(
      (res) => {
        this.UserList = [];
        const temp1: any = {
          name: "-- None --",
          code: "Null",
        };
        this.UserList.push(temp1);
        res.responseData.forEach((ele) => {
          const temp: any = {
            name: ele.userName ? ele.userName : "Null",
            code: ele.userId,
          };
          this.UserList.push(temp);
        });

        this.UserList.sort(this.keysrt("name"));
        localStorage.setItem("userList", JSON.stringify(this.UserList));
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  onselfClick(data) {
    this.selectedUserId = this.userData.userId;
    this.selectedUsername = this.userData.userName;
    if (this.selectedBtns == "") {
      this.selectedBtns = data;
      this.setAssigneeGroupFlag = true;
      this.setAssigneeFlag = true;
      this.parseUserData = parseInt(this.userData.userGroupId);
      this.createForm.patchValue({
        selectedAssignTo: {
          name: this.userData.userName,
          code: this.userData.userId,
        },

        selectedAssignGroup: {
          name: this.userData.userGroup,
          code: this.parseUserData,
        },
      });
    } else {
      this.setAssigneeFlag = false;
      this.selectedBtns = "";
      this.setAssigneeGroupFlag = false;
      this.createForm.patchValue({
        selectedAssignTo: {
          name: "",
          code: "Null",
        },
        selectedAssignGroup: {
          name: "",
          code: "Null",
        },
      });
    }
  }

  onselfClickEdit(data) {
    this.selectedUserId = this.userData.userId;
    this.selectedUsername = this.userData.userName;
    if (this.selectedBtns == "") {
      this.selectedBtns = data;
      this.setAssigneeGroupFlag = true;
      this.setAssigneeFlag = true;
      this.parseUserDataEdit = parseInt(this.userData.userGroupId);
      this.viewForm.patchValue({
        selectedAssignTo: {
          name: this.userData.userName,
          code: this.userData.userId,
        },
        selectedAssignGroup: {
          name: this.userData.userGroup,
          code: this.parseUserDataEdit,
        },
      });
    } else {
      this.viewForm.patchValue({
        selectedAssignTo: {
          name: "",
          code: "Null",
        },
        selectedAssignGroup: {
          name: "",
          code: "Null",
        },
      });
      this.setAssigneeFlag = false;
      this.selectedBtns = "";
      this.setAssigneeGroupFlag = false;
    }
  }

  onDateBtnClick(selectedBtn) {
    this.selectedBtn = "";
    this.selectedBtn = selectedBtn;
    const todaydate = new Date();
    switch (selectedBtn) {
      case "Today":
        this.createForm.patchValue({
          dueDate: todaydate,
        });
        break;
      case "Tomorrow":
        const currentDate = new Date();
        const today = moment(new Date());
        const tomorrow = moment(new Date()).add(1, "days");
        const yesterday = moment(new Date()).add(-1, "days");

        this.createForm.patchValue({
          dueDate: tomorrow["_d"],
        });
        break;
      case "This Week":
        const saturday = moment().endOf("week");
        const newSaturday = moment(saturday["_d"]).format("YYYY-MM-DD");
        const newTodayDate = moment(todaydate).format("YYYY-MM-DD");
        if (newSaturday !== newTodayDate) {
          const friday = moment(saturday).add(-1, "days");
          this.createForm.patchValue({
            dueDate: friday["_d"],
          });
        } else {
          const friday = moment(saturday).add(6, "days");
          this.createForm.patchValue({
            dueDate: friday["_d"],
          });
        }
        break;
      default:
        break;
    }
  }

  onCloseDueDate(event) {
    this.selectedBtn = "";
  }

  onShowDueDate(event) {
    this.selectedBtn = "DATE";
  }

  onDateBtnClickview(selectedBtn) {
    this.selectedBtn = "";
    this.selectedBtn = selectedBtn;
    const todaydate = new Date();
    switch (selectedBtn) {
      case "Today":
        this.viewForm.patchValue({
          dueDate: todaydate,
        });
        break;
      case "Tomorrow":
        const currentDate = new Date();
        const today = moment(new Date());
        const tomorrow = moment(new Date()).add(1, "days");
        const yesterday = moment(new Date()).add(-1, "days");

        this.viewForm.patchValue({
          dueDate: tomorrow["_d"],
        });
        break;
      case "This Week":
        const saturday = moment().endOf("week");
        const newSaturday = moment(saturday["_d"]).format("YYYY-MM-DD");
        const newTodayDate = moment(todaydate).format("YYYY-MM-DD");
        if (newSaturday !== newTodayDate) {
          const friday = moment(saturday).add(-1, "days");
          this.viewForm.patchValue({
            dueDate: friday["_d"],
          });
        } else {
          const friday = moment(saturday).add(6, "days");
          this.viewForm.patchValue({
            dueDate: friday["_d"],
          });
        }
        break;
      default:
        break;
    }
  }

  getProject() {
    this.TaskService.getProjectListActiveAndEnabled().subscribe(
      (res) => {
        this.projects = [];
        const temp1: any = {
          name: "-- None --",
          code: "Null",
        };
        this.projects.push(temp1);
        res.responseData.forEach((ele) => {
          const temp: any = {
            name: ele.projectName,
            code: ele.projectId,
          };
          this.projects.push(temp);
        });
        this.projects.sort(this.keysrt("name"));
        localStorage.setItem("projectList", JSON.stringify(this.projects));
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  gettasktype() {
    this.TaskService.getAllTasktypeActiveAndEnabled().subscribe(
      (res) => {
        this.taskTypeData = [];
        const temp1: any = {
          name: "-- None --",
          code: "Null",
        };
        this.taskTypeData.push(temp1);
        res.responseData.forEach((ele) => {
          const temp: any = {
            name: ele.taskTypeName,
            code: ele.taskTypeName,
          };
          this.taskTypeData.push(temp);
        });
        this.taskTypeData.sort(this.keysrt("name"));

        localStorage.setItem("taskTypeList", JSON.stringify(this.taskTypeData));
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  openWatchlist() {
    this.displayWatchlist = true;
  }

  closeWatchlist() {
    this.displayWatchlist = false;
  }

  handler() {
    // show create dialog
  }

  addWatchlist(value) {
    this.ngxLoader.start();
    if (value.default == "") {
      this.defaultData = false;
    } else {
      this.defaultData = value.default;
    }
    const json = {
      watchlistName: value.watchlistName,
      defaultWatchlist: value.defaultData,
      createdById: this.userData.userId,
      createdByName: this.userData.userName,
    };
    this.TaskService.addWatchlist(json).subscribe(
      (res) => {
        this.ngxLoader.stop();
        console.log(res);
        this.taskCount = res.responseData;
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  getProduct() {
    this.TaskService.getProductListActiveAndEnabled().subscribe(
      (res) => {
        this.product = [];
        const temp1: any = {
          name: "-- None --",
          code: "Null",
        };
        this.product.push(temp1);
        res.responseData.forEach((ele) => {
          const temp: any = {
            name: ele.productName,
            code: ele.productId,
          };
          this.product.push(temp);
        });

        this.product.sort(this.keysrt("name"));
        localStorage.setItem("productList", JSON.stringify(this.product));
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }
  getTemplateList() {
    let businessId = localStorage.getItem("businessId");
    // this.ngxLoader.start();
    this.TaskService.getTemplateListByBusinessIdV1(businessId).subscribe(
      (res) => {
        this.templateList = [];
        if (res["status"] == "success") {
          // this.ngxLoader.stop();
          if (res["responseData"]["templateDetails"].length == 1) {
            this.multipleTemplateList = false;
            // let selectedTempList='';
            res["responseData"]["templateDetails"].forEach((ele) => {
              const temp: any = {
                name: ele.templateName,
                code: ele.templateName,
              };
              this.templateList.push(temp);
              console.log("this.templateList--", this.templateList);
            });
            this.selectedTempList = this.templateList[0].name;
            this.TaskService.getTemplatebyId(this.selectedTempList).subscribe(
              (res) => {
                if ((res["status"] = "success")) {
                  this.dynamicJson = res["responseData"]["additionalField"];

                  console.log(" this.dynamicJson----", this.dynamicJson);

                  for (let j = 0; j < this.dynamicJson.length; j++) {
                    if (
                      this.dynamicJson[j].parameterValue &&
                      this.dynamicJson[j].isMandatory == true
                    ) {
                      this.dynamicJson[j].isValueChanged = true;
                      this.dynamicJson[j].isValidatedExp = true;
                    }
                    let dummy = [];
                    let dropDownArr = [];
                    const temp1: any = {
                      name: "-- None --",
                      code: "Null",
                    };
                    dropDownArr.push(temp1);
                    if (this.dynamicJson[j].datatype == "dropdown") {
                      let arr = this.dynamicJson[j].displayName;
                      dummy = arr.split(",");
                      dummy.forEach((ele) => {
                        const temp: any = {
                          name: ele,
                          code: ele,
                        };
                        dropDownArr.push(temp);
                      });
                      this.dynamicJson[j]["dropNewValue"] = dropDownArr;
                      console.log("dropDownArr", dropDownArr);
                    let temp:any;
                      // let temp: any = {
                      //   // name: this.dynamicJson[j].parameterValue,
                      //   // code: this.dynamicJson[j].parameterValue,
                      // };
                      if (this.isCopyTask) {
                        temp = {
                          name: this.dynamicJsonEdit[j].parameterValue,
                          code: this.dynamicJsonEdit[j].parameterValue,
                        };
                      } else {
                        temp = {
                          name: this.dynamicJson[j].parameterValue,

                          code: this.dynamicJson[j].parameterValue,
                        };
                      }
                      this.dynamicJson[j]["selection"] = temp;
                      console.log(
                        "this.dynamicJson selection ---",
                        this.dynamicJson[j]["selection"]
                      );
                      // delete this.dynamicJsonEdit[j].parameterValue ;
                      // console.log("After Delete---",this.dynamicJsonEdit[j]);
                    }
                    if (this.dynamicJson[j].datatype == "employee") {
                      let url = this.dynamicJson[j].displayName;

                      this.TaskService.getEmployeeList(url).subscribe(
                        (res) => {
                          this.UserListEmp = [];
                          const temp1: any = {
                            name: "-- None --",
                            code: "Null",
                          };
                          this.UserListEmp.push(temp1);
                          res.responseData.forEach((ele) => {
                            const temp: any = {
                              name: ele.userName ? ele.userName : "Null",
                              code: ele.userName ? ele.userName : "Null",
                            };
                            this.UserListEmp.push(temp);
                          });

                          this.UserListEmp.sort(this.keysrt("name"));
                          // localStorage.setItem("userList", JSON.stringify(this.UserList));
                          this.dynamicJson[j]["dropNewValue"] =
                            this.UserListEmp;
                          // const temp: any = {
                          //   name: this.dynamicJson[j].parameterValue,
                          //   code: this.dynamicJson[j].parameterValue,
                          // };
                          let temp:any;
                          // let temp: any = {
                          //   // name: this.dynamicJson[j].parameterValue,
                          //   // code: this.dynamicJson[j].parameterValue,
                          // };
                          if (this.isCopyTask) {
                            temp = {
                              name: this.dynamicJsonEdit[j].parameterValue,
                              code: this.dynamicJsonEdit[j].parameterValue,
                            };
                          } else {
                            temp = {
                              name: this.dynamicJson[j].parameterValue,
    
                              code: this.dynamicJson[j].parameterValue,
                            };
                          }
                          this.dynamicJson[j]["selectionEmp"] = temp;
                        },
                        (error) => {
                          this.ngxLoader.stop();
                        }
                      );
                    }
                  }

                  console.log("view  this.dynamicJson", this.dynamicJson);
                  this.isValidated = this.dynamicJson.every((ele) =>
                    this.checkMandatory1(ele.isMandatory)
                  );
                  console.log("this.isValidated", this.isValidated);
                }
              }
            );
          } else if (res["responseData"]["templateDetails"].length == 0) {
            this.isValidated = true;
            this.isValidatedManField = true;
            this.isValidatedNonManField = true;
            this.noBusinessAssigned=false;
          } else if (res["responseData"]["templateDetails"].length > 1) {
            this.multipleTemplateList = true;

            // const temp1: any = {
            //   name: "-- None --",
            //   code: "Null",
            // };
            // this.templateList.push(temp1);
            res["responseData"]["templateDetails"].forEach((ele) => {
              const temp: any = {
                name: ele.templateName,
                code: ele.templateName,
              };
              this.templateList.push(temp);
              console.log("this.templateList--", this.templateList);


              if(this.isCopyTask){
                this.selectedTempList = this.templateList[0].name;
                this.TaskService.getTemplatebyId(this.selectedTempList).subscribe(
                  (res) => {
                    if ((res["status"] = "success")) {
                      this.dynamicJson = res["responseData"]["additionalField"];
    
                      console.log(" this.dynamicJson----", this.dynamicJson);
    
                      for (let j = 0; j < this.dynamicJson.length; j++) {
                        if (
                          this.dynamicJson[j].parameterValue &&
                          this.dynamicJson[j].isMandatory == true
                        ) {
                          this.dynamicJson[j].isValueChanged = true;
                          this.dynamicJson[j].isValidatedExp = true;
                        }
                        let dummy = [];
                        let dropDownArr = [];
                        const temp1: any = {
                          name: "-- None --",
                          code: "Null",
                        };
                        dropDownArr.push(temp1);
                        if (this.dynamicJson[j].datatype == "dropdown") {
                          let arr = this.dynamicJson[j].displayName;
                          dummy = arr.split(",");
                          dummy.forEach((ele) => {
                            const temp: any = {
                              name: ele,
                              code: ele,
                            };
                            dropDownArr.push(temp);
                          });
                          this.dynamicJson[j]["dropNewValue"] = dropDownArr;
                          console.log("dropDownArr", dropDownArr);
                        let temp:any;
                          // let temp: any = {
                          //   // name: this.dynamicJson[j].parameterValue,
                          //   // code: this.dynamicJson[j].parameterValue,
                          // };
                          if (this.isCopyTask) {
                            temp = {
                              name: this.dynamicJsonEdit[j].parameterValue,
                              code: this.dynamicJsonEdit[j].parameterValue,
                            };
                          } else {
                            temp = {
                              name: this.dynamicJson[j].parameterValue,
    
                              code: this.dynamicJson[j].parameterValue,
                            };
                          }
                          this.dynamicJson[j]["selection"] = temp;
                          console.log(
                            "this.dynamicJson selection ---",
                            this.dynamicJson[j]["selection"]
                          );
                          // delete this.dynamicJsonEdit[j].parameterValue ;
                          // console.log("After Delete---",this.dynamicJsonEdit[j]);
                        }
                        if (this.dynamicJson[j].datatype == "employee") {
                          let url = this.dynamicJson[j].displayName;
    
                          this.TaskService.getEmployeeList(url).subscribe(
                            (res) => {
                              this.UserListEmp = [];
                              const temp1: any = {
                                name: "-- None --",
                                code: "Null",
                              };
                              this.UserListEmp.push(temp1);
                              res.responseData.forEach((ele) => {
                                const temp: any = {
                                  name: ele.userName ? ele.userName : "Null",
                                  code: ele.userName ? ele.userName : "Null",
                                };
                                this.UserListEmp.push(temp);
                              });
    
                              this.UserListEmp.sort(this.keysrt("name"));
                              // localStorage.setItem("userList", JSON.stringify(this.UserList));
                              this.dynamicJson[j]["dropNewValue"] =
                                this.UserListEmp;
                              // const temp: any = {
                              //   name: this.dynamicJson[j].parameterValue,
                              //   code: this.dynamicJson[j].parameterValue,
                              // };
                              let temp:any;
                              // let temp: any = {
                              //   // name: this.dynamicJson[j].parameterValue,
                              //   // code: this.dynamicJson[j].parameterValue,
                              // };
                              // if (this.isCopyTask) {
                                temp = {
                                  name: this.dynamicJsonEdit[j].parameterValue,
                                  code: this.dynamicJsonEdit[j].parameterValue,
                                };
                             
                              this.dynamicJson[j]["selectionEmp"] = temp;
                            },
                            (error) => {
                              this.ngxLoader.stop();
                            }
                          );
                        }
                      }
    
                      console.log("view  this.dynamicJson", this.dynamicJson);
                      this.isValidated = this.dynamicJson.every((ele) =>
                        this.checkMandatory1(ele.isMandatory)
                      );
                      console.log("this.isValidated", this.isValidated);
                    }
                  }
                );
              }
            });
          }
        }
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }
  clearFormArray(formArray: FormArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  CancelCreateForm() {
    console.log("after copy", this.dynamicJson);
    this.dynamicJson.forEach((ele) => delete ele.isValueChanged);
    this.dynamicJson.forEach((ele) => delete ele.isValidatedExp);
    this.dynamicJson.forEach((ele) => delete ele.dropNewValue);
    this.dynamicJson.forEach((ele) => delete ele.selection);
    this.dynamicJson.forEach((ele) => delete ele.selectionEmp);
    this.getTemplateList();
    this.dynamicJson = [];
    this.clearFormArray(this.formArr);
    this.addNewRow();
    this.urls = [];
    this.files = [];
    this.selectedBtns = "";
    this.createForm.reset();
    this.isinvalid = false;
    this.fileSize = false;
    this.createSidebar = false;
    this.selectedBtn = "";
    this.selectedBtns = "";
    this.setAssigneeGroupFlags = false;
    this.setAssigneeFlag = false;
    this.setAssigneeGroupFlag = false;
    this.recurrenceMsg = null;
    const file = document.querySelector(".file") as HTMLInputElement;
    file.value = "";
    this.openFPFormFeedback();
    this.isValidated = false;
    this.isValidatedManField = true;
    this.isValidatedNonManField = true;
    this.isValidDesc = false;
    this.isValidDesc1 = false;
  }

  CancelViewForm() {
    this.addedComment = null;
    this.recurrenceMsg = null;
    this.invoiceForms.reset();
    this.viewForm.reset();
    this.viewDialogueBox = false;
    this.urls = [];
    this.files = [];
    this.selectedBtns = "";
    this.isinvalid = false;
    this.fileSize = false;
    this.createSidebar = false;
    this.selectedBtn = "";
    this.selectedBtns = "";
    this.setAssigneeGroupFlags = false;
    this.setAssigneeFlag = false;
    this.setAssigneeGroupFlag = false;
    this.recurrenceMsg = null;
    this.isStatusSelected = false;
    this.isValidDesc = false;
    this.isValidDesc1 = false;
    this.isValidated = false;
    this.isValidatedManField = true;
    this.isValidatedNonManField = true;
    const file = document.querySelector(".file") as HTMLInputElement;
    file.value = "";
    if (this.isUpdateClick == false) {
      this.closeViewDialogBox = false;
      this.closeIconView.emit(this.closeViewDialogBox);
      this.closeEventView.emit(this.viewDialogueBox);
    } else {
      this.closeViewDialogBox = true;
      this.closeIconView.emit(this.closeViewDialogBox);
      this.closeEventView.emit(this.viewDialogueBox);
    }
  }

  CallDashboardApi() {
    this.NewDatavalue = false;
    this.closeEventAdd.emit(this.NewDatavalue);
  }
  CallDashboardApiAfterEdit() {
    this.NewDatavalue = false;
    this.closeEventView.emit(this.NewDatavalue);
  }
  openFPFormFeedback() {
    this.watchlistDropdown = JSON.parse(
      localStorage.getItem("watchlistDropdown")
    );
    this.invoiceForm.reset();
    while (this.formArr.length !== 0) {
      this.formArr.removeAt(0);
    }
    this.urls = [];
    this.files = [];
    this.displayFeedback = !this.displayFeedback;
    this.selectedBtn = "";
    this.selectedBtns = "";
    this.setAssigneeGroupFlags = false;
    this.setAssigneeFlag = false;
    this.setAssigneeGroupFlag = false;
    this.createForm.reset();
    this.isValidated = false;
    this.isValidatedManField = true;
    this.isValidatedNonManField = true;
    this.checklist = false;
    this.deleteRow(0);
    this.checklistFinal = [];
    this.checklistArray = [];
    if (this.isCopyTask == true && this.isTaskCreated == true) {
      this.viewDialogueBox = false;
      this.closeViewDialogBox = true;
      this.closeIconView.emit(this.closeViewDialogBox);
      this.closeEventView.emit(this.viewDialogueBox);
    } else {
      this.viewDialogueBox = false;
      this.closeViewDialogBox = false;
      this.closeIconView.emit(this.closeViewDialogBox);
      this.closeEventView.emit(this.viewDialogueBox);
    }
    this.isCopyTask = false;
    this.isValidDesc = false;
    this.isValidDesc1 = false;

    this.isValidPriority = false;
    this.isValidPriority1 = false;
    this.isValidProduct = false;
    this.isValidProduct1 = false;
    this.isValidProject = false;
    this.isValidProject1 = false;
    this.getTemplateList();
  }
  closeDialog() {
    this.invoiceForms.reset();
    this.viewDialogueBox = false;
    this.closeEventView.emit(this.viewDialogueBox);
  }
  openviewTaskForm() {
    this.displayView = !this.displayView;
  }

  editFormOpen() {
    this.headerNameData = "Edit Task";
    this.viewForm.patchValue({
      ticketType: {
        name: this.taskDataForEdit.ticketType,
        code: this.taskDataForEdit.ticketType,
      },
    });
    this.readonlyAtt = false;
    this.onEditClickCheck = true;
    this.viewFormDisabled = false;
    this.isValidated = true;
  }

  utcToLocal(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  existedWatchlistNames = [];

  openViewDialogue(id) {
    this.isStatusSelected = false;
    this.isValidDesc = false;
    this.isValidDesc1 = false;
    this.isValidated = false;
    this.isValidatedManField = true;
    this.isValidatedNonManField = true;
    this.ngxLoader.start();
    console.log("this.templateList ----", this.templateList);

    localStorage.removeItem("taskId");
    this.addedComment = null;
    this.isStatusSelected = false;
    this.isAssigneeUpdated = false;
    this.dateUpdated = false;
    this.isTaskUpdated = false;
    this.selectedTaskId = id;
    this.createdByidElement = "";
    localStorage.setItem("selectedTask", this.selectedTaskId);
    this.getHistory();
    this.getRecurrence(id);
    this.viewSidebar = true;
    this.comment = false;
    this.isValidDesc = false;
    this.isValidDesc1 = false;
    this.isValidPriority = false;
    this.isValidPriority1 = false;
    this.isValidProduct = false;
    this.isValidProduct1 = false;
    this.isValidProject = false;
    this.isValidProject1 = false;
    if (this.viewDialogueBoxData == "true") {
      this.readonlyAtt = false;
      this.headerNameData = "Edit Task";
      this.viewFormDisabled = false;
    } else {
      this.readonlyAtt = true;
      this.viewFormDisabled = true;
    }
    let reqjson = {
      taskId: id,
    };
    this.userDataNew = JSON.parse(localStorage.getItem("userData"));

    let watchlistreqjson = {
      taskId: id,
      createdById: this.userDataNew.userId,
    };
    this.ngxLoader.start();
    let businessId = localStorage.getItem("businessId");
    this.TaskService.getTemplateListByBusinessIdCloneV1(businessId).subscribe(
      (res) => {
        if (res["status"] == "success") {
          this.templateListArr = [];
          // const temp1: any = {
          //   name: "-- None --",
          //   code: "Null",
          // };
          // this.templateListArr.push(temp1);
          res["responseData"]["templateDetails"].forEach((ele) => {
            const temp: any = {
              name: ele.templateName,
              code: ele.templateName,
            };
            this.templateListArr.push(temp);
            console.log("this.templateList--", this.templateListArr);
            if(this.templateListArr.length==0){
              this.noBusinessAssigned=false;
            }
          });
          this.dynamicJsonEdit = [];
          this.ngxLoader.start();
          this.TaskService.getTaskDetailById(reqjson).subscribe(
            (res) => {
              this.taskDataForEdit = res.responseData;

              console.log("templateList------View", this.templateListArr);
              for (let i = 0; i < this.templateListArr.length; i++) {
                if (
                  this.templateListArr[i]["name"] ==
                  this.taskDataForEdit.ticketType
                ) {
                  this.dynamicJsonEdit = res.responseData["AdditionalField"];

                  for (let j = 0; j < this.dynamicJsonEdit.length; j++) {
                    if (
                      this.dynamicJsonEdit[j].parameterValue &&
                      this.dynamicJsonEdit[j].isMandatory == true
                    ) {
                      this.dynamicJsonEdit[j].isValueChanged = true;
                      this.dynamicJsonEdit[j].isValidatedExp = true;
                    }
                    let dummy = [];
                    let dropDownArrEdit = [];
                    const temp1: any = {
                      name: "-- None --",
                      code: "Null",
                    };
                    dropDownArrEdit.push(temp1);
                    if (this.dynamicJsonEdit[j].datatype == "dropdown") {
                      let arr = this.dynamicJsonEdit[j].displayName;
                      dummy = arr.split(",");
                      dummy.forEach((ele) => {
                        const temp: any = {
                          name: ele,
                          code: ele,
                        };
                        dropDownArrEdit.push(temp);
                      });
                      this.dynamicJsonEdit[j]["dropNewValue"] = dropDownArrEdit;
                      console.log("dropDownArrEdit", dropDownArrEdit);

                      const temp: any = {
                        name: this.dynamicJsonEdit[j].parameterValue,
                        code: this.dynamicJsonEdit[j].parameterValue,
                      };

                      this.dynamicJsonEdit[j]["selection"] = temp;
                      console.log(
                        "this.dynamicJsonEdi selection ---",
                        this.dynamicJsonEdit[j]["selection"]
                      );
                    }

                    if (this.dynamicJsonEdit[j].datatype == "employee") {
                      let url = this.dynamicJsonEdit[j].displayName;

                      this.TaskService.getEmployeeList(url).subscribe(
                        (res) => {
                          this.UserListEmpEdit = [];
                          const temp1: any = {
                            name: "-- None --",
                            code: "Null",
                          };
                          this.UserListEmpEdit.push(temp1);
                          res.responseData.forEach((ele) => {
                            const temp: any = {
                              name: ele.userName ? ele.userName : "Null",
                              code: ele.userName ? ele.userName : "Null",
                            };
                            this.UserListEmpEdit.push(temp);
                          });

                          this.UserListEmpEdit.sort(this.keysrt("name"));
                          this.dynamicJsonEdit[j]["dropNewValue"] =
                            this.UserListEmpEdit;
                          // for(let k=0;k<this.UserListEmpEdit.length;k++){

                          //  if(this.UserListEmpEdit[k]['name']==this.dynamicJsonEdit[j].parameterValue){
                          const temp: any = {
                            name: this.dynamicJsonEdit[j].parameterValue,
                            code: this.dynamicJsonEdit[j].parameterValue,
                          };

                          this.dynamicJsonEdit[j]["selectionEmp"] = temp;
                          console.log(
                            "this.dynamicJsonEdit[j]********",
                            this.dynamicJsonEdit[j]["selectionEmp"]
                          );
                          //  }

                          // }
                        },
                        (error) => {
                          this.ngxLoader.stop();
                        }
                      );
                    }
                  }

                  console.log("view  this.dynamicJson", this.dynamicJsonEdit);
                  break;
                }
              }

              this.taskNameData = this.taskDataForEdit["taskName"];
              this.createdByidElement = this.taskDataForEdit["createdById"];
              this.createdByName = this.taskDataForEdit["createdByName"];
              console.log("new createdByName : " + this.createdByName);
              if (this.taskDataForEdit["privateTask"] == true) {
                this.isPrivateTaskData = true;
                this.userDataNew = JSON.parse(localStorage.getItem("userData"));
                if (
                  this.taskDataForEdit["createdById"] == this.userDataNew.userId
                ) {
                  this.privateTaskFalse = false;
                  this.privateTaskAllow = true;
                } else {
                  this.privateTaskFalse = true;
                  this.privateTaskAllow = false;
                }
              } else {
                this.isPrivateTaskData = false;
              }
              this.inputText = this.taskDataForEdit.taskDescription;
              if (this.taskDataForEdit["dueDate"] !== null) {
                this.taskDataForEdit.dueDate = new Date(
                  this.taskDataForEdit.dueDate
                );
              } else {
                this.taskDataForEdit.dueDate = this.taskDataForEdit.dueDate;
              }
              if (this.taskDataForEdit.checklist) {
                if (typeof this.taskDataForEdit.checklist[0] !== "undefined") {
                  if (this.taskDataForEdit.checklist[0].criteria == null) {
                    this.checklist = false;
                  } else {
                    this.checklist = true;
                  }
                } else {
                  this.checklist = true;
                }
                this.ngxLoader.start();
                this.taskDataForEdit.checklist.forEach((res) => {
                  this.checkRespo = res;
                  console.log(this.checkRespo);
                  console.log(this.checkRespo.checked);
                  this.formArrs.push(
                    this._fb.group({
                      criteria: [this.checkRespo.criteria],
                      checked: [this.checkRespo.checked],
                    })
                  );
                });
              }
              this.deleteRowss(0);
              this.taskIdData = this.taskDataForEdit.taskId;
              this.commnetArrays = this.taskDataForEdit.comments;
              if (
                typeof this.commnetArrays !== "undefined" &&
                this.commnetArrays !== null
              ) {
                console.log(this.commnetArray);
                this.commnetArray = this.commnetArrays.reverse();
                console.log(this.commnetArray);
              } else {
                this.commnetArray = this.taskDataForEdit.comments;
              }
              this.ngxLoader.start();
              this.userData = JSON.parse(localStorage.getItem("userData"));
              this.watchlistChangeValueNew = [];
              this.watchListInput = this.taskDataForEdit["watchlist"];
              if (this.watchListInput) {
                Object.keys(this.watchListInput).map((key) => {
                  console.log("key: " + key);
                  console.log("value: " + this.watchListInput[key]);
                  if (key == this.userData.userId) {
                    console.log("inside value: " + this.watchListInput[key]);
                    let valuesList = this.watchListInput[key];
                    console.log(valuesList);
                    this.dtWatchlistNew = valuesList;
                  }
                });
              }
              let finalDate;
              if (this.taskDataForEdit.dueDate != null) {
                let date = new Date(this.taskDataForEdit.dueDate)
                  .toISOString()
                  .trim();
                let dueDate = date.split("-");
                let day = dueDate[2].split("T");
                finalDate = dueDate[1] + "/" + day[0] + "/" + dueDate[0];
              } else {
                finalDate = null;
              }
              if (this.priority != null) {
                for (let i = 0; i < this.priority.length; i++) {
                  if (this.taskDataForEdit.priority == this.priority[i].name) {
                    this.viewForm.patchValue({
                      selectedPriority: {
                        name: this.priority[i].name,
                        code: this.priority[i].code,
                      },
                    });
                  }
                }
              }

              this.viewForm.patchValue({
                taskName: this.taskDataForEdit.taskName,
                selectedProduct: {
                  name: this.taskDataForEdit.productName,
                  code: this.taskDataForEdit.productId,
                },
                selectedProject: {
                  name: this.taskDataForEdit.projectName,
                  code: this.taskDataForEdit.projectId,
                },
                description: this.taskDataForEdit.taskDescription,

                dueDate: finalDate,
                dueTime: this.taskDataForEdit.estimatedTime,
                selectedAssignTo: {
                  name: this.taskDataForEdit.userName,
                  code: this.taskDataForEdit.userId,
                },
                selectedAssignGroup: {
                  name: this.taskDataForEdit.userGroupName,
                  code: this.taskDataForEdit.userGroupId,
                },
                taskType: {
                  name: this.taskDataForEdit.taskType,
                  code: this.taskDataForEdit.taskType,
                },
                ticketType: {
                  name: this.taskDataForEdit.ticketType,
                  code: this.taskDataForEdit.ticketType,
                },
                privateTask: this.taskDataForEdit.privateTask,
                // ticketType: this.taskDataForEdit.ticketType,
              });
              if (this.viewFormDisabled) {
                this.viewForm.patchValue({
                  ticketType: this.taskDataForEdit.ticketType,
                });
              }
              console.log("this.viewForm---", this.viewForm);
              console.log(this.StatusList);
              this.statusInput = this.taskDataForEdit["status"];
              if (this.StatusList != null) {
                if (this.StatusList.length != 0) {
                  this.StatusList.forEach((ele) => {
                    if (ele.name == this.taskDataForEdit["status"]) {
                      this.viewForm.patchValue({
                        Status: ele,
                      });
                    }
                  });
                }
              }

              if (
                this.taskDataForEdit.attachments != null &&
                this.taskDataForEdit.attachments[0] != null
              ) {
                this.files = this.taskDataForEdit.attachments;
                for (
                  var i = 0;
                  i < this.taskDataForEdit.attachments.length;
                  i++
                ) {
                  const data = this.taskDataForEdit.attachments[i].fileData;
                  let objectURL = "data:image/jpeg;base64," + data;
                  this.srcc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                  this.files[i].imgSrc = this.srcc;
                }
                console.log(this.srcc);
              }
              if (this.taskDataForEdit.comments != null) {
                for (let i = 0; i < this.taskDataForEdit.comments.length; i++) {
                  if (this.taskDataForEdit.comments[i].comment) {
                    this.taskDataForEdit.comments[i].comment =
                      this.sanitizer.bypassSecurityTrustHtml(
                        this.taskDataForEdit.comments[i].comment
                      );
                  }
                }
              }

              this.ngxLoader.stop();
            },
            (error) => {
              this.ngxLoader.stop();
            }
          );
          this.TaskService.getWatchlistsByTaskId(watchlistreqjson).subscribe(
            (res) => {
              if (res.responseData && res.result == "Success") {
                res.responseData.forEach((element) => {
                  this.existedWatchlistNames.push(element.watchlistName);
                  this.dtWatchlistNew = this.existedWatchlistNames;
                });
              }
            }
          );
        }
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  findCommentandFile(data) {
    data.forEach((x) => {
      const reqjson = {
        taskId: x.taskId,
      };
      let taskNewData = "";
      this.TaskService.getTaskDetailById(reqjson).subscribe((res) => {
        taskNewData = res.responseData;
        x.comment = taskNewData["comments"];
        x.attachment = taskNewData["attachments"];
      });
    });
    return data;
  }

  getTaskById(req) {
    this.TaskService.getTaskByAction(req).subscribe(
      (res) => {
        this.taskData = res.responseData;
        this.taskData = this.findCommentandFile(this.taskData);
      },
      (error) => {}
    );
  }

  getTaskCount() {
    const json = {
      userId: this.userData.userId,
      userName: this.userData.userName,
    };
    this.TaskService.getTaskCount(json).subscribe(
      (res) => {
        this.taskCount = res.responseData;
      },
      (error) => {
        this.ngxLoader.stop();
      }
    );
  }

  getHistory() {
    const json = {
      taskId: this.selectedTaskId,
    };
    this.TaskService.getHistory(json).subscribe(
      (res) => {
        this.HistoryData = res.responseData;
      },
      (error) => {
        // this.ngxLoader.stop();
      }
    );
  }

  date(date) {
    if (date != null) {
      let temp = date.split("T");
      return temp[0];
    } else {
      return "";
    }
  }
  time(date) {
    if (date != null) {
      let temp = date.split("T");
      let temp2 = temp[1].split(".");
      return temp2[0];
    }
  }

  convertDate(str) {
    if (str != null && str != undefined) {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
  }

  onSelect($event) {
    const hour = new Date($event).getHours();
    const min = new Date($event).getMinutes();
    if (min < 10) {
      this.timeValue = `${hour}:0${min}`;
    } else {
      this.timeValue = `${hour}:${min}`;
    }
  }

  cancel() {
    this.displayFPImage = false;
  }

  showCommnetsDialogue() {
    this.position = "right";
    this.displayComment = true;
    this.displayHistory = false;
  }

  showHistoryDialogue() {
    this.positionHistory = "right";
    this.displayHistory = true;
    this.displayComment = false;
  }
  cancelComment() {
    this.displayComment = false;
    this.isValidComment = false;
  }

  cancelHistory() {
    this.displayHistory = false;
  }

  openImage(src) {
    this.currVerifiedLoanOfficerPhoto = src;
    this.displayFPImage = true;
  }

  b64toBlob(b64Data: any, contentType: any, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  downloadFile(fileDetail: any) {
    let res = {
      _id: fileDetail._id,
    };
    const blob = this.b64toBlob(fileDetail.fileData, fileDetail.contentType);
    fileSaver.saveAs(blob, fileDetail.fileName);
  }
  download(data: any) {
    const blob = this.b64toBlob(data.fileData, data.contentType);
    const url = window.URL.createObjectURL(blob);
    fileSaver.saveAs(blob, data.fileName);
  }

  isSetAssignee: boolean = false;
  setAssigneeGroup(value) {
    console.log(this.userGroups);
    if (value.name == "-- None --") {
      this.setAssigneeGroupFlag = false;
      this.setAssigneeGroupFlags = false;
      this.selectedUserId = "";
      this.selectedUsername = "";
    } else {
      this.setAssigneeGroupFlag = true;
      const temp = this.userGroups.findIndex((obj) => {
        return obj.userGroupId === value.code;
      });
      if (temp != null || temp != undefined || temp != -1) {
        this.isSetAssignee = true;
        this.selectedUserId = this.userGroups[temp].managerId;
        this.selectedUsername = this.userGroups[temp].manager;
      }
      if (this.selectedUserId == null) {
        let msg1;
        this.translateService
          .get("usergrpMngrNotAvailable.")
          .subscribe((txt) => {
            msg1 = txt;
          });
        this.messageService.add({ severity: "warn", summary: msg1 });
      }
    }
  }

  CreateTask(data) {
    console.log("userData",this.userData);
    console.log("data--", data);
    // console.log("dyanmic json--",this.dynamicJson);
    for (let i = 0; i < this.dynamicJson.length; i++) {
      if (this.dynamicJson[i]?.datatype == "dropdown") {
        if (this.dynamicJson[i]?.selection?.name == "-- None --") {
          this.dynamicJson[i].parameterValue = "";
        } else {
          this.dynamicJson[i].parameterValue =
            this.dynamicJson[i]?.selection?.name;
        }
      }
      if (this.dynamicJson[i]?.datatype == "employee") {
        if (this.dynamicJson[i]?.selectionEmp?.name == "-- None --") {
          this.dynamicJson[i].parameterValue = "";
        } else {
          this.dynamicJson[i].parameterValue =
            this.dynamicJson[i]?.selectionEmp?.name;
        }
      }
    }
    this.dynamicJson.forEach((ele) => delete ele?.isValueChanged);
    this.dynamicJson.forEach((ele) => delete ele?.isValidatedExp);
    this.dynamicJson.forEach((ele) => delete ele?.dropNewValue);
    this.dynamicJson.forEach((ele) => delete ele?.selection);
    this.dynamicJson.forEach((ele) => delete ele?.selectionEmp);
    // console.log("dynamic json-2",this.dynamicJson);
    let businessData = JSON.parse(localStorage.getItem("userPData"));
    this.checklistArray = this.invoiceForm?.value;
    this.checklistFinal = this.invoiceForm?.value?.checklist;
    if (data.ticketType == null) {
      this.TicketTypeDataSelected = this.selectedTempList;
    } else {
      this.TicketTypeDataSelected = data?.ticketType?.name;
    }
    if (this.createForm.valid) {
      this.ngxLoader.start();
      let date = this.convertDate(data.dueDate);
      const formData = new FormData();
      if (this.files.length === 0) {
        this.files = [];
        formData.append("file", this.files);
      } else {
        for (const file of this.files) {
          formData.append("file", file);
        }
      }

      if ((date == "" || date == "NaN-aN-aN") && this.selectedBtn != "") {
        date = this.selectedBtn;
      }
      if (
        data.selectedProject == "" ||
        data.selectedProject == null ||
        data?.selectedProject?.code == null ||
        data?.selectedProject?.name == null
      ) {
        data.selectedProject = "";
      }
      if (
        data.selectedProduct == "" ||
        data.selectedProduct == null ||
        data?.selectedProduct?.code == null ||
        data?.selectedProduct?.name == null
      ) {
        data.selectedProduct = "";
      }
      if (
        data.selectedAssignTo == "" ||
        data.selectedAssignTo == null ||
        data?.selectedAssignTo?.code == null ||
        data?.selectedAssignTo?.name == null
      ) {
        data.selectedAssignTo = "";
      }
      if (
        data.selectedPriority == "" ||
        data.selectedPriority == null ||
        data?.selectedPriority?.code == null ||
        data?.selectedPriority?.name == null
      ) {
        data.selectedPriority = "";
      }
      if (data.taskType == "" || data.taskType == null) {
        data.taskType = "";
      }
      if (data.ticketType == "" || data.ticketType == null) {
        data.ticketType = "";
      }
      if (data.privateTask == null || data.privateTask == "") {
        data.privateTask = false;
      }

      let watchlistObject = {};

      watchlistObject[this.userData.userId] = this.watchlistSelected;

      if (data.selectedAssignTo != null) {
        if (data.selectedAssignTo.name == "-- None --") {
          data.selectedAssignTo.name = null;
          data.selectedAssignTo.code = null;
        }
      }
      if (data.selectedPriority != null) {
        if (data.selectedPriority.name == "-- None --") {
          data.selectedPriority.name = null;
          data.selectedPriority.code = null;
        }
      }
      if (data.selectedProduct != null) {
        if (data.selectedProduct.name == "-- None --") {
          data.selectedProduct.name = null;
          data.selectedProduct.code = null;
        }
      }
      if (data.selectedProject != null) {
        if (data.selectedProject.name == "-- None --") {
          data.selectedProject.name = null;
          data.selectedProject.code = null;
        }
      }
      if (data.taskType != null) {
        if (data.taskType.name == "-- None --") {
          data.taskType.name = null;
          data.taskType.code = null;
        }
      }
      if (data.ticketType != null) {
        if (data.ticketType.name == "-- None --") {
          data.ticketType.name = null;
          data.ticketType.code = null;
        }
      }
      let selectedAssignGroupName, selectedAssignGroupCode;
      if (data.selectedAssignGroup != null) {
        if (data.selectedAssignGroup.name == "-- None --") {
          data.selectedAssignGroup.name = null;
          data.selectedAssignGroup.code = null;
        } else {
          selectedAssignGroupCode = data.selectedAssignGroup.code;
          selectedAssignGroupName = data.selectedAssignGroup.name;
        }
      } else {
        selectedAssignGroupName = null;
        selectedAssignGroupCode = null;
      }
      if (
        this.selectedUserId == "Null" &&
        this.selectedUsername == "-- None --"
      ) {
        this.selectedUsername = null;
        this.selectedUserId = null;
      }
      console.log("create data============", data);
      let taskDetails;
      if (this.recurrenceReq) {
        taskDetails = {
          taskName: data.taskName,
          taskDescription: data.description,
          createdById: this.userData.userId,
          createdByName: this.userData.userName,
          estimatedTime: data.dueTime,
          updatedBy: data.updatedBy,
          userName: this.selectedUsername,
          projectId: data.selectedProject.code,
          productId: data.selectedProduct.code,
          productName: data.selectedProduct.name,
          projectName: data.selectedProject.name,
          userId: this.selectedUserId,
          dueDate: date,
          priority: data.selectedPriority.name,
          checklist: this.checklistFinal,
          taskType: data.taskType.name,
          project: {
            projectName: data.selectedProject.name,
            projectId: data.selectedProject.code,
          },
          product: {
            productName: data.selectedProduct.name,
            productId: data.selectedProduct.code,
          },
          userGroupId: selectedAssignGroupCode,
          userGroupName: selectedAssignGroupName,
          watchlist: null,
          privateTask: data.privateTask,
          recurrence: this.recurrenceReq,
          businessName: businessData.businessName,
          // ticketType: {
          //   name: data.ticketType.name,
          //   code: data.ticketType.name,
          // },
          ticketType: this.TicketTypeDataSelected,
          AdditionalField: this.dynamicJson,
        };

        this.canceltaskRecurrence();
      } else {
        taskDetails = {
          taskName: data.taskName,
          taskDescription: data.description,
          createdById: this.userData.userId,
          createdByName: this.userData.userName,
          estimatedTime: data.dueTime,
          updatedBy: data.updatedBy,
          userName: this.selectedUsername,
          projectId: data.selectedProject.code,
          productId: data.selectedProduct.code,
          productName: data.selectedProduct.name,
          projectName: data.selectedProject.name,
          userId: this.selectedUserId,
          dueDate: date,
          priority: data.selectedPriority.name,
          checklist: this.checklistFinal,
          taskType: data.taskType.name,
          project: {
            projectName: data.selectedProject.name,
            projectId: data.selectedProject.code,
          },
          product: {
            productName: data.selectedProduct.name,
            productId: data.selectedProduct.code,
          },
          watchlist: null,
          privateTask: data.privateTask,
          businessName: businessData.businessName,
          userGroupId: selectedAssignGroupCode,
          userGroupName: selectedAssignGroupName,
          ticketType: this.TicketTypeDataSelected,
          // ticketType: {
          //   name: data.ticketType.name,
          //   code: data.ticketType.name,
          // },
          AdditionalField: this.dynamicJson,
        };
      }
      formData.append("taskDetails", JSON.stringify(taskDetails));

      this.TaskService.createTicket(formData).subscribe(
        (res) => {
          if (res.result == "Success") {
            this.isValidated = false;
            // new logic
            if (
              this.watchlistSelected != null &&
              this.watchlistSelected.length > 0
            ) {
              let reqJson = {
                taskId: res.responseData,
                watchlistNames: this.watchlistSelected,
                createdById: this.userData.userId,
              };
              this.TaskService.addTaskToWatchlist(reqJson).subscribe((res) => {
                if (res.result == "Success") {
                } else {
                }
              });
            }
            const req = {
              userId: this.userData.userId,
              basedOn: "Priority",
              action: "Task Assigned",
            };

            this.urls = [];
            this.files = [];
            this.createForm.reset();
            this.dynamicJson = [];
            this.CallDashboardApi();
            this.recurrenceReq = null;
            this.isTaskCreated = true;
            this.ngxLoader.stop();
            const msg = res.responseMessage.split(":");
            let s1;
            this.translateService.get("id").subscribe((res) => {
              s1 = res;
            });
            let msg1;
            this.translateService.get("taskCreatedSuccess").subscribe((res) => {
              msg1 = res;
            });
            this.messageService.add({
              severity: "success",
              summary: s1 + " - " + res.responseData + " " + msg1,
              detail: msg[1],
            });
            this.openFPFormFeedback();
            this.selectedBtns = "";
            this.selectedBtn = "";
            this.recurrenceMsg = null;
            this.setAssigneeGroupFlags = false;
            this.setAssigneeGroupFlag = false;
            this.setAssigneeFlag = false;
            this.invoiceForm.reset();
            this.checklistFinal = [];
            this.checklistArray = [];
          } else {
            this.urls = [];
            this.files = [];
            this.isTaskCreated = false;
            this.createForm.reset();
            let msg1;
            this.translateService.get("failedaddTask").subscribe((txt) => {
              msg1 = txt;
            });
            this.messageService.add({
              severity: "error",
              summary: msg1,
              detail: res.responseMessage,
            });
            this.openFPFormFeedback();
            this.selectedBtns = "";
            this.selectedBtn = "";
            this.recurrenceMsg = null;
            this.setAssigneeGroupFlags = false;
            this.setAssigneeGroupFlag = false;
            this.setAssigneeFlag = false;
            this.invoiceForm.reset();
            this.checklistFinal = [];
            this.checklistArray = [];
          }
        },
        (error) => {
          this.ngxLoader.stop();
          this.isTaskCreated = false;
          let msg1;
          this.translateService
            .get("errorwhileCreatingTask")
            .subscribe((txt) => {
              msg1 = txt;
            });
          this.messageService.add({ severity: "error", summary: msg1 });
        }
      );
    } else {
      this.selectedBtns = "";
      this.selectedBtn = "";
      this.isinvalid = true;
      this.recurrenceMsg = null;
      this.setAssigneeGroupFlags = false;
      this.setAssigneeGroupFlag = false;
      this.setAssigneeFlag = false;
      this.invoiceForm.reset();
      this.checklistFinal = [];
      this.checklistArray = [];
    }
  }

  showSelectedStatus: any;
  openCommentBox(Status) {
    this.showSelectedStatus = Status.name;
    this.isStatusSelected = false;
    for (let i = 0; i < this.mainStatusList.length; i++) {
      if (
        this.mainStatusList[i].statusId == Status.code &&
        this.mainStatusList[i].isCommentRequired == "Yes"
      ) {
        this.isStatusSelected = true;
        this.showCommnetsDialogue();
      }
    }
  }

  getWatchlist() {
    let json = {
      createdById: this.userData.userId,
    };
    this.User.getWatchlist(json).subscribe((res) => {
      this.watchlistDropdown = [];
      let List: any = res["responseData"];

      res["responseData"].forEach((ele) => {
        let temp2 = {
          label: ele.watchlistName,
          value: ele.watchlistName,
        };
        this.watchlistDropdown.push(temp2);
      });
      this.watchlistDropdown.sort(this.keysrt("label"));
      localStorage.setItem(
        "watchlistDropdown",
        JSON.stringify(this.watchlistDropdown)
      );
    });
  }

  onChangeWathlist(event) {
    this.watchlistSelected = event.value;
  }

  mainStatusList: any = [];
  getStatusList() {
    this.TaskService.getTaskStatusListActiveAndEnabled().subscribe((res) => {
      this.StatusList = [];
      this.mainStatusList = res["responseData"];
      let temp = {
        name: "-- None --",
        code: "null",
      };
      this.StatusList.push(temp);
      res["responseData"].forEach((ele) => {
        let temp2 = {
          name: ele.statusName,
          code: ele.statusId,
        };
        this.StatusList.push(temp2);
      });
      this.StatusList.sort(this.keysrt("name"));
      localStorage.setItem("statusList", JSON.stringify(this.StatusList));
      localStorage.setItem(
        "mainStatusList",
        JSON.stringify(this.mainStatusList)
      );
    });
  }

  UpdateTask(FormValues) {
    for (let i = 0; i < this.dynamicJsonEdit.length; i++) {
      if (this.dynamicJsonEdit[i].datatype == "dropdown") {
        // this.dynamicJsonEdit[i].parameterValue =
        //   this.dynamicJsonEdit[i].selection["name"];

        if (this.dynamicJsonEdit[i].selection.name == "-- None --") {
          this.dynamicJsonEdit[i].parameterValue = "";
        } else {
          this.dynamicJsonEdit[i].parameterValue =
            this.dynamicJsonEdit[i].selection["name"];
        }
      }
      if (this.dynamicJsonEdit[i].datatype == "employee") {
        // this.dynamicJsonEdit[i].parameterValue =
        //   this.dynamicJsonEdit[i].selectionEmp["name"];
        if (this.dynamicJsonEdit[i].selectionEmp.name == "-- None --") {
          this.dynamicJsonEdit[i].parameterValue = "";
        } else {
          this.dynamicJsonEdit[i].parameterValue =
            this.dynamicJsonEdit[i].selectionEmp["name"];
        }
      }
    }
    this.dynamicJsonEdit.forEach((ele) => delete ele.isValueChanged);
    this.dynamicJsonEdit.forEach((ele) => delete ele.isValidatedExp);
    this.dynamicJsonEdit.forEach((ele) => delete ele.dropNewValue);
    this.dynamicJsonEdit.forEach((ele) => delete ele.selection);
    this.dynamicJsonEdit.forEach((ele) => delete ele.selectionEmp);

    // this.dynamicJsonEdit.forEach((ele) =>  ele.parameterValue=ele.parameterValue['name']);
    //  this.dynamicJsonEdit=this.dynamicJsonEdit.parameterValue["name"];
    let businessData = JSON.parse(localStorage.getItem("userPData"));
    this.dateUpdated = false;
    this.checklistFinals = this.invoiceForms.value.checklist;
    if (this.viewForm.invalid) {
      this.dateError = true;
      return 0;
    } else {
      this.dateError = false;
    }
    this.ngxLoader.start();
    let date;
    let status;
    if (FormValues.dueDate != null && FormValues.dueDate != "") {
      date = this.convertDate(FormValues.dueDate);
    }
    const formData = new FormData();
    if (this.files.length === 0) {
      this.files = [];
      formData.append("file", "null");
    } else {
      for (let file of this.files) {
        formData.append("file", file);
      }
    }
    if (this.isSetAssignee == false) {
      if (
        FormValues.selectedUserId !== null ||
        FormValues.selectedUsername !== null
      ) {
        this.selectedUserId = FormValues.selectedAssignTo.code;
        this.selectedUsername = FormValues.selectedAssignTo.name;
      }
    }
    if (FormValues.selectedProduct.code == null) {
      FormValues.selectedProduct.code = null;
      FormValues.selectedProduct.name = null;
    }
    if (FormValues.selectedProject.code == null) {
      FormValues.selectedProject.code = null;
      FormValues.selectedProject.name = null;
    }
    if (FormValues.privateTask == null || FormValues.privateTask == "") {
      FormValues.privateTask = false;
    }
    if (FormValues.Status.code == null) {
      status = "Assigned";
    } else if (FormValues.Status == "") {
      status = "Assigned";
    } else if (
      FormValues.Status.code == "null" &&
      FormValues.Status.name == "-- None --"
    ) {
      status = null;
    } else {
      status = FormValues.Status.name;
    }

    if (
      this.selectedUserId == "Null" &&
      this.selectedUsername == "-- None --"
    ) {
      this.selectedUsername = null;
      this.selectedUserId = null;
    }
    let watchlistObject = {};
    watchlistObject[this.userData.userId] = this.watchlistSelected;
    if (this.watchlistSelected) {
      this.watchlistSelected = this.watchlistSelected;
    } else {
      this.watchlistSelected = [];
    }
    let reqJson = {
      taskId: this.selectedTaskId,
      newWatchlistNames: this.watchlistSelected,
      previousWatchlistNames: this.existedWatchlistNames,
      createdById: this.userData.userId,
    };
    this.TaskService.updateTaskToWatchlist(reqJson).subscribe((res) => {
      if (res.result == "Success") {
      } else {
      }
    });
    if (this.fileRemoveArray.length != 0) {
      this.fileRemoveArray.forEach((data) => {
        this.removedAttachmentId.push({ _id: data._id });
      });
      let req = {
        taskId: this.selectedTaskId,
        attachments: this.removedAttachmentId,
      };
      this.TaskService.removeTaskAttachment(req).subscribe((response) => {});
    }

    if (FormValues.selectedAssignTo != null) {
      if (FormValues.selectedAssignTo.name == "-- None --") {
        FormValues.selectedAssignTo.name = null;
        FormValues.selectedAssignTo.code = null;
        this.selectedUserId = null;
      }
    }
    if (FormValues.selectedPriority != null) {
      if (FormValues.selectedPriority.name == "-- None --") {
        FormValues.selectedPriority.name = null;
        FormValues.selectedPriority.code = null;
      }
    }
    if (FormValues.selectedProduct != null) {
      if (FormValues.selectedProduct.name == "-- None --") {
        FormValues.selectedProduct.name = null;
        FormValues.selectedProduct.code = null;
      }
    }
    if (FormValues.selectedProject != null) {
    }
    if (FormValues.selectedProject.name == "-- None --") {
      FormValues.selectedProject.name = null;
      FormValues.selectedProject.code = null;
    }
    if (FormValues.taskType != null) {
      if (FormValues.taskType.name == "-- None --") {
        FormValues.taskType.name = null;
        FormValues.taskType.code = null;
      }
    }
    if (FormValues.ticketType != null) {
      if (FormValues.ticketType.name == "-- None --") {
        FormValues.ticketType.name = null;
        FormValues.ticketType.code = null;
      }
    }
    if (FormValues.selectedAssignGroup != null) {
      if (FormValues.selectedAssignGroup.name == "-- None --") {
        FormValues.selectedAssignGroup.name = null;
        FormValues.selectedAssignGroup.code = null;
      }
    }
    this.removedAttachmentId = [];
    this.fileRemoveArray = [];
    let taskDetails: any = {
      taskName: FormValues.taskName,
      taskDescription: FormValues.description,
      createdByName: this.createdByName,
      updatedById: this.userData.userId,
      updatedByName: this.userData.userName,
      estimatedTime: FormValues.dueTime,
      checklist: this.checklistFinals,
      userName: this.selectedUsername,
      projectId: FormValues.selectedProject.code,
      productId: FormValues.selectedProduct.code,
      productName: FormValues.selectedProduct.name,
      projectName: FormValues.selectedProject.name,
      userId: this.selectedUserId,
      dueDate: date,
      status: status,
      priority: FormValues.selectedPriority.name,
      taskId: this.selectedTaskId,
      taskType: FormValues.taskType.name,
      project: {
        projectName: FormValues.selectedProject.name,
        projectId: FormValues.selectedProject.code,
      },
      product: {
        productName: FormValues.selectedProduct.name,
        productId: FormValues.selectedProduct.code,
      },
      watchlist: null,
      privateTask: FormValues.privateTask,
      businessName: businessData.businessName,
      userGroupId: FormValues.selectedAssignGroup.code,
      userGroupName: FormValues.selectedAssignGroup.name,
      ticketType: FormValues.ticketType.name,
      AdditionalField: this.dynamicJsonEdit,
    };
    formData.append("taskDetails", JSON.stringify(taskDetails));
    this.TaskService.updateTicket(formData).subscribe(
      (res) => {
        let msg = res.responseMessage.split(":");
        if (res.result == "Success") {
          this.viewDialogueBox = false;
          let req;
          if (this.taskType == "Assigned") {
            req = {
              userId: this.userData.userId,
              action: "CreatedBy",
              userName: this.userData.userName,
            };
          } else {
            req = {
              userId: this.userData.userId,
              action: this.taskType,
            };
          }
          this.isUpdateClick = true;
          this.selectedBtns = "";
          this.selectedBtn = "";
          this.recurrenceMsg = null;
          let msg1;
          this.translateService.get("taskUpdateSuccess").subscribe((res) => {
            msg1 = res;
          });
          this.messageService.add({
            severity: "success",
            summary: this.selectedTaskId + " " + msg1,
            detail: msg[1],
          });
          this.viewForm.reset();
          this.CancelViewForm();
          this.ngxLoader.stop();
        } else {
          this.viewDialogueBox = false;
          this.isUpdateClick = false;
          this.selectedBtns = "";
          this.selectedBtn = "";
          this.recurrenceMsg = null;
          let msg1;
          this.translateService.get("taskNotUpdated").subscribe((txt) => {
            msg1 = txt;
          });
          this.messageService.add({
            severity: "error",
            summary: msg1,
            detail: msg[1],
          });
          this.ngxLoader.stop();
        }
      },
      (error) => {
        this.ngxLoader.stop();
        this.viewDialogueBox = false;
        this.isUpdateClick = false;
        this.selectedBtns = "";
        this.selectedBtn = "";
        this.recurrenceMsg = null;
        let msg1;
        this.translateService.get("errorOccuredinService").subscribe((txt) => {
          msg1 = txt;
        });
        this.messageService.add({ severity: "error", summary: msg1 });
      }
    );
  }

  downloadAttachment(data: any) {
    this.ngxLoader.start();
    const blob = this.b64toBlob(data.fileData, data.contentType);
    const url = window.URL.createObjectURL(blob);
    fileSaver.saveAs(blob, data.fileName);
    this.ngxLoader.stop();
  }
  /* recurrence */
  canceltaskRecurrence() {
    this.displaytaskrecurrence = false;
    this.patternRadio = null;
    this.recEndDate = null;
    this.recStartDate = null;
    this.dailyRadio = null;
    this.dailyNumber = null;
    this.endRadio = "endby";
    this.noOfRec = null;
    this.weekno = null;
    this.dailyNumber = null;
    this.monthlyRadio = null;
    this.yearlyRadio = null;
    this.yearDd1 = null;
    this.yearDd2 = null;
    this.yearDd3 = null;
    this.yearDd4 = null;
    this.yearno = null;
  }
  openRecurrence() {
    if (this.viewDialogueBox == true) {
      this.getRecurrence(this.selectedTaskId);
    }
    this.displaytaskrecurrence = true;
    this.position1 = "bottom";
  }
  changeRangeRecRadio(value) {
    this.endRadio = value;
    this.recEndDate = null;
    this.noOfRec = null;
    if (this.endRadio == "noenddate") {
      this.isNoEndDate = true;
      this.recEndDate = null;
      this.noOfRec = null;
    } else if (this.endRadio == "endby") {
      this.isNoEndDate = null;
      this.noOfRec = null;
    } else if (this.endRadio == "endafter") {
      this.isNoEndDate = null;
      this.recEndDate = null;
    }
  }
  changePatternRadio(value) {
    this.patternRadio = value;
    this.selectedWeekday = [];
    this.weekno = null;
    this.dailyNumber = null;
    this.dailyRadio = null;
    this.monthlyRadio = null;
    this.monthNumber = null;
    this.monthNumber1 = null;
    this.yearlyRadio = null;
    this.yearDd1 = null;
    this.yearDd2 = null;
    this.yearDd3 = null;
    this.yearDd4 = null;
    this.isEveryWeekDays = false;
    this.isNoEndDate = false;
  }
  changeDailyRadios(value) {
    this.dailyRadio = value;
    if (this.dailyRadio == "everyweekday") {
      this.isEveryWeekDays = true;
      this.dailyNumber = null;
    } else if (this.dailyRadio == "everyday") {
      this.isEveryWeekDays = false;
    }
  }
  onChangeMonthlyRadio(value) {
    this.monthlyRadio = value;
    if (this.monthlyRadio == "dayofeverymonth") {
      this.monthdd = null;
      this.monthdd1 = null;
      this.monthNumber11 = null;
    } else if (this.monthlyRadio == "thofeverymonth") {
      this.monthNumber = null;
      this.monthNumber1 = null;
    }
  }
  changeYearlyRadio(value) {
    this.yearlyRadio = value;
    if (this.yearlyRadio == "on") {
      this.yearDd2 = null;
      this.yearDd3 = null;
      this.yearDd4 = null;
    } else if (this.yearlyRadio == "onthe") {
      this.yearDd1 = null;
      this.yearNumber1 = null;
    }
  }
  yearno;
  createRecurrence() {
    this.isCreateRecurrence = true;
    /* Recurrence Add Task */
    if (this.displayFeedback == true) {
      if (this.patternRadio == "Daily") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              noEndDate: this.isNoEndDate,
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          /* Reccurance: Occurs every March 5 effective 05-03-2020 from 18:30 to 19:00 */
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endByDate: this.datePipe.transform(this.recEndDate, "yyyy-MM-dd"),
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endAfterOccurence: this.noOfRec,
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Occurrences";
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Occurrences";
          }
        }
      } else if (this.patternRadio == "Weekly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              noEndDate: this.isNoEndDate,
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endByDate: this.datePipe.transform(this.recEndDate, "yyyy-MM-dd"),
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
            " and end by " +
            this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          this.recurrenceReq = {
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endAfterOccurence: this.noOfRec,
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
            " end after " +
            this.noOfRec +
            " Occurrences";
        }
      } else if (this.patternRadio == "Monthly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd.name +
              " " +
              this.monthdd1.name +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd.name +
              " " +
              this.monthdd1.name +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd.name +
              " " +
              this.monthdd1.name +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          }
        }
      } else if (this.patternRadio == "Yearly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " day of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " day of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " day of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          }
        }
      }
    }
    /* Recurrence Edit Task */
    if (this.viewDialogueBox == true) {
      this.ngxLoader.start();
      if (this.patternRadio == "Daily") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              noEndDate: this.isNoEndDate,
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endByDate: this.datePipe.transform(this.recEndDate, "yyyy-MM-dd"),
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endAfterOccurence: this.noOfRec,
            },
            pattern: {
              occurrence: "Daily",
              daily: {
                everyWeekDays: this.isEveryWeekDays,
                afterEveryDays: this.dailyNumber,
              },
            },
          };
          if (this.isEveryWeekDays == true) {
            this.recurrenceMsg =
              "Occurs every weekday effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Occurrences";
          } else {
            this.recurrenceMsg =
              "Occurs every " +
              this.dailyNumber +
              " days effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Occurrences";
          }
        }
      } else if (this.patternRadio == "Weekly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              noEndDate: this.isNoEndDate,
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endByDate: this.datePipe.transform(this.recEndDate, "yyyy-MM-dd"),
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
            " and end by " +
            this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          this.recurrenceReq = {
            _id: this._id,
            taskId: this.selectedTaskId,
            createdById: this.userData.userId,
            createdByName: this.userData.userName,
            range: {
              startDate: this.datePipe.transform(
                this.recStartDate,
                "yyyy-MM-dd"
              ),
              endAfterOccurence: this.noOfRec,
            },
            pattern: {
              occurrence: "Weekly",
              weekly: {
                afterEveryWeek: this.weekno,
                everyWeekOn: this.selectedWeekday,
              },
            },
          };
          this.recurrenceMsg =
            "Recur " +
            this.weekno +
            " weeks on " +
            this.selectedWeekday.toString() +
            " effective from " +
            this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
            " end after " +
            this.noOfRec +
            " Occurrences";
        }
      } else if (this.patternRadio == "Monthly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              taskId: this.selectedTaskId,
              _id: this._id,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd +
              " " +
              this.monthdd1 +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd +
              " " +
              this.monthdd1 +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          if (this.monthlyRadio == "dayofeverymonth") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: this.monthNumber,
                  afterEveryMonths: this.monthNumber1,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on " +
              this.monthNumber +
              " day of every " +
              this.monthNumber1 +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          } else if (this.monthlyRadio == "thofeverymonth") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Monthly",
                monthly: {
                  onDayNumber: null,
                  afterEveryMonths: this.monthNumber11,
                  onDayNameOfWeek: this.monthdd1.name,
                  onWeek: this.monthdd.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur on the " +
              this.monthdd +
              " " +
              this.monthdd1 +
              " of every " +
              this.monthNumber11 +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          }
        }
      } else if (this.patternRadio == "Yearly") {
        if (this.endRadio == "noenddate") {
          this.isNoEndDate = true;
          this.recEndDate = null;
          this.noOfRec = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                noEndDate: this.isNoEndDate,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endby") {
          this.isNoEndDate = null;
          this.noOfRec = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endByDate: this.datePipe.transform(
                  this.recEndDate,
                  "yyyy-MM-dd"
                ),
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          }
        } else if (this.endRadio == "endafter") {
          this.isNoEndDate = null;
          this.recEndDate = null;
          if (this.yearlyRadio == "on") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: this.yearNumber1,
                  onMonthName: this.yearDd1.name,
                  onDayNameOfWeek: null,
                  onWeek: null,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on " +
              this.yearNumber1 +
              " day of " +
              this.yearDd1.name +
              " months effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          } else if (this.yearlyRadio == "onthe") {
            this.recurrenceReq = {
              _id: this._id,
              taskId: this.selectedTaskId,
              createdById: this.userData.userId,
              createdByName: this.userData.userName,
              range: {
                startDate: this.datePipe.transform(
                  this.recStartDate,
                  "yyyy-MM-dd"
                ),
                endAfterOccurence: this.noOfRec,
              },
              pattern: {
                occurrence: "Yearly",
                yearly: {
                  afterEveryYears: this.yearno,
                  onDayNumber: null,
                  onMonthName: this.yearDd4.name,
                  onDayNameOfWeek: this.yearDd3.name,
                  onWeek: this.yearDd2.name,
                },
              },
            };
            this.recurrenceMsg =
              "Recur every " +
              this.yearno +
              " years on the " +
              this.yearDd2.name +
              " " +
              this.yearDd3.name +
              " of " +
              this.yearDd4.name +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end after " +
              this.noOfRec +
              " Ocurrences";
          }
        }
      }
      this.TaskService.updateRecurrence(this.recurrenceReq).subscribe(
        (response) => {
          this.ngxLoader.stop();
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.displaytaskrecurrence = false;
  }

  removeRecurrence() {
    let req = {
      taskId: this.selectedTaskId,
    };
    this.ngxLoader.start();
    this.TaskService.deleteRecurrence(req).subscribe((response) => {
      this.ngxLoader.stop();
      this.displaytaskrecurrence = false;
      let msg1;
      this.translateService.get("RecurrRemovedSuccess").subscribe((txt) => {
        msg1 = txt;
      });
      this.messageService.add({ severity: "success", summary: msg1 });
    });
  }

  getRecurrence(id) {
    let json = {
      taskId: id,
    };
    this.TaskService.getRecurrenceById(json).subscribe((response) => {
      this.recurrenceData = response.responseData;
      if (this.recurrenceData != null) {
        this.isCreateRecurrence = true;
        this.recStartDate = new Date(this.recurrenceData.range.startDate);
        if (this.recurrenceData.pattern.occurrence == "Daily") {
          this.patternRadio = "Daily";
          if (this.recurrenceData.pattern.daily.everyWeekDays == true) {
            this.dailyRadio = "everyweekday";
          } else {
            this.dailyRadio = "everyday";
            this.dailyNumber = this.recurrenceData.pattern.daily.afterEveryDays;
          }
        }
        if (this.recurrenceData.pattern.occurrence == "Weekly") {
          this.patternRadio = "Weekly";
          this.weekno = this.recurrenceData.pattern.weekly.afterEveryWeek;
          this.selectedWeekday = this.recurrenceData.pattern.weekly.everyWeekOn;
        }
        if (this.recurrenceData.pattern.occurrence == "Monthly") {
          this.patternRadio = "Monthly";
          if (this.recurrenceData.pattern.monthly.onWeek == null) {
            this.monthlyRadio = "dayofeverymonth";
            this.monthNumber1 =
              this.recurrenceData.pattern.monthly.afterEveryMonths;
            this.monthNumber = this.recurrenceData.pattern.monthly.onDayNumber;
          } else {
            this.monthlyRadio = "thofeverymonth";
            this.monthdd = {
              name: this.recurrenceData.pattern.monthly.onWeek,
              code: this.recurrenceData.pattern.monthly.onWeek,
            };
            this.monthdd1 = {
              name: this.recurrenceData.pattern.monthly.onDayNameOfWeek,
              code: this.recurrenceData.pattern.monthly.onDayNameOfWeek,
            };
            this.monthNumber11 =
              this.recurrenceData.pattern.monthly.afterEveryMonths;
          }
        }
        if (this.recurrenceData.pattern.occurrence == "Yearly") {
          this.patternRadio = "Yearly";
          if (this.recurrenceData.pattern.yearly.onWeek == null) {
            this.yearlyRadio = "on";
            this.yearno = this.recurrenceData.pattern.yearly.afterEveryYears;
            this.yearNumber1 = this.recurrenceData.pattern.yearly.onDayNumber;
            this.yearDd1 = {
              name: this.recurrenceData.pattern.yearly.onMonthName,
              code: this.recurrenceData.pattern.yearly.onMonthName,
            };
          } else {
            this.yearlyRadio = "onthe";
            this.yearno = this.recurrenceData.pattern.yearly.afterEveryYears;
            this.yearDd2 = {
              name: this.recurrenceData.pattern.yearly.onWeek,
              code: this.recurrenceData.pattern.yearly.onWeek,
            };
            this.yearDd3 = {
              name: this.recurrenceData.pattern.yearly.onDayNameOfWeek,
              code: this.recurrenceData.pattern.yearly.onDayNameOfWeek,
            };
            this.yearDd4 = {
              name: this.recurrenceData.pattern.yearly.onMonthName,
              code: this.recurrenceData.pattern.yearly.onMonthName,
            };
          }
        }
        if (this.recurrenceData.range.noEndDate == true) {
          this.endRadio = "noenddate";
          this.recEndDate = null;
          this.noOfRec = null;
        } else if (this.recurrenceData.range.endByDate != null) {
          this.endRadio = "endby";
          this.recEndDate = new Date(this.recurrenceData.range.endByDate);
          this.noOfRec = null;
        } else if (this.recurrenceData.range.endAfterOccurence != null) {
          this.endRadio = "endafter";
          this.noOfRec = this.recurrenceData.range.endAfterOccurence;
          this.recEndDate = null;
        }
        this._id = this.recurrenceData._id;

        if (this.patternRadio == "Daily") {
          if (this.endRadio == "noenddate") {
            if (this.dailyRadio == "everyday") {
              this.recurrenceMsg =
                "Occurs every " +
                this.dailyNumber +
                " days effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            } else if (this.dailyRadio == "everyweekday") {
              this.recurrenceMsg =
                "Occurs every weekday effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endby") {
            if (this.dailyRadio == "everyday") {
              this.recurrenceMsg =
                "Occurs every " +
                this.dailyNumber +
                " days effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            } else if (this.dailyRadio == "everyweekday") {
              this.recurrenceMsg =
                "Occurs every weekday effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endafter") {
            if (this.dailyRadio == "everyday") {
              this.recurrenceMsg =
                "Occurs every " +
                this.dailyNumber +
                " days effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Occurrences";
            } else if (this.dailyRadio == "everyweekday") {
              this.recurrenceMsg =
                "Occurs every weekday effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Occurrences";
            }
          }
        } else if (this.patternRadio == "Weekly") {
          if (this.endRadio == "noenddate") {
            this.recurrenceMsg =
              "Recur " +
              this.weekno +
              " weeks on " +
              this.selectedWeekday.toString() +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
          } else if (this.endRadio == "endby") {
            this.recurrenceMsg =
              "Recur " +
              this.weekno +
              " weeks on " +
              this.selectedWeekday.toString() +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " and end by " +
              this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
          } else if (this.endRadio == "endafter") {
            this.recurrenceMsg =
              "Recur " +
              this.weekno +
              " weeks on " +
              this.selectedWeekday.toString() +
              " effective from " +
              this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
              " end after " +
              this.noOfRec +
              " Occurrences";
          }
        } else if (this.patternRadio == "Monthly") {
          if (this.endRadio == "noenddate") {
            if (this.monthlyRadio == "dayofeverymonth") {
              this.recurrenceMsg =
                "Recur on " +
                this.monthNumber +
                " day of every " +
                this.monthNumber1 +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            } else if (this.monthlyRadio == "thofeverymonth") {
              this.recurrenceMsg =
                "Recur on the " +
                this.monthdd +
                " " +
                this.monthdd1 +
                " of every " +
                this.monthNumber11 +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endby") {
            if (this.monthlyRadio == "dayofeverymonth") {
              this.recurrenceMsg =
                "Recur on " +
                this.monthNumber +
                " day of every " +
                this.monthNumber1 +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            } else if (this.monthlyRadio == "thofeverymonth") {
              this.recurrenceMsg =
                "Recur on the " +
                this.monthdd.name +
                " " +
                this.monthdd1.name +
                " of every " +
                this.monthNumber11 +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endafter") {
            if (this.monthlyRadio == "dayofeverymonth") {
              this.recurrenceMsg =
                "Recur on " +
                this.monthNumber +
                " day of every " +
                this.monthNumber1 +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Ocurrences";
            } else if (this.monthlyRadio == "thofeverymonth") {
              this.recurrenceMsg =
                "Recur on the " +
                this.monthdd +
                " " +
                this.monthdd1 +
                " of every " +
                this.monthNumber11 +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Ocurrences";
            }
          }
        } else if (this.patternRadio == "Yearly") {
          if (this.endRadio == "noenddate") {
            if (this.yearlyRadio == "on") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on " +
                this.yearNumber1 +
                " day of " +
                this.yearDd1.name +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            } else if (this.yearlyRadio == "onthe") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on the " +
                this.yearDd2.name +
                " " +
                this.yearDd3.name +
                " of " +
                this.yearDd4.name +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endby") {
            if (this.yearlyRadio == "on") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on " +
                this.yearNumber1 +
                " day of " +
                this.yearDd1.name +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            } else if (this.yearlyRadio == "onthe") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on the " +
                this.yearDd2.name +
                " " +
                this.yearDd3.name +
                " of " +
                this.yearDd4.name +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end by " +
                this.datePipe.transform(this.recEndDate, "MM-dd-yyyy");
            }
          } else if (this.endRadio == "endafter") {
            if (this.yearlyRadio == "on") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on " +
                this.yearNumber1 +
                " day of " +
                this.yearDd1.name +
                " months effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Ocurrences";
            } else if (this.yearlyRadio == "onthe") {
              this.recurrenceMsg =
                "Recur every " +
                this.yearno +
                " years on the " +
                this.yearDd2.name +
                " " +
                this.yearDd3.name +
                " of " +
                this.yearDd4.name +
                " effective from " +
                this.datePipe.transform(this.recStartDate, "MM-dd-yyyy") +
                " and end after " +
                this.noOfRec +
                " Ocurrences";
            }
          }
        }
      }
    });
  }

  isCopyTask: boolean = false;
  isTaskCreated: boolean = false;
  openCopyTask() {
    this.isValidDesc = false;
    this.isValidDesc1 = false;
    this.isValidated = false;
    this.isValidatedManField = true;
    this.isValidatedNonManField = true;
    this.isStatusSelected = false;
    this.isAssigneeUpdated = false;
    this.dateUpdated = false;

    this.isTaskUpdated = false;
    this.selectedTaskId = this.viewTaskId;
    this.isCopyTask = true;
    this.createdByidElement = "";
    localStorage.setItem("selectedTask", this.selectedTaskId);
    this.comment = false;
    // this.getTemplateList();
    let reqjson = {
      taskId: this.viewTaskId,
    };
    this.userDataNew = JSON.parse(localStorage.getItem("userData"));
    let watchlistreqjson = {
      taskId: this.viewTaskId,
      createdById: this.userDataNew.userId,
    };
    this.TaskService.getWatchlistsByTaskId(watchlistreqjson).subscribe(
      (res) => {
        if (res.responseData && res.result == "Success") {
          res.responseData.forEach((element) => {
            this.existedWatchlistNames.push(element.watchlistName);
            this.dtWatchlistNew = this.existedWatchlistNames;
          });
        }
      }
    );
    if (this.templateList.length <= 1) {
      this.multipleTemplateList = false;
    } else {
      this.multipleTemplateList = true;
    }
    this.dynamicJson = [];
    this.dynamicJsonEdit = [];
    this.ngxLoader.start();
    this.TaskService.getTaskDetailById(reqjson).subscribe((res) => {
      this.taskDataForEdit = res.responseData;
      this.dynamicJsonEdit = res.responseData["AdditionalField"];
      this.dynamicJson=res.responseData["AdditionalField"];
      console.log("view template JSON---", this.dynamicJson);
      this.getTemplateList();
      //   for (let j = 0; j < this.dynamicJson.length; j++) {
      //     if (
      //       this.dynamicJson[j].parameterValue &&
      //       this.dynamicJson[j].isMandatory == true
      //     ) {
      //       this.dynamicJson[j].isValueChanged = true;
      //       this.dynamicJson[j].isValidatedExp = true;
      //     }
      //     let dummy = [];
      //     let dropDownArr = [];
      //     const temp1: any = {
      //       name: "-- None --",
      //       code: "Null",
      //     };
      //     dropDownArr.push(temp1);
      //     if (this.dynamicJson[j].datatype == "dropdown") {
      //       let arr = this.dynamicJson[j].displayName;
      //       dummy = arr.split(",");
      //       dummy.forEach((ele) => {
      //         const temp: any = {
      //           name: ele,
      //           code: ele,
      //         };
      //         dropDownArr.push(temp);
      //       });
      //       this.dynamicJson[j]["dropNewValue"] = dropDownArr;
      //       console.log("dropDownArr", dropDownArr);

      //       const temp: any = {
      //         name: this.dynamicJson[j].parameterValue,
      //         code: this.dynamicJson[j].parameterValue,
      //       };

      //       this.dynamicJson[j]["selection"] = temp;
      //       console.log("this.dynamicJson selection ---",this.dynamicJson[j]["selection"]);

      //     }
      //    if (this.dynamicJson[j].datatype == "employee") {
      //       let url = this.dynamicJson[j].displayName;

      //       this.TaskService.getEmployeeList(url).subscribe(
      //         (res) => {
      //           this.UserListEmp = [];
      //           const temp1: any = {
      //             name: "-- None --",
      //             code: "Null",
      //           };
      //           this.UserListEmp.push(temp1);
      //           res.responseData.forEach((ele) => {

      //             const temp: any = {
      //               name: ele.userName ? ele.userName : "Null",
      //               code: ele.userName ? ele.userName : "Null",
      //             };
      //             this.UserListEmp.push(temp);
      //           });

      //           this.UserListEmp.sort(this.keysrt("name"));
      //           this.dynamicJson[j]["dropNewValue"] = this.UserListEmp;

      //               const temp: any = {
      //                 name: this.dynamicJson[j].parameterValue,
      //                 code: this.dynamicJson[j].parameterValue,
      //               };

      //               this.dynamicJson[j]["selectionEmp"] = temp;

      //           }
      //          ,
      //         (error) => {
      //           this.ngxLoader.stop();
      //         }
      //       );
      //     }
      //   }

      //   console.log("view  this.dynamicJson", this.dynamicJson);
      //   this.isValidated = this.dynamicJson.every((ele) =>
      //   this.checkMandatory1(ele.isMandatory)
      // );
      // console.log("this.isValidated", this.isValidated);
      this.taskNameData = this.taskDataForEdit["taskName"];
      this.createdByidElement = this.taskDataForEdit["createdById"];
      this.createdByName = this.taskDataForEdit["createdByName"];
      if (this.taskDataForEdit["privateTask"] == true) {
        this.isPrivateTaskData = true;
        this.userDataNew = JSON.parse(localStorage.getItem("userData"));
        if (this.taskDataForEdit["createdById"] == this.userDataNew.userId) {
          this.privateTaskFalse = false;
          this.privateTaskAllow = true;
        } else {
          this.privateTaskFalse = true;
          this.privateTaskAllow = false;
        }
      } else {
        this.isPrivateTaskData = false;
      }
      this.inputText = this.taskDataForEdit.taskDescription;
      if (this.taskDataForEdit["dueDate"] !== null) {
        this.taskDataForEdit.dueDate = new Date(this.taskDataForEdit.dueDate);
      } else {
        this.taskDataForEdit.dueDate = this.taskDataForEdit.dueDate;
      }
      if (this.taskDataForEdit.checklist) {
        if (typeof this.taskDataForEdit.checklist[0] !== "undefined") {
          if (this.taskDataForEdit.checklist[0].criteria == null) {
            this.checklist = false;
          } else {
            this.checklist = true;
          }
        } else {
          this.checklist = true;
        }
        this.taskDataForEdit.checklist.forEach((res) => {
          this.checkRespo = res;
          this.formArr.push(
            this._fb.group({
              criteria: [this.checkRespo.criteria],
              checked: [this.checkRespo.checked],
            })
          );
        });
      }
      this.deleteRow(0);
      this.taskIdData = this.taskDataForEdit.taskId;
      this.selectedUserId = this.taskDataForEdit.userId;
      this.selectedUsername = this.taskDataForEdit.userName;
      this.userData = JSON.parse(localStorage.getItem("userData"));
      this.watchlistChangeValueNew = [];
      this.watchListInput = this.taskDataForEdit["watchlist"];
      if (this.watchListInput) {
        Object.keys(this.watchListInput).map((key) => {
          if (key == this.userData.userId) {
            let valuesList = this.watchListInput[key];
            this.dtWatchlistNew = valuesList;
          }
        });
      }
      console.log(this.dtWatchlistNew);
      if (this.priority != null) {
        for (let i = 0; i < this.priority.length; i++) {
          if (this.taskDataForEdit.priority == this.priority[i].name) {
            this.createForm.patchValue({
              selectedPriority: {
                name: this.priority[i].name,
                code: this.priority[i].code,
              },
            });
          }
        }
      }
      this.createForm.patchValue({
        taskName: this.taskDataForEdit.taskName,
        selectedProduct: {
          name: this.taskDataForEdit.productName,
          code: this.taskDataForEdit.productId,
        },
        selectedProject: {
          name: this.taskDataForEdit.projectName,
          code: this.taskDataForEdit.projectId,
        },
        description: this.taskDataForEdit.taskDescription,

        dueDate: this.taskDataForEdit.dueDate,
        dueTime: this.taskDataForEdit.estimatedTime,
        selectedAssignTo: {
          name: this.taskDataForEdit.userName,
          code: this.taskDataForEdit.userId,
        },
        /*  selectedPriority: {
            'name': this.taskDataForEdit.priority,
            'code': this.taskDataForEdit.priority
          }, */
        selectedAssignGroup: {
          name: this.taskDataForEdit.userGroupName,
          code: this.taskDataForEdit.userGroupId,
        },
        taskType: {
          name: this.taskDataForEdit.taskType,
          code: this.taskDataForEdit.taskType,
        },
        ticketType: {
          name: this.taskDataForEdit.ticketType,
          code: this.taskDataForEdit.ticketType,
        },
        privateTask: this.taskDataForEdit.privateTask,
      });

      this.ngxLoader.stop();
      if (
        this.taskDataForEdit.attachments != null &&
        this.taskDataForEdit.attachments[0] != null
      ) {
        this.files = [];
      }
    });
    this.displayFeedback = true;
    this.isValidated = true;
    // this.getTemplateList();
  }

  isValidComment: boolean = false;
  isValidComment1: boolean = false;
  onChangeComment(event) {
    if (event.textValue == "") {
      this.isValidComment = true;
    } else {
      this.isValidComment1 = false;
      this.isValidComment = false;
    }

    if (event.htmlValue == null) {
      this.isValidComment1 = true;
    } else {
      this.isValidComment1 = false;
    }

    if (event.htmlValue.includes("img") && event.textValue == "") {
      this.isValidComment1 = false;
      this.isValidComment = false;
    }
  }
  isValidDesc: boolean = false;
  isValidDesc1: boolean = false;
  isValidPriority: boolean = false;
  isValidPriority1: boolean = false;
  isValidProduct: boolean = false;
  isValidProduct1: boolean = false;
  isValidProject: boolean = false;
  isValidProject1: boolean = false;
  onChangeDescription(event) {
    if (event.textValue == "") {
      this.isValidDesc = true;
    } else {
      this.isValidDesc1 = false;
      this.isValidDesc = false;
    }

    if (event.htmlValue == null) {
      this.isValidDesc1 = true;
    } else {
      this.isValidDesc1 = false;
    }

    if (event.htmlValue.includes("img") && event.textValue == "") {
      this.isValidDesc1 = false;
      this.isValidDesc = false;
    }
  }

  onChangePriority(event) {
    if (event.value == "") {
      this.isValidPriority = true;
    } else {
      this.isValidPriority1 = false;
      this.isValidPriority = false;
    }

    if (event.htmlValue == null) {
      this.isValidPriority1 = true;
    } else {
      this.isValidPriority1 = false;
    }
  }

  onChangeProject(event) {
    if (event.value == "") {
      this.isValidProject = true;
    } else {
      this.isValidProject1 = false;
      this.isValidProject = false;
    }

    if (event.htmlValue == null) {
      this.isValidProject1 = true;
    } else {
      this.isValidProject1 = false;
    }
  }

  onChangeProduct(event) {
    if (event.value == "") {
      this.isValidProduct = true;
    } else {
      this.isValidProduct1 = false;
      this.isValidProduct = false;
    }

    if (event.htmlValue == null) {
      this.isValidProduct1 = true;
    } else {
      this.isValidProduct1 = false;
    }
  }
  onShowAdd() {
    setTimeout(() => {
      this.testInput.nativeElement.focus();
    }, 500);
  }

  getAllPriority() {
    this.TaskService.getAllPriorities().subscribe((res) => {
      this.priority = [];
      const temp1: any = {
        name: "-- None --",
        code: "Null",
      };
      this.priority.push(temp1);
      res.responseData.forEach((ele) => {
        const temp: any = {
          name: ele.priorityName,
          code: ele.priorityId,
        };
        this.priority.push(temp);
      });

      this.priority.sort(this.keysrt("name"));
      localStorage.setItem("priorityList", JSON.stringify(this.priority));
    });
  }

  onChangeTaskType(event) {
    let selectedTaskType = event.value;
    this.dynamicJson = [];
    console.log("selectedTaskType", selectedTaskType["name"]);
    let TaskType = selectedTaskType["name"];
    this.TaskService.getTemplatebyId(TaskType).subscribe((res) => {
      if ((res["status"] = "success")) {
        this.dynamicJson = res["responseData"]["additionalField"];
        console.log(" this.dynamicJson----", this.dynamicJson);
        for (let i = 0; i < this.dynamicJson.length; i++) {
          let dummy = [];
          let dropDownArrEdit = [];
          const temp1: any = {
            name: "-- None --",
            code: "Null",
          };
          dropDownArrEdit.push(temp1);
          if (this.dynamicJson[i].datatype == "dropdown") {
            let arr = this.dynamicJson[i].displayName;
            dummy = arr.split(",");
            dummy.forEach((ele) => {
              const temp: any = {
                name: ele,
                code: ele,
              };
              dropDownArrEdit.push(temp);
            });
            this.dynamicJson[i]["dropNewValue"] = dropDownArrEdit;
            console.log("dropDownArrEdit", dropDownArrEdit);

            const temp: any = {
              name: this.dynamicJson[i].parameterValue,
              code: this.dynamicJson[i].parameterValue,
            };

            this.dynamicJson[i]["selection"] = temp;
          }

          if (this.dynamicJson[i].datatype == "employee") {
            let url = this.dynamicJson[i].displayName;

            this.TaskService.getEmployeeList(url).subscribe(
              (res) => {
                this.UserListEmp = [];
                const temp1: any = {
                  name: "-- None --",
                  code: "Null",
                };
                this.UserListEmp.push(temp1);
                res.responseData.forEach((ele) => {
                  const temp: any = {
                    name: ele.userName ? ele.userName : "Null",
                    code: ele.userName ? ele.userName : "Null",
                  };
                  this.UserListEmp.push(temp);
                });

                this.UserListEmp.sort(this.keysrt("name"));
                // localStorage.setItem("userList", JSON.stringify(this.UserList));
                this.dynamicJson[i]["dropNewValue"] = this.UserListEmp;
                const temp: any = {
                  name: this.dynamicJson[i].parameterValue,
                  code: this.dynamicJson[i].parameterValue,
                };

                this.dynamicJson[i]["selectionEmp"] = temp;
              },
              (error) => {
                this.ngxLoader.stop();
              }
            );
          }
        }

        this.isValidated = this.dynamicJson.every((ele) =>
          this.checkMandatory1(ele.isMandatory)
        );
        console.log("this.isValidated", this.isValidated);
      }
    });
  }

  checkMandatory1(isMandatory) {
    return !isMandatory;
  }
  validateNonMandatoryPatternFields(name, i) {
    let text = name;
    let found = text.match(/^(?!\s)[a-zA-Z0-9_\s-]*$/);
    if (!found && name != "") {
      this.dynamicJson[i].isValidatedExp = false;
      this.isValidatedNonManField = false;
    } else if (!found && name == "") {
      this.dynamicJson[i].isValidatedExp = true;
      this.isValidatedNonManField = true;
    } else if (found) {
      this.dynamicJson[i].isValidatedExp = true;
      this.isValidatedNonManField = true;
    }
  }
  validateNonMandatoryPatternFieldsEdit(name, i) {
    let text = name;
    let found = text.match(/^(?!\s)[a-zA-Z0-9_\s-]*$/);
    if (!found && name != "") {
      this.dynamicJsonEdit[i].isValidatedExp = false;
      this.isValidatedNonManField = false;
    } else if (!found && name == "") {
      this.dynamicJsonEdit[i].isValidatedExp = true;
      this.isValidatedNonManField = true;
    } else if (found) {
      this.dynamicJsonEdit[i].isValidatedExp = true;
      this.isValidatedNonManField = true;
    }
  }
  validatePatternFields(e, i, type) {
    if (this.dynamicJson[i]["datatype"] == "textarea") {
      this.validationText = e;
    } else if (this.dynamicJson[i]["datatype"] == "text") {
      this.validationText = e.target.value;
    }
    let found = this.validationText.match(/^(?!\s)[a-zA-Z0-9_\s-]*$/);
    if (!found) {
      this.dynamicJson[i].isValidatedExp = false;
      if (this.dynamicJson[i].isMandatory == true) {
        this.isValidatedManField = false;
      }
      // }
    } else if (this.validationText == "") {
      if (this.dynamicJson[i].isMandatory == true) {
        this.dynamicJson[i].isValidatedExp = false;
        this.isValidatedManField = false;
      }
    }
    //    else {
    //     this.dynamicJson[i].isValidatedExp = true;
    //   }
    // }
    else {
      this.dynamicJson[i].isValidatedExp = true;
      this.mandatoryArr = [];
      for (let j = 0; j < this.dynamicJson.length; j++) {
        if (
          this.dynamicJson[j]["isMandatory"] == true &&
          (this.dynamicJson[j]["datatype"] == "text" ||
            this.dynamicJson[j]["datatype"] == "textarea")
        ) {
          this.mandatoryArr.push(this.dynamicJson[j]);
        }
        console.log("mandatory", this.mandatoryArr);
      }
    }
    this.isValidatedManField = this.mandatoryArr.every((ele) =>
      this.checkMandatoryFieldVal(ele.isValidatedExp, ele.parameterValue)
    );
    console.log(" this.isValidatedManField", this.isValidatedManField);
  }

  validatePatternFieldsEdit(e, i, type) {
    if (this.dynamicJsonEdit[i]["datatype"] == "textarea") {
      this.validationTextEdit = e;
    } else if (this.dynamicJsonEdit[i]["datatype"] == "text") {
      this.validationTextEdit = e.target.value;
    }

    let found = this.validationTextEdit.match(/^(?!\s)[a-zA-Z0-9_\s-]*$/);
    if (!found) {
      this.dynamicJsonEdit[i].isValidatedExp = false;
      if (this.dynamicJsonEdit[i].isMandatory == true) {
        this.isValidatedManField = false;
      }
      // }
    } else if (this.validationTextEdit == "") {
      if (this.dynamicJsonEdit[i].isMandatory == true) {
        this.dynamicJsonEdit[i].isValidatedExp = false;
        this.isValidatedManField = false;
      }
    }
    //    else {
    //     this.dynamicJson[i].isValidatedExp = true;
    //   }
    // }
    else {
      this.dynamicJsonEdit[i].isValidatedExp = true;
      this.mandatoryArr = [];
      for (let j = 0; j < this.dynamicJsonEdit.length; j++) {
        if (
          this.dynamicJsonEdit[j]["isMandatory"] == true &&
          (this.dynamicJsonEdit[j]["datatype"] == "text" ||
            this.dynamicJsonEdit[j]["datatype"] == "textarea")
        ) {
          this.mandatoryArr.push(this.dynamicJsonEdit[j]);
        }
        console.log("mandatory", this.mandatoryArr);
      }
    }
    this.isValidatedManField = this.mandatoryArr.every((ele) =>
      this.checkMandatoryFieldVal(ele.isValidatedExp, ele.parameterValue)
    );
    console.log(" this.isValidatedManField", this.isValidatedManField);
  }

  checkMandatoryFieldVal(isValidatedExp, parameterValue) {
    return isValidatedExp && parameterValue;
  }

  onChangeDropValue1(e, i) {
    this.dynamicJson[i].parameterValue = e.value["name"];
  }

  onChangeDropValueEdit(e, i) {
    this.dynamicJsonEdit[i].parameterValue = e.value["name"];
  }

  validateMandatoryFields(e, i, type) {
    if (this.dynamicJson[i]["datatype"] == "textarea") {
      this.selectedValueMan = e;
    } else if (this.dynamicJson[i]["datatype"] == "text") {
      this.selectedValueMan = e.target.value;
    } else if (this.dynamicJson[i]["datatype"] == "dropdown") {
      this.selectedValueMan = e.value["name"];
    } else if (this.dynamicJson[i]["datatype"] == "employee") {
      this.selectedValueMan = e.value["name"];
    }
    // this.selectedValueMan=e.target.value;
    if (this.selectedValueMan && this.dynamicJson[i]["isMandatory"] == true) {
      this.dynamicJson[i].isValueChanged = true;
      this.mandatoryArr = [];
      for (let j = 0; j < this.dynamicJson.length; j++) {
        if (this.dynamicJson[j]["isMandatory"] == true) {
          this.mandatoryArr.push(this.dynamicJson[j]);
        }
        console.log("mandatory", this.mandatoryArr);
      }
      this.isValidated = this.mandatoryArr.every((ele) =>
        this.checkMandatory(
          ele.isValueChanged,
          ele.isMandatory,
          ele.parameterValue
        )
      );
      console.log("this.isValidated", this.isValidated);
    } else {
      this.dynamicJson[i].isValueChanged = false;

      this.isValidated = false;
    }
  }

  validateMandatoryFieldsEdit(e, i, type) {
    if (this.dynamicJsonEdit[i]["datatype"] == "textarea") {
      this.selectedValueManEdit = e;
    } else if (this.dynamicJsonEdit[i]["datatype"] == "text") {
      this.selectedValueManEdit = e.target.value;
    } else if (this.dynamicJsonEdit[i]["datatype"] == "dropdown") {
      this.selectedValueManEdit = e.value["name"];
    } else if (this.dynamicJsonEdit[i]["datatype"] == "employee") {
      this.selectedValueManEdit = e.value["name"];
    }
    if (
      this.selectedValueManEdit &&
      this.dynamicJsonEdit[i]["isMandatory"] == true
    ) {
      this.dynamicJsonEdit[i].isValueChanged = true;
      this.mandatoryArr = [];
      for (let j = 0; j < this.dynamicJsonEdit.length; j++) {
        if (this.dynamicJsonEdit[j]["isMandatory"] == true) {
          this.mandatoryArr.push(this.dynamicJsonEdit[j]);
        }
        console.log("mandatory", this.mandatoryArr);
      }
      this.isValidated = this.mandatoryArr.every((ele) =>
        this.checkMandatory(
          ele.isValueChanged,
          ele.isMandatory,
          ele.parameterValue
        )
      );
      console.log("this.isValidated", this.isValidated);
    } else {
      this.dynamicJsonEdit[i].isValueChanged = false;

      this.isValidated = false;
    }
  }

  checkMandatory(isValueChanged, isMandatory, parameterValue) {
    return (isValueChanged || parameterValue) && isMandatory;
  }

  maximizeDescription() {
    this.displayDialogue = true;
    this.textDescription = null;
    this.textDescription = this.createForm.get("description").value;
  }
  minimizeDialog() {
    this.displayDialogue = false;
    this.createForm.get("description").setValue(this.textDescription);
  }
  cancelminimizeDialog() {
    this.displayDialogue = false;
  }
  canceleditminimizeDialog() {
    this.displayDialogue1 = false;
  }
  viewmaximizeDescription() {
    this.displayDialogue2 = true;
    this.textDescription2 = this.viewForm.get("description").value;
  }
  viewminimizeDialog() {
    this.displayDialogue2 = false;
    this.viewForm.get("description").setValue(this.textDescription2);
  }
  editmaximizeDescription() {
    this.displayDialogue1 = true;
    this.textDescription1 = null;
    this.textDescription1 = this.viewForm.get("description").value;
  }
  editminimizeDialog() {
    this.displayDialogue1 = false;
    this.viewForm.get("description").setValue(this.textDescription1);
  }

  @HostListener("window:load", ["$event"])
  onPageLoad(event: Event) {
    // this.ngxLoader.start();
    console.log("loaded", event);

    setTimeout(() => {
      this.ngxLoader.stop();
      this.isLoaded = true;
    }, 5000);
  }
}
