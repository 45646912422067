import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../../services/login.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [MessageService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class ResetPasswordComponent implements OnInit {

  changePassForm: FormGroup;
  submitted = false;
  storedEmail = '';
  lastPath = '';
  resetPassForm: FormGroup;
  emailFromUrl: any;
  cPassError = false;
  year: number;



  validToken: boolean = false;
  linkToken: any;
  constructor(private fb: FormBuilder,
              private base: LoginService,
              private activatedRouter: ActivatedRoute,
              private messageService: MessageService,
              private router: Router,
              private location: Location,
              public loader: NgxUiLoaderService) { }

  ngOnInit() {
    
    this.year = new Date().getFullYear();
    this.loader.start();
    this.getUserEmail();
    this.resetPassForm = this.fb.group({
      email: [this.emailFromUrl, [Validators.required, Validators.email]],
      // tslint:disable-next-line: max-line-length
      newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(32), Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/)
      ]],
      confirmpassword: ['', Validators.required]
    });

    this.location.replaceState('#/reset-password');
  }


  get f() {
    return this.resetPassForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.resetPassForm.invalid) {
      return;
    }

    if (this.resetPassForm.value.newpassword !== this.resetPassForm.value.confirmpassword) {
      this.cPassError = true;
      return;
    } else {
      this.cPassError = false;
    }
    this.loader.start();
    const req = {
      emailId: this.resetPassForm.value.email,
      userPassword: this.resetPassForm.value.newpassword
    };
    this.base.setForgotPassword(req,this.linkToken).subscribe(res => {
      this.loader.stop();
      if (res && res.status.statusCode === 2000) {
        // this.messageService.add({ severity: 'success', summary: 'Password reset successfully', life: 3000 });
        this.messageService.add({ severity: 'success', summary: res.status.statusMsg, life: 3000 }); 
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 501);

      } else {
        this.messageService.add({ severity: 'warn', summary: 'Something went wrong!', life: 3000 });
      }

    // tslint:disable-next-line: no-unused-expression
    }), error => {
      this.loader.stop();
      if(error.error.message){
        console.log(error);
        this.messageService.add({ severity: 'error', summary: error.error.message, life: 3000 });  
      }
      else{
        console.log(error.error.status.statusMsg);
        this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg, life: 3000});
      }

    };
  }

  getUserEmail() {
    this.activatedRouter.queryParams.subscribe(params => {
      this.loader.stop();
      if (params) {
        // this.emailFromUrl = params.userEmail;
        this.verifyUserEmailLink(params.token);
        console.log("params.token---",params.token);
        this.linkToken=params.token;
      }
    });
  }

  goBack() {
    this.router.navigateByUrl('/login');
  }



  userData:any;
  verifyUserEmailLink(token){
    this.loader.start();
    this.base.getData(`verifyUserEmailLink?token=${token}`)
      .subscribe((res: any) => {
        this.loader.stop();
        if (res.status.statusMsg === 'Success') {
          this.validToken = true;
          this.userData = res?.data;
          this.resetPassForm.controls['email'].setValue(this.userData.emailId);
          this.emailFromUrl = this.userData.emailId;
        //   this.signUpForm.patchValue({
        //     businessName: localStorage.getItem('businessName') ? localStorage.getItem('businessName') : this.userprofileData.businessName,
        //     industry: this.userprofileData.industry ? this.userprofileData.industry : undefined,
        //     employee: this.userprofileData.employees ? this.userprofileData.employees : undefined,
        //     country: this.userprofileData.country,
        //     emailId: email,
        //     firstName: this.userprofileData.firstname ? this.userprofileData.firstname : '',
        //     lastName: this.userprofileData.lastname ? this.userprofileData.lastname : '',
        //   });
        //   console.log(this.signUpForm);
        } else if(res.status['statusMsg'] == 'Varification Link is expired or invalid') {
          this.validToken = false;
          // this.router.navigate(['login']);
          this.router.navigateByUrl('/password-expired');
          // this.messageService.add({ severity: 'warn', summary: `${res.status.statusMsg}`, life: 2000 });
         // this.messageService.add({ severity: 'warn', summary: res.status.statusMsg, life: 3000 });
          this.messageService.add({
            severity: "warn",
            summary: "Varification Link is expired or invalid.",
            detail: "",
          });
        }
      }, err => {
        this.validToken = false;
        this.loader.stop();
        // console.log(err);
        // this.messageService.add({ severity: 'warn', summary: err, life: 3000 });
      });
  }


}
