<div class="addTask-button" *ngIf="!viewDialogueBox">
    <button class="btn btn-sm addbtndemo" (click)="openFPFormFeedback()" aria-label="AddTask">{{'addTask' |
        translate}}</button>
</div>
<p-dialog class="maxdialog" [(visible)]="displayDialogue" [style]="{width: '70vw'}" [baseZIndex]="10000"
    position="left">
    <p-editor class="editor" [(ngModel)]="textDescription" [style]="{'height':'650px'}"></p-editor>
    <p-footer>
        <button type="button" pButton (click)="minimizeDialog()" label="Save" [disabled]="!textDescription"></button>
        <button type="button" pButton (click)="cancelminimizeDialog()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<p-dialog class="maxdialog" [(visible)]="displayDialogue1" [style]="{width: '70vw'}" [baseZIndex]="10000"
    position="left">
    <p-editor class="editor" [(ngModel)]="textDescription1" [style]="{'height':'625px'}"></p-editor>
    <p-footer>
        <button type="button" pButton (click)="editminimizeDialog()" label="Save"
            [disabled]="!textDescription1"></button>
        <button type="button" pButton (click)="canceleditminimizeDialog()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<p-dialog class="maxdialog2" [(visible)]="displayDialogue2" [style]="{width: '70vw'}" [baseZIndex]="10000"
    position="left">
    <p-editor class="editor" [(ngModel)]="textDescription2" [style]="{'height':'650px'}" [readonly]="true"></p-editor>
    <p-footer>
        <button type="button" pButton (click)="viewminimizeDialog()" label="Cancel"
            [disabled]="!textDescription2"></button>
    </p-footer>
</p-dialog>
<p-dialog [closable]="false" [(visible)]="displayFeedback" styleClass="overlayAddTask" [modal]="true"
    [baseZIndex]="10000" (onShow)="onShowAdd()" [style]="{'color':'red',width: '698px', height: 'auto',zoom: '89%'}"
    [draggable]="false">
    <p-header>
        <p
            style="display: inline-block;   line-height: 0 ;padding-bottom: 10px;padding-top: 10px;padding-right: 10px; padding-left: 10px;font-size: 14px;">
            {{'createTask' | translate}}
        </p>
        <img (click)="CancelCreateForm()" src="../../../../assets/img/Group 8423.png" alt="cancel"
            style="width: 18px;float: right;display: inline-block;margin-top: 2px;cursor: pointer;">
    </p-header>

    <p-scrollPanel [style]="{width: '100%', height: '640px', overflow: 'hidden'}" class="createTaskDetails">
        <form [formGroup]="createForm" (ngSubmit)="CreateTask(createForm.value)">
            <div class="wrapper" style="margin-bottom:2px">
                <div class="form-group form-inline taskName">
                    <input type="text" pInputText class="form-control taskNameInput ng-dirty ng-invalid"
                        id="taskNameAdd" maxlength="500" [class.invalid]="!createForm.valid"
                        placeholder="{{'addTaskName' | translate}}*" formControlName="taskName" name="taskName"
                        #testInput autocomplete="off">

                    <span class="privateSpan commonhead"></span>
                    <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                        class="privateSpan" formControlName="privateTask"></p-checkbox>

                    <div *ngIf="createForm.controls['taskName'].errors">
                        <div class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                            *ngIf="(createForm.controls['taskName'].errors?.required&&createForm.controls['taskName'].dirty) || !createForm.controls['taskName'].value">
                            <!-- {{'taskNameMandatory' | translate}} -->
                        </div>
                        <div class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                            *ngIf="!createForm.controls['taskName'].valid&&!createForm.controls['taskName'].errors?.required&&createForm.controls['taskName'].dirty">
                            <!-- {{'validTaskNameMsg' | translate}} -->
                        </div>
                    </div>
                </div>


                <div class="productSelection">
                    <p class="commonhead commonBottom">{{'selectProduct' | translate}}<span class="text-danger">*</span>

                        <span
                            *ngIf="createForm.controls['selectedProduct'].errors || isValidProduct==true || isValidProduct1==true">
                            <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                                *ngIf="(createForm.controls['selectedProduct'].errors?.required&&createForm.controls['selectedProduct'].dirty) || !createForm.controls['selectedProduct'].value">
                                <!-- {{'mandatory' | translate}} -->
                            </span>
                        </span>
                    </p>
                    <p-dropdown #dd [options]="product" filter="true" formControlName="selectedProduct"
                        (onShow)="dd.resetFilter()" (onTextChange)="onChangeProduct($event)"
                        placeholder="{{'selectProduct' | translate}}" optionLabel="name"
                        styleClass="widthdropdownProjectCreate"></p-dropdown>

                </div>

                <div class="projectSelection">
                    <p class="commonhead commonBottom">{{'selectProject' | translate}}<span class="text-danger">*</span>

                        <span
                            *ngIf="createForm.controls['selectedProject'].errors || isValidProject==true || isValidProject1==true">
                            <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                                *ngIf="(createForm.controls['selectedProject'].errors?.required&&createForm.controls['selectedProject'].dirty) || !createForm.controls['selectedProject'].value">
                                <!-- {{'mandatory' | translate}} -->
                            </span>
                        </span>
                    </p>
                    <p-dropdown #projectdd [options]="projects" filter="true" (onShow)="projectdd.resetFilter()"
                        formControlName="selectedProject" optionLabel="name" (onTextChange)="onChangeProject($event)"
                        placeholder="{{'selectProject' | translate}}" styleClass="widthdropdown1Create"></p-dropdown>

                </div>

                <div class="projectSelection">
                    <p class="commonhead commonBottom">{{'selectPriority' | translate}}<span
                            class="text-danger">*</span>
                        <span *ngIf="createForm.controls['selectedPriority'].errors">

                            <span
                                *ngIf="createForm.controls['selectedPriority'].errors || isValidPriority==true || isValidPriority1==true">
                                <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                                    *ngIf="(createForm.controls['selectedPriority'].errors?.required&&createForm.controls['selectedPriority'].dirty) || !createForm.controls['selectedPriority'].value">
                                    <!-- {{'mandatory' | translate}} -->
                                </span>
                            </span>
                        </span>
                    </p>
                    <p-dropdown [options]="priority" formControlName="selectedPriority" ui-dropdown-filter=''
                        placeholder="{{'selectPriority' | translate}}" (onTextChange)="onChangePriority($event)"
                        optionLabel="name" styleClass="widthdropdown2Create">
                    </p-dropdown>

                </div>
            </div>
           
            <div class="editor" style="width: 1335px;">
                <p class="commonhead commonBottom widthTitle" style="width:430px">{{'desc' | translate}}<span
                        class="text-danger">*</span>
                    <span *ngIf="createForm.controls['description'].errors || isValidDesc==true || isValidDesc1==true">
                        <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                            *ngIf="(createForm.controls['description'].errors?.required&&createForm.controls['description'].dirty) || !createForm.controls['description'].value">
                            <!-- {{'descriptionIsMandatory' | translate}} -->
                        </span>
                    </span>
                </p>
                <p-editor formControlName="description" name="description" [style]="{'height':'220px'}"
                    [required]="true" (onTextChange)="onChangeDescription($event)" styleClass="editorCreateTask">
                </p-editor>
                <span class="maximize" (click)="maximizeDescription()" style="cursor: pointer;">
                    <i class="pi pi-window-maximize"></i>
                </span>
                <span class="invalid-feedback" style="font-size: 12px !important;"
                    *ngIf="isValidDesc==true || isValidDesc1==true">Please enter valid description.</span>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 ticketType" *ngIf="multipleTemplateList"
            style="padding-left: 0px;padding-right: 5px;width: 98% !important;margin-bottom:4px;">
            <p class="commonhead commonBottom" style="width: 130px;margin-right: 8px!important;">{{'templateType' |
                translate}}<span class="text-danger">*</span>
                <span *ngIf="createForm.controls['ticketType'].errors">
                    <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                        *ngIf="(createForm.controls['ticketType'].errors?.required&&createForm.controls['ticketType'].dirty) || !createForm.controls['ticketType'].value">
                        <!-- {{'mandatory' | translate}} -->
                    </span>
                </span>
            </p>


            <p-dropdown #ticketty style="width: 100%;" [options]="templateList" formControlName="ticketType"
                filter="true" (onChange)="onChangeTaskType($event)" (onShow)="ticketty.resetFilter()"
                optionLabel="name" placeholder="{{'selectTemplateType' | translate}}"
                styleClass="setUserClassSetType">
            </p-dropdown>


        </div>


        <!-- Div for dynamic template -->
        <div class="col-lg-12 col-md-12 col-sm-12 " *ngIf="noBusinessAssigned"
            style="padding-left: 0px;padding-right: 5px;width: 99% !important;">
            <div *ngFor="let data of dynamicJson ;let i = index;let even = even;"
                [ngClass]="[dynamicJson[i].datatype=='textarea' ? 'dynamicFieldDivNew' : 'dynamicFieldDiv']">
                <div>

                    <p class="formLabel mb-0 commonhead commonBottom" style="font-size:14px">{{data.parameterName |
                        translate}} <span *ngIf="data.isMandatory" style="color: red;">*</span><span
                            style="color: red;font-size: 10px;" *ngIf="data?.isValidatedExp==false"> Field value not
                            valid.</span> </p>
                    <ng-template [ngIf]="!dynamicJson[i].isMandatory">
                        <input type="text" [(ngModel)]="data.parameterValue" *ngIf="dynamicJson[i].datatype=='text'"
                            maxlength="45" [ngModelOptions]="{standalone: true}" class="form-control" id="data{{i}}"
                            name="data{{i}}" #uname
                            style="height: 23px;width: 100%;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8"
                            (keyup)="validateNonMandatoryPatternFields(uname.value,i);"
                            pTooltip="{{data.parameterValue}}" tooltipPosition="top">


                        <p-dropdown *ngIf="dynamicJson[i].datatype=='dropdown'"
                            [(ngModel)]="dynamicJson[i].selection" [ngModelOptions]="{standalone: true}"
                            [options]="dynamicJson[i].dropNewValue" optionLabel="name"
                            placeholder="{{'select' | translate}}">
                        </p-dropdown>

                        <p-dropdown *ngIf="dynamicJson[i].datatype=='employee'" filter="true"
                        [(ngModel)]="dynamicJson[i].selectionEmp" [ngModelOptions]="{standalone: true}"
                        [options]="dynamicJson[i].dropNewValue" optionLabel="name"
                        placeholder="{{'select' | translate}}">
                    </p-dropdown>

                        <textarea #lname [(ngModel)]="data.parameterValue" class="form-control"
                            [ngModelOptions]="{standalone: true}"
                            style="height: 70px;width: 1335px;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8;border-radius: 5px;"
                            *ngIf="dynamicJson[i].datatype=='textarea'"
                            (keyup)="validateNonMandatoryPatternFields(lname.value,i);">
    </textarea>
                    </ng-template>
                    <ng-template [ngIf]="dynamicJson[i].isMandatory">

                        <input type="text" class="textField" *ngIf="dynamicJson[i].datatype=='text'"
                            [(ngModel)]="data.parameterValue" class="form-control"
                            [ngModelOptions]="{standalone: true}"
                            style="height: 23px;width: 100%;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border: 1px solid #c8c8c8;"
                            maxlength="45" name="dynamicName{{i}}" id="data{{i}}"
                            (change)="validateMandatoryFields($event,i,'text');validatePatternFields($event,i,'text')"
                            pTooltip="{{data.parameterValue}}" tooltipPosition="top">

                        <p-dropdown *ngIf="dynamicJson[i].datatype=='dropdown'"
                            [(ngModel)]="dynamicJson[i].selection" [ngModelOptions]="{standalone: true}"
                            [options]="dynamicJson[i].dropNewValue"
                            (onChange)="validateMandatoryFields($event,i,'text');" optionLabel="name"
                            placeholder="{{'select' | translate}}">
                        </p-dropdown>
                        <!-- onChangeDropValue1($event,i) -->

                        <p-dropdown *ngIf="dynamicJson[i].datatype=='employee'"  filter="true"
                        [(ngModel)]="dynamicJson[i].selectionEmp" [ngModelOptions]="{standalone: true}"
                        [options]="dynamicJson[i].dropNewValue" optionLabel="name" (onChange)="validateMandatoryFields($event,i,'text');"
                        placeholder="{{'select' | translate}}">
                    </p-dropdown>

                        <textarea id="w3review" name="w3review"
                            (ngModelChange)="validateMandatoryFields($event,i,'textarea');validatePatternFields($event,i,'text')"
                            [(ngModel)]="data.parameterValue" class="form-control"
                            [ngModelOptions]="{standalone: true}"
                            style="height: 70px;width: 1335px;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8;border-radius: 5px;"
                            *ngIf="dynamicJson[i].datatype=='textarea'">
       </textarea>
                    </ng-template>
                </div>
            </div>
        </div>
            <div class="row m-0 calender" style="margin-top: 10px !important;margin-bottom: 8px !important;">
                <p class="commonhead DuedateHead widthTitle dueDateMultiLang" title="{{'dueDate' | translate}}">
                    {{'dueDate' | translate}}</p>
                <p-calendar id="dueDateAdd" formControlName="dueDate" styleClass="dateFieldAdd"
                    placeholder="{{'calendar' | translate}}" [locale]="calendarValue" [minDate]="minDueDate"
                    [class.selectedBtn]="selectedBtn == 'DATE'" (click)="onDateBtnClick('DATE')"
                    (onClose)="onCloseDueDate($event)">
                </p-calendar>
                <button [class.selectedBtn]="selectedBtn == 'Today'" type="button" style="margin-left: 25px;"
                    class="btn btn-default btnStyles" *ngIf="lang!='fr'" (click)="onDateBtnClick('Today')"
                    aria-label="Today"><b>{{'today' | translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'Tomorrow'" type="button" class="btn btn-default btnStyles"
                    *ngIf="lang!='fr'" (click)="onDateBtnClick('Tomorrow')" aria-label="Tomorrow"><b>{{'tomorrow' |
                        translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'This Week'" type="button" class="btn btn-default btnStyles"
                    *ngIf="lang!='fr'" (click)="onDateBtnClick('This Week')" aria-label="thisWeek"><b>{{'thisWeek' |
                        translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'Recurrence'" type="button" (click)="openRecurrence()"
                    *ngIf="lang!='fr'" class="btn btn-default btnStyles" aria-label="recurrence"><i
                        class="fas fa-redo-alt" style="margin-right: 3px;"></i><b>{{'recurrence' |
                        translate}}</b></button>

                <button [class.selectedBtn]="selectedBtn == 'Today'" type="button" class="btn btn-default btnStyles"
                    *ngIf="lang=='fr'" style="margin-left: 8px !important;"
                    (click)="onDateBtnClick('Today')"><b>{{'today' | translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'Tomorrow'" type="button" class="btn btn-default btnStyles"
                    *ngIf="lang=='fr'" style="margin-left: 8px !important;"
                    (click)="onDateBtnClick('Tomorrow')"><b>{{'tomorrow' | translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'This Week'" type="button" class="btn btn-default btnStyles"
                    *ngIf="lang=='fr'" style="margin-left: 8px !important;"
                    (click)="onDateBtnClick('This Week')"><b>{{'thisWeek' | translate}}</b></button>
                <button [class.selectedBtn]="selectedBtn == 'Recurrence'" type="button" (click)="openRecurrence()"
                    *ngIf="lang=='fr'" style="margin-left: 8px !important;" class="btn btn-default btnStyles"><i
                        class="fas fa-redo-alt" style="margin-right: 3px;"></i><b>{{'recurrence' |
                        translate}}</b></button>
                <span class="recMsgSpan" *ngIf="isCreateRecurrence==true">{{'recurrence' | translate}}:
                    {{recurrenceMsg}}</span>
                <p class="commonhead assignLabels widthTitle"
                    style="margin-left:16px !important;width: 65px !important;">{{'estimate' | translate}}</p>
                <input type="number" value=1 formControlName="dueTime" class="form-control" min="1" max="10"
                    oninput="validity.valid||(value='');" placeholder="" class="inputEstimate" id="estimateAdd">
                <p class=" commonhead assignLabels" style="display: inline-block;margin-left: 5px;">{{'hrs' |
                    translate}}</p>
            </div>

            <div class="assign" [ngClass]="{'ifrecMsg':isCreateRecurrence==true}">
                <p class="commonhead assignLabels widthTitle overflowEllipsisCss" style="margin-right: 0px; "
                    title="{{'assignTo' | translate}}">{{'assignTo' | translate}}</p>
                <button type="button" [class.selectedBtn]="selectedBtns == 'self'" class="btn btn-default btnStylesss"
                    (click)="onselfClick('self')"><b>{{'self' | translate}}</b></button>

                <p-dropdown #assigndd [options]="UserList" filter="true" [disabled]="setAssigneeGroupFlag"
                    formControlName="selectedAssignTo" (onShow)="assigndd.resetFilter()"
                    placeholder="{{'selectUser' | translate}}" (onChange)="setAssignee($event.value)" optionLabel="name"
                    styleClass="setUserClassNew">
                </p-dropdown>

                <p-dropdown #assigndgroupd placeholder="{{'selectUserGroup' | translate}}" [options]="UserGroupList"
                    styleClass="setUserClassSetTypeUserNewe" filter="true" [disabled]="setAssigneeFlag"
                    formControlName="selectedAssignGroup" (onChange)="setAssigneeGroup($event.value)"
                    (onShow)="assigndgroupd.resetFilter()" optionLabel="name"
                    [ngClass]="{'userGroupDropdownMulti' : lang=='fr'}">
                </p-dropdown>
                <!-- <div class="assign " style="margin-top: 17px;"> -->

                <!-- </div> -->
            </div>

            <div class="fileupload " style="height: 30px;">

                <div class="row pl-12">
                    <div class="">
                        <p class="commonhead commonBottom" style="
                            padding-left: 15px;
                            width: 107px;
                            display: inline-block;
                            margin-right: 8px!important;">{{'taskType' | translate}}<span class="text-danger">*</span>
                            <span *ngIf="createForm.controls['taskType'].errors">


                                <span class="text-danger" style="font-size: 12px;margin-top: -14px !important;"
                                    *ngIf="(createForm.controls['taskType'].errors?.required&&createForm.controls['taskType'].dirty) || !createForm.controls['taskType'].value">
                                    <!-- {{'mandatory' | translate}} -->
                                </span>

                            </span>


                        </p>
                        <p-dropdown #taskdd style="width: 575px;display: inline-block;" [options]="taskTypeData"
                            formControlName="taskType" filter="true" (onShow)="taskdd.resetFilter()" optionLabel="name"
                            placeholder="{{'selectTaskType' | translate}}" styleClass="setUserClassSetType">
                        </p-dropdown>



                    </div>

                    <div class="">

                        <div style="margin-bottom: 15px;margin-left: 26px;" class="watchListAddDiv">
                            <p class="commonhead taskTypeLabelTs widthTitle overflowEllipsisCss"
                                style="width: 80px !important;" title="{{'watchlist' | translate}}">{{'watchlist' |
                                translate}}</p>
                            <p-multiSelect (onChange)="onChangeWathlist($event)" [options]="watchlistDropdown"
                                defaultLabel="{{'selectWatchlist' | translate}}" formControlName="selectWatchlist"
                                styleClass="widthdropdownProjectMultiselect" [ngClass]="{'watchListMulti':lang=='fr'}">
                            </p-multiSelect>
                        </div>


                    </div>
                </div>
            </div>

            <div class="row pl-12" style="height: 13vh;">
                <div class=" " style="height: 13vh;width:687px !important">
                    <div class="assign mbottom">
                        <!-- <p class="commonhead assignLabels">Check List</p> -->
                        <p> <img src="../../../../assets/img/Group8434.png" style="width: 15px;cursor: pointer;"
                                (click)="addNewRow()" alt="checklist">
                            {{'addchecklist' | translate}}</p>
                        <p-scrollPanel [style]="{ height: '70px','overflow':'scroll'}" styleClass="mystyle">
                            <form [formGroup]="invoiceForm">
                                <div formArrayName="checklist" style="margin-top: 10px;">
                                    <div *ngFor="let itemrow of invoiceForm.controls.checklist.controls; let i=index"
                                        [formGroupName]="i">
                                        <div style="display: inline-block;height: 9px;">
                                            <div class="form-group"
                                                style="width: 10px;display: inline-block;margin-right: 5px;height: 26px;vertical-align: sub;">
                                                <input formControlName="checked" class="form-control" type="checkbox"
                                                    id="checkListAdd"
                                                    style=" display: inline-block; width: 20px; height: 16px;border-radius: 10px;cursor: pointer;">
                                            </div>
                                            <div class="form-group"
                                                style="width: 300px;display: inline-block;margin-bottom: 5px;margin-right: 15px;">
                                                <input formControlName="criteria" class="form-control inputclass"
                                                    id="criteriaAdd"
                                                    style="height: 21px;margin-bottom: 5px !important;margin-left: 9px;margin-bottom: 7px;margin-top: 5px;display: inline-block;">
                                            </div>
                                        </div>
                                        <img (click)="deleteRow(i)" src="../../../../assets/img/close.png"
                                            *ngIf="invoiceForm.controls.checklist.controls.length > 0" alt="delete"
                                            style="display: inline-block;width: 15px;cursor: pointer;">
                                    </div>
                                </div>
                            </form>
                        </p-scrollPanel>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 btnmozilla" style="height: 13vh;">
                    <input type="button" style="margin-left: 15px;margin-top: 8px;" class="btn btn-sm attachfilebtn"
                        name="attachfile" id="attachfile" (click)="uploader.click()"
                        value="{{'chooseFile' | translate}}">
                    <input hidden type="file" (click)="uploader.value = null" (change)="onFileChange($event)"
                        class="file" id="addattachfile" #uploader multiple>
                    <label class="fileSizeLabel mb-0 ml-1 fileSizeMsgMulti" for="addattachfile"
                        style="width: 200px;"><span class="text-danger">*</span> {{'fileSizeMsg' | translate}}</label>
                    <p-scrollPanel styleClass="mystyleCreate" [style]="{ height: '35px', width:'345px'}">
                        <div *ngFor="let files of files;let index = index" style="margin-top: 1px;margin-left: 10px;"
                            class="createdattachmentdiv">
                            <span style="display: inline-block;"><img class="attachedImg" tooltipPosition="bottom"
                                    pTooltip="{{files.name}}" style="width: 28px;margin-right: 15px;"
                                    tooltipStyleClass="custom-tooltip" alt="image"
                                    *ngIf="files!==null && files['type'] == 'image/jpeg'"
                                    [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'image/png'" alt="image"
                                    [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'application/octet-stream'" alt="image"
                                    src="../../assets/img/docs.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'application/vnd.ms-excel'" alt="image"
                                    src="../../assets/img/xl.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image"
                                    *ngIf="files!==null && files['type']  == 'application/vnd.ms-excel.sheet.binary.macroenabled.12'"
                                    src="../../assets/img/xl.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image"
                                    *ngIf="files!==null && files['type']  == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                    src="../../assets/img/xl.png"></span>
                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'xlsx'"
                                    src="../../assets/img/xl.png"></span>
                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'pdf'"
                                    src="../../assets/img/pdf.png"></span>

                            <span><img class="attachedImg" tooltipPosition="top" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'xlsx'"
                                    src="../../assets/img/pdf.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == ''"
                                    src="../../assets/img/pdf.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'docx'"
                                    src="../../assets/img/pdf.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'application/pdf'"
                                    src="../../assets/img/pdf.png"></span>

                            <span><img class="attachedImg" tooltipPosition="bottom" pTooltip="{{files.name}}"
                                    style="width: 28px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image" *ngIf="files!==null && files['type']  == 'application/docx'"
                                    src="../../assets/img/pdf.png"></span>

                            <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                    tooltipPosition="bottom" title="{{files.fileName}}" (click)="download(files)"
                                    style="width: 33px;margin-right: 15px;" tooltipStyleClass="custom-tooltip"
                                    alt="image"
                                    *ngIf="files!==null && files['type']  == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                                    src="../../assets/img/ppt.png"></span>
                            <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                    tooltipPosition="bottom" title="{{files.fileName}}" (click)="download(files)"
                                    alt="image" style="width: 33px;margin-right: 15px;"
                                    tooltipStyleClass="custom-tooltip" *ngIf="files!==null && files['type']  == 'pptx'"
                                    src="../../assets/img/ppt.png"></span>
                            <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                    tooltipPosition="bottom" title="{{files.fileName}}" (click)="download(files)"
                                    alt="image" style="width: 33px;margin-right: 15px;"
                                    tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                    src="../../assets/img/word.png"></span>
                            <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                    tooltipPosition="bottom" title="{{files.fileName}}" (click)="download(files)"
                                    alt="image" style="width: 29px;margin-right: 15px;"
                                    tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'video/quicktime'"
                                    src="../../assets/img/multimedia.png"></span>
                            <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                    tooltipPosition="bottom" title="{{files.fileName}}" (click)="download(files)"
                                    alt="image" style="width: 29px;margin-right: 15px;"
                                    tooltipStyleClass="custom-tooltip"
                                    *ngIf="files!==null && files['type']  == 'video/mp4'"
                                    src="../../assets/img/multimedia.png"></span>
                            <p tooltipPosition="left" pTooltip="{{files.name}}" title="{{files.name}}"
                                style="display: inline-block;font-size: 10px;cursor:pointer;width: 75px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                {{files.name}}</p>
                            <img (click)="removeSelectedFile(index)" src="../../../../assets/img/close.png" alt="image"
                                style="width: 15px;display: inline-block; margin-left: 7px;cursor: pointer;">
                        </div>
                    </p-scrollPanel>
                    <button type="button" class="btn btn-default submitBtns" (click)="CancelCreateForm()" style="position: relative;
                    margin-right: 21px;bottom:15px;">{{'cancel ' | translate}}</button>

                    <input type="submit" class="btn btn-default submitBtnDash " value="{{'createBTN' | translate}}"
                        [disabled]="!createForm.valid || isValidDesc==true || isValidDesc1==true || !isValidated || !isValidatedManField || !isValidatedNonManField"
                        style="position: relative;
                    right:-490px;cursor: pointer;bottom:15px;">


                </div>
            </div>
        </form>
    </p-scrollPanel>
</p-dialog>

<p-dialog [(visible)]="viewDialogueBox" [closable]="false" styleClass="overlayDialogFeedbackView" [modal]="true"
    [style]="{'color':'red', height: 'auto',zoom: '80%'}" [draggable]="false" class="ViewEditTask">
    <p-header>
        <p *ngIf="headerNameData=='Edit Task'"
            style="display: inline-block; font-size: 14px;  line-height: 0 ;padding-bottom: 10px;padding-top: 10px;padding-right: 10px; padding-left: 10px;">
            {{'editTask' | translate}}</p>
        <p *ngIf="headerNameData=='View Task'"
            style="display: inline-block; font-size: 14px;  line-height: 0 ;padding-bottom: 10px;padding-top: 10px;padding-right: 10px; padding-left: 10px;">
            {{'viewTask' | translate}}</p>
        <img (click)="CancelViewForm()" src="../../../../assets/img/Group 8423.png" alt="cancel"
            style="width: 18px;float: right;display: inline-block;margin-top: 2px;cursor: pointer;">
    </p-header>

    <p-scrollPanel [style]="{width: 'auto', height: 'auto'}">
        <div class="row">
            <div style="width: 60% !important;
       padding-left: 20px;
       overflow-y: auto;
       overflow-x: hidden;
       height: 590px;" class="viewTaskDetails">




                <form [formGroup]="viewForm" (ngSubmit)="UpdateTask(viewForm.value)">
                    <div class="wrapper">
                        <div class="form-group form-inline taskName widthIncrease" *ngIf="isPrivateTaskData">
                            <div>
                                <input type="text" *ngIf="viewFormDisabled" pInputText
                                    class="form-control taskNameInput ng-dirty ng-invalid" id="taskNameEdit"
                                    maxlength="500" [class.invalid]="!this.viewForm.valid" readonly
                                    placeholder="{{'addTaskName500Char' | translate}}*" formControlName="taskName"
                                    name="taskName">
                                <input type="text" *ngIf="!viewFormDisabled" pInputText
                                    class="form-control taskNameInput ng-dirty ng-invalid" id="taskNameEdit1"
                                    maxlength="500" [class.invalid]="!this.viewForm.valid"
                                    placeholder="{{'addTaskName500Char' | translate}}*" formControlName="taskName"
                                    name="taskName">
                                <p class="paraTask">{{'taskId' | translate}} : <b>{{taskIdData}}</b></p>
                            </div>
                            <span class="privateSpanView commonhead"></span>
                            <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                                class="privateSpanView" formControlName="privateTask" *ngIf="!onEditClickCheck"
                                disabled="true"></p-checkbox>

                            <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                                class="privateSpanView" formControlName="privateTask"
                                *ngIf="!privateTaskAllow && onEditClickCheck" disabled="true">
                            </p-checkbox>

                            <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                                class="privateSpanView" formControlName="privateTask"
                                *ngIf="privateTaskAllow && onEditClickCheck"></p-checkbox>
                            <div style="float: right;display: inline-flex;
                    flex-direction: column; margin-left: 0px;" class="commentHistoryIcons">
                                <img src="../../../../assets/img/Group 8695.png" title="{{'viewComments' | translate}}"
                                    style="width: 28px;float: right;cursor: pointer;" alt=""
                                    (click)="showCommnetsDialogue()">
                                <img (click)="showHistoryDialogue()" title="{{'viewHistory' | translate}}"
                                    src="../../../../assets/img/Group 8694.png"
                                    style="width: 28px;float: right;cursor: pointer;" alt="">
                            </div>
                        </div>

                        <div class="form-group form-inline taskName widthIncrease" *ngIf="!isPrivateTaskData">
                            <div>
                                <input type="text" *ngIf="viewFormDisabled" pInputText
                                    class="form-control taskNameInput ng-dirty ng-invalid" id="taskNameEdit2"
                                    maxlength="500" [class.invalid]="!this.viewForm.valid" readonly
                                    placeholder="{{'addTaskName500Char' | translate}}*" formControlName="taskName"
                                    name="taskName">
                                <input type="text" *ngIf="!viewFormDisabled" pInputText
                                    class="form-control taskNameInput ng-dirty ng-invalid" id="taskNameEdit3"
                                    maxlength="500" [class.invalid]="!this.viewForm.valid"
                                    placeholder="{{'addTaskName500Char' | translate}}*" formControlName="taskName"
                                    name="taskName">
                                <p class="paraTask">{{'taskId' | translate}} : <b>{{taskIdData}}</b></p>

                            </div>
                            <span class="privateSpanView commonhead"></span>
                            <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                                class="privateSpanView" formControlName="privateTask" *ngIf="viewFormDisabled"
                                disabled="true"></p-checkbox>

                            <p-checkbox name="privateTask" binary="true" label="{{'privateTask' | translate}}"
                                class="privateSpanView" formControlName="privateTask"
                                *ngIf="!viewFormDisabled"></p-checkbox>
                            <div style="float: right;display: inline-flex; 
                        flex-direction: column;margin-left: 0px;" class="commentHistoryIcons">
                                <img src="../../../../assets/img/Group 8695.png" title="{{'viewComments' | translate}}"
                                    style="width: 28px;float: right;cursor: pointer;" alt="viewComment"
                                    (click)="showCommnetsDialogue()">
                                <img (click)="showHistoryDialogue()" title="{{'viewHistory' | translate}}"
                                    src="../../../../assets/img/Group 8694.png"
                                    style="width: 28px;float: right;cursor: pointer;" alt="viewHistory">
                            </div>
                        </div>


                        <div class="productSelection">
                            <p class="commonhead commonBottom">{{'selectProduct' | translate}}</p>
                            <p-dropdown #dd [options]="product" filter="true" formControlName="selectedProduct"
                                placeholder="{{'selectProduct' | translate}}" (onShow)="dd.resetFilter()"
                                optionLabel="name" [disabled]="viewFormDisabled"
                                styleClass="widthdropdownProject"></p-dropdown>
                        </div>

                        <div class="projectSelectionView">
                            <p class="commonhead commonBottom">{{'selectProject' | translate}}</p>
                            <p-dropdown #projectdd [options]="projects" filter="true" (onShow)="projectdd.resetFilter()"
                                formControlName="selectedProject" optionLabel="name" [disabled]="viewFormDisabled"
                                placeholder="{{'selectProject' | translate}}"
                                styleClass="widthdropdownProject"></p-dropdown>
                        </div>

                        <div class="projectSelectionView">
                            <p class="commonhead commonBottom">{{'selectPriority' | translate}}</p>
                            <p-dropdown [options]="priority" formControlName="selectedPriority" ui-dropdown-filter=''
                                placeholder="{{'selectPriority' | translate}}" optionLabel="name"
                                [disabled]="viewFormDisabled" styleClass="widthdropdownProject1"></p-dropdown>
                        </div>
                    </div>





                   
                    <div class="editor" *ngIf="!viewFormDisabled" style="width: 830px;">
                        <p class="commonhead commonBottom">{{'desc' | translate}}
                            <!-- <span class="text-danger">*</span>
                    <span *ngIf="viewForm.controls['description'].errors">
                        <span class="text-danger"  style="font-size: 12px;margin-top: -14px !important;"
                            *ngIf="(viewForm.controls['description'].errors?.required&&viewForm.controls['description'].dirty) || !viewForm.controls['description'].value">
                            {{'descriptionIsMandatory' | translate}}
                        </span>
                    </span> -->
                        </p>
                        <p-editor formControlName="description" name="description"
                            [style]="{'height':'200px','width': '830px', 'font-size': '12px'}"
                            (onTextChange)="onChangeDescription($event)" styleClass="editorCreateTaskView">
                        </p-editor>
                        <span class="maximize1" (click)="editmaximizeDescription()" style="cursor: pointer;">
                            <i class="pi pi-window-maximize"></i>
                        </span>

                        <!-- <span class="invalid-feedback" style="font-size: 12px !important;" *ngIf="isValidDesc==true || isValidDesc1==true">Please enter valid description.</span> -->
                    </div>

                    <div class="editor" *ngIf="viewFormDisabled" style="width:94%">
                        <p class="commonhead commonBottom">{{'desc' | translate}}
                            <!-- <span class="text-danger">*</span>
                    <span class="text-danger"  style="font-size: 12px;margin-top: -14px !important;"
                    *ngIf="(viewForm.controls['description'].errors?.required&&viewForm.controls['description'].dirty) || !viewForm.controls['description'].value">
                        Description is mandatory.
                    </span> -->
                        </p>
                        <div [innerHTML]="inputText" type="text" id="usrTextarea"> </div>
                        <span class="maximize2" (click)="viewmaximizeDescription()" style="cursor: pointer;">
                            <i class="pi pi-window-maximize"></i>
                        </span>

                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 " *ngIf="noBusinessAssigned"
                    style="margin-bottom:7px;padding-left: 0px;padding-right: 5px;width: 99.5% !important;">
                    <div *ngFor="let data of dynamicJsonEdit ;let i = index;let even = even;"
                        [ngClass]="[dynamicJsonEdit[i].datatype=='textarea' ? 'dynamicFieldDivNew2' : 'dynamicFieldDiv2']">
                        <p class="formLabel mb-0 commonhead commonBottom" style="font-size:14px">
                            {{data.parameterName | translate}}<span *ngIf="data.isMandatory"
                                style="color: red;">*</span><span style="color: red;font-size: 10px;"
                                *ngIf="data?.isValidatedExp==false"> Field value not valid.</span> </p>

                        <ng-template [ngIf]="!dynamicJsonEdit[i].isMandatory">
                            <input type="text" [(ngModel)]="dynamicJsonEdit[i].parameterValue"
                                [ngModelOptions]="{standalone: true}" class="form-control" id="data{{i}}"
                                [disabled]="viewFormDisabled" name="data{{i}}" maxlength="45" #lname
                                style="height: 25px;width: 100%;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8; background-color: white !important;"
                                pTooltip="{{data.parameterValue}}" tooltipPosition="top"
                                (keyup)="validateNonMandatoryPatternFieldsEdit(lname.value,i)"
                                *ngIf="dynamicJsonEdit[i].datatype=='text'">
                                <!-- {{dynamicJsonEdit[i].selection |json}} -->
                            <p-dropdown *ngIf="dynamicJsonEdit[i].datatype=='dropdown'"
                                [(ngModel)]="dynamicJsonEdit[i].selection" [disabled]="viewFormDisabled"
                                [ngModelOptions]="{standalone: true}" [options]="dynamicJsonEdit[i].dropNewValue"
                                optionLabel="name" placeholder="{{'select' | translate}}">
                            </p-dropdown>
                            <!-- {{dynamicJsonEdit[i].selectionEmp |json}} -->
                            <p-dropdown *ngIf="dynamicJsonEdit[i].datatype=='employee'" filter="true"
                            [(ngModel)]="dynamicJsonEdit[i].selectionEmp" [disabled]="viewFormDisabled" [ngModelOptions]="{standalone: true}"
                            [options]="dynamicJsonEdit[i].dropNewValue" optionLabel="name" placeholder="{{'select' | translate}}"
                           >
                        </p-dropdown>


                            <textarea #lname [(ngModel)]="dynamicJsonEdit[i].parameterValue"
                                [disabled]="viewFormDisabled" class="form-control"
                                [ngModelOptions]="{standalone: true}"
                                style="height: 70px;width: 825px;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8;border-radius: 5px;"
                                *ngIf="dynamicJsonEdit[i].datatype=='textarea'"
                                (ngModelChange)="validateNonMandatoryPatternFieldsEdit(lname.value,i)">
                    </textarea>


                        </ng-template>
                        <ng-template [ngIf]="dynamicJsonEdit[i].isMandatory">

                            <input type="text" class="textField" [(ngModel)]="dynamicJsonEdit[i].parameterValue"
                                class="form-control" [ngModelOptions]="{standalone: true}"
                                [disabled]="viewFormDisabled" maxlength="45"
                                style="height: 25px;width: 100%;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border: 1px solid #c8c8c8; background-color: white !important;"
                                (change)="validateMandatoryFieldsEdit($event,i,'text');validatePatternFieldsEdit($event,i,'text')"
                                name="data{{i}}" id="data{{i}}" *ngIf="dynamicJsonEdit[i].datatype=='text'"
                                pTooltip="{{data.parameterValue}}" tooltipPosition="top">

                            <p-dropdown *ngIf="dynamicJsonEdit[i].datatype=='dropdown'"
                                [(ngModel)]="dynamicJsonEdit[i].selection" [ngModelOptions]="{standalone: true}"
                                [options]="dynamicJsonEdit[i].dropNewValue" [disabled]="viewFormDisabled"
                                (onChange)="validateMandatoryFieldsEdit($event,i,'text');" optionLabel="name"
                                placeholder="{{'select' | translate}}">
                            </p-dropdown>

                            <p-dropdown *ngIf="dynamicJsonEdit[i].datatype=='employee'" (onChange)="validateMandatoryFieldsEdit($event,i,'text');"  filter="true"
                            [(ngModel)]="dynamicJsonEdit[i].selectionEmp" [ngModelOptions]="{standalone: true}"
                            [options]="dynamicJsonEdit[i].dropNewValue" optionLabel="name"  [disabled]="viewFormDisabled" placeholder="{{'select' | translate}}"
                            >
                        </p-dropdown>
                            

                            <textarea id="w3review" name="w3review"
                                (ngModelChange)="validateMandatoryFieldsEdit($event,i,'textarea');validatePatternFieldsEdit($event,i,'text')"
                                [disabled]="viewFormDisabled" [(ngModel)]="dynamicJsonEdit[i].parameterValue"
                                class="form-control" [ngModelOptions]="{standalone: true}"
                                style="height: 70px;width: 825px;display: inline;padding-left: 6px !important;font-size: 13px !important;color:#333333;border:1px solid #c8c8c8;border-radius: 5px;"
                                *ngIf="dynamicJsonEdit[i].datatype=='textarea'">
                               </textarea>


                        </ng-template>

                    </div>
                </div>

                    <div class="calender">
                        <p class="commonhead DuedateHead widthTitle dueDateMultiLang" title="{{'dueDate' | translate}}">
                            {{'dueDate' | translate}}</p>
                        <p-calendar id="dueDateEdit" formControlName="dueDate" styleClass="dateField"
                            [minDate]="minDueDate" [locale]="calendarValue" [class.selectedBtn]="selectedBtn == 'DATE'"
                            (click)="onDateBtnClickview('DATE')" (onClose)="onCloseDueDate($event)"
                            [disabled]="viewFormDisabled">
                        </p-calendar>
                        <button [class.selectedBtn]="selectedBtn == 'Today'" type="button"
                            class="btn btn-default btnStylesview" (click)="onDateBtnClickview('Today')"
                            [disabled]="viewFormDisabled"><b>{{'today' | translate}}</b></button>
                        <button [class.selectedBtn]="selectedBtn == 'Tomorrow'" type="button"
                            class="btn btn-default btnStylesview" (click)="onDateBtnClickview('Tomorrow')"
                            [disabled]="viewFormDisabled"><b>{{'tomorrow' | translate}}</b></button>
                        <button [class.selectedBtn]="selectedBtn == 'This Week'" type="button"
                            class="btn btn-default btnStylesview" (click)="onDateBtnClickview('This Week')"
                            [disabled]="viewFormDisabled"><b>{{'thisWeek' | translate}}</b></button>
                        <button [class.selectedBtn]="selectedBtn == 'Recurrence'" type="button"
                            (click)="openRecurrence()" class="btn btn-default btnStylesview"
                            [disabled]="viewFormDisabled"><i class="fas fa-redo-alt"
                                style="margin-right: 3px;"></i><b>{{'recurrence' | translate}}</b></button>
                        <span class="recMsgSpan" *ngIf="isCreateRecurrence==true">{{'reccurance' | translate}}:
                            {{recurrenceMsg}}</span>
                        <!-- <div class="assign" style="margin-top: 17px;"> -->
                        <p class="commonhead assignLabels widthTitle" style="margin-left: 25px !important;width: 65px;">
                            {{'estimate' | translate}}</p>

                        <input type="number" formControlName="dueTime" class="form-control" min="1" max="10"
                            oninput="validity.valid||(value='');" [disabled]="viewFormDisabled" placeholder=""
                            class="inputEstimate" id="estimateEdit" *ngIf="viewFormDisabled" readonly>

                        <input type="number" formControlName="dueTime" class="form-control" min="1" max="10"
                            oninput="validity.valid||(value='');" [disabled]="viewFormDisabled" placeholder=""
                            class="inputEstimate" id="editEstimate" *ngIf="!viewFormDisabled">
                        <p class=" commonhead assignLabels" style="display: inline-block;margin-left: 5px;">{{'hrs' |
                            translate}}</p>

                        <!-- </div> -->
                    </div>

                    <div class="assign" *ngIf="!isPrivateTaskData" [ngClass]="{'ifrecMsg':isCreateRecurrence==true}">
                        <p class="commonhead assignLabels widthTitle overflowEllipsisCss" style="margin-right: 0px; "
                            title="{{'assignTo' | translate}}">{{'assignTo' | translate}}</p>
                        <button type="button" [class.selectedBtn]="selectedBtns == 'self'"
                            class="btn btn-default btnStylesss" (click)="onselfClickEdit('self')"
                            [disabled]="viewFormDisabled"><b>{{'self' | translate}}</b></button>
                        <p-dropdown #assigndd [options]="UserList" filter="true" [disabled]="setAssigneeGroupFlag"
                            formControlName="selectedAssignTo" (onShow)="assigndd.resetFilter()"
                            placeholder="{{'selectUser' | translate}}" (onChange)="setAssignee($event.value)"
                            optionLabel="name" styleClass="setUserClassView" [disabled]="viewFormDisabled">
                        </p-dropdown>
                        <p-dropdown #assigndgroupd [options]="UserGroupList" styleClass="setUserClassSetTypeUserView"
                            filter="true" [disabled]="setAssigneeFlag" formControlName="selectedAssignGroup"
                            placeholder="{{'selectUserGroup' | translate}}" (onChange)="setAssigneeGroup($event.value)"
                            (onShow)="assigndgroupd.resetFilter()" optionLabel="name" [disabled]="viewFormDisabled">
                        </p-dropdown>
                    </div>

                    <div class="assign" *ngIf="isPrivateTaskData" [ngClass]="{'ifrecMsg':isCreateRecurrence==true}">
                        <p class="commonhead assignLabels widthTitle overflowEllipsisCss" style="margin-right: 0px; "
                            title="{{'assignTo' | translate}}">{{'assignTo' | translate}}</p>
                        <button type="button" [class.selectedBtn]="selectedBtns == 'self'"
                            class="btn btn-default btnStylesss" (click)="onselfClickEdit('self')"
                            *ngIf="!onEditClickCheck" [disabled]=true><b>{{'self' | translate}}</b></button>

                        <p-dropdown #assigndd [options]="UserList" filter="true" *ngIf="!onEditClickCheck"
                            [disabled]=true formControlName="selectedAssignTo" (onShow)="assigndd.resetFilter()"
                            placeholder="{{'selectUser' | translate}}" (onChange)="setAssignee($event.value)"
                            optionLabel="name" styleClass="setUserClassView" [disabled]=true>
                        </p-dropdown>
                        <p-dropdown #assigndgroupd [options]="UserGroupList" *ngIf="!onEditClickCheck"
                            styleClass="setUserClassSetTypeUserView" filter="true" [disabled]=true
                            placeholder="{{'selectUserGroup' | translate}}" formControlName="selectedAssignGroup"
                            (onChange)="setAssigneeGroup($event.value)" (onShow)="assigndgroupd.resetFilter()"
                            optionLabel="name" [disabled]=true>
                        </p-dropdown>

                        <button type="button" [class.selectedBtn]="selectedBtns == 'self'"
                            class="btn btn-default btnStylesss" (click)="onselfClickEdit('self')"
                            *ngIf="!privateTaskAllow && onEditClickCheck" [disabled]=true><b>{{'self' |
                                translate}}</b></button>

                        <p-dropdown #assigndd [options]="UserList" filter="true"
                            *ngIf="!privateTaskAllow && onEditClickCheck" [disabled]=true
                            formControlName="selectedAssignTo" (onShow)="assigndd.resetFilter()"
                            placeholder="{{'selectUser' | translate}}" (onChange)="setAssignee($event.value)"
                            optionLabel="name" styleClass="setUserClassView" [disabled]=true>
                        </p-dropdown>
                        <p-dropdown #assigndgroupd [options]="UserGroupList"
                            *ngIf="!privateTaskAllow && onEditClickCheck" styleClass="setUserClassSetTypeUserView"
                            filter="true" [disabled]=true placeholder="{{'selectUserGroup' | translate}}"
                            formControlName="selectedAssignGroup" (onChange)="setAssigneeGroup($event.value)"
                            (onShow)="assigndgroupd.resetFilter()" optionLabel="name" [disabled]=true>
                        </p-dropdown>

                        <button type="button" [class.selectedBtn]="selectedBtns == 'self'"
                            class="btn btn-default btnStylesss" (click)="onselfClickEdit('self')"
                            *ngIf="privateTaskAllow && onEditClickCheck" [disabled]="viewFormDisabled"><b>{{'self' |
                                translate}}</b></button>

                        <p-dropdown #assigndd [options]="UserList" filter="true"
                            *ngIf="privateTaskAllow && onEditClickCheck" [disabled]="setAssigneeGroupFlag"
                            formControlName="selectedAssignTo" placeholder="{{'selectUser' | translate}}"
                            (onShow)="assigndd.resetFilter()" (onChange)="setAssignee($event.value)" optionLabel="name"
                            styleClass="setUserClassView" [disabled]="viewFormDisabled">
                        </p-dropdown>
                        <p-dropdown #assigndgroupd [options]="UserGroupList"
                            *ngIf="privateTaskAllow && onEditClickCheck" styleClass="setUserClassSetTypeUserView"
                            filter="true" [disabled]="setAssigneeFlag" placeholder="{{'selectUserGroup' | translate}}"
                            formControlName="selectedAssignGroup" (onChange)="setAssigneeGroup($event.value)"
                            (onShow)="assigndgroupd.resetFilter()" optionLabel="name" [disabled]="viewFormDisabled">
                        </p-dropdown>
                    </div>

                    <div class="fileupload ">
                        <div class="row pl-12">
                            <div class="col-lg-5 col-md-5 col-sm-5 ">
                                <div class="taskCriteria" style="left: 5px;"
                                    [ngClass]="{'statusDiv':isStatusSelected==true}">
                                    <p class="commonhead taskTypeLabelsss widthTitle">{{'status' | translate}}</p>
                                    <p-dropdown [options]="StatusList" optionLabel="name" formControlName="Status"
                                        filter="true" (onChange)="openCommentBox($event.value)"
                                        styleClass="statusDropdown" placeholder="{{'selectStatus' | translate}}"
                                        [disabled]="viewFormDisabled"></p-dropdown>
                                    <div class="invalid-feedback" *ngIf="isStatusSelected"
                                        style="width:315px;font-size: 12px !important;">
                                        {{'commentIsMandatoryForSelectedStatus' | translate}} {{showSelectedStatus}}
                                        {{'status' | translate}}.</div>
                                </div>
                                <p class="commonhead taskTypeLabelTs widthTitle overflowEllipsisCss"
                                    title="{{'taskType' | translate}}">{{'taskType' | translate}}</p>
                                <!-- {{taskType.name}} -->
                                <p-dropdown #taskdd [options]="taskTypeData" formControlName="taskType" filter="true"
                                    placeholder="{{'selectTaskType' | translate}}" (onShow)="taskdd.resetFilter()"
                                    optionLabel="name" styleClass="setUserClassSetTypeView"
                                    [disabled]="viewFormDisabled">
                                </p-dropdown>
                                <!-- <div class="assign" style="margin-top: 17px;">
                            <p class="commonhead assignLabels widthTitle">{{'estimate' | translate}}</p>

                            <input type="number" formControlName="dueTime" class="form-control" min="1" max="10" oninput="validity.valid||(value='');"
                                [disabled]="viewFormDisabled" placeholder="" class="inputEstimate" id="estimateEdit"
                                *ngIf="viewFormDisabled" readonly>

                            <input type="number" formControlName="dueTime" class="form-control" min="1" max="10" oninput="validity.valid||(value='');"
                                [disabled]="viewFormDisabled" placeholder="" class="inputEstimate" id="editEstimate"
                                *ngIf="!viewFormDisabled">
                            <p class=" commonhead assignLabels" style="display: inline-block;margin-left: 5px;">{{'hrs' | translate}}</p>

                        </div> -->

                            </div>
                            <div class="col-lg-7 col-md-5 col-sm-5 " style="padding-left: 0px;">

                                <div style="margin-bottom: 10px;margin-left: 39px;" class="watchlistDivEdit">
                                    <p class="commonhead taskTypeLabelTs widthTitle overflowEllipsisCss"
                                        style="margin-left: 10px;" title="{{'watchlist' | translate}}">{{'watchlist' |
                                        translate}}</p>

                                    <p-multiSelect [(ngModel)]="dtWatchlistNew" formControlName="selectWatchlists"
                                        (onChange)="onChangeWathlist($event)" [options]="watchlistDropdown"
                                        defaultLabel="{{'selectWatchlist' | translate}}"
                                        styleClass="widthdropdownProjectMultiselect"
                                        [disabled]="viewFormDisabled"></p-multiSelect>

                                </div>

                                <p class="commonhead attachmentLabel"> <img src="../../../../assets/img/Attachment.png"
                                        style="width: 13px;" alt="attach"></p>
                                <input type="button" class="btn btn-sm attachfilebtn" name="attachfile" id="attachfile"
                                    [disabled]="viewFormDisabled" (click)="uploaders.click()"
                                    value="{{'chooseFile' | translate}}">
                                <input hidden type="file" [disabled]="viewFormDisabled" (click)="uploader.value = null"
                                    (change)="onFileChange2($event)" class="file" id="addattachfile" #uploaders
                                    multiple>
                                <label class="fileSizeLabel mb-0 ml-1 fileSizeMsgMulti" style="width: 200px;"
                                    *ngIf="!viewFormDisabled"><span class="text-danger">*</span> {{'fileSizeMsg' |
                                    translate}}</label>

                                <p-scrollPanel styleClass="mystyleCreate"
                                    [style]="{ height: '45px','position':'absolute', width:'345px'}">
                                    <div *ngFor="let files of files;let index = index"
                                        style="margin-top: 0px;margin-left: 45px; cursor:pointer;"
                                        class="editAttachmentDiv">
                                        <span class="widthFixed" style="display: inline-block;"
                                            title="{{files.fileName}}"><img class="attachedImg"
                                                (click)="openImage(files.imgSrc)" tooltipPosition="left"
                                                title="View Attachment" title="{{files.fileName}}"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip" alt="image"
                                                *ngIf="files!==null && files['contentType'] == 'image/jpeg'"
                                                [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                                        <span class="widthFixed" style="display: inline-block;"
                                            title="{{files.fileName}}"><img class="attachedImg"
                                                (click)="openImage(files.imgSrc)" tooltipPosition="left"
                                                title="{{files.fileName}}" style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip" alt="image"
                                                *ngIf="files!==null && files['contentType'] == 'jpeg'"
                                                [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"
                                            style="display: inline-block;"><img class="attachedImg"
                                                (click)="openImage(files.imgSrc)" tooltipPosition="left"
                                                title="{{files.fileName}}" style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip" alt="image"
                                                *ngIf="files!==null && files['contentType'] == 'png'"
                                                [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="openImage(files.imgSrc)"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'image/png'" alt="image"
                                                [src]="files.imgSrc? files.imgSrc:'../../assets/img/img.png'"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/octet-stream'"
                                                src="../../assets/img/docs.png"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/vnd.ms-excel'"
                                                src="../../assets/img/xl.png"></span>

                                        <span title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" (click)="download(files)"
                                                title="{{files.fileName}}" style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip" alt="image"
                                                *ngIf="files!==null && files['contentType']  == ''"
                                                src="../../assets/img/xl.png"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/vnd.ms-excel.sheet.binary.macroenabled.12'"
                                                src="../../assets/img/xl.png"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                                src="../../assets/img/xl.png"></span>
                                        <span><img class="attachedImg" tooltipPosition="bottom"
                                                pTooltip="{{files.name}}" style="width: 28px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'xlsx'" alt="image"
                                                src="../../assets/img/xl.png"></span>
                                        <span title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" (click)="download(files)"
                                                title="{{files.fileName}}" style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip" alt="image"
                                                *ngIf="files!==null && files['contentType']  == 'pdf'"
                                                src="../../assets/img/pdf.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/pdf'"
                                                src="../../assets/img/pdf.png"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/docx'"
                                                src="../../assets/img/pdf.png"></span>

                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'docx'"
                                                src="../../assets/img/pdf.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                                                src="../../assets/img/ppt.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'pptx'"
                                                src="../../assets/img/ppt.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 33px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                                src="../../assets/img/word.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'video/quicktime'"
                                                src="../../assets/img/multimedia.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['contentType']  == 'video/mp4'"
                                                src="../../assets/img/multimedia.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['type']  == 'video/quicktime'"
                                                src="../../assets/img/multimedia.png"></span>
                                        <span class="widthFixed" title="{{files.fileName}}"><img class="attachedImg"
                                                tooltipPosition="bottom" title="{{files.fileName}}"
                                                (click)="download(files)" alt="image"
                                                style="width: 29px;margin-right: 15px;"
                                                tooltipStyleClass="custom-tooltip"
                                                *ngIf="files!==null && files['type']  == 'video/mp4'"
                                                src="../../assets/img/multimedia.png"></span>
                                        <p #attach title="{{files.fileName}}" style="display: inline-block;font-size: 10px !important; cursor: pointer;width: 75px;
                                white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                                            (click)="downloadAttachment(files)">{{files.fileName}}</p>

                                        <img *ngIf="!viewFormDisabled" (click)="removeSelectedFileEdit(index)"
                                            src="../../../../assets/img/close.png" alt="image"
                                            style="width: 15px;display: inline-block; margin-left: 7px;cursor: pointer;">
                                    </div>
                                </p-scrollPanel>

                                <div class="filecontainer" *ngIf="file.length > 0">
                                    <ul>
                                        <li style="list-style-type: none;" *ngFor="let file of files let i = index">
                                            <i *ngIf="file.customType == 'png' || file.customType == 'jpg'"
                                                class="fa fa-file-image-o" aria-hidden="true"></i>
                                            <i *ngIf="file.customType == 'docx'" class="fa fa-file-word-o"
                                                aria-hidden="true"></i>
                                            <i *ngIf="file.customType == 'xlsx' || file.customType == 'xls'"
                                                class="fa fa-file-excel-o" aria-hidden="true"></i>
                                            {{file.name}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pl-12" *ngIf="viewFormDisabled">
                        <div class="col-lg-7 col-md-7 col-sm-7 ">
                            <div class="assign mbottom" *ngIf="viewFormDisabled && checklist">
                                <p-scrollPanel [style]="{ height: '70px',width:'76%'}" styleClass="mystyle">
                                    <form [formGroup]="invoiceForms">
                                        <div formArrayName="checklist">
                                            <p> <img src="../../../../assets/img/Group8434.png" alt="checklist"
                                                    style="width: 15px;margin-left: 4px;"> {{'viewChecklist' |
                                                translate}}</p>
                                            <div *ngFor="let itemrow of invoiceForms.controls.checklist.controls; let i=index"
                                                [formGroupName]="i">
                                                <div style="display: inline-block;height: 9px;">
                                                    <div class="form-group"
                                                        style="width: 10px;display: inline-block;margin-bottom: 5px;margin-right: 5px;margin-left: 2px;vertical-align: sub;">
                                                        <input [disabled]="viewFormDisabled==true"
                                                            [attr.readonly]="readonlyAtt" formControlName="checked"
                                                            class="form-control" type="checkbox"
                                                            style=" display: inline-block; width: 20px; height: 16px;"
                                                            disabled>
                                                    </div>
                                                    <div class="form-group"
                                                        style="width: 200px;display: inline-block;margin-bottom: 5px;margin-right: 9px;">
                                                        <input [attr.readonly]="readonlyAtt" formControlName="criteria"
                                                            class="form-control inputclass"
                                                            style="height: 18px;margin-bottom: 5px !important;margin-left: 5px;margin-bottom: 7px;margin-top: 5px;display: inline-block;width: 337px;border-width: 0 0 0px;border-color: #80808061;">
                                                    </div>
                                                </div>
                                                <img (click)="deleteRowss(i)" src="../../../../assets/img/close.png"
                                                    alt="delete"
                                                    *ngIf="invoiceForms.controls.checklist.controls.length > 0 &&  !viewFormDisabled"
                                                    alt="" style="display: inline-block;width: 15px;cursor: pointer;">
                                            </div>
                                        </div>
                                    </form>
                                </p-scrollPanel>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 ">
                            <button type="button" class="btn btn-default submitBtnView"
                                (click)="editFormOpen()">{{'editBTN' | translate}}</button>
                            <button type="button" class="btn btn-default submitBtns" (click)="CancelViewForm()"
                                style="position: absolute;line-height: 1;right: 95px; bottom:  0%;">{{'cancelBTN' |
                                translate}}</button>
                            <button type="button" class="btn btn-default submitBtns copybtn" (click)="openCopyTask()"
                                style="position: absolute;right: 184px; padding: 5px; bottom:   0%;"
                                *ngIf="lang!='en'"><i class="pi pi-copy copyico"></i>
                                <div class="copytxt">{{'copyBTN' | translate}}</div>
                            </button>
                            <button type="button" class="btn btn-default submitBtns copybtn" (click)="openCopyTask()"
                                style="position: absolute;right: 184px; padding: 5px; bottom:   0%;"
                                *ngIf="lang=='en'"><i class="pi pi-copy copyico"
                                    style="margin-left: -39px !important;"></i>
                                <div class="copytxt">{{'copyBTN' | translate}}</div>
                            </button>
                        </div>
                    </div>

                    <!-- Checklist for View -->
                    <div class="row pl-12" *ngIf="!viewFormDisabled">
                        <div class="col-lg-7 col-md-7 col-sm-7 ">
                            <div class="assign mbottom">
                                <p *ngIf="!viewFormDisabled"> <img src="../../../../assets/img/Group8434.png"
                                        alt="checklist" style="width: 15px;margin-left: 2px;cursor: pointer;"
                                        (click)="addNewRows()">{{'addchecklist' | translate}}</p>
                                <p-scrollPanel [style]="{ height: '51px', 'width':'76%'}" styleClass="mystyle">
                                    <form [formGroup]="invoiceForms">
                                        <div formArrayName="checklist">
                                            <div *ngFor="let itemrow of invoiceForms.controls.checklist.controls; let i=index"
                                                [formGroupName]="i">
                                                <div style="display: inline-block;height: 9px;">
                                                    <div class="form-group"
                                                        style="width: 10px;display: inline-block;margin-bottom: 5px;margin-right: 5px;vertical-align: sub;">
                                                        <input formControlName="checked" class="form-control"
                                                            type="checkbox" id="checlistEdit"
                                                            style=" display: inline-block; width: 20px; height: 16px;cursor: pointer;">
                                                    </div>
                                                    <div class="form-group"
                                                        style="width: 300px;display: inline-block;margin-bottom: 5px;margin-right: 15px;">
                                                        <input formControlName="criteria"
                                                            class="form-control inputclass" id="criteriaEdit"
                                                            style="height: 18px;margin-bottom: 5px !important;margin-left: 9px;margin-bottom: 7px;margin-top: 5px;display: inline-block;">
                                                    </div>
                                                </div>
                                                <img (click)="deleteRowss(i)" src="../../../../assets/img/close.png"
                                                    alt="close"
                                                    *ngIf="invoiceForms.controls.checklist.controls.length > 0 &&  !viewFormDisabled"
                                                    alt="" style="display: inline-block;width: 15px;cursor: pointer;">
                                            </div>
                                        </div>
                                    </form>
                                </p-scrollPanel>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5 col-sm-5 updatebtnscss">
                            <input type="submit" class="btn btn-default submitBtnDash updateBtnUpdateTask"
                                value="{{'updateBTN' | translate}}"
                                [disabled]="!viewForm.valid || isStatusSelected==true || isValidDesc==true || isValidDesc1==true || !isValidated || !isValidatedManField || !isValidatedNonManField"
                                style="margin-right: 15px;position: absolute;right:48px;cursor: pointer;bottom:5px;width: 76px;"
                                *ngIf="lang=='fr'">
                            <input type="submit" class="btn btn-default submitBtnDash updateBtnUpdateTask"
                                value="{{'updateBTN' | translate}}"
                                [disabled]="!viewForm.valid || isStatusSelected==true || isValidDesc==true || isValidDesc1==true  || !isValidated || !isValidatedManField || !isValidatedNonManField"
                                style="margin-right: 15px;position: absolute;right:48px;cursor: pointer;bottom:5px;width: 76px;"
                                *ngIf="lang!='fr'">
                            <button type="button" class="btn btn-default submitBtns" (click)="CancelViewForm()"
                                style="position: absolute;right: 95px;bottom:5px;" *ngIf="lang!='en'">{{'cancelBTN' |
                                translate}}</button>
                            <button type="button" class="btn btn-default submitBtns" (click)="CancelViewForm()"
                                style="position: absolute;right: 95px;bottom:5px;" *ngIf="lang=='en'">{{'cancelBTN' |
                                translate}}</button>
                            <button type="button" class="btn btn-default submitBtns copybtn" (click)="openCopyTask()"
                                style="position: absolute;right: 182px;bottom:5px;" *ngIf="lang!='en'"><i
                                    class="pi pi-copy copyico"></i>
                                <div class="copytxt">{{'copyBTN' | translate}}</div>
                            </button>
                            <button type="button" class="btn btn-default submitBtns copybtn" (click)="openCopyTask()"
                                style="position: absolute;right: 182px;bottom:5px;" *ngIf="lang=='en'"><i
                                    class="pi pi-copy copyico"></i>
                                <div class="copytxt">{{'copyBTN' | translate}}</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- </div> -->

            <div style=" width:585px !important;padding-left: 10px;box-shadow: -20px 0px 60px -66px #000000;"
                class="viewCommentDetails" *ngIf="displayComment">
                <!-- 
        <p-dialog header="{{'comments' | translate}}" (onHide)="cancelComment()" [(visible)]="displayComment" styleClass="displayCommnetData" [dismissableMask]="true"
        [position]="position" [modal]="true" [style]="{zoom: '89%',width: '490px', height: '701px'}" [draggable]="false" > -->
                <div class="commentRow">
                    <div class="reportHeader" style="margin: 0px 0 20px 0;
            background: #ff5100;
            border-radius: 2px;
            padding: 5px 8px 7px 10px;"><span class="dashTitle" style="    color: #fff;
    font-size: 16px;">Comments</span></div>
                    <div class="row" style="width: 100%;"
                        *ngFor="let comment of taskDataForEdit?.comments;let i=index ">
                        <div class="col-lg-3 col-md-3 col-sm-3 b-bottom" style="padding-right: 0px; ">
                            <p style="font-size: 13px;">{{comment.commentedBy}}</p>
                            <p style="font-size: 10px;display: inline-block;" class="date">
                                {{date(comment.commentedDate) | date:'MM/dd/yyyy'}}-</p>
                            <p style="font-size: 10px;display: inline-block;" class="date">
                                {{time(comment.commentedDate)}}</p>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 NoPadding b-bottomsr" style="font-size:10px" [id]="i"
                            [innerHTML]="comment.comment"></div>
                    </div>
                </div>
                <div *ngIf="comment" class="col-lg-5 col-md-5 col-sm-5">
                    <div class="commentHeading row pl-4">
                        <span class="title">{{'addComments' | translate}}</span>
                        <span><i class="fa fa-times commentCross" aria-hidden="true"
                                (click)="comment = false"></i></span>
                    </div>

                    <div class="row pl-4" *ngFor="let comnt of commnetArray">
                        <div class="col-lg-3 col-md-3 col-sm-3 NoPadding b-bottom">
                            <p style="font-size: 10px;">{{comnt.commentedBy}}</p>
                            <p style="font-size: 10px;" class="date">{{date(comnt.commentedDate)}}</p>
                            <p style="font-size: 10px;" class="date">{{time(comnt.commentedDate)}}</p>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-9 NoPadding b-bottomsr">
                            <p style="font-size: 10px !important;">{{comnt.comment}}</p>
                        </div>
                    </div>
                </div>

                <div class="row pl-4"
                    style="margin-right: 8px;margin-bottom: 10px;margin-top: 30px;padding-left:15px!important ;">
                    <p-editor [(ngModel)]="addedComment" class="editorTaskCommnet"
                        [style]="{'height':'175px', 'padding-bottom':'5px','width': '100%'}"
                        (onTextChange)="onChangeComment($event)" styleClass="editorCreateTaskCommnet"></p-editor>
                    <div class="invalid-feedback" style="font-size: 12px !important;"
                        *ngIf="isValidComment==true || isValidComment1==true">{{'validComment' | translate}}</div>
                </div>
                <div class="invalid-feedback row pl-4" *ngIf="isStatusSelected"
                    style="width:465px;font-size: 12px !important;">{{'commentIsMandatoryForSelectedStatus' |
                    translate}} {{showSelectedStatus}} {{'status' | translate}}.</div>
                <div class="saveCommentDiv">
                    <input type="button" class="btn btn-default submitBtnComment createBtnCommnet"
                        value="{{'addCommentBTN' | translate}}" (click)="saveComment()"
                        [disabled]="!addedComment || isValidComment==true || isValidComment1==true">
                    <!-- <input type="button" class="btn btn-default submitBtnDash createBtnCommnet" value="{{'closeBTN' | translate}}" (click)="cancelComment()"> -->
                </div>
                <!-- </p-dialog>
    
    <p-dialog header="{{'history' | translate}}" (onHide)="cancelHistory()" [(visible)]="displayHistory" styleClass="displayHistoryData" [dismissableMask]="true"
        [position]="positionHistory" [modal]="true" [style]="{zoom: '89%',width: '490px', height: '701px'}"
        [draggable]="false">
        <div class="col-lg-12 col-md-12 col-sm-12" style="padding-left: 0px;padding-right: 0px;width: 486px;height: 625px;overflow: scroll;">
            <table class="table TFtable" style="height: auto;overflow: scroll;">
                <tbody>
                    <th><b>{{'changeDate' | translate}}</b></th>
                    <th><b>{{'changeBy' | translate}}</b></th>
                    <th><b>{{'comments' | translate}}</b></th>
                    <th><b>{{'details' | translate}}</b></th>
                    <tr *ngFor="let history of HistoryData;">
                        <td>{{history.createdDate | date: 'MM/dd/yyyy HH:mm:ss'}}</td>
                        <td style="text-align:left !important;padding-left:4px !important">{{history.createdBy}}</td>
                        <td style="text-align:left !important;padding-left:4px !important">{{history.actionTaken}}</td>
                        <td style="text-align:left !important;padding-left:4px !important">{{history.newValue}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </p-dialog> -->
            </div>
            <div style=" width:39% !important;padding-left: 10px;box-shadow: -20px 0px 60px -66px #000000;"
                class="viewHistoryDetails" *ngIf="displayHistory">
                <div class="col-lg-12 col-md-12 col-sm-12"
                    style="padding-left: 0px;padding-right: 0px;width: 600px;height: 585px;overflow: auto;">
                    <div class="reportHeader" style="margin: 0px 0 20px 0;
            background: #304254 !important;
            border-radius: 2px;
            padding: 5px 8px 7px 10px;"><span class="dashTitle" style="    color: #fff;
    font-size: 16px;">History</span></div>
                    <table class="table TFtable" style="height: auto;overflow: scroll;">
                        <tbody>
                            <th><b>{{'changeDate' | translate}}</b></th>
                            <th><b>{{'changeBy' | translate}}</b></th>
                            <th><b>{{'comments' | translate}}</b></th>
                            <th><b>{{'details' | translate}}</b></th>
                            <tr *ngFor="let history of HistoryData;">
                                <td>{{history.createdDate | date: 'MM/dd/yyyy HH:mm:ss'}}</td>
                                <td style="text-align:left !important;padding-left:4px !important">{{history.createdBy}}
                                </td>
                                <td style="text-align:left !important;padding-left:4px !important">
                                    {{history.actionTaken}}</td>
                                <td style="text-align:left !important;padding-left:4px !important">{{history.newValue}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </p-scrollPanel>
</p-dialog>

<p-dialog class="imageDescriptionTitlebar" header="{{'imageDescription' | translate}}" (onHide)="cancel()"
    [(visible)]="displayFPImage" [modal]="true" [style]="{width: '800px', height: '700px'}" [draggable]="false">
    <img [src]="currVerifiedLoanOfficerPhoto" style="width: 100%;height: 400px;" alt="img">
</p-dialog>

<p-dialog header="{{'taskRecurrence' | translate}}" (onHide)="canceltaskRecurrence()"
    [(visible)]="displaytaskrecurrence" [modal]="true" styleClass="overlayDialogrecurrence"
    [style]="{width: '545px', height: '388px'}" [draggable]="false" [baseZIndex]="999999">
    <form #recForm="ngForm">
        <p class="titleRe">{{'rangeOfRecurrence' | translate}}</p>
        <div class="row m-0 mt-1">
            <div class="form-inline col-4 p-0" style="margin-top: -51px;">
                <label class="subtitle" style="margin-left: 13px !important;" for="start">{{'start' |
                    translate}}</label>
                <p-calendar class="recCalendar" id="start" name="start" [(ngModel)]="recStartDate" [required]="true"
                    [minDate]="todayDate" [locale]="calendarValue"></p-calendar>
            </div>
            <div class="col-8">
                <div>
                    <p-radioButton class="recRadio" [(ngModel)]="endRadio" [ngModelOptions]="{standalone: true}"
                        name="group" value="endby" label="{{'endBy' | translate}}" inputId="opt21"
                        (onClick)="changeRangeRecRadio('endby')"></p-radioButton>
                    <p-calendar id="end" name="end" class="recCalendar recCalendar1" [(ngModel)]="recEndDate"
                        [locale]="calendarValue" [required]="endRadio=='endby'" [disabled]="endRadio!='endby'"
                        [minDate]="recStartDate"></p-calendar>
                </div>
                <div class="mt-1">
                    <p-radioButton class="recRadio" [(ngModel)]="endRadio" [ngModelOptions]="{standalone: true}"
                        name="group" value="endafter" label="{{'endAfter' | translate}}" inputId="opt22"
                        (onClick)="changeRangeRecRadio('endafter')"></p-radioButton>
                    <input class="inputNumber" id="recno" name="recno" type="number" min="1" [(ngModel)]="noOfRec"
                        [disabled]="endRadio!='endafter'" [required]="endRadio=='endafter'"
                        style="margin-left: 38px !important;" *ngIf="lang!='es'">
                    <input class="inputNumber" id="recno" name="recno" type="number" min="1" [(ngModel)]="noOfRec"
                        [disabled]="endRadio!='endafter'" [required]="endRadio=='endafter'"
                        style="margin-left: 28px !important;" *ngIf="lang=='es'">
                    <label class="textLabel ml-2">{{'occurrences' | translate}}</label>
                </div>
                <div style="margin-top:-4px"><p-radioButton class="recRadio" [(ngModel)]="endRadio" name="group"
                        value="noenddate" label="{{'noEndDate' | translate}}" inputId="opt23"
                        (onClick)="changeRangeRecRadio('noenddate')"></p-radioButton></div>
            </div>
        </div>
        <hr>
        <p class="titleRe">{{'recurrencePattern' | translate}}</p>
        <div class="row m-0 mt-2">
            <div class="col-3 pr-0">
                <div><p-radioButton class="recRadio" [(ngModel)]="patternRadio" name="group1" value="Daily"
                        label="{{'daily' | translate}}" inputId="opt1"
                        (onClick)="changePatternRadio('Daily')"></p-radioButton></div>
                <div><p-radioButton class="recRadio" [(ngModel)]="patternRadio" name="group1" value="Weekly"
                        label="{{'weekly' | translate}}" inputId="opt2"
                        (onClick)="changePatternRadio('Weekly')"></p-radioButton></div>
                <div><p-radioButton class="recRadio" [(ngModel)]="patternRadio" name="group1" value="Monthly"
                        label="{{'monthly' | translate}}" inputId="opt3"
                        (onClick)="changePatternRadio('Monthly')"></p-radioButton></div>
                <div><p-radioButton class="recRadio" [(ngModel)]="patternRadio" name="group1" value="Yearly"
                        label="{{'yearly' | translate}}" inputId="opt4"
                        (onClick)="changePatternRadio('Yearly')"></p-radioButton></div>
            </div>
            <div class="col-9 pl-0">
                <div class="patternDiv">
                    <!-- Daily -->
                    <div class="ml-3 mt-2" *ngIf="patternRadio=='Daily'">
                        <div><p-radioButton [(ngModel)]="dailyRadio" class="recRadio" name="group2" value="everyday"
                                label="{{'every' | translate}}" inputId="opt11"
                                (onClick)="changeDailyRadios('everyday')"></p-radioButton><input
                                class="inputNumber ml-2" id="dailyno" name="dailyno" type="number" min="1"
                                [(ngModel)]="dailyNumber" [disabled]="dailyRadio=='everyweekday' || dailyRadio==null"
                                [required]="dailyRadio=='everyday'"><label class="ml-2 textLabel">{{'days' |
                                translate}}</label></div>
                        <div><p-radioButton [(ngModel)]="dailyRadio" class="recRadio" name="group2" value="everyweekday"
                                label="{{'everyWeekday' | translate}}" inputId="opt12"
                                (onClick)="changeDailyRadios('everyweekday')"></p-radioButton></div>
                    </div>
                    <!-- weekly -->
                    <div class="ml-1 mt-2" *ngIf="patternRadio=='Weekly'">
                        <div class="row m-0">
                            <label class="textLabel ml-2">{{'recur' | translate}}</label>
                            <input class="inputNumber ml-1" id="weekNo" name="weekNo" type="number" min="1"
                                [(ngModel)]="weekno" required>
                            <label class="textLabel ml-1">{{'week' | translate}} {{'on' |
                                translate}}:</label><!-- week(s) on -->
                        </div>
                        <div class="row" style="margin-top: -24px;margin-left: 200px;" *ngIf="lang!='fr'">
                            <div class="col p-0">
                                <div><p-checkbox class="recCheckbox" name="group3" value="Monday"
                                        label="{{'monday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="mo"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Tuesday"
                                        label="{{'tuesday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="tu"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Wednesday"
                                        label="{{'wednesday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="wed"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Thursday"
                                        label="{{'thursday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="thu"></p-checkbox></div>
                            </div>
                            <div class="col p-0">
                                <div><p-checkbox class="recCheckbox" name="group3" value="Friday"
                                        label="{{'friday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="fri"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Saturday"
                                        label="{{'saturday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="sat"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Sunday"
                                        label="{{'sunday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="sun"></p-checkbox></div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: -24px;margin-left: 233px;" *ngIf="lang=='fr'">
                            <div class="col p-0">
                                <div><p-checkbox class="recCheckbox" name="group3" value="Monday"
                                        label="{{'monday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="mo"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Tuesday"
                                        label="{{'tuesday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="tu"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Wednesday"
                                        label="{{'wednesday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="wed"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Thursday"
                                        label="{{'thursday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="thu"></p-checkbox></div>
                            </div>
                            <div class="col p-0">
                                <div><p-checkbox class="recCheckbox" name="group3" value="Friday"
                                        label="{{'friday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="fri"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Saturday"
                                        label="{{'saturday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="sat"></p-checkbox></div>
                                <div><p-checkbox class="recCheckbox" name="group3" value="Sunday"
                                        label="{{'sunday' | translate}}" [(ngModel)]="selectedWeekday"
                                        inputId="sun"></p-checkbox></div>
                            </div>
                        </div>
                    </div>
                    <!-- Monthly -->
                    <div class="ml-3 mt-2" *ngIf="patternRadio=='Monthly'">
                        <div>
                            <p-radioButton [(ngModel)]="monthlyRadio" class="recRadio" name="group4"
                                value="dayofeverymonth" label="{{'day' | translate}}" inputId="opt31"
                                (onClick)="onChangeMonthlyRadio('dayofeverymonth')"></p-radioButton>
                            <input class="inputNumber ml-1" id="monthno" name="monthno" type="number" min="1"
                                [(ngModel)]="monthNumber"
                                [disabled]="monthlyRadio=='thofeverymonth' || monthlyRadio==null"
                                [required]="monthlyRadio!='thofeverymonth'">
                            <label class="textLabel ml-1">{{'ofEvery' | translate}}</label>
                            <input class="inputNumber ml-1" id="monthno1" name="monthno1" type="number" min="1"
                                [(ngModel)]="monthNumber1"
                                [disabled]="monthlyRadio=='thofeverymonth' || monthlyRadio==null"
                                [required]="monthlyRadio!='thofeverymonth'">
                            <label class="textLabel ml-1">{{'months' | translate}}</label>
                        </div>
                        <div>
                            <p-radioButton [(ngModel)]="monthlyRadio" class="recRadio" name="group4"
                                value="thofeverymonth" label="{{'the' | translate}}" inputId="opt32"
                                (onClick)="onChangeMonthlyRadio('thofeverymonth')"></p-radioButton>
                            <p-dropdown class="ml-1 monthdropdown" #monthDD id="monthdropdown" name="monthdropdown"
                                placeholder="{{'select' | translate}}" [options]="monthFirstddData"
                                [(ngModel)]="monthdd" optionLabel="name"
                                [disabled]="monthlyRadio!='thofeverymonth' || monthlyRadio==null"
                                [required]="monthlyRadio=='thofeverymonth'"></p-dropdown>
                            <p-dropdown class="ml-1 monthdropdown" #monthDD1 id="monthdropdown1" name="monthdropdown1"
                                placeholder="{{'select' | translate}}" [options]="monthSecondddData"
                                [(ngModel)]="monthdd1" optionLabel="name"
                                [disabled]="monthlyRadio!='thofeverymonth' || monthlyRadio==null"
                                [required]="monthlyRadio=='thofeverymonth'"></p-dropdown>
                            <label class="textLabel ml-1">{{'ofEvery' | translate}}</label>
                            <input class="inputNumber ml-1" id="monthno2" name="monthno2" type="number" min="1"
                                [(ngModel)]="monthNumber11"
                                [disabled]="monthlyRadio!='thofeverymonth' || monthlyRadio==null"
                                [required]="monthlyRadio=='thofeverymonth'">
                            <label class="textLabel ml-1">{{'months' | translate}}</label><!-- months(s) -->
                        </div>
                    </div>
                    <!-- Yearly -->
                    <div class="mt-1" *ngIf="patternRadio=='Yearly'">
                        <div class="row ml-2 m-0">
                            <label class="textLabel ml-2">{{'recurEvery' | translate}}</label>
                            <input class="inputNumber ml-1" id="weekNo" name="weekNo" type="number" min="1"
                                [(ngModel)]="yearno" required>
                            <label class="textLabel ml-1">{{'years' | translate}}</label><!-- years(s) -->
                        </div>
                        <div class="ml-3">
                            <p-radioButton [(ngModel)]="yearlyRadio" class="recRadio" name="group5" value="on"
                                label="{{'on' | translate}}" inputId="opt41"
                                (onClick)="changeYearlyRadio('on')"></p-radioButton>
                            <p-dropdown class="ml-1 monthdropdown" #yearDD id="yeardd" name="yeardd"
                                placeholder="{{'select' | translate}}" [options]="yearlyddData" [(ngModel)]="yearDd1"
                                optionLabel="name" [disabled]="yearlyRadio=='onthe' || yearlyRadio==null"
                                [required]="yearlyRadio=='on'"></p-dropdown>
                            <input class="inputNumber ml-1" id="yearno1" name="yearno1" type="number" min="1"
                                [(ngModel)]="yearNumber1" [disabled]="yearlyRadio=='onthe' || yearlyRadio==null"
                                [required]="yearlyRadio!='onthe'">
                        </div>
                        <div class="ml-3 mt-1">
                            <p-radioButton [(ngModel)]="yearlyRadio" class="recRadio" name="group5" value="onthe"
                                label="{{'onThe' | translate}}" inputId="opt42"
                                (onClick)="changeYearlyRadio('onthe')"></p-radioButton>
                            <p-dropdown class="ml-1 monthdropdown" #monthDD id="yeardd1" name="yeardd1"
                                placeholder="{{'select' | translate}}" [options]="monthFirstddData"
                                [(ngModel)]="yearDd2" optionLabel="name"
                                [disabled]="yearlyRadio=='on' || yearlyRadio==null"
                                [required]="yearlyRadio=='onthe'"></p-dropdown>
                            <p-dropdown class="ml-1 monthdropdown" #monthDD1 id="yeardd2" name="yeardd2"
                                placeholder="{{'select' | translate}}" [options]="monthSecondddData"
                                [(ngModel)]="yearDd3" optionLabel="name"
                                [disabled]="yearlyRadio=='on' || yearlyRadio==null"
                                [required]="yearlyRadio=='onthe'"></p-dropdown>
                            <label class="textLabel ml-1">{{'of' | translate}}</label>
                            <p-dropdown class="ml-1 monthdropdown" #yearDD id="yeardd3" name="yeardd3"
                                placeholder="{{'select' | translate}}" [options]="yearlyddData" [(ngModel)]="yearDd4"
                                optionLabel="name" [disabled]="yearlyRadio=='on' || yearlyRadio==null"
                                [required]="yearlyRadio=='onthe'"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end m-0 mt-3">
            <button class="btn btn-sm mr-2 recBtn" (click)="createRecurrence()" [disabled]="recForm.invalid">{{'okBTN' |
                translate}}</button>
            <button class="btn btn-sm mr-2 recBtn" (click)="canceltaskRecurrence()">{{'cancelSmallCaps' |
                translate}}</button>
            <button class="btn btn-sm mr-4 recBtn" (click)="removeRecurrence()"
                [disabled]="displayFeedback==true">{{'removeRecurrence' | translate}}</button>
        </div>
    </form>
</p-dialog>

<p-dialog header="{{'addWatchlist' | translate}}" [(visible)]="displayWatchlist" [modal]="true"
    styleClass="overlayDialogWatchlist" [style]="{width: '400px', height: '218px'}" [draggable]="false">
    <form [formGroup]="watchlistForm" (ngSubmit)="addWatchlist(watchlistForm.value)">
        <div style="margin-left: 0px;display: inline-block;margin-top: 20px">
            <div class="ui-g-12 ui-md-12" style="display: inline-block;margin-bottom: 20px;">
                <p class="commonhead assignLabels widthTitle" style="display: inline-block;">{{'estimate' | translate}}
                </p>
                <div class="ui-inputgroup" style="display: inline-block;">
                    <input type="text" pInputText placeholder="{{'addWatchlistName' | translate}}"
                        style="height: 28px;width: 206px;" formControlName="watchlistName" id="watchListNameAdd">
                </div>
            </div>
            <div style="margin-top:25px; margin-left: 7px;">
                <p class="commonhead assignLabels widthTitle" style="display: inline-block;">{{'default' | translate}}
                </p>
                <p-checkbox formControlName="default" name="privateTask" binary="true" class="watchCheckboc"
                    class="watchlistClass" styleClass="privateSpanOccurenceb"></p-checkbox>
            </div>
        </div>
        <div style=" margin-left: 172px;">
            <button type="button" class="btn btn-default submitBtns" (click)="closeWatchlist()" style="position: absolute;
                right: 162px;bottom:  11px;">{{'cancelBTN' | translate}}</button>

            <input type="submit" class="btn btn-default submitBtnDash " id="btnCreateBtn"
                value="{{'createBTN' | translate}}" [disabled]="!watchlistForm.valid"
                style="margin-right: 15px;position: absolute;right:48px;cursor: pointer;bottom:  11px;">
        </div>
    </form>
</p-dialog>

<!-- <p-dialog header="{{'comments' | translate}}" (onHide)="cancelComment()" [(visible)]="displayComment" styleClass="displayCommnetData" [dismissableMask]="true"
    [position]="position" [modal]="true" [style]="{zoom: '89%',width: '490px', height: '701px'}" [draggable]="false" >
    <div class="commentRow">
        <div class="row" style="width: 100%;" *ngFor="let comment of taskDataForEdit?.comments;let i=index ">
            <div class="col-lg-3 col-md-3 col-sm-3 b-bottom" style="padding-right: 0px; ">
                <p style="font-size: 13px;">{{comment.commentedBy}}</p>
                <p style="font-size: 10px;display: inline-block;" class="date">
                    {{date(comment.commentedDate) | date:'MM/dd/yyyy'}}-</p>
                <p style="font-size: 10px;display: inline-block;" class="date">{{time(comment.commentedDate)}}</p>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 NoPadding b-bottomsr" [id]="i" [innerHTML]="comment.comment" ></div> 
        </div>
    </div>
    <div *ngIf="comment" class="col-lg-5 col-md-5 col-sm-5">
        <div class="commentHeading row pl-4">
            <span class="title">{{'addComments' | translate}}</span>
            <span><i class="fa fa-times commentCross" aria-hidden="true" (click)="comment = false"></i></span>
        </div>

        <div class="row pl-4" *ngFor="let comnt of commnetArray">
            <div class="col-lg-3 col-md-3 col-sm-3 NoPadding b-bottom">
                <p style="font-size: 10px;">{{comnt.commentedBy}}</p>
                <p style="font-size: 10px;" class="date">{{date(comnt.commentedDate)}}</p>
                <p style="font-size: 10px;" class="date">{{time(comnt.commentedDate)}}</p>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 NoPadding b-bottomsr">
                <p>{{comnt.comment}}</p>
            </div>
        </div>
    </div>

    <div class="row pl-4" style="margin-right: 8px;margin-bottom: 10px;margin-top: 70px;">
        <p-editor [(ngModel)]="addedComment" [style]="{'height':'160px', 'padding-bottom':'5px','width': '450px'}" (onTextChange)="onChangeComment($event)"
            styleClass="editorCreateTaskCommnet"></p-editor>
        <div class="invalid-feedback" style="font-size: 12px !important;" *ngIf="isValidComment==true || isValidComment1==true">{{'validComment' | translate}}</div>
    </div>
    <div class="invalid-feedback row pl-4" *ngIf="isStatusSelected" style="width:465px;font-size: 12px !important;">{{'commentIsMandatoryForSelectedStatus' | translate}} {{showSelectedStatus}} {{'status' | translate}}.</div>
    <div class="saveCommentDiv">
        <input type="button" class="btn btn-default submitBtnComment createBtnCommnet" value="{{'addCommentBTN' | translate}}"
            (click)="saveComment()" [disabled]="!addedComment || isValidComment==true || isValidComment1==true">
        <input type="button" class="btn btn-default submitBtnDash createBtnCommnet" value="{{'closeBTN' | translate}}" (click)="cancelComment()">
    </div>
</p-dialog>

<p-dialog header="{{'history' | translate}}" (onHide)="cancelHistory()" [(visible)]="displayHistory" styleClass="displayHistoryData" [dismissableMask]="true"
    [position]="positionHistory" [modal]="true" [style]="{zoom: '89%',width: '490px', height: '701px'}"
    [draggable]="false">
    <div class="col-lg-12 col-md-12 col-sm-12" style="padding-left: 0px;padding-right: 0px;width: 486px;height: 625px;overflow: scroll;">
        <table class="table TFtable" style="height: auto;overflow: scroll;">
            <tbody>
                <th><b>{{'changeDate' | translate}}</b></th>
                <th><b>{{'changeBy' | translate}}</b></th>
                <th><b>{{'comments' | translate}}</b></th>
                <th><b>{{'details' | translate}}</b></th>
                <tr *ngFor="let history of HistoryData;">
                    <td>{{history.createdDate | date: 'MM/dd/yyyy HH:mm:ss'}}</td>
                    <td style="text-align:left !important;padding-left:4px !important">{{history.createdBy}}</td>
                    <td style="text-align:left !important;padding-left:4px !important">{{history.actionTaken}}</td>
                    <td style="text-align:left !important;padding-left:4px !important">{{history.newValue}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</p-dialog> -->

<p-confirmDialog [style]="{width: '23vw'}" [baseZIndex]="999999999"></p-confirmDialog>