<p-toast></p-toast>
<div class="default-theme">
    <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid p-0">
            <!-- Brand -->
            <div id="ctl_straviso_logo_div" class="brand_name_div tooltip_custom">
                <a class="navbar-brand">
                    <img src="../../../../assets/img/myTaskEasy_logo_updated.png" alt="Logo" class="head-logo" 
                        style="cursor: pointer;">
                </a>
            </div>
         
        </div>
    </nav>
</div>
<div class="container m-t-100"  *ngIf="validToken==true">
    <div class="icon text-center">
        <span class="fa fa-lock fa-4x"></span>
    </div>
    <div class="sec-text text-center mt-2 mb-2">
        <h5>Reset Password</h5>
    </div>
    <div class="form-wrap">
        <form [formGroup]="resetPassForm" (ngSubmit)="onSubmit()" autocomplete="false">
            <div class="form-group">
                <label for="email">Email Id</label>
                <input type="email" class="form-control" formControlName="email" id="email" readonly>
            </div>
            <div class="form-group">
                <label for="newpass">New Password</label>
                <input type="password" class="form-control" id="newpass" formControlName="newpassword" autocomplete="off" list="autocompleteOff">
                <small class="form-text hint-text">
                    Password should be at least 8 to 32 characters long with combination of one captial letter,one digit and one symbol eg.A90$asdb
                </small>
                <div *ngIf="submitted && f.newpassword.errors">
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.required">
                        New Password is required.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.minlength ">
                        Password should be at least 8 to 32 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.maxlength ">
                        Password should be at least 8 to 32 characters.
                    </small>
                    <small class="form-text text-danger" *ngIf="f.newpassword.errors.pattern ">
                        Password should be combination of at least one uppercase, one lower case, one digit and one special character.
                    </small>
                </div>
            </div>
            <div class="form-group">
                <label for="cpass">Confirm Password</label>
                <input id="cpass" type="password" class="form-control" formControlName="confirmpassword" autocomplete="off" list="autocompleteOff">
                <div *ngIf="submitted && cPassError">
                    <small class="form-text text-danger">Confirm Password should match with New Password.</small>
                </div>
            </div>
            <button class="btn btn-primary mr-2" type="submit" aria-label="reset">Reset Password</button>
            <button class="btn btn-secondary" (click)="goBack()" aria-label="cancel">Cancel</button>

        </form>
    </div>
</div>
<footer class="footer">
    <span>{{year}} Copyright@StraViso</span>
</footer>

<!-- <div class="container m-t-100" *ngIf="validToken==false" style="text-align:center;">
<img src="../../assets/img/warning.png" width="80px"> 
<h1 style="text-align: center;margin-top: 15px;">Varification link is expired or invalid.</h1>
</div> -->