import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { MessageService } from 'primeng/api';
// import { LoginService } from '../services/base.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Compiler } from '@angular/core';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
  providers: [
    MessageService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ]
})
export class VerifyComponent implements OnInit {
  verifyForm: FormGroup;
  mail: any = '';
  invitedEmail = '';
  invitedMode = '';
  customerName='';
  UISentmailFlag = false;
  alreadyRegistred = false;

  constructor(
    public ActivatedRoute: ActivatedRoute,
    private LoginService: LoginService,
    private router: Router,
    public messageService: MessageService,
    // private LoginService: LoginService,
    public loader: NgxUiLoaderService,
    private location: Location,
    private _compiler: Compiler
  ) { }

  ngOnInit() {
    localStorage.clear();
    this._compiler.clearCache();
    // this.loader.start();
    this.verifyForm = new FormGroup({
      otp: new FormControl('', Validators.required)
    });

    this.ActivatedRoute.queryParams.subscribe(params => {
      if (params.token) {
        localStorage.setItem("isInvitedUser","false");
        // Code for bussiness user
        localStorage.setItem('auth_token', null);
        const temp = params.token.split('=>');
        console.log("params temp===",temp);
        this.mail=temp[temp.length - 1];
        localStorage.setItem('mail', this.mail);
        localStorage.setItem('userData', JSON.stringify({ accessToken: params.authtoken }));
        //this.mail = localStorage.getItem('mail');
        this.LoginService.OTPRequest(params['token'])
          .subscribe(res => {
            this.loader.stop();
            if (res.status.statusMsg === 'Success' ) {
              this.messageService.add({ severity: 'success', summary: 'OTP Successfully sent to ' + this.mail});
              // this.router.navigateByUrl("/verify");
            } else {
              this.messageService.add({ severity: 'error', summary: res.status.statusMsg });
            }
          },
            error => {
              this.loader.stop();
              this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg });
            });
      } else {
        // code for invited user
        // localStorage.setItem('auth_token', params.auth_token);
        localStorage.setItem('auth_token', params['authtoken']);
        this.invitedEmail = params['email'];
        this.invitedMode = params['mode'];
        localStorage.setItem("isInvitedUser","true");
        this.mail = params['email'];
        this.customerName = params['customerName'];
        this.callToOTPUI();
      }
      this.location.replaceState('#/verify');

    });

    // if (this.ActivatedRoute.snapshot.queryParams && this.ActivatedRoute.snapshot.queryParams['token']) {
    //   localStorage.setItem('auth_token', this.ActivatedRoute.snapshot.queryParams['token']);
    //   let temp = this.ActivatedRoute.snapshot.queryParams['token'].split("_");
    //   localStorage.setItem('mail', temp[temp.length - 1]);
    //   localStorage.setItem("userData", JSON.stringify({ 'accessToken': this.ActivatedRoute.snapshot.queryParams['authtoken'] }))
    // }

    // if (!localStorage.getItem('auth_token')) {
    //   this.mail = localStorage.getItem("mail");
    //   this.signupService.OTPRequest(localStorage.getItem('auth_token'))
    //     .subscribe(res => {
    //       this.loader.stop();
    //       if (res.status['statusCode'] == 2000) {
    //         this.messageService.add({ severity: 'success', summary: 'OTP Successfully send to Your ' + this.mail + ' Mail ID' });
    //         this.router.navigateByUrl("/verify");
    //       }
    //       else {
    //         //console.log("inside failed Otp");
    //         this.messageService.add({ severity: 'error', summary: 'Error occure while sending OTP !' });
    //       }
    //     },
    //       error => {
    //         this.loader.stop();
    //         this.messageService.add({ severity: 'error', summary: error.error.status['statusMsg'] });
    //       })
    // }

    // this.verifyInviteOtp();

  }

  verifyOTP(formValue) {
  //  console.log("verify otp email====",localStorage.getItem('mail'));
    const req = {
      OTP: formValue.otp,
      userEmail: localStorage.getItem('mail')
    };

    // at the time of invite member.
    if (this.invitedEmail && this.invitedMode === 'UI') {
      this.LoginService.getData(`verifyOTP?OTP=${formValue.otp}&userEmail=${this.invitedEmail}`)
        .subscribe(result => {
          this._compiler.clearCache();
          if (result['status'].statusMsg === 'Success') {
            localStorage.setItem('verify', 'true');
            localStorage.setItem('mail', this.invitedEmail);
            this.messageService.add({ severity: 'success', summary: 'OTP Verify Successfully' });
            this.router.navigateByUrl('/login');
          } else if (result['status'].statusMsg === 'OTP Expires') {
            this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
          } else if (result['status'].statusMsg === 'OTP Resend Limit Reached') {
            this.messageService.add({ key: 'op', sticky: true, severity: 'warn'});
          } else {
            this.messageService.add({ severity: 'warn', summary: result['status'].statusMsg });
          }
        // tslint:disable-next-line: no-unused-expression
        }), err => {
          this._compiler.clearCache();
          this.messageService.add({ severity: 'error', summary: 'OTP verification failed' });
        };
    } else {
      // sign up service.
      this.LoginService.verifyOTP(formValue.otp, localStorage.getItem('mail'))
        .subscribe(res => {
          if (res.status.statusMsg === 'Success') {
            this.messageService.add({ severity: 'success', summary: 'OTP Verify Successfully' });
            localStorage.setItem('verify', 'true');
            this.router.navigateByUrl('/login');
          } else if (res.status.statusMsg === 'OTP verification Fail') {
            this.messageService.add({ severity: 'warn', summary: res.status.statusMsg });
          } else if (res.status.statusMsg === 'OTP Resend Limit Reached') {
            this.messageService.add({ key: 'op', sticky: true, severity: 'warn'});
          } else {
            this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
          }
        },
          error => {
            this.messageService.add({ severity: 'error', summary: 'OTP Verification failed' });
          });
    }
  }

  onConfirm() {
    this.messageService.clear('op');
  }
  isResendOtp:boolean=false;
  resendOTP() {
    this.isResendOtp=true;
    let mail = localStorage.getItem('mail');
     this.customerName =localStorage.getItem('customerName');
    if (this.invitedEmail && this.invitedMode === 'UI') {
      mail = localStorage.getItem('inviteEmail');
    this.customerName=localStorage.getItem('customerName');
    }
    this.LoginService.getData(`getOtpForInviteUser?email=${this.mail}&customerName=${this.customerName}`)
      .subscribe(res => {
        if (res.status.statusMsg == 'Success') {
          this.messageService.add({ severity: 'success', summary: 'OTP sent Successfully' });
          this.isResendOtp=false;
        } else if (res[status].statusMsg == 'OTP Resend Limit Reached') {
          this.messageService.add({ key: 'op', sticky: true, severity: 'warn'});
          this.isResendOtp=false;
        } else {
          this.messageService.add({ severity: 'error', summary: res[status].statusMsg });
          this.isResendOtp=false;
        }
      },
        error => {
          this.messageService.add({ severity: 'error', summary: error.error.status.statusMsg });
          this.isResendOtp=false;
        });
  }

  verifyInviteOtp() {
    this.loader.stop();
    this.ActivatedRoute.queryParams.subscribe(params => {
      this.invitedEmail = params.email;
      this.invitedMode = params.mode;
      this.customerName= params.customerName;
      localStorage.setItem('customerName',this.customerName);
      this.callToOTPUI();
    });
  }

  callToOTPUI() {
    if (this.invitedEmail && this.invitedMode === 'UI') {
      this.LoginService.getData(`getOtpForInviteUser?email=${this.invitedEmail}&customerName=${this.customerName}`)
        .subscribe(result => {
          this.loader.stop();
          if (result['status'].statusMsg === 'Success') {
            this.messageService.add({ severity: 'success', summary: 'Mail sent successfully' });
          } else {
            this.messageService.add({ severity: 'error', summary: result['status'].statusMsg });
          }
        },
        err => {
          this.messageService.add({ severity: 'error', summary: err[status].statusMsg });
        });
    }
  }

  test() {
    this.messageService.add({ key: 'v', sticky: true, severity: 'success' });
  }

}

