import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private message = new BehaviorSubject([{
    'label': 'Home',
    'url': environment.mytaskeasy_url + 'business-dashboard',
  }]);
 

  comp1Val: string;
  _comp1ValueBS = new BehaviorSubject<string>('');

  newMessage=new BehaviorSubject<string>('');
 
  sharedMessage = this.message.asObservable();

  constructor() { }

  nextMessage(message: any) {
    
console.log(this.sharedMessage);
    this.message.next(message)
  }

  updateComp1Val(val) {
    this.comp1Val = val;
    this._comp1ValueBS.next(this.comp1Val);
  }

  sendMessage(data: any) {
    
        this.newMessage.next(data)
      }
}
