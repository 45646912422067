<p-toast position="center" class="success otpFailed" key="v" [modal]="true"
    [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="otp">
            <i class="fa fa-exclamation-circle exclamation" aria-hidden="true"></i>
            <h6>OTP expired</h6>
            <h6>Please Click to resend OTP</h6>
        </div>
        <div class="ui-g ui-fluid">
            <button type="button" (click)="resendOTP()" class="btn btn-primary resendOTP" aria-label="resend">Resend OTP</button>
            
        </div>
    </ng-template>
</p-toast>
<p-toast position="center" class="success otpFailed" key="op" [modal]="true"
    [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="otp">
            <i class="fa fa-exclamation-circle exclamation" aria-hidden="true"></i>
            <h6>You have reached the OTP Limit </h6>
            <h6>For further help, please reach out to us at support@northstar.straviso.net.</h6>
        </div>
        <div class="ui-g ui-fluid">
            <button type="button" (click)="onConfirm()" class="btn btn-primary" aria-label="ok">OK</button>
        </div>
    </ng-template>
</p-toast>
<p-toast [style]="{marginTop: '80px'}"></p-toast>
<div class="container-fluid verifyContainer">
    <div class="verifyRow text-center">
        <form [formGroup]="verifyForm" (ngSubmit)="verifyOTP(verifyForm.value)">
            <p class="m-0 pb-3 big head">Verify Your Identity</p>
            <p class="m-0 pb-1 small">Enter the verification code we emailed to</p>
            <p class="m-0 pb-3 big">{{mail}}</p>
            <p class="m-0 pb-2 small">Verification code</p>
            <p class="m-0 pb-2"><input class="otpInput text-center" type="number" autocomplete="off" formControlName="otp" name="otp" id="otp"></p>
            <p class="m-0 pb-3 big"><button type="submit" [disabled]="!verifyForm.valid" class="btn btn-primary verifyBtn" maxlength="5" aria-label="verify">Verify</button></p>
            <p class="m-0 small resend" (click)="resendOTP()" [ngClass]="{'resentotpcodecss':isResendOtp==true}">Resend Code</p>
        </form>
        <p class="p-0 m-0" *ngIf="UISentmailFlag">OTP sent successfully. Please check your email</p>
    </div>
</div>
