import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, Input, HostListener, Output, ChangeDetectorRef } from '@angular/core';
import { TaskDataService } from '../../services/task-data.service';
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location, LocationStrategy, PathLocationStrategy, formatDate, DatePipe } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';
import { environment } from 'src/environments/environment';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { Paginator } from 'primeng/paginator/paginator';
import { Table } from 'primeng/table';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PaymentService } from 'src/app/services/payment.service';
import { EventEmitter } from 'protractor';
import { UserIdleService } from 'angular-user-idle';
// import { TasksComponent } from './tasks/tasks.component';
const noData = require('highcharts/modules/no-data-to-display.js');
noData(Highcharts);
declare var require: any
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService]
})

export class DashboardComponent implements OnInit, AfterViewInit {
  // @ViewChild('taskComponent') taskComponent: TasksComponent;
  tourImages:any=JSON.parse(localStorage.getItem('productTourImages'));
  dtWatchlistJson=[];
  watchlistImage: string;
  watchlistImageNew=false;
  highcharts = Highcharts;
  title = "hii";
  text2 = "helloo";
  selected:any="";
  file: File[] = [];
  product: any[] = [];
  projects: any[] = [];
  AssignTo: any[];
  manager: any[];
  editCustomerSidebar = false;
  showCustomerSidebar = false;
  user: any[];
  lastModified: string;
  createSidebar: boolean = false;
  editSidebar: boolean = false;
  tasks: any[] = [];
  files: any = [];
  newFileArray: any;
  fileType: any;
  selectedProduct: any=[];
  selectedProject: any=[];
  createForm: FormGroup;
  advanceSearchForm: FormGroup;
  taskCount: any;
  userData: any;
  searchtext:any="";
  cols: any[];
  _selectedColumns: any[];
  UserList: any[];
  selectedAssignTo: any;
  taskTypeData: any[];
  selectedBtn: any = '';
  minDueDate: any;
  currentDate: any;
  selectedTab: any;
  todaydate: any;
  Status: any;
  tablePriorityList = [];
  tableTaskIdData = [];
  tableTaskNameData = [];
  tableTaskTypeData = [];
  tableAssignToList = [];
  tableCreatedByList = [];
  tableProductList = [];
  tableProjectList = [];
  tableStatusList = [];
  tableCreatedDate = [];
  tableDueDate = [];
  tableUpdatedDate = [];
  isinvalid: boolean;
  fileSize: boolean;
  taskData: any;
  UserGroupList: any[];
  setAssigneeFlag: boolean;
  setAssigneeGroupFlag: boolean;
  userDetails: any;
  token: string;
  noOfRows;
  noOfCards ; 
  isMinimized: boolean = false;
  chart: any;
  chartOptions1 = {};
  chartOptions2 = {};
  graphData = [];
  watchlistForm: FormGroup;
  /* view */
  isStatusSelected: boolean = false;
  isAssigneeUpdated: boolean = false;
  isProductChanged: boolean = false;
  isProjectChanged: boolean = false;
  isPriorityChanged: boolean = false;
  isStatusChanged: boolean;
  dateUpdated: boolean = false;
  isTaskUpdated: boolean = false;
  isDescriptionupdated: boolean = false;
  isFileUpdated: boolean = false;
  dateError: boolean;
  selectedTaskId: any;
  viewForm: FormGroup;
  viewSidebar: boolean;
  taskDataForView: any;
  comment: boolean = false;
  fileUrl: any;
  taskStatusData: any;
  addedComment: any;
  /* edit */
  editForm: FormGroup;
  taskDataForEdit: any[] = [];
  StatusList: any = [];
  commentError: boolean = false;
  toggle: boolean = true;
  expand: boolean = false;
  isCreate: boolean = false;
  isUpdate: boolean = false;
  myAllTasks: boolean = false;
  countList: any;
  priorityCountList: any;
  priorityStatusList: any;
  taskStatus: any;
  taskPriority: any;
  addTaskId;
  viewTaskId;
  advanceSearchExport: boolean=false;
  displayflicker:boolean=false;
  showAddTaskData: boolean = false;
  colors: any = [];
  assignedTasks: boolean = false;
  createdTasks: boolean = false;
  overdueTasks: boolean = false;
  atriskTasks: boolean = false;
  allTasks: boolean = false;
  viewTaskData: boolean = false;
  viewOpenTaskData: boolean = false;
  editCustomerId: any;
  viewDialogueBox = false;
  closeViewBox=false;
  viewDialogueBoxData;
  addDialogueBox = false;
  newreq: any;
  addWatchlistDropdown=true;
  @ViewChild('p', { static: false }) paginator: Paginator;
  @ViewChild('p1', { static: false }) paginator1: Paginator;
  @ViewChild('dt', { static: false }) private table: Table;
  @ViewChild('dt2', { static: false }) private cardTable: Table;
  dt22: any;
  dt23: string;
  dt24: string;
  dt25: string;
  dt26: string;
  dt27: string;
  dt28: string;
  dt29: string;
  dt30: string;
  dt31: string;
  dt32: string; 
  dt33: string;
  LocalValueDropDowns: any;
  fieldSelected: any;
  isActiveHead: boolean = false;
  filterApplied: boolean[] = [];
  @ViewChild('taskId', { static: false }) taskId: ElementRef;
  @ViewChild('taskName', { static: false }) taskName: ElementRef;
  @ViewChild('taskType', { static: false }) taskType: ElementRef;
  @ViewChild('productName', { static: false }) productName: ElementRef;
  @ViewChild('projectName', { static: false }) projectName: ElementRef;
  @ViewChild('userName', { static: false }) userName: ElementRef;
  @ViewChild('createdBy', { static: false }) createdBy: ElementRef;
  @ViewChild('priority', { static: false }) priority: ElementRef;
  @ViewChild('status', { static: false }) status: ElementRef;
  @ViewChild('createdDate', { static: false }) createdDate: ElementRef;
  @ViewChild('dueDate', { static: false }) dueDate: ElementRef;
  @ViewChild('updatedDate', { static: false }) updatedDate: ElementRef;
  @ViewChild(Table) dt: Table;
  selectedItemsLabel: string;
  selectedColumn = '';
  cardView: boolean = false;
  tableView: boolean = true;
  templateList: any = [];
  displayWatchlist: boolean;
  defaultData: boolean;
  watchlistDropdown: any[];
  watchlistChangeValue=[];
  changeColorDrop = false;
  changeColorDrops: string;
  insideWatchlist: boolean;
  dtWatchlist:any;
  advanceSearchexportedData:any;
  defaultWatchlistArray: any[];
  items: any[];
  selectedCars1: string[] = [];
  selectedCars2: string[] = [];
  cars: { label: string; value: string; }[];
  idWatchlist: any;
  idWatchlistNew: any[];
  newobjectWatchlist: {};
  newIdForwatchlistDefault: any;
  displayWatchlistDefault: boolean;
  watchlistFormDefault: FormGroup;
  multiselectWa: any;
  touropen:any;
  dt34: string;
  dt35: string;
  dt36: string;
  dt37: string;
  dt38: string;
  dt39: string;
  dt40: string;
  dt41: string;
  dt42: string;
  dt43: string;
  dt44: string;
  dt45: string;
  watchlistAlready=false;
  submitted: boolean;
  displayTakeTour :any;
  position='top';
  lang:any='en';
  text: string;
  showSearch1:boolean=false;
  dashboardGlobalSearch:boolean=true;
  selectedValue:string='val1';
  isListView:boolean;
  isCardView:boolean;
  dontshowagain;
  isDontShowAgain:boolean;
  isPromocodeExpired:boolean=false;
  closeViewDialogBox = false;
  isTasksNotPresent:boolean=false;
  languageDropdown:any=[];
  selectedLanguage:any;
  settingPresent:boolean=false;
  breadCrumb: any;
  label2:any;
  label1:any;
  labelAllTask1;
  labelAllTask2;
  homeLabel:any;
  cols1:any=[];
  languageImage:any=[];
  viewLangData=false;
  isManager: boolean = false;
  isExecutive: boolean = false;
  isAdmin: boolean = false;
  //help menu
  faqResponse:any=[];
  CopyfaqResponse:any=[];
  faqResponseNew:any=[];
  videoResponse:any=[];
  CopyvideoResponse:any=[];
  videoResponseNew:any=[];
  guideResponse:any=[];
  CopyguideResponse:any=[];
  guideResponseNew:any=[];
  teamCount:any;
  myCount:any;
  isAuthToken:boolean=false;
  advanceSearchFormClear:boolean=false;
  advanceSearchFormSubmit:boolean=false;
  selectedGroupAssigne: any = [];


  totalTask: number;
  globalFilterData: boolean = false;
  advanceSearchData: boolean = false;
  pageClicked: boolean = false;
  lazyLoad: boolean = false;
  pageNumber: number;
  // noOfRows:number;
  totalRecords: any;
  advancedSearch: boolean=false;
  advanced_Search_Div: boolean=false;
  statuses: any=[];
  createdByList:any=[];
  priorityArr: any=[];
  assignedToArr: any=[];
  currentUser: any;
  getData: string[];
  selectedUsers: any;
  selectedCreatedUser: any=[];
  isSlectedUsers: boolean = false;
  selectedListOfPriority: any=[];
  selectedListOfPriorityName:any=[];
  selectedStatusName: any=[];
  selectedStatus: any=[];
  selectedUsersId: any=[];
  userIdSelected: any;
  userIdAdvancedSearch: any='';
  globalSearchValue: any;
  tasksExport: any[] = [];
  AdvancedSearchTaskId: any;
  AdvancedSearchTaskName: any;
  // fromdateNew: any;
  // todateNew: any;
  fromDate: any;
  toDate: any;
  fromDateToday:any;
  toDateToday:any;
  fromdatetoday_mmddyyyy:any;
  todatetoday_mmddyyyy:any;
  fromDateWeek:any;
  toDateWeek:any;
  fromdateweek_mmddyyyy:any;
  todateweek_mmddyyyy:any;
  fromDateMonth :any ;
  toDateMonth  :any;
  fromdatemonth_mmddyyyy  :any;
  todatemonth_mmddyyyy  :any
  fromDateYear :any;
  toDateYear :any;
  fromdateyear_mmddyyyy :any;
  todateyear_mmddyyyy :any;
  customDateSelection: any;
  selectedButtonIndex = 0;
  selectedButton :boolean = false;
  additionalFields: any = [];
  tempBusinessIds: any = [];
  isLoaded: boolean=false;
  isExportData:boolean=false;
  advanceSearchArray:any= {};
  isAdvanceSearch:boolean=false;
  selectedTaskTypeArr: any=[];
  imgSelected: any='';
  templateListSelected: any;
  selectedDropdown: boolean=false;
  advanceExportData: boolean=false;
  displayDropdown: boolean=true;
  isUserAuthorised: boolean = true;
  constructor(
    private TaskService: TaskDataService,
    private ActivatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private ngxLoader: NgxUiLoaderService,
    private location: Location,
    private domSanitizer: DomSanitizer,
    private User: UserService,
    public datepipe: DatePipe,
    private sharedService: SharedService,
    private translateservice:TranslateService,
    private paymentService:PaymentService,
    private _changeDetectorRef: ChangeDetectorRef,
    private userIdle: UserIdleService
  ) {
    this.tourImages=JSON.parse(localStorage.getItem('productTourImages'));
    this.tourImages=this.tourImages;
    let newValue = this.sharedService.comp1Val;
    if (newValue != undefined || newValue != "" && newValue == "successTask") {
      this.getTaskCount();
      this.getDashboardData();
    };
  }

  ngOnInit() {
    localStorage.removeItem('userNamesDd');
    localStorage.removeItem('priorityDd');
    localStorage.removeItem('productsDd');
    localStorage.removeItem('createdByNamesDd');
    localStorage.removeItem('taskIds');
    localStorage.removeItem('createdDateDd');
    localStorage.removeItem('taskNames');
    localStorage.removeItem('statusDd');
    localStorage.removeItem('projectsDd');
    localStorage.removeItem('dueDateDd');
    localStorage.removeItem('updatedDateDd');
    localStorage.removeItem('taskTypes');
    sessionStorage.removeItem("localvalue");
    const moment = require('moment-timezone');
    this.getData = ['Today', 'WTD', 'MTD', 'YTD']; /* , 'ALL' */
    // const today = moment(new Date());
    // this.fromdatetoday_mmddyyyy = moment(today).format('MM-DD-YYYY');
    // this.todatetoday_mmddyyyy = moment(today).format('MM-DD-YYYY');
    // this.selected = { start: this.fromdatetoday_mmddyyyy, end: this.todatetoday_mmddyyyy };
    this.advanceSearchForm = new FormGroup({
      taskName: new FormControl(""),
      product: new FormControl(""),
      project: new FormControl(""),
      createdBy: new FormControl(""),
      assignedTo: new FormControl(""),
      taskId: new FormControl(""),
      userGroup: new FormControl(""),
      dueDate: new FormControl(""),
      startDate: new FormControl(""),
      reportName: new FormControl("",Validators.pattern(".*\\S.*[a-zA-Z0-9 _~^%{}|?><:;!@#$&()\\-`.+,/\"]")),
      status: new FormControl(""),
      priority: new FormControl("")
    });
    this.pageNumber = 0;
    this.noOfRows = 15;
    this.ngxLoader.start();
    // localStorage.removeItem("taskId");
    if (this.router.url.indexOf('/dashboard') > -1) {
      this.breadCrumb = [{ "label": "Home", "url": environment.mytaskeasy_url + 'dashboard' }];
    }
    setTimeout(() => {
      // localStorage.setItem('breadCrumb', JSON.stringify(this.breadCrumb));
      this.sharedService.nextMessage( this.breadCrumb);
    }, 1000);
      //check if manager
      this.TaskService.getAllUserList()
      .subscribe(res => {
        let data = res.responseData;
        let manager = data.map(
          x => x.manager
        );
        this.isAdmin = this.currentUser.admin;
        if (manager.includes(this.currentUser.userName) == false) {
          this.isManager = false;
        } else {
          this.isManager = true;
        }
        if (this.currentUser.userGroup == "Executive") {
          this.isExecutive = true;
        } else {
          this.isExecutive = false;
        }
        
        this.assignedToArr = [];
        let data1 = res.responseData;
        let manager1 = data1.map(
          x => x.manager1
        );
        if (this.isAdmin) {
          //if admin push every user.
          res.responseData.forEach(ele => {
            let temp: any;
            temp = {
              "name": ele.userName,
              "code": ele.userId
            };
            this.assignedToArr.push(temp);
            this.assignedToArr.sort(this.keysrt('name'));
          });
        } else if (!this.isAdmin && this.isManager) {
          //if not admin but  manager.
          res.responseData.forEach(ele => {
            let temp: any;
            if (ele.manager == this.currentUser.userName) {
              temp = {
                "name": ele.userName,
                "code": ele.userId
              };
              this.assignedToArr.push(temp);
              this.assignedToArr.sort(this.keysrt('name'));
            }
          });
          //Manger name added to assignee. 
          let temp: any = {
            "name": this.currentUser.userName,
            "code": this.currentUser.userId
          };
          this.assignedToArr.push(temp);
          this.assignedToArr.sort(this.keysrt('name'));

        
        } else {
          //if only user.
          let temp: any = {
            "name": this.currentUser.userName,
            "code": this.currentUser.userId,
            disabled: true,
          };
          this.assignedToArr.push(temp);
          this.assignedToArr.sort(this.keysrt('name'));
        }


        this.createdByList = [];
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.userName,
            "code": ele.userId
          };
          this.createdByList.push(temp);
          this.createdByList.sort(this.keysrt('name'));
          // this.ngxLoader.stop();
        });
        //duplicate the default assignee group
        this.selectedGroupAssigne = this.assignedToArr;
      });
    // this.ngxLoader.start();
    localStorage.setItem("first",""+this.first+"");
    localStorage.setItem("firstCardView",""+this.firstCardView+"")
    this.assignedTasks = true;
    this.displayTakeTour=false;
    this.ActivatedRoute.queryParams.subscribe(params => {
      if (params['token']) {
        // this.ngxLoader.start();
        localStorage.setItem("auth_token", params['token']);
        if (params['quickLink']) {
          this.createSidebar = true;
        }
        if(this.ActivatedRoute.snapshot.queryParams && this.ActivatedRoute.snapshot.queryParams['taskId']){
          localStorage.setItem('taskId', this.ActivatedRoute.snapshot.queryParams['taskId']);
          if(!localStorage.getItem('auth_token')){
            this.router.navigateByUrl('/');
          }
        }
        if(localStorage.getItem('auth_token')){
          this.isAuthToken=true;
          this.TaskService.getUserDetails()
          .subscribe(res => {
            if (res.result == "Success") {
              // this.userData = res.responseData;
              localStorage.setItem("userData", JSON.stringify(res.responseData));
              this.userData = JSON.parse(localStorage.getItem("userData"));
              this.userIdAdvancedSearch=res.responseData['userId'];
             
          console.log("this.userIdSelected-----",this.userIdAdvancedSearch);
          this.currentUser=res.responseData;
          console.log("this.currentUser---",this.currentUser);
              let pdata:any=JSON.parse(localStorage.getItem('userPData'))
              if(pdata.tourFlag==true){
                this.dontshowagain=true;
                this.isDontShowAgain=true;
              }
              else{
                this.dontshowagain=false;
                this.isDontShowAgain=false;
              }
              this.getTaskCount();
              this.getDashboardData();
              this.getWatchlist();
              /* this.getWatchlist();
              this.getProject();
              this.getProduct();
              this.getUser();
              this.getUserGroupList();
              this.getStatusList(); */
              this.submitWatchlist();
              this.getDefaultOptionsForDropdown();
              this.findPromocodeByBusinessId();
              this.getTextValues();
            }
            
            else {
              this.router.navigate([environment.logo_url + '#/home']);
            }
          },error=>{
          });
        }
        else{
          this.router.navigateByUrl('/');
        }
      }
      else {
        if(this.ActivatedRoute.snapshot.queryParams && this.ActivatedRoute.snapshot.queryParams['taskId']){
        //   localStorage.setItem('taskId', this.ActivatedRoute.snapshot.queryParams['taskId']);
        //   if(!localStorage.getItem('auth_token')){
        //     this.router.navigateByUrl('/');
        //   }
        // else{
          
        // }
        let userData = JSON.parse(localStorage.getItem("userData"));
        let taskData = this.ActivatedRoute.snapshot.data['taskData']
        let req = { "taskId": this.ActivatedRoute.snapshot.queryParams['taskId'] }
        // this.ngxLoader.start()
        this.TaskService.getTaskDetailById(req).subscribe(res => {
          taskData = res['responseData']
          if((userData['roleName'].toLowerCase() !== 'manager' && userData['roleName'].toLowerCase() !== 'admin') &&
          (userData['userName'] !== taskData['assignedToName'] && Object.keys(taskData).length !== 0)) {
            this.isUserAuthorised = false;
            localStorage.removeItem('taskId');
            // console.log("================================ authorised 1",this.isUserAuthorised);
            this.messageService.add({severity: 'error', summary: `You are not authorized to view the details of ${taskData["taskId"]}`,life: 2000 })
          }else {
            localStorage.setItem('taskId', this.ActivatedRoute.snapshot.queryParams['taskId']);
            this.isUserAuthorised = true;
            // console.log("================================ authorised 2",this.isUserAuthorised);
          }
          this.getTaskCount();
          this.getDashboardData();
        })

            // let userData = JSON.parse(localStorage.getItem("userData"));
            // let taskData = this.ActivatedRoute.snapshot.data['taskData']
            // let req = { "taskId": this.ActivatedRoute.snapshot.queryParams['taskId'] }
            // this.ngxLoader.start()
            // this.TaskService.getTaskDetailById(req)
            // .subscribe(res => {
            //   taskData = res['responseData']
            //   if((userData['systemRole'] !== 'Admin') &&
            //   (userData['userName'] !== taskData['assignedToName'] && Object.keys(taskData).length !== 0)) {
            //     this.isUserAuthorised = false;
            //     localStorage.removeItem('taskId');
            //     this.ngxLoader.stop()
            //     // console.log("================================ authorised 1",this.isUserAuthorised);
            //     this.messageService.add({severity: 'error', summary: `You are not authorized to view the details of ${taskData["taskId"]}`,life: 4000 })
            //   }else {
            //     localStorage.setItem('taskId', this.ActivatedRoute.snapshot.queryParams['taskId']);
            //     this.isUserAuthorised = true;
            //     // console.log("================================ authorised 2",this.isUserAuthorised);
            //   }
            //   let pdata:any=JSON.parse(localStorage.getItem('userPData'));
            //   if(pdata.tourFlag==true){
            //     this.dontshowagain=true;
            //     this.isDontShowAgain=true;
            //   }
            //   else{
            //     this.dontshowagain=false;
            //     this.isDontShowAgain=false;
            //   }
            //   this.getTaskCount();
            //   this.getDashboardData();
            // })

        }
        // else {
        //   // this.ngxLoader.start();
        //   this.userData = JSON.parse(localStorage.getItem("userData"));
        //   let pdata:any=JSON.parse(localStorage.getItem('userPData'));
        //   if(pdata.tourFlag==true){
        //     this.dontshowagain=true;
        //     this.isDontShowAgain=true;
        //   }
        //   else{
        //     this.dontshowagain=false;
        //     this.isDontShowAgain=false;
        //   }
        //   this.getTaskCount();
        //   this.getDashboardData();
        // }
       if(localStorage.getItem('auth_token')){
         this.isAuthToken=true;
         this.userData = JSON.parse(localStorage.getItem("userData"));
         let pdata:any=JSON.parse(localStorage.getItem('userPData'));
         if(pdata.tourFlag==true){
           this.dontshowagain=true;
           this.isDontShowAgain=true;
         }
         else{
           this.dontshowagain=false;
           this.isDontShowAgain=false;
         }
         this.getTaskCount();
         this.getDashboardData();
         if (this.userData === null || this.userData.userId === null) {
           this.router.navigate([environment.logo_url + '#/home']);
         }
         this.getWatchlist();
 
        //  console.log("================================ authorised 3",this.isUserAuthorised);
          // this.userData = JSON.parse(localStorage.getItem("userData"));
          // let pdata:any=JSON.parse(localStorage.getItem('userPData'));
          // if(pdata.tourFlag==true){
          //   this.dontshowagain=true;
          //   this.isDontShowAgain=true;
          // }
          // else{
          //   this.dontshowagain=false;
          //   this.isDontShowAgain=false;
          // }
          // // this.getTaskCount();
          // // this.getDashboardData();
          // if (this.userData === null || this.userData.userId === null) {
          //   this.router.navigate([environment.logo_url + '#/home']);
          // }
          // this.getWatchlist();
      /* this.getWatchlist();
              this.getProject();
              this.getProduct();
              this.getUser();
              this.getUserGroupList();
              this.getStatusList(); */
        this.getDefaultOptionsForDropdown();
        this.findPromocodeByBusinessId();
        this.getTextValues();
       }
       else{
        this.router.navigateByUrl('/');
       }
        
      }
     
     /*  if(localStorage.getItem('taskId')){
        this.openViewTaskForm(localStorage.getItem('taskId'))
      } */
    });
    this.location.replaceState('/dashboard');
   
    let title = "hii";
    let dte = new Date();
    this.currentDate = moment().tz('America/Mexico_City').format('MM/DD/YYYY | h:mm A');
    localStorage.setItem('currentDate',this.currentDate);
    dte.setDate(dte.getDate() - 2);
    this.lastModified = dte.toString();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.minDueDate = moment(new Date())._i;
    localStorage.removeItem("taskType");
    localStorage.removeItem("selectedTask");
    this.watchlistForm = new FormGroup({
      watchlistName: new FormControl(null, [Validators.required, Validators.pattern('.*\\S.*[a-zA-Z\\s]*')]),
      default: new FormControl(''),
    });
    this.watchlistFormDefault = new FormGroup({
      watchlistName: new FormControl(null, [Validators.required, Validators.pattern('.*\\S.*[a-zA-Z\\s]*')]),
      default: new FormControl(''),
    });
    this.cols = [
      { field: 'taskId', header: 'Task ID', class: "taskId", isHilighted:false },
      { field: 'taskName', header: 'Task Name', class: "taskName", isHilighted:false },
      { field: 'taskType', header: 'Task Type', class: "taskType", isHilighted:false },
      { field: 'productName', header: 'Product', class: "productName", isHilighted:false },
      { field: 'projectName', header: 'Project Name', class: "projectName", isHilighted:false },
      { field: 'userName', header: 'Assigned To', class: "userName", isHilighted:false },
      { field: 'createdByName', header: 'Created By', class: "createdByName", isHilighted:false },
      { field: 'priority', header: 'Priority', class: "priority", isHilighted:false },
      { field: 'status', header: 'Status', class: "status", isHilighted:false },
      { field: 'createdDate', header: 'Start Date', class: "createdDate", isHilighted:false },
      { field: 'dueDate', header: 'Due Date', class: "dueDate", isHilighted:false },
      { field: 'updatedDate', header: 'Last Updated', class: "updatedDate", isHilighted:false },
      // { field: 'action', header: '', class: "action" },
    ];
    this.items = [];
    for (let i = 0; i < 10000; i++) {
        this.items.push({label: 'Item ' + i, value: 'Item ' + i});
    }
    this._selectedColumns = this.cols;
    if (this.graphData) {
      this.drawGraphByPriority(this.graphData,this.priorityNameList);
    }
    if (localStorage.hasOwnProperty('localvalue')) {
      if (localStorage.getItem("localvalue")) {
        this.LocalValueDropDowns = JSON.parse(localStorage.getItem("localvalue"));
        if (this.LocalValueDropDowns.hasOwnProperty("filters")) {
          if (this.LocalValueDropDowns.filters.hasOwnProperty("taskId")) {

            this.dt22 = this.LocalValueDropDowns.filters.taskId.value;

          }
          if (this.LocalValueDropDowns.filters.hasOwnProperty("taskName")) {
            this.dt23 = this.LocalValueDropDowns.filters.taskName.value;
          }
          // if (this.LocalValueDropDowns.filters.hasOwnProperty("customerId")) {
          //   this.dt24 = this.LocalValueDropDowns.filters.customerId.value;

          // }
          // if (this.LocalValueDropDowns.filters.hasOwnProperty("interactionType")) {
          //   this.dt25 = this.LocalValueDropDowns.filters.interactionType.value;

          // }
          // if (this.LocalValueDropDowns.filters.hasOwnProperty("interactionMessage")) {
          //   this.dt26 = this.LocalValueDropDowns.filters.interactionMessage.value;

          // }

        }
      }
    }
    
    if(localStorage.getItem('ListView')==null && localStorage.getItem('CardView')==null){
      this.isCardView=true;
      this.isListView=false;
      this.showSearch1=true;
    }
    else if(localStorage.getItem('ListView')=="true" && localStorage.getItem('CardView')=="false"){
      this.isListView=true;
      this.isCardView=false;
      this.showSearch1=false;
    }
    else if(localStorage.getItem('CardView')=="true" && localStorage.getItem('ListView')=="false"){
      this.isCardView=true;
      this.isListView=false;
      this.showSearch1=true;
    } 

    this.lang=localStorage.getItem('selectedLanguage');
    if(localStorage.getItem('selectedLanguage')=='en'){
      this.translateservice.currentLang='en';
      setTimeout(()=>{
       if(localStorage.getItem('englishLabels')){
        this.translateservice.setTranslation('en',JSON.parse(localStorage.getItem('englishLabels')));
       }
      },2000)
      this.translateservice.setTranslation('en',JSON.parse(localStorage.getItem('englishLabels')));
      this.translateservice.use('en');
    }
    else if(localStorage.getItem('selectedLanguage')=='es'){
      this.translateservice.currentLang='es';
      setTimeout(()=>{
        this.translateservice.setTranslation('es',JSON.parse(localStorage.getItem('SpanishLabels')));
      },2000)
      this.translateservice.setTranslation('es',JSON.parse(localStorage.getItem('SpanishLabels')));
      this.translateservice.use('es');
    }
    else if(localStorage.getItem('selectedLanguage')=='pr'){
      this.translateservice.currentLang='pr';
      setTimeout(()=>{
        this.translateservice.setTranslation('pr',JSON.parse(localStorage.getItem('portugueseLabels')));
      },2000);
      this.translateservice.setTranslation('pr',JSON.parse(localStorage.getItem('portugueseLabels')));
      this.translateservice.use('pr');
    }
    else if(localStorage.getItem('selectedLanguage')=='hi'){
      this.translateservice.currentLang='hi';
      setTimeout(()=>{
        this.translateservice.setTranslation('hi',JSON.parse(localStorage.getItem('hindiLabels')));
      },2000);
      this.translateservice.setTranslation('hi',JSON.parse(localStorage.getItem('hindiLabels')));
      this.translateservice.use('hi');
    }
    else if(localStorage.getItem('selectedLanguage')=='fr'){
      this.translateservice.currentLang='fr';
      setTimeout(()=>{
        this.translateservice.setTranslation('fr',JSON.parse(localStorage.getItem('frenchLabels')));
      },2000);
      this.translateservice.setTranslation('fr',JSON.parse(localStorage.getItem('frenchLabels')));
      this.translateservice.use('fr');
    }
    this.label1=this.translateservice.instant('taskAssigned');
    this.labelAllTask1= this.translateservice.instant('allTasks');

    this.translateservice.get('taskAssigned').subscribe(txt=>{
      this.label1=txt;
    })
    this.label2=this.translateservice.instant('byPriority');
    this.translateservice.get('byPriority').subscribe(txt=>{
      this.label2=txt;
    })


    this.currentUser = JSON.parse(localStorage.getItem("userData"));
console.log(this.currentUser)
    this.TaskService.getAllProductList()
.subscribe(res => {
  this.product = [];
  res.responseData.forEach(ele => {
    let temp: any = {
      "name": ele.productName,
      "code": ele.productId
    };
    this.product.push(temp);
    this.product.sort(this.keysrt('name'));
  });
});

this.TaskService.getAllProjectList()
.subscribe(res => {
  this.projects = [];
  res.responseData.forEach(ele => {
    let temp: any = {
      "name": ele.projectName,
      "code": ele.projectId
    };
    this.projects.push(temp);
    this.projects.sort(this.keysrt('name'));
  });
});

this.TaskService.getTaskStatusList()
.subscribe(res => {
  this.statuses = [];
  res.responseData.forEach(ele => {
    let temp: any = {
      "name": ele.statusName,
      "code": ele.statusId
    };
    this.statuses.push(temp);
  });
  this.statuses.sort(this.keysrt('name'));
});

// this.TaskService.getAllCreatedByList()
// .subscribe(res => {
//   this.createdByList = [];
//   res.responseData.forEach(ele => {
//     let temp: any = {
//       "name": ele.userName,
//       "code": ele.userId
//     };
//     this.createdByList.push(temp);
//     this.createdByList.sort(this.keysrt('name'));
//     this.ngxLoader.stop();
//   });
// });

this.TaskService.getAllPriorities().subscribe(res => {
  this.priorityArr = [];
  res.responseData.forEach(ele => {
    let temp: any = {
      "name": ele.priorityName,
      "code": ele.priorityId
    };
    this.priorityArr.push(temp);
    this.priorityArr.sort(this.keysrt('name'));
    // this.ngxLoader.stop();
  });
});
// this.defaultAsignToList();

  }

  btnClickNew(i) {

    this.selectedButtonIndex = i;
    this.selectedButton = false;
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
    const today = moment(new Date());
    this.fromDateToday = moment(today).format('YYYY-MM-DD');
    this.toDateToday = moment(today).format('YYYY-MM-DD');
    this.fromdatetoday_mmddyyyy = moment(today).format('MM-DD-YYYY');
    this.todatetoday_mmddyyyy = moment(today).format('MM-DD-YYYY');

    const monday = moment().day(1);
    this.fromDateWeek = moment(monday).format('YYYY-MM-DD');
    this.toDateWeek = moment(today).format('YYYY-MM-DD');
    this.fromdateweek_mmddyyyy = moment(monday).format('MM-DD-YYYY');
    this.todateweek_mmddyyyy = moment(today).format('MM-DD-YYYY');

    const startOfMonth = moment().startOf('month');
    this.fromDateMonth = moment(startOfMonth).format('YYYY-MM-DD');
    this.toDateMonth = moment(today).format('YYYY-MM-DD');
    this.fromdatemonth_mmddyyyy = moment(startOfMonth).format('MM-DD-YYYY');
    this.todatemonth_mmddyyyy = moment(today).format('MM-DD-YYYY');

    const startOfYear = moment().startOf('year');;
    this.fromDateYear = moment(startOfYear).format('YYYY-MM-DD');
    this.toDateYear = moment(today).format('YYYY-MM-DD');
    this.fromdateyear_mmddyyyy = moment(startOfYear).format('MM-DD-YYYY');
    this.todateyear_mmddyyyy = moment(today).format('MM-DD-YYYY');

  
    switch (i) {

      case 0:
       
          this.fromDate= this.fromDateToday;
          this.toDate= this.toDateToday;
          this.selected = { start: this.fromdatetoday_mmddyyyy, end: this.todatetoday_mmddyyyy };
        break;
      case 1:
        
          this.fromDate= this.fromDateWeek;
          this.toDate= this.toDateWeek;
          this.selected = { start: this.fromdateweek_mmddyyyy, end: this.todateweek_mmddyyyy };
        break;

      case 2:
          this.fromDate= this.fromDateMonth;
          this.toDate= this.toDateMonth;  
          this.selected = { start: this.fromdatemonth_mmddyyyy, end: this.todatemonth_mmddyyyy };
        break;

      case 3:
          this.fromDate= this.fromDateYear;
          this.toDate= this.toDateYear;
          this.selected = { start: this.fromdateyear_mmddyyyy, end: this.todateyear_mmddyyyy };
        break;

      case 4:
          this.selectDate(this.customDateSelection);
          break;

      default:
        break;
    }
  }
  selectDate(event) {
    console.log(event);

    if (event.start && event.end) {
      this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
      this.selectedButton = true;
      this.selectedButtonIndex = 4;
      this.customDateSelection = event;
    
      this.fromDate= event.start.format("YYYY-MM-DD");
      this.toDate = event.end.format("YYYY-MM-DD");
    }
  }


defaultAsignToList() {
  this.TaskService.getAllUserList()
    .subscribe(res => {
      this.assignedToArr = [];
      let data = res.responseData;
      let manager = data.map(
        x => x.manager
      );
      if (this.isAdmin) {
        //if admin push every user.
        res.responseData.forEach(ele => {
          let temp: any;
          temp = {
            "name": ele.userName,
            "code": ele.userId
          };
          this.assignedToArr.push(temp);
          this.assignedToArr.sort(this.keysrt('name'));
        });
      } else if (!this.isAdmin && this.isManager) {
        //if not admin but  manager.
        res.responseData.forEach(ele => {
          let temp: any;
          if (ele.manager == this.currentUser.userName) {
            temp = {
              "name": ele.userName,
              "code": ele.userId
            };
            this.assignedToArr.push(temp);
            this.assignedToArr.sort(this.keysrt('name'));
          }
        });
        //Manger name added to assignee. 
        let temp: any = {
          "name": this.currentUser.userName,
          "code": this.currentUser.userId
        };
        this.assignedToArr.push(temp);
        this.assignedToArr.sort(this.keysrt('name'));
      } else {
        //if only user.
        let temp: any = {
          "name": this.currentUser.userName,
          "code": this.currentUser.userId,
          disabled: true,
        };
        this.assignedToArr.push(temp);
        this.assignedToArr.sort(this.keysrt('name'));
      }
      //duplicate the default assignee group
      this.selectedGroupAssigne = this.assignedToArr;
    });
}
  getLabels(){
    this.TaskService.getMultiLangLabels().subscribe(response=>{
      let languageLabels:any=[];
      let englishLabels:any;
      let SpanishLabels:any;
      let hindiLabels:any;
      let frenchLabels:any;
      let portugueseLabels:any;
      languageLabels=response.responseData;
          for(let i=0;i<languageLabels.length;i++){
            if(languageLabels[i].language=='English'){
              englishLabels= languageLabels[i].labels;
              localStorage.setItem('englishLabels',JSON.stringify(englishLabels));
            }
            if(languageLabels[i].language=='Spanish'){
              SpanishLabels= languageLabels[i].labels;
              localStorage.setItem('SpanishLabels',JSON.stringify(SpanishLabels));
            }
            if(languageLabels[i].language=='Hindi'){
              hindiLabels= languageLabels[i].labels;
              localStorage.setItem('hindiLabels',JSON.stringify(hindiLabels));
            }
            if(languageLabels[i].language=='French'){
              frenchLabels= languageLabels[i].labels;
              localStorage.setItem('frenchLabels',JSON.stringify(frenchLabels));
            }
            if(languageLabels[i].language=='Portuguese'){
              portugueseLabels= languageLabels[i].labels;
              localStorage.setItem('portugueseLabels',JSON.stringify(portugueseLabels));
            }
          }

          if(this.lang=='en'){
            this.translateservice.setTranslation('en', englishLabels);
            this.translateservice.setDefaultLang('en');
          }
          else if(this.lang=='es'){
            this.translateservice.setTranslation('es', SpanishLabels);
            this.translateservice.setDefaultLang('es');
          }
          else if(this.lang=='pr'){
            this.translateservice.setTranslation('pr',portugueseLabels);
            this.translateservice.setDefaultLang('pr');
          }
          else if(this.lang=='hi'){
            this.translateservice.setTranslation('hi',hindiLabels);
            this.translateservice.setDefaultLang('hi');
          }
          else if(this.lang=='fr'){
            this.translateservice.setTranslation('fr',frenchLabels);
            this.translateservice.setDefaultLang('fr');
          }
          
    })
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  settingsColumns=[];
  set selectedColumns(val: any[]) {
    this._selectedColumns=[];
      //restore original order
      //this._selectedColumns = this.cols.filter(col => val.includes(col));
      for(let i=0;i<this.cols.length;i++){
        for(let j=0;j<val.length;j++){
          if(this.cols[i].field==val[j].field){
            this._selectedColumns.push(val[j]);
          }
        }
      }
  }

  ngAfterViewInit(){
    if (window.innerWidth >= 1500) {
      this.noOfCards = 15;
      this.noOfRows = 15;
    }
    else if (window.innerWidth == 1366) {
      this.noOfCards = 10;
      this.noOfRows = 10;
    }
    else if(window.innerWidth<1366){
      this.noOfCards = 10;
      this.noOfRows = 10;
    }
    else if(window.innerWidth>=1707){
      this.noOfCards = 15;
      this.noOfRows = 15;
    }
    if(this.isAuthToken==true){
      if(localStorage.getItem('taskIds')!=null) {
        this.dt22=JSON.parse(localStorage.getItem('taskIds'));
        this.table.filter(this.dt22,'taskId','in');
        this.cardTable.filter(this.dt22,'taskId','in');
      }
      if(localStorage.getItem('taskNames')!=null) {
        this.dt23=JSON.parse(localStorage.getItem('taskNames'));
        this.table.filter(this.dt23,'taskName','in');
        this.cardTable.filter(this.dt23,'taskName','in');
      }
      if(localStorage.getItem('taskTypes')!=null) {
        this.dt24=JSON.parse(localStorage.getItem('taskTypes'));
        this.table.filter(this.dt24,'taskType','in');
        this.cardTable.filter(this.dt24,'taskType','in');
      }
      if(localStorage.getItem('productsDd')!=null) {
        this.dt25=JSON.parse(localStorage.getItem('productsDd'));
        this.table.filter(this.dt25,'productName','in');
        this.cardTable.filter(this.dt25,'productName','in');
      }
      if(localStorage.getItem('projectsDd')!=null) {
        this.dt26=JSON.parse(localStorage.getItem('projectsDd'));
        this.table.filter(this.dt26,'projectName','in');
        this.cardTable.filter(this.dt26,'projectName','in');
      }
      if(localStorage.getItem('userNamesDd')!=null) {
        this.dt27=JSON.parse(localStorage.getItem('userNamesDd'));
        this.table.filter(this.dt27,'userName','in');
        this.cardTable.filter(this.dt27,'userName','in');
      }
      if(localStorage.getItem('createdByNamesDd')!=null) {
        this.dt28=JSON.parse(localStorage.getItem('createdByNamesDd'));
        this.table.filter(this.dt28,'createdByName','in');
        this.cardTable.filter(this.dt28,'createdByName','in');
      }
      if(localStorage.getItem('priorityDd')!=null) {
        this.dt29=JSON.parse(localStorage.getItem('priorityDd'));
        this.table.filter(this.dt29,'priority','in');
        this.cardTable.filter(this.dt29,'priority','in');
      }
      if(localStorage.getItem('statusDd')!=null) {
        this.dt30=JSON.parse(localStorage.getItem('statusDd'));
        this.table.filter(this.dt30,'status','in');
        this.cardTable.filter(this.dt30,'status','in');
      }
      if(localStorage.getItem('createdDateDd')!=null) {
        this.dt31=JSON.parse(localStorage.getItem('createdDateDd'));
        this.table.filter(this.dt31,'createdDate','in');
        this.cardTable.filter(this.dt31,'createdDate','in');
      }
      if(localStorage.getItem('dueDateDd')!=null) {
        this.dt32=JSON.parse(localStorage.getItem('dueDateDd'));
        this.table.filter(this.dt32,'dueDate','in');
        this.cardTable.filter(this.dt32,'dueDate','in');
      }
      if(localStorage.getItem('updatedDateDd')!=null) {
        this.dt33=JSON.parse(localStorage.getItem('updatedDateDd'));
        this.table.filter(this.dt33,'updatedDate','in');
        this.cardTable.filter(this.dt33,'updatedDate','in');
      }
  
      if(localStorage.getItem('updatedDateDd')==null && localStorage.getItem('dueDateDd')==null && localStorage.getItem('createdDateDd')==null && localStorage.getItem('statusDd')==null
      && localStorage.getItem('priorityDd')==null && localStorage.getItem('createdByNamesDd')==null && localStorage.getItem('userNamesDd')==null && localStorage.getItem('projectsDd')==null && 
      localStorage.getItem('productsDd')==null && localStorage.getItem('taskTypes')==null && localStorage.getItem('taskNames')==null && localStorage.getItem('taskIds')==null){
        
        this.table.reset();
        this.cardTable.reset();
        this.dt22 = '';
        this.dt23 = '';
        this.dt24 = '';
        this.dt25 = '';
        this.dt26 = '';
        this.dt27 = '';
        this.dt28 = '';
        this.dt29 = '';
        this.dt30 = '';
        this.dt31 = '';
        this.dt32 = '';
        this.dt33 = '';
        this.dt34 = '';
        this.dt35 = '';
        this.dt36 = '';
        this.dt37 = '';
        this.dt38 = '';
        this.dt39 = ''; 
        this.dt40 = '';
        this.dt41 = '';
        this.dt42 = '';
        this.dt43 = '';
        this.dt44 = '';
        this.dt45 = '';
      }
    }
    
  }

  clicked(taskCount) {
    if (taskCount >= 999) {
      return true;
    }
    return false;
  }
 
  submitWatchlist(){
  this.insideWatchlist=true;
    const json = {
      "userId":this.userData.userId,
      "watchlists":this.watchlistChangeValue
  };
  this.TaskService.submitWatchlist(json)
      .subscribe(res => {
        if(res.result=='Success'){
          res.responseData.forEach(element => {
            const key = 'label';
            if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
              let d = { label: element.taskId, value: element.taskId };
              this.tableTaskIdData.push(d);
              this.tableTaskIdData.sort(this.keysrt('label'));
              this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskIdData.length; i++) {
                if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                  this.tableTaskIdData.splice(i, 1);
                }
              }
            }
            if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
              let d = { label: element.taskName, value: element.taskName };
              this.tableTaskNameData.push(d);
              this.tableTaskNameData.sort(this.keysrt('label'));
              this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskNameData.length; i++) {
                if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                  this.tableTaskNameData.splice(i, 1);
                }
              }
            }
            if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
              let d = { label: element.taskType, value: element.taskType };
              this.tableTaskTypeData.push(d);
              this.tableTaskTypeData.sort(this.keysrt('label'));
              this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskTypeData.length; i++) {
                if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                  this.tableTaskTypeData.splice(i, 1);
                }
              }
            }
            if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
              let d = { label: element.projectName, value: element.projectName };
              this.tableProjectList.push(d);
              this.tableProjectList.sort(this.keysrt('label'));
              this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProjectList.length; i++) {
                if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                  this.tableProjectList.splice(i, 1);
                }
              }
            }
            if (element.productName != null || element.productName != "" || element.productName != undefined) {
              let d = { label: element.productName, value: element.productName };
              this.tableProductList.push(d);
              this.tableProductList.sort(this.keysrt('label'));
              this.tableProductList = [...new Map(this.tableProductList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProductList.length; i++) {
                if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                  this.tableProductList.splice(i, 1);
                }
              }
            }
            if (element.userName != null || element.userName != "" || element.userName != undefined) {
              let d = { label: element.userName, value: element.userName };
              this.tableAssignToList.push(d);
              this.tableAssignToList.sort(this.keysrt('label'));
              this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableAssignToList.length; i++) {
                if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                  this.tableAssignToList.splice(i, 1);
                }
              }
            }
            if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
              let d = { label: element.createdByName, value: element.createdByName };
              this.tableCreatedByList.push(d);
              this.tableCreatedByList.sort(this.keysrt('label'));
              this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedByList.length; i++) {
                if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                  this.tableCreatedByList.splice(i, 1);
                }
              }
            }
            if (element.priority != null || element.priority != "" || element.priority != undefined) {
              let d = { label: element.priority, value: element.priority };
              this.tablePriorityList.push(d);
              this.tablePriorityList.sort(this.keysrt('label'));
              this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tablePriorityList.length; i++) {
                if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                  this.tablePriorityList.splice(i, 1);
                }
              }
            }
            if (element.status != null || element.status != "" || element.status != undefined) {
              let d = { label: element.status, value: element.status };
              this.tableStatusList.push(d);
              this.tableStatusList.sort(this.keysrt('label'));
              this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableStatusList.length; i++) {
                if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                  this.tableStatusList.splice(i, 1);
                }
              }
            }
  
            if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
              let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
              this.tableCreatedDate.push(d);
              this.tableCreatedDate.sort(this.keysrt('label'));
              this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedDate.length; i++) {
                if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                  this.tableCreatedDate.splice(i, 1);
                }
              }
              element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
            }
  
            if (element.dueDate != null) {
              let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
              this.tableDueDate.push(d);
              this.tableDueDate.sort(this.keysrt('label'));
              this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableDueDate.length; i++) {
                if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                  this.tableDueDate.splice(i, 1);
                }
              }
              element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
            }
  
            if (element.updatedDate != null) {
              let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
              this.tableUpdatedDate.push(d);
              this.tableUpdatedDate.sort(this.keysrt('label'));
              this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableUpdatedDate.length; i++) {
                if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                  this.tableUpdatedDate.splice(i, 1);
                }
              }
              element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
            }
          });
          this.tasks = res['responseData'];
        }
      },
        error => {
        });
  }

  submitWatchlistDefault(){
    this.dtWatchlist=[];
    this.watchlistImageNew = true;
    this.dtWatchlist = this.defaultWatchlistArray;
    for (var i=0;i<this.defaultWatchlistArray.length;i++){
       this.dtWatchlistJson.push(this.defaultWatchlistArray[i].watchlistName);
    }
    this.insideWatchlist=true;
    const json = {
      "userId": this.userData.userId,
      "watchlists": this.dtWatchlistJson
    };
    this.TaskService.submitWatchlist(json).subscribe(res => {
          res.responseData.forEach(element => {
            const key = 'label';
            if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
              let d = { label: element.taskId, value: element.taskId };
              this.tableTaskIdData.push(d);
              this.tableTaskIdData.sort(this.keysrt('label'));
              this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskIdData.length; i++) {
                if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                  this.tableTaskIdData.splice(i, 1);
                }
              }
            }

            if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
              let d = { label: element.taskName, value: element.taskName };
              this.tableTaskNameData.push(d);
              this.tableTaskNameData.sort(this.keysrt('label'));
              this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskNameData.length; i++) {
                if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                  this.tableTaskNameData.splice(i, 1);
                }
              }
            }

            if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
              let d = { label: element.taskType, value: element.taskType };
              this.tableTaskTypeData.push(d);
              this.tableTaskTypeData.sort(this.keysrt('label'));
              this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskTypeData.length; i++) {
                if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                  this.tableTaskTypeData.splice(i, 1);
                }
              }
            }

            if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
              let d = { label: element.projectName, value: element.projectName };
              this.tableProjectList.push(d);
              this.tableProjectList.sort(this.keysrt('label'));
              this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProjectList.length; i++) {
                if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                  this.tableProjectList.splice(i, 1);
                }
              }
            }

            if (element.productName != null || element.productName != "" || element.productName != undefined) {
              let d = { label: element.productName, value: element.productName };
              this.tableProductList.push(d);
              this.tableProductList.sort(this.keysrt('label'));
              this.tableProductList = [...new Map(this.tableProductList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProductList.length; i++) {
                if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                  this.tableProductList.splice(i, 1);
                }
              }
            }

            if (element.userName != null || element.userName != "" || element.userName != undefined) {
              let d = { label: element.userName, value: element.userName };
              this.tableAssignToList.push(d);
              this.tableAssignToList.sort(this.keysrt('label'));
              this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableAssignToList.length; i++) {
                if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                  this.tableAssignToList.splice(i, 1);
                }
              }
            }

            if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
              let d = { label: element.createdByName, value: element.createdByName };
              this.tableCreatedByList.push(d);
              this.tableCreatedByList.sort(this.keysrt('label'));
              this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedByList.length; i++) {
                if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                  this.tableCreatedByList.splice(i, 1);
                }
              }
            }

            if (element.priority != null || element.priority != "" || element.priority != undefined) {
              let d = { label: element.priority, value: element.priority };
              this.tablePriorityList.push(d);
              this.tablePriorityList.sort(this.keysrt('label'));
              this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tablePriorityList.length; i++) {
                if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                  this.tablePriorityList.splice(i, 1);
                }
              }
            }

            if (element.status != null || element.status != "" || element.status != undefined) {
              let d = { label: element.status, value: element.status };
              this.tableStatusList.push(d);
              this.tableStatusList.sort(this.keysrt('label'));
              this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableStatusList.length; i++) {
                if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                  this.tableStatusList.splice(i, 1);
                }
              }
            }

            if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
              let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
              this.tableCreatedDate.push(d);
              this.tableCreatedDate.sort(this.keysrt('label'));
              this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedDate.length; i++) {
                if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                  this.tableCreatedDate.splice(i, 1);
                }
              }
              element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
            }

            if (element.dueDate != null) {
              let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
              this.tableDueDate.push(d);
              this.tableDueDate.sort(this.keysrt('label'));
              this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableDueDate.length; i++) {
                if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                  this.tableDueDate.splice(i, 1);
                }
              }
              element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
            }

            if (element.updatedDate != null) {
              let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
              this.tableUpdatedDate.push(d);
              this.tableUpdatedDate.sort(this.keysrt('label'));
              this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableUpdatedDate.length; i++) {
                if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                  this.tableUpdatedDate.splice(i, 1);
                }
              }
              element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
            }


          });

          this.tasks = res['responseData'];
          //this.tasks = this.findCommentandFile(this.tasks);
        },
          error => {
    });
  }
  
  onChangeWathlist(event){
    this.watchlistChangeValue=[];
    this.watchlistChangeValue=[];
    if(event.value.length>0){
      for(var i=0;i<event.value.length;i++){
        this.watchlistChangeValue.push( event.value[i].watchlistName);
      }
    }
    if (this.watchlistChangeValue.length > 0) {
      this.addWatchlistDropdown = false;
      this.changeColorDrops = "true";
      this.watchlistImageNew = true;
    } else {
      this.addWatchlistDropdown = true;
      this.changeColorDrops = "false";
      this.watchlistImageNew = false;
    }
  }

  addWatchlist(value) {
    this.ngxLoader.start();
    if(value.default == "" || value.default == null){
      this.defaultData=false;
    }else{
      this.defaultData=value.default;
    }
    const json = {
        "watchlistName":value.watchlistName,
        "defaultWatchlist":this.defaultData,
        "createdById": this.userData.userId,
        "createdByName":this.userData.userName
    };

    this.TaskService.addWatchlist(json)
      .subscribe(res => {
        this.ngxLoader.stop();
        this.watchlistForm.reset();
        this.dtWatchlist= '';
        this.watchlistChangeValue=[];
        this.displayWatchlist = false;
        this.addWatchlistDropdown = true;
        this.watchlistImageNew = false;
        this.getWatchlist();
      },
        error => {
          this.ngxLoader.stop();
        });
  }
  
  
  onTaskClick(taskId) {
    localStorage.setItem("selectedTask", taskId);
    this.isStatusSelected = false;
    this.isAssigneeUpdated = false;
    this.dateUpdated = false;
    this.isTaskUpdated = false;
    this.selectedTaskId = taskId;
    localStorage.setItem("selectedTask", this.selectedTaskId);
    this.viewSidebar = true;
    this.comment = false;
    let reqjson = {
      "taskId": taskId
    }
    this.TaskService.getTaskDetailById(reqjson)
      .subscribe(res => {
        this.taskDataForView = res.responseData;
        if (this.taskDataForView.attachments != null && this.taskDataForView.attachments[0] != null) {
          const data = this.taskDataForView.attachments[0]['fileData'];
          let blob: any = new Blob([data], { type: this.taskDataForView.attachments[0]['contentType'] });

          this.fileUrl = this.domSanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
        }
      })
  }

  // getUser() {
  //   this.TaskService.getUserList()
  //     .subscribe(res => {
  //       this.UserList = [];
  //       let temp1: any = {
  //         "name": "Select User",
  //         "code": "Null"
  //       }
  //       this.UserList.push(temp1);
  //       let temp2: any = {
  //         "name": this.userData.userName,
  //         "code": this.userData.userId
  //       }
  //       this.UserList.push(temp2);
  //       res.responseData.forEach(ele => {
  //         let temp: any = {
  //           "name": ele.userName ? ele.userName : 'Null',
  //           "code": ele.userId
  //         };
  //         this.UserList.push(temp);
  //         const key = 'name';
  //         this.UserList = [...new Map(this.UserList.map(item =>
  //           [item[key], item])).values()];
  //       });
  //       localStorage.setItem("userList", JSON.stringify(this.UserList));
  //     },
  //       error => {
  //       });
  // }

  getUserGroupList() {
    this.TaskService.getUserGroupList()
      .subscribe(res => {
        this.UserGroupList = [];
        let temp1: any = {
          "name": "Select User",
          "code": "Null"
        }
        this.UserGroupList.push(temp1);
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.userGroupName,
            "code": ele.userGroupId ? ele.userGroupId : 'Null'
          };
          this.UserGroupList.push(temp);
        });
        localStorage.setItem("userGroupList", JSON.stringify(this.UserGroupList));
      },
        error => {
        });
  }

  refresh(): void {
    window.location.reload();
  }

  getProduct() {
    this.TaskService.getAllProductList()
      .subscribe(res => {
        this.product = [];
        let temp1: any = {
          "name": "Select Product",
          "code": "Null"
        }
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.productName,
            "code": ele.productId
          };
          this.product.push(temp);
        });
        this.product = this.sortData(this.product);
        this.product.splice(0, 0, temp1);
        localStorage.setItem("productList", JSON.stringify(this.product));
      },
        error => {
        });
  }

  sortData(arrayList: Array<any | string>): Array<any | string> {
    return arrayList.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  openWatchlist(){
    this.displayWatchlist=true
  }

  openWatchlistDefault(event,multiselect,watchlistC){
    this.watchlistChangeValue=[];
   this.multiselectWa=multiselect;
    for(var i=0; i<this.idWatchlistNew.length;i++){
      if(this.idWatchlistNew[i].name == event){
      this.newIdForwatchlistDefault=this.idWatchlistNew[i].id;
      }
    }
    this.watchlistFormDefault.patchValue({
      watchlistName: event,
      default:watchlistC.value.defaultWatchlist
    })
    this.dtWatchlist= '';
    this.displayWatchlistDefault=true;
  }

  closeWatchlistDefault(){
    this.watchlistFormDefault.reset();
    this.dtWatchlist= '';
    this.addWatchlistDropdown = true;
    this.watchlistImageNew = false;
    this.watchlistChangeValue=[];
    this.displayWatchlistDefault=false;
  }

  checkIfNameExist(value){
      let json = {
        "createdById":this.userData.userId,
        "watchlistName":value    
        }
        this.TaskService.checkWatchlsit(json).subscribe(res => {
        if (res.result == "Success") {
         this.watchlistAlready = true;
         this.submitted = false;
        }
        else {
          this.watchlistAlready = false;
          this.submitted = false;
        }
      },
      error => {
      });
    }

  setAsDefault(value){
    this.ngxLoader.start();
    if(value.default == "" || value.default == null){
      this.defaultData=false;
    }else{
      this.defaultData=value.default;
    }
      const json = {
        "createdById":this.userData.userId,
        "_id":this.newIdForwatchlistDefault,
        "defaultWatchlist":this.defaultData
    };
  
    this.TaskService.setAsDefault(json).subscribe(res => {
            this.watchlistDropdown = [];
            let List: any = res['responseData'];
            this.defaultWatchlistArray=[];
            this.watchlistDropdown.sort(this.keysrt('name'));
            res['responseData'].forEach(ele => {
              let temp2 = {
                label: ele.watchlistName,
                value: ele.watchlistName
              }
              if(ele.defaultWatchlist){
                this.defaultWatchlistArray.push(ele.watchlistName);
              }
              this.watchlistDropdown.push(temp2);
            });
            localStorage.setItem("watchlistDropdown", JSON.stringify(this.watchlistDropdown));
            this.displayWatchlistDefault=false
            this.dtWatchlist= '';
            this.watchlistChangeValue=[];
            this.addWatchlistDropdown = true;
            this.watchlistImageNew = false;
            this.getWatchlist();
            this.ngxLoader.stop();
        },
        error => {
          this.displayWatchlistDefault=false
          this.ngxLoader.stop();
        });
  }

  deleteWatchlist(value){
    this.ngxLoader.start();
      const json = {
        "createdById":this.userData.userId,
        "_id":this.newIdForwatchlistDefault,
    };
    this.TaskService.deleteDefault(json).subscribe(res => {
          this.watchlistFormDefault.reset();
          this.dtWatchlist='';
          this.addWatchlistDropdown = true;
          this.watchlistImageNew = false;
            this.displayWatchlistDefault=false
            this.getWatchlist();
            this.ngxLoader.stop();
        },
        error => {
          this.displayWatchlistDefault=false
          this.ngxLoader.stop();
        });
  }

  closeWatchlist(){
    this.watchlistForm.reset();
    this.watchlistAlready = false;
    this.displayWatchlist=false
  }

  getProject() {
    this.TaskService.getAllProjectList()
      .subscribe(res => {
        this.projects = [];
        let temp1: any = {
          "name": "Select project",
          "code": "Null"
        }
        res.responseData.forEach(ele => {
          let temp: any = {
            "name": ele.projectName,
            "code": ele.projectId
          };
          this.projects.push(temp);
        });
        this.projects = this.sortData(this.projects);
        this.projects.splice(0, 0, temp1);
        localStorage.setItem("projectList", JSON.stringify(this.projects));
      },
        error => {
         // this.ngxLoader.stop();
        });
  }

  keysrt(key) {
    return function(a,b){
     if (a[key] > b[key]) return 1;
     if (a[key] < b[key]) return -1;
     return 0;
    }
  }

  getDropdownsList(req,pageNumber,noOfRows) {
    this.TaskService.getDashboardTaskDataByPagination(req,pageNumber,noOfRows)
      .subscribe(res => {
        if(res.result=="Success"){
          this.tableTaskIdData = [];
          this.tableTaskNameData = [];
          this.tableTaskTypeData = [];
          this.tableProjectList = [];
          this.tableProductList = [];
          this.tableAssignToList = [];
          this.tableCreatedByList = [];
          this.tableStatusList = [];
          this.tableCreatedDate = [];
          this.tableDueDate = [];
          this.tableUpdatedDate = [];
          this.tablePriorityList = [];
          this.filterApplied = [];
         /*  this.cols.forEach(
            el => {
              this.filterApplied.push(false);
            }
          ) */
          this.tasks = res['responseData']['content'];
          this.totalRecords=res['responseData']['totalElements'];
        
          setTimeout(()=>{
            if(this.tasks){
              this.ngxLoader.stop();
            }
          },500)
          res.responseData['content'].forEach(element => {
            const key = 'label';
            if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
              let d = { label: element.taskId, value: element.taskId };
              this.tableTaskIdData.push(d);
              this.tableTaskIdData.sort(this.keysrt('label'));
              this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskIdData.length; i++) {
                if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                  this.tableTaskIdData.splice(i, 1);
                }
              }
            }
  
            if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
              let d = { label: element.taskName, value: element.taskName };
              this.tableTaskNameData.push(d);
              this.tableTaskNameData.sort(this.keysrt('label'));
              this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskNameData.length; i++) {
                if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                  this.tableTaskNameData.splice(i, 1);
                }
              }
            }
  
            if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
              let d = { label: element.taskType, value: element.taskType };
              this.tableTaskTypeData.push(d);
              this.tableTaskTypeData.sort(this.keysrt('label'));
              this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskTypeData.length; i++) {
                if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                  this.tableTaskTypeData.splice(i, 1);
                }
              }
            }
  
            if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
              let d = { label: element.projectName, value: element.projectName };
              this.tableProjectList.push(d);
              this.tableProjectList.sort(this.keysrt('label'));
              this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProjectList.length; i++) {
                if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                  this.tableProjectList.splice(i, 1);
                }
              }
            }
  
            if (element.productName != null || element.productName != "" || element.productName != undefined) {
              let d = { label: element.productName, value: element.productName };
              this.tableProductList.push(d);
              this.tableProductList.sort(this.keysrt('label'));
              this.tableProductList = [...new Map(this.tableProductList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProductList.length; i++) {
                if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                  this.tableProductList.splice(i, 1);
                }
              }
            }
  
            if (element.userName != null || element.userName != "" || element.userName != undefined) {
              let d = { label: element.userName, value: element.userName };
              this.tableAssignToList.push(d);
              this.tableAssignToList.sort(this.keysrt('label'));
              this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableAssignToList.length; i++) {
                if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                  this.tableAssignToList.splice(i, 1);
                }
              }
            }
  
            if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
              let d = { label: element.createdByName, value: element.createdByName };
              this.tableCreatedByList.push(d);
              this.tableCreatedByList.sort(this.keysrt('label'));
              this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedByList.length; i++) {
                if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                  this.tableCreatedByList.splice(i, 1);
                }
              }
            }
  
            if (element.priority != null || element.priority != "" || element.priority != undefined) {
              let d = { label: element.priority, value: element.priority };
              this.tablePriorityList.push(d);
              this.tablePriorityList.sort(this.keysrt('label'));
              this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tablePriorityList.length; i++) {
                if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                  this.tablePriorityList.splice(i, 1);
                }
              }
            }
  
            if (element.status != null || element.status != "" || element.status != undefined) {
              let d = { label: element.status, value: element.status };
              this.tableStatusList.push(d);
              this.tableStatusList.sort(this.keysrt('label'));
              this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableStatusList.length; i++) {
                if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                  this.tableStatusList.splice(i, 1);
                }
              }
            }
  
            if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
              let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
              this.tableCreatedDate.push(d);
              this.tableCreatedDate.sort(this.keysrt('label'));
              this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedDate.length; i++) {
                if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                  this.tableCreatedDate.splice(i, 1);
                }
              }
              element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
            }
  
            if (element.dueDate != null) {
              let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
              this.tableDueDate.push(d);
              this.tableDueDate.sort(this.keysrt('label'));
              this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableDueDate.length; i++) {
                if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                  this.tableDueDate.splice(i, 1);
                }
              }
              element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
            }
  
            if (element.updatedDate != null) {
              let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
              this.tableUpdatedDate.push(d);
              this.tableUpdatedDate.sort(this.keysrt('label'));
              this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableUpdatedDate.length; i++) {
                if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                  this.tableUpdatedDate.splice(i, 1);
                }
              }
              element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
            }
  
  
          });
        }
        else{
          //this.ngxLoader.stop();
        }
      },
        error => {
        });
  }

  getDashboardData() {
    this.advanced_Search_Div=false;
    // this.ngxLoader.start();
    let dashjson = {
      "userId": this.userData.userId,
      "action": "My Active Task"
    }
        if(localStorage.getItem('selectedBubble')==null){
          this.selectedTab='My Active Task';
          this.newreq = {
            "userId": this.userData.userId,
            "status": "Assigned",
            "action": "My Active Task"
          }
          this.myAllTasks = false;
          this.assignedTasks = true;
          this.createdTasks = false;
          this.overdueTasks = false;
          this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
         // this.getDashboardDataExport(this.newreq);
          let req11 = {
            "userId": this.userData.userId,
            "basedOn": "Priority",
            "action": "Task Active"
          }
          this.getTaskPriorityWiseCount(req11);
        }
        else{
          this.selectedTab=localStorage.getItem('selectedBubble');
          switch (this.selectedTab) {
            case 'My Active Task':
              let req1={
                "userId": this.userData.userId,
                "status": "Assigned",
                "action": "My Active Task"
              }
              this.myAllTasks = false;
              this.assignedTasks = true;
              this.createdTasks = false;
              this.overdueTasks = false;
              this.getDashboardDataNew(req1,this.pageNumber, this.noOfRows);
            //  this.getDashboardDataExport(req1);
              let req111 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Task Active"
              }
              this.getTaskPriorityWiseCount(req111);
              break;
            case 'Team Active Task':
              let req2 = {
                "userId": this.userData.userId,
                "status": "Assigned",
                "action": "Team Active Task"
              }
              this.myAllTasks = false;
              this.assignedTasks = true;
              this.createdTasks = false;
              this.overdueTasks = false;
              this.getDashboardDataNew(req2,this.pageNumber, this.noOfRows);
             // this.getDashboardDataExport(req2);
              let req22 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Team Active"
              }
              this.getTaskPriorityWiseCount(req22);
              break;
            case 'My Created Task':
              let req3 = {
                "userId": this.userData.userId,
                "action": "My Created Task",
                "createdById": this.userData.userId
              }
              this.myAllTasks = false;
              this.createdTasks = true;
              this.assignedTasks = false;
              this.overdueTasks = false;
              this.getDashboardDataNew(req3,this.pageNumber, this.noOfRows);
             // this.getDashboardDataExport(req3);
              let req33 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Task Created",
                "createdById": this.userData.userId
              }
              this.getTaskPriorityWiseCount(req33);
              break;
            case 'Team Created Task':
              let req4 = {
                "userId": this.userData.userId,
                "action": "Team Created Task",
                "createdById": this.userData.userId
              }
              this.myAllTasks = false;
              this.createdTasks = true;
              this.assignedTasks = false;
              this.overdueTasks = false;
              this.getDashboardDataNew(req4,this.pageNumber, this.noOfRows);
            //  this.getDashboardDataExport(req4);
              let req44 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Team Created",
                "createdById": this.userData.userId
              }
              this.getTaskPriorityWiseCount(req44);
              break;
            case 'My Overdue Task':
              let req7 = {
                "userId": this.userData.userId,
                "action": "My Overdue Task"
              }
              this.myAllTasks = false;
              this.overdueTasks = true;
              this.assignedTasks = false;
              this.createdTasks = false;
              this.getDashboardDataNew(req7,this.pageNumber, this.noOfRows);
            //  this.getDashboardDataExport(req7);
              let req77 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Task Overdue"
              }
              this.getTaskPriorityWiseCount(req77);
              break;
            case 'Team Overdue Task':
              let req5 = {
                "userId": this.userData.userId,
                "action": "Team Overdue Task"
              }
              this.myAllTasks = false;
              this.overdueTasks = true;
              this.assignedTasks = false;
              this.createdTasks = false;
              this.getDashboardDataNew(req5,this.pageNumber, this.noOfRows);
             // this.getDashboardDataExport(req5);
              let req55 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Team Overdue"
              }
              this.getTaskPriorityWiseCount(req55);
              break;
            case 'My At Risk Task':
              let req8 = {
                "userId": this.userData.userId,
                "status": "At Risk",
                "action": "My At Risk Task"
              }
              this.atriskTasks = true;
              this.allTasks = false;
              this.myAllTasks = true;
              this.getDashboardDataNew(req8,this.pageNumber, this.noOfRows);
            //  this.getDashboardDataExport(req8);
              let req88 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Task At Risk"
              }
              this.getTaskStatusWiseCount(req88);
              break;
            case 'Team At Risk Task':
              let req6 = {
                "userId": this.userData.userId,
                "status": "At Risk",
                "action": "Team At Risk Task"
              }
              this.myAllTasks = true;
              this.atriskTasks = true;
              this.allTasks = false;
              this.getDashboardDataNew(req6,this.pageNumber, this.noOfRows);
             // this.getDashboardDataExport(req6);
              let req66 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Team At Risk"
              }
              this.getTaskStatusWiseCount(req66);
              break;
            case 'My All Task':
              let req9 = {
                "userId": this.userData.userId,
                "action": "My All Task"
              }
              this.myAllTasks = true;
              this.atriskTasks = false;
              this.allTasks = true;
              this.getDashboardDataNew(req9,this.pageNumber, this.noOfRows);
          //    this.getDashboardDataExport(req9);
              let req99 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "All Task"
              }
              this.getTaskStatusWiseCount(req99);
              break;
            case 'Team All Task':
              let req10 = {
                "userId": this.userData.userId,
                "action": "Team All Task"
              }
              this.myAllTasks = true;
              this.atriskTasks = false;
              this.allTasks = true;
              this.getDashboardDataNew(req10,this.pageNumber, this.noOfRows);
           //   this.getDashboardDataExport(req10);
              let req100 = {
                "userId": this.userData.userId,
                "basedOn": "Priority",
                "action": "Team All Task"
              }
              this.getTaskStatusWiseCount(req100);
              break;
            default:
              break;
          }
        }
        
        if(this.dontshowagain==false){
          this.getProductTourImages();
        }
  }

  showTakeaTourLogin(){
  if(localStorage.getItem('isLogin')=='true' && localStorage.getItem('isImages')=='true'){
    this.tourImages=JSON.parse(localStorage.getItem('productTourImages'));
   // this.ngxLoader.start();
    setTimeout(()=>{
      //this.ngxLoader.stop();
    },2000)
    this.displayTakeTour=true;
  }
  localStorage.removeItem('isLogin');
  localStorage.removeItem('isImages');
  }

  getProductTourImages(){
    let req;
    let data:any=JSON.parse(localStorage.getItem('userData'));
    let isadmin=data.admin
    if(isadmin==true){
     req='admin';
    }
    else{
      req='user';
    }
     this.TaskService.getProductTourImagesV1(req).subscribe(response=>{
      let imageUrl:any;
      imageUrl=response.responseData.url;
      let Imagelist:any=[];
      Imagelist=response.responseData.product;
      this.tourImages=[];
       for(let i=0;i<Imagelist.length;i++){
         this.tourImages[i]=imageUrl+Imagelist[i].fileName;
       }
       this.tourImages=[...this.tourImages];
       localStorage.setItem('productTourImages',JSON.stringify(this.tourImages));
       localStorage.setItem('isImages','true')
       this.showTakeaTourLogin();
     })
   }

  //  getDashboardDataExport(req) {
    
  //   //this.ngxLoader.start();
  //   this.lazyLoad = false;
  //   this.searchtext="";
  //   this.TaskService.getDashboardTaskData(req).subscribe(res => {

  //       if(res.result=='Success'){
  //         this.tasksExport = res['responseData'];
  //         console.log("ExportData---",this.tasksExport);

        
  //       }
  //     });
  //   }
  

    getExcelFromApi(data){
      if(data=='advanceExport'){
        this.advanceExportData=true;
      }else{
        this.advanceExportData=false;
      }
      this.isExportData=true;
      this.getTemplateList();
    }

onClickImg(data){
 this.imgSelected=data;
 this.selectedValue=this.selectedValue;
 this.templateListSelected=null;
 if(this.imgSelected=='plainExport'){
  this.selectedDropdown=true;
  this.displayDropdown=false;
 }else{
 this.selectedDropdown=false;
 this.displayDropdown=true;
 }
}
dashboardExportExcelDownload(){
  if(this.imgSelected=='plainExport'){
 
    this.ngxLoader.start();

    switch (this.selectedTab) {
      case 'My Active Task':

        let req1 = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "My Active Task"
        }
        this.TaskService.getExcelData(req1)
          .subscribe(res => {
            console.log(res);
            this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
            saveAs(res, 'taskReport.xlsx');
            this.isExportData=false;
            this.selectedValue='val1';
            this.displayDropdown=true;
            this.advanceExportData=false;
            this.ngxLoader.stop();
    
          });
       
        break;
      case 'Team Active Task':
        let req2 = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "Team Active Task"
        }
        this.TaskService.getExcelData(req2)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'My Created Task':
        let req3 = {
          "userId": this.userData.userId,
          "action": "My Created Task",
          "createdById": this.userData.userId
        }
        this.TaskService.getExcelData(req3)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'Team Created Task':
        let req4 = {
          "userId": this.userData.userId,
          "action": "Team Created Task",
          "createdById": this.userData.userId
        }
        this.TaskService.getExcelData(req4)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'My Overdue Task':
        let req5 = {
          "userId": this.userData.userId,
          "action": "My Overdue Task"
        }
        this.TaskService.getExcelData(req5)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'Team Overdue Task':
        let req6 = {
          "userId": this.userData.userId,
          "action": "Team Overdue Task"
        }
        this.TaskService.getExcelData(req6)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'My At Risk Task':
        let req7 = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "My At Risk Task"
        }
        this.TaskService.getExcelData(req7)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'Team At Risk Task':
        let req8 = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "Team At Risk Task"
        }
        this.TaskService.getExcelData(req8)
          .subscribe(res => {
            console.log(res);
            this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
            saveAs(res, 'taskReport.xlsx');
            this.isExportData=false;
            this.selectedValue='val1';
            this.displayDropdown=true;
            this.advanceExportData=false;
            this.ngxLoader.stop();
    
          });
        break;
      case 'My All Task':
        let req9 = {
          "userId": this.userData.userId,
          "action": "My All Task"
        }
        this.TaskService.getExcelData(req9)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
        break;
      case 'Team All Task':
        let req10 = {
          "userId": this.userData.userId,
          "action": "Team All Task"
        }
        this.TaskService.getExcelData(req10)
        .subscribe(res => {
          console.log(res);
          this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
          saveAs(res, 'taskReport.xlsx');
          this.isExportData=false;
          this.selectedValue='val1';
          this.displayDropdown=true;
          this.advanceExportData=false;
          this.ngxLoader.stop();
  
        });
     
        break;
      default:
        break;
    }
  }
 else{
// alert("additionalExport");
this.ngxLoader.start();

switch (this.selectedTab) {
  case 'My Active Task':

    let req1 = {
      "userId": this.userData.userId,
      "status": "Assigned",
      "action": "My Active Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req1)
      .subscribe(res => {
        console.log(res);
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
        saveAs(res, 'taskReport.xlsx');
        this.isExportData=false;
        this.advanceExportData=false;
        this.selectedValue='val1';
        this.displayDropdown=true;
        this.templateListSelected=[];
        this.selectedDropdown=false;
        this.ngxLoader.stop();

      });
   
    break;
  case 'Team Active Task':
    let req2 = {
      "userId": this.userData.userId,
      "status": "Assigned",
      "action": "Team Active Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req2)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'My Created Task':
    let req3 = {
      "userId": this.userData.userId,
      "action": "My Created Task",
      "createdById": this.userData.userId,
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req3)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.ngxLoader.stop();

    });
    break;
  case 'Team Created Task':
    let req4 = {
      "userId": this.userData.userId,
      "action": "Team Created Task",
      "createdById": this.userData.userId,
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req4)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'My Overdue Task':
    let req5 = {
      "userId": this.userData.userId,
      "action": "My Overdue Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req5)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'Team Overdue Task':
    let req6 = {
      "userId": this.userData.userId,
      "action": "Team Overdue Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req6)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'My At Risk Task':
    let req7 = {
      "userId": this.userData.userId,
      "status": "At Risk",
      "action": "My At Risk Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req7)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'Team At Risk Task':
    let req8 = {
      "userId": this.userData.userId,
      "status": "At Risk",
      "action": "Team At Risk Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req8)
      .subscribe(res => {
        console.log(res);
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
        saveAs(res, 'taskReport.xlsx');
        this.isExportData=false;
        this.advanceExportData=false;
        this.templateListSelected=[];
        this.selectedDropdown=false;
        this.selectedValue='val1';
        this.displayDropdown=true;
        this.ngxLoader.stop();

      });
    break;
  case 'My All Task':
    let req9 = {
      "userId": this.userData.userId,
      "action": "My All Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req9)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
    break;
  case 'Team All Task':
    let req10 = {
      "userId": this.userData.userId,
      "action": "Team All Task",
      "selectedTemplateList":this.selectedTaskTypeArr
    }
    this.TaskService.getExcelDynamicData(req10)
    .subscribe(res => {
      console.log(res);
      this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
      saveAs(res, 'taskReport.xlsx');
      this.isExportData=false;
      this.advanceExportData=false;
      this.templateListSelected=[];
      this.selectedDropdown=false;
      this.selectedValue='val1';
      this.displayDropdown=true;
      this.ngxLoader.stop();

    });
 
    break;
  default:
    break;
}
 }
}


advanceExportExcelDownload(){
  if(this.imgSelected=='plainExport'){
   delete this.advanceSearchArray['selectedTemplateList'];
    this.ngxLoader.start();
    console.log("advanceSearchArray***",this.advanceSearchArray);
    this.TaskService.getAdvanceExcelData(this.advanceSearchArray)
      .subscribe(res => {
        console.log(res);
        this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
        saveAs(res, 'taskReport.xlsx');
        this.isExportData=false;
        this.selectedValue='val1';
        this.displayDropdown=true;
        this.ngxLoader.stop();
  
      });
  }
 else{
// alert("additionalExport");
this.ngxLoader.start();
console.log("advanceSearchArray***",this.advanceSearchArray);

this.advanceSearchArray['selectedTemplateList']=this.selectedTaskTypeArr;
console.log("advanceSearchArray after dropdown***",this.advanceSearchArray);

this.TaskService.getDynamicAdvanceExcelData(this.advanceSearchArray)
  .subscribe(res => {
    console.log(res);
    this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
    saveAs(res, 'taskReport.xlsx');
    this.isExportData=false;
    this.selectedValue='val1';
    this.displayDropdown=true;
    this.selectedDropdown=false;
    this.templateListSelected = [];
    this.ngxLoader.stop();

  });

 }
}
ExportCancel(){
  this.selectedValue='val1';
  this.displayDropdown=true;
  this.isExportData=false;
  this.templateListSelected=[];
 this.selectedDropdown=false;
}
getTemplateList() {
  let businessId = localStorage.getItem("businessId");
  // this.ngxLoader.start();
  this.TaskService.getTemplateListByBusinessIdV1(businessId).subscribe(
    (res) => {
      this.templateList = [];
      if (res["status"] == "success") {
        
       
          res["responseData"]["templateDetails"].forEach((ele) => {
            const temp: any = {
              name: ele.templateName,
              code: ele.templateId,
            };
            this.templateList.push(temp);
            console.log("this.templateList======", this.templateList);
          });
          
      }
    }),
    (error) => {
      this.ngxLoader.stop();
    }
}

onChangeTaskType(event) {


this.selectedDropdown=true;
  let temp: any[] = [];
  event.value.forEach(element => {
    temp.push(element.name);
    this.selectedTaskTypeArr = temp;

  });
  console.log("sss***",this.selectedTaskTypeArr);
console.log("dd***",this.templateListSelected)
  

}
  getDashboardDataNew(req,pageNumber,noOfRows) {
    
    //this.ngxLoader.start();
    this.lazyLoad = false;
    this.searchtext="";
    this.TaskService.getDashboardTaskDataByPagination(req,pageNumber,noOfRows).subscribe(res => {

        if(res.result=='Success'){
         
         // this.ngxLoader.start();
         
          res.responseData['content'].forEach(element => {
            const key = 'label';
            if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
              let d = { label: element.taskId, value: element.taskId };
              this.tableTaskIdData.push(d);
              this.tableTaskIdData.sort(this.keysrt('label'));
              this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskIdData.length; i++) {
                if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                  this.tableTaskIdData.splice(i, 1);
                }
              }
            }
  
            if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
              let d = { label: element.taskName, value: element.taskName };
              this.tableTaskNameData.push(d);
              this.tableTaskNameData.sort(this.keysrt('label'));
              this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskNameData.length; i++) {
                if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                  this.tableTaskNameData.splice(i, 1);
                }
              }
            }
  
            if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
              let d = { label: element.taskType, value: element.taskType };
              this.tableTaskTypeData.push(d);
              this.tableTaskTypeData.sort(this.keysrt('label'));
              this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskTypeData.length; i++) {
                if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                  this.tableTaskTypeData.splice(i, 1);
                }
              }
            }
  
            if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
              let d = { label: element.projectName, value: element.projectName };
              this.tableProjectList.push(d);
              this.tableProjectList.sort(this.keysrt('label'));
              this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProjectList.length; i++) {
                if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                  this.tableProjectList.splice(i, 1);
                }
              }
            }
  
            if (element.productName != null || element.productName != "" || element.productName != undefined) {
              let d = { label: element.productName, value: element.productName };
              this.tableProductList.push(d);
              this.tableProductList.sort(this.keysrt('label'));
              this.tableProductList = [...new Map(this.tableProductList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProductList.length; i++) {
                if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                  this.tableProductList.splice(i, 1);
                }
              }
            }
  
            if (element.userName != null || element.userName != "" || element.userName != undefined) {
              let d = { label: element.userName, value: element.userName };
              this.tableAssignToList.push(d);
              this.tableAssignToList.sort(this.keysrt('label'));
              this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableAssignToList.length; i++) {
                if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                  this.tableAssignToList.splice(i, 1);
                }
              }
            }
  
            if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
              let d = { label: element.createdByName, value: element.createdByName };
              this.tableCreatedByList.push(d);
              this.tableCreatedByList.sort(this.keysrt('label'));
              this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedByList.length; i++) {
                if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                  this.tableCreatedByList.splice(i, 1);
                }
              }
            }
  
            if (element.priority != null || element.priority != "" || element.priority != undefined) {
              let d = { label: element.priority, value: element.priority };
              this.tablePriorityList.push(d);
              this.tablePriorityList.sort(this.keysrt('label'));
              this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tablePriorityList.length; i++) {
                if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                  this.tablePriorityList.splice(i, 1);
                }
              }
            }
  
            if (element.status != null || element.status != "" || element.status != undefined) {
              let d = { label: element.status, value: element.status };
              this.tableStatusList.push(d);
              this.tableStatusList.sort(this.keysrt('label'));
              this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableStatusList.length; i++) {
                if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                  this.tableStatusList.splice(i, 1);
                }
              }
            }
  
            if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
              let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
              this.tableCreatedDate.push(d);
              this.tableCreatedDate.sort(this.keysrt('label'));
              this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedDate.length; i++) {
                if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                  this.tableCreatedDate.splice(i, 1);
                }
              }
              element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
            }
  
            if (element.dueDate != null) {
              let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
              this.tableDueDate.push(d);
              this.tableDueDate.sort(this.keysrt('label'));
              this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableDueDate.length; i++) {
                if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                  this.tableDueDate.splice(i, 1);
                }
              }
              element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
            }
  
            if (element.updatedDate != null) {
              let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
              this.tableUpdatedDate.push(d);
              this.tableUpdatedDate.sort(this.keysrt('label'));
              this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableUpdatedDate.length; i++) {
                if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                  this.tableUpdatedDate.splice(i, 1);
                }
              }
              element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
            }
  
  
          });
         
          this.tasks = res['responseData']['content'];
          this.totalRecords=res['responseData']['totalElements'];
        
          setTimeout(()=>{
            if(this.tasks){
              if(this.tasks.length==0){
                this.isTasksNotPresent=true;
              }
              else{
                this.isTasksNotPresent=false;
              }
            this.ngxLoader.stop();
            }
          },500);
         
          if(localStorage.getItem('taskId')){
            this.openViewTaskForm(localStorage.getItem('taskId'))
          }
        }
        else{
          this.tasks=res.responseData['content'];
          this.totalRecords=res['responseData']['totalElements'];
         
          if(this.tasks){
            if(this.tasks.length==0){
              this.isTasksNotPresent=true;
            }
            else{
              this.isTasksNotPresent=false;
            }
          }
          this.ngxLoader.stop();
        }
      },
        error => {
        });
  }

  findCommentandFile(data) {
    data.forEach(
      x => {
        let reqjson = {
          "taskId": x.taskId
        }
        let taskNewData = "";
        this.TaskService.getTaskDetailById(reqjson).subscribe(
          res => {
            taskNewData = res.responseData;
            x.comment = taskNewData["comments"];
            x.attachment = taskNewData["attachments"]
          }
        );
      }
    );
    return data;
  }

  getTaskCount() {
    let json = {
      "userId": this.userData.userId,
      "userName": this.userData.userName
    }
    this.TaskService.getMyCount(json).subscribe(response=>{
      if(response.result=='Success'){
        this.myCount=response['responseData'];
      }
      
    },error=>{

    });
    this.TaskService.getTeamCount(json).subscribe(response=>{
     if(response.result=='Success'){
      this.teamCount=response['responseData'];
     }
    },error=>{

    });
   /*  this.TaskService.getTaskCount(json)
      .subscribe(res => {
        this.taskCount = res['responseData'];
      },
        error => {
        }); */
  }

  getMyTaskCount(){
    let json = {
      "userId": this.userData.userId,
      "userName": this.userData.userName
    }
    this.TaskService.getMyCount(json).subscribe(response=>{
      this.myCount=response['responseData'];
    },error=>{

    });
  }

  getTeamTaskCount(){
    let json = {
      "userId": this.userData.userId,
      "userName": this.userData.userName
    }

    this.TaskService.getTeamCount(json).subscribe(response=>{
      this.teamCount=response['responseData'];
    },error=>{

    });
  }

  convertDate(str) {
    if (str != null && str != undefined) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
  }

  drawGraphByStatus(data) {
    this.myAllTasks = true;
    this.colors = [];
    if (this.atriskTasks == true && this.allTasks == false) {
      this.colors = ['#DC3838', '#7B3939', '#CD9393', '#E2D5D4'];
      this.text = "At Risk | by Priority"
    }
    if (this.allTasks == true && this.atriskTasks == false) {
      this.colors=['#66CCFF','#0099CC','#3895B2','#B7B7FF'];
      this.text = this.labelAllTask1+" | "+this.label2
    }

    Highcharts.setOptions({
      colors: this.colors
    });

    this.chartOptions2 = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        // Edit chart spacing
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        // Explicitly tell the width and height of a chart
        width: null,
        height: null
      },
      colors: this.colors,
      title: {
        text: this.text,
        floating: true,
        align: 'center',
        fontSize: '20px',
        x: 10,
        y: 380
      },
      tooltip: {
        headerFormat: '<span style="font-size: 20px">{point.key}:</span><br/>',
        pointFormat: '<b>{point.y}</b>',
        shadow: true,
        useHTML: false,
        style: {
          padding: 100,
          fontSize: '30px'
        }
      },
      accessibility: {
        announceNewData: {
          enabled: true
        },
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          color: '#000000',
          size: 250,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}',
            style: {
              fontSize: '18px',
              fontWeight: 'normal'
            },
            distance: 10,
            size: '180%',
          },
          showInLegend: false
        }
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        type: 'pie',
        data: data
      }],
      lang: {
        noData: 'No Data Available'
      },
      legend: {
        align: 'right',
        verticalAlign: 'bottom',
        floating: true,
        itemStyle: {
          color: '#000000',
          fontWeight: 'normal',
          fontSize: '18px',
        }
      }
    };
  }

  drawCreatedGraphByPriority(priorityCountList) {
    Highcharts.setOptions({
      colors: ['yellow', 'yellow', 'yellow', 'yellow']
    });
    this.chartOptions1 = {
      // bar chart starts
      chart: {
        type: 'bar',
        height: 400
      },
      title: {

        text: this.label1+' | '+this.label2
      },
      xAxis: {
        categories: ['Critical', 'High', 'Medium', 'Low']
      },
      yAxis: {
        allowDecimals: false,
        gridLineWidth: 0,
        min: 0,
        title: {
          // text: 'Total fruit consumption'
        }
      },
      legend: {
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [
        {
          // name: 'John',
          showInLegend: false,
          data: priorityCountList
        }
      ],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            },
            yAxis: {
              labels: {
                align: 'left',
                x: 0,
                y: -5
              },
              title: {
                text: null
              }
            },
            subtitle: {
              text: null
            },
            credits: {
              enabled: false
            }
          }
        }]
      }
    }

  }

  drawGraphByPriority(priorityCountList,nameList) {
  // console.log("priorityCountList=="+priorityCountList)
    this.colors = [];
    if (this.assignedTasks == true && this.createdTasks == false) {
      this.colors = ['#00C689', '#38b58f', '#69b9a0', '#69b9a09e'];
    } else if (this.createdTasks == true && this.overdueTasks == false) {
      this.colors = ['#39CCCC', '##7dcece', '##a6d2d2', '#a6d2d291'];
    } else if (this.overdueTasks == true && this.createdTasks == false) {
      this.colors = ['#ff3500', '#e44921', '#e26444', '#e26444cc'];
    }
    Highcharts.setOptions({
      colors: this.colors
    });
    
    this.chartOptions1 = {
      chart: {
          type: 'column'
      },
      title: {
        text: this.label1+' | '+this.label2
      },
      colors: this.colors,
      subtitle: {
        //  text: 'Source: <a href="http://en.wikipedia.org/wiki/List_of_cities_proper_by_population">Wikipedia</a>'
      },
      xAxis: {
          //type: 'category',
          
        categories: nameList,
      },
      yAxis: {
          min: 0
      },
      legend: {
          enabled: false
      },
      tooltip: {
             // headerFormat:'{series.name}: <b>{point.percentage:.1f}%</b><br/>',
            pointFormat: '<span><b style="font-size: 2em">{series.name}</b><br/><b>{point.y}</b></span>',
             style: {
                 fontSize: '1.5em'
             },
             enabled: true
          },
      series: [{
         name: '',
          data:priorityCountList,
          dataLabels: {
              enabled: true,
              rotation: -90,
              color: '#FFFFFF',
              align: 'right',
              y: 10,
              style: {
                  fontSize: '13px',
                  fontFamily: 'Verdana, sans-serif'
              }
          }
      }]
  }
  }

  getTaskStatusWiseCount(req) {
    var arr = [];
    this.myAllTasks = false;
    this.TaskService.getTaskPriorityWiseCount(req)
      .subscribe(res => {
        if (res.result == "Success") {
          let list = res.responseData;

          if (res.responseData['statusList']) {
            this.taskStatus = []
            res.responseData['statusList'].forEach((element, i) => {
              this.taskStatus.push(
                {
                  name: res.responseData['statusList'][i],
                  y: res.responseData['countList'][i]
                }
              )
            });
            this.drawGraphByStatus(this.taskStatus);
          }
          if (res.responseData['priorityList']) {
            this.taskPriority = []
            res.responseData['priorityList'].forEach((element, i) => {
              this.taskPriority.push(
                {
                  name: res.responseData['priorityList'][i],
                  y: res.responseData['countList'][i]
                }
              )
            });
            this.drawGraphByStatus(this.taskPriority);
          }
        }
      },
        error => {
        });
  }

  priorityNameList:any=[];
  getTaskPriorityWiseCount(req) {
    var arr = [];
    this.TaskService.getTaskPriorityWiseCount(req)
      .subscribe(res => {
        if (res.result == "Success") {
          let list = res.responseData;
          let statusList = list.statusList;
          let countList = list.countList;
          this.priorityCountList = countList;
          let priorityNameList=list.priorityList;
          this.priorityNameList=priorityNameList;
          this.drawGraphByPriority(this.priorityCountList,this.priorityNameList);
        }
      },
        error => {
        });
  }

  minimizeDiv() {
  }

  onOptionClick(product: any) {
  }

  getTaskByIdndAction(req) {
    this.TaskService.getTaskByAction(req)
      .subscribe(res => {
        this.tasks = [];
        this.tasks = res['responseData'];
      },
        error => {
        });
  }

  checkClass(index, value) {

    switch (value) {
      case "taskId":
        this.filterApplied[0] = this.taskId['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableTaskIdData.length == this.taskId['value'].length) {
          this.filterApplied[0] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[0]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;
      case "taskName":
        this.filterApplied[1] = this.taskName['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableTaskNameData.length == this.taskName['value'].length) {
          this.filterApplied[1] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[1]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;
      case "taskType":
        this.filterApplied[2] = this.taskType['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableTaskTypeData.length == this.taskType['value'].length) {
          this.filterApplied[2] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[2]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;
      case "productName":
        this.filterApplied[3] = this.productName['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableProductList.length == this.productName['value'].length) {
          this.filterApplied[3] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[3]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "projectName":
        this.filterApplied[4] = this.projectName['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableProjectList.length == this.projectName['value'].length) {
          this.filterApplied[4] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[4]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "userName":
        this.filterApplied[5] = this.userName['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableAssignToList.length == this.userName['value'].length) {
          this.filterApplied[5] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[5]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "createdBy":
        this.filterApplied[6] = this.createdBy['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableCreatedByList.length == this.createdBy['value'].length) {
          this.filterApplied[6] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[6]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "priority":
        this.filterApplied[7] = this.priority['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tablePriorityList.length == this.priority['value'].length) {
          this.filterApplied[7] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[7]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "status":
        this.filterApplied[8] = this.status['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableStatusList.length == this.status['value'].length) {
          this.filterApplied[8] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[8]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "createdDate":
        this.filterApplied[9] = this.createdDate['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableCreatedDate.length == this.createdDate['value'].length) {
          this.filterApplied[9] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[9]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "dueDate":
        this.filterApplied[10] = this.dueDate['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableDueDate.length == this.dueDate['value'].length) {
          this.filterApplied[10] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[10]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;

      case "updatedDate":
        this.filterApplied[11] = this.updatedDate['el'].nativeElement.classList.contains('ui-inputwrapper-filled');
        if (this.tableUpdatedDate.length == this.updatedDate['value'].length) {
          this.filterApplied[11] = false;
        }
        this.fieldSelected = value;
        if (this.filterApplied[11]) {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.add('highlight')
        } else {
          const classContainer: any = document.getElementsByClassName(this.fieldSelected);
          let classes: DOMTokenList = classContainer[0].classList;
          classes.remove('highlight')
        }
        break;
      default:
        break;
    }
  }

  checkClass1(i, col, event) {
    if (event.value.length == 0) {
    col.isHilighted = false;
    }else{ 
      col.isHilighted = true;
       switch (col.field) {
      case "taskId":
        if (this.tableTaskIdData.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
      case "taskName":
        if (this.tableTaskNameData.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "taskType":
        if (this.tableTaskTypeData.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "productName":
        if (this.tableProductList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "projectName":
        if (this.tableProjectList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "userName":
        if (this.tableAssignToList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "createdByName":
        if (this.tableCreatedByList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "priority":
        if (this.tablePriorityList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "status":
        if (this.tableStatusList.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "createdDate":
        if (this.tableCreatedDate.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "dueDate":
        if (this.tableDueDate.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
        case "updatedDate":
        if (this.tableUpdatedDate.length == event.value['length']) {
          col.isHilighted = false;
        }
        break;
      default:
        break;
    }
    }
  }
  
  clearState() {
   
    this.changePageToFirst(event);
    this.getDashboardData();
    this.exportedData=[];
    this.dt22 = '';
    this.dt23 = '';
    this.dt24 = '';
    this.dt25 = '';
    this.dt26 = '';
    this.dt27 = '';
    this.dt28 = '';
    this.dt29 = '';
    this.dt30 = '';
    this.dt31 = '';
    this.dt32 = '';
    this.dt33 = '';
    this.dt34 = '';
    this.dt35 = '';
    this.dt36 = '';
    this.dt37 = '';
    this.dt38 = '';
    this.dt39 = ''; 
    this.dt40 = '';
    this.dt41 = '';
    this.dt42 = '';
    this.dt43 = '';
    this.dt44 = '';
    this.dt45 = '';
    this.dtWatchlist = '';
    this.searchtext='';
    
    // this.globalFilterData = false;
    this.addWatchlistDropdown = true;
    this.watchlistImageNew = false;
    this._selectedColumns.forEach(ele => {
      ele.isHilighted = false;
    })
    this.cols.forEach(ele => {
      ele.isHilighted = false;
    })

    localStorage.setItem("localvalue", "");
    sessionStorage.removeItem("localvalue");
    this.table.reset();
    this.cardTable.reset();
       

    if(this.insideWatchlist && this.isBubbleClick==false){
      this.ngxLoader.start();
      switch (this.selectedTab) {
        case 'My Active Task':
  
          this.newreq = {
            "userId": this.userData.userId,
            "status": "Assigned",
            "action": "My Active Task"
          }
  
          break;
        case 'Team Active Task':
          this.newreq = {
            "userId": this.userData.userId,
            "status": "Assigned",
            "action": "Team Active Task"
          }
          break;
        case 'My Created Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "My Created Task",
            "createdById": this.userData.userId
          }
          break;
        case 'Team Created Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "Team Created Task",
            "createdById": this.userData.userId
          }
          break;
        case 'My Overdue Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "My Overdue Task"
          }
          break;
        case 'Team Overdue Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "Team Overdue Task"
          }
          break;
        case 'My At Risk Task':
          this.newreq = {
            "userId": this.userData.userId,
            "status": "At Risk",
            "action": "My At Risk Task"
          }
          break;
        case 'Team At Risk Task':
          this.newreq = {
            "userId": this.userData.userId,
            "status": "At Risk",
            "action": "Team At Risk Task"
          }
          break;
        case 'My All Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "My All Task"
          }
          break;
        case 'Team All Task':
          this.newreq = {
            "userId": this.userData.userId,
            "action": "Team All Task"
          }
          break;
        default:
          break;
      }
      this.insideWatchlist=false;
      this.watchlistChangeValue=[];
      this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
      // this.getDashboardDataExport(this.newreq);
      this.getExcelFromApi('dashboardExport');
    }
    localStorage.removeItem('userNamesDd');
    localStorage.removeItem('priorityDd');
    localStorage.removeItem('productsDd');
    localStorage.removeItem('createdByNamesDd');
    localStorage.removeItem('taskIds');
    localStorage.removeItem('createdDateDd');
    localStorage.removeItem('taskNames');
    localStorage.removeItem('statusDd');
    localStorage.removeItem('projectsDd');
    localStorage.removeItem('dueDateDd');
    localStorage.removeItem('updatedDateDd');
    localStorage.removeItem('taskTypes');
  }




newClearFunc(){
  this.exportedData=[];
  this.dt22 = '';
  this.dt23 = '';
  this.dt24 = '';
  this.dt25 = '';
  this.dt26 = '';
  this.dt27 = '';
  this.dt28 = '';
  this.dt29 = '';
  this.dt30 = '';
  this.dt31 = '';
  this.dt32 = '';
  this.dt33 = '';
  this.dt34 = '';
  this.dt35 = '';
  this.dt36 = '';
  this.dt37 = '';
  this.dt38 = '';
  this.dt39 = ''; 
  this.dt40 = '';
  this.dt41 = '';
  this.dt42 = '';
  this.dt43 = '';
  this.dt44 = '';
  this.dt45 = '';
  this.dtWatchlist = '';
  this.searchtext='';
  
  // this.globalFilterData = false;
  this.addWatchlistDropdown = true;
  this.watchlistImageNew = false;
  this._selectedColumns.forEach(ele => {
    ele.isHilighted = false;
  })
  this.cols.forEach(ele => {
    ele.isHilighted = false;
  })

  localStorage.setItem("localvalue", "");
  this.table.reset();
  this.cardTable.reset();
}

  isBubbleClick:boolean=false;
  onTaskCardClick(task) {
    this.globalFilterData = false;
    // this.advanceSearchData=false;
    this.isBubbleClick=true;
    this.ngxLoader.start();
    this.selectedTab = task;
    localStorage.setItem('selectedBubble',this.selectedTab);
    // this.paginator.first = 0;
    this.pageNumber=0;
    switch (task) {
      case 'My Active Task':

        let req1 = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "My Active Task"
        }
        this.myAllTasks = false;
        this.assignedTasks = true;
        this.createdTasks = false;
        this.overdueTasks = false;
        this.getDropdownsList(req1,this.pageNumber,this.noOfRows);
       // this.getDashboardDataExport(req1);
        this.getMyTaskCount();
        let req11 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Task Active"
        }
        this.getTaskPriorityWiseCount(req11);

        break;
      case 'Team Active Task':
        let req2 = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "Team Active Task"
        }
        this.myAllTasks = false;
        this.assignedTasks = true;
        this.createdTasks = false;
        this.overdueTasks = false;
        this.getDropdownsList(req2,this.pageNumber,this.noOfRows);
      //  this.getDashboardDataExport(req2);
        this.getTeamTaskCount();
        let req22 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Team Active"
        }
        this.getTaskPriorityWiseCount(req22);
        break;
      case 'My Created Task':
        let req3 = {
          "userId": this.userData.userId,
          "action": "My Created Task",
          "createdById": this.userData.userId
        }
        this.myAllTasks = false;
        this.createdTasks = true;
        this.assignedTasks = false;
        this.overdueTasks = false;
        this.getDropdownsList(req3,this.pageNumber,this.noOfRows);
       // this.getDashboardDataExport(req3);
        this.getMyTaskCount();
        let req33 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Task Created",
          "createdById": this.userData.userId
        }
        this.getTaskPriorityWiseCount(req33);
        break;
      case 'Team Created Task':
        let req4 = {
          "userId": this.userData.userId,
          "action": "Team Created Task",
          "createdById": this.userData.userId
        }
        this.myAllTasks = false;
        this.createdTasks = true;
        this.assignedTasks = false;
        this.overdueTasks = false;
        this.getDropdownsList(req4,this.pageNumber,this.noOfRows);
     //   this.getDashboardDataExport(req4);
        this.getTeamTaskCount();
        let req44 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Team Created",
          "createdById": this.userData.userId
        }
        this.getTaskPriorityWiseCount(req44);
        break;
      case 'My Overdue Task':
        let req7 = {
          "userId": this.userData.userId,
          "action": "My Overdue Task"
        }
        this.myAllTasks = false;
        this.overdueTasks = true;
        this.assignedTasks = false;
        this.createdTasks = false;
        this.getDropdownsList(req7,this.pageNumber,this.noOfRows);
      //  this.getDashboardDataExport(req7);
        this.getMyTaskCount();
        let req77 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Task Overdue"
        }
        this.getTaskPriorityWiseCount(req77);
        break;
      case 'Team Overdue Task':
        let req5 = {
          "userId": this.userData.userId,
          "action": "Team Overdue Task"
        }
        this.myAllTasks = false;
        this.overdueTasks = true;
        this.assignedTasks = false;
        this.createdTasks = false;
        this.getDropdownsList(req5,this.pageNumber,this.noOfRows);
      //  this.getDashboardDataExport(req5);
        this.getTeamTaskCount();
        let req55 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Team Overdue"
        }
        this.getTaskPriorityWiseCount(req55);
        break;
      case 'My At Risk Task':
        let req8 = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "My At Risk Task"
        }
        this.atriskTasks = true;
        this.allTasks = false;
        this.myAllTasks = true;
        this.getDropdownsList(req8,this.pageNumber,this.noOfRows);
       // this.getDashboardDataExport(req8);
        this.getMyTaskCount();
        let req88 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Task At Risk"
        }
        this.getTaskStatusWiseCount(req88);
        break;
      case 'Team At Risk Task':
        let req6 = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "Team At Risk Task"
        }
        this.myAllTasks = true;
        this.atriskTasks = true;
        this.allTasks = false;
        this.getDropdownsList(req6,this.pageNumber,this.noOfRows);
       // this.getDashboardDataExport(req6);
        this.getTeamTaskCount();
        let req66 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Team At Risk"
        }
        this.getTaskStatusWiseCount(req66);
        break;
      case 'My All Task':
        let req9 = {
          "userId": this.userData.userId,
          "action": "My All Task"
        }
        this.myAllTasks = true;
        this.atriskTasks = false;
        this.allTasks = true;
        this.getDropdownsList(req9,this.pageNumber,this.noOfRows);
       // this.getDashboardDataExport(req9);
        this.getMyTaskCount();
        let req99 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "All Task"
        }
        this.getTaskStatusWiseCount(req99);
        break;
      case 'Team All Task':
        let req10 = {
          "userId": this.userData.userId,
          "action": "Team All Task"
        }
        this.myAllTasks = true;
        this.atriskTasks = false;
        this.allTasks = true;
        this.getDropdownsList(req10,this.pageNumber,this.noOfRows);
      //  this.getDashboardDataExport(req10);
        this.getTeamTaskCount();
        let req100 = {
          "userId": this.userData.userId,
          "basedOn": "Priority",
          "action": "Team All Task"
        }
        this.getTaskStatusWiseCount(req100);
        break;
      default:
        break;
    }
    //this.getTaskCount();
    this.clearState();
  }

  onProductDropChange(event) {
  }

  date(date) {
    if (date != null) {
      let temp = date.split("T");
      return temp[0];
    }
    else {
      return "";
    }
  }

  overdue(dueDate, status) {
    let todayDate = new Date();
    todayDate.setHours(0,0,0);
    //let todayDate = moment(today).format('MM/DD/YYYY');
    // let status = this.tasks[0].status;
    let dueDate1=new Date(dueDate);
    dueDate1.setHours(0,0,0)
    if (dueDate1 != undefined && dueDate1.getTime() < todayDate.getTime() && dueDate1.toDateString() != todayDate.toDateString()) {
      if(status=="Completed" || status=="Cancelled" || status=="Duplicate Task" || status=="Rejected" || status=="Resolved" || status=="Answered"){
        return false;
      }
      else{
        return true;
      }
      
    }
    else {
      return false;
    }

  }

  getTaskById(req) {
    this.TaskService.getTaskByAction(req)
      .subscribe(res => {
        this.taskData = res['responseData'];
        this.taskData = this.findCommentandFile(this.taskData);
      },
        error => {

        });
  }

  download(fileDetail: any) {
    let res = {
      "_id": fileDetail._id
    }
    const blob = this.b64toBlob(fileDetail.fileData, fileDetail.contentType);
    fileSaver.saveAs(blob, fileDetail.fileName);
  }


  b64toBlob(b64Data: any, contentType: any, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  time(date) {
    if (date != null) {
      let temp = date.split("T");
      let temp2 = temp[1].split(".");
      return temp2[0];
    }
  }

  getStatusList() {
    this.User.getTaskStatusList()
      .subscribe(res => {
        this.StatusList = [];
        let List: any = res['responseData'];
        let temp = {
          name: "Select Status",
          code: "null"
        }
        this.StatusList.push(temp);
        res['responseData'].forEach(ele => {
          let temp2 = {
            name: ele.statusName,
            code: ele.statusId
          }
          this.StatusList.push(temp2);
        });
        localStorage.setItem("statusList", JSON.stringify(this.StatusList));
      });
  }


  getWatchlist() {
    let json = {
      "createdById": this.userData.userId,
       }
    this.User.getWatchlist(json).subscribe(res => {
        this.watchlistDropdown = [];
        let List: any = res['responseData'];
        this.defaultWatchlistArray=[];
        this.newobjectWatchlist={}

        this.idWatchlistNew=[];

        for(var i=0; i< res['responseData'].length;i++){

          this.idWatchlistNew.push({
            name:res['responseData'][i].watchlistName,
            id: res['responseData'][i]._id
        });
    }
        res['responseData'].forEach(ele => {
          let temp2 = {
            label: ele.watchlistName,
            value: ele
          }
          if(ele.defaultWatchlist) {
            this.defaultWatchlistArray.push(ele);
          }
          this.watchlistDropdown.push(temp2);
        }); 
        localStorage.setItem("watchlistDropdown", JSON.stringify(this.watchlistDropdown));
      });
  }

  convertDateDropdown(str) {
    if (str != null && str != undefined) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("/");
    }
  }

  convertDateForDropdown(str) {
    if (str != null && str != undefined) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    }
  }
  // advanceSearchExportExcel(){
   
  //     let tableData = [];
  //     if(this.advanceSearchexportedData.length!=0){
  //       this.advanceSearchexportedData.forEach(data => {
  //         tableData.push(
  //           {
  //             "Task ID": data.taskId,
  //             "Task Name": data.taskName,
  //             "Task Type": data.taskType,
  //             "Product": data.productName,
  //             "Project Name": data.projectName,
  //             "Assigned To": data.userName,
  //             "Created By": data.createdByName,
  //             "Priority": data.priority,
  //             "Status": data.status,
  //             "Start Date": this.convertDateDropdown(data.createdDate),
  //             "Due Date": this.convertDateDropdown(data.dueDate),
  //             "Last Updated": this.convertDateDropdown(data.updatedDate)
  //           })
  //       });
  //     }
  //     // else{
  //     //   this.tasksExport.forEach(data => {
  //     //     tableData.push(
  //     //       {
  //     //         "Task ID": data.taskId,
  //     //         "Task Name": data.taskName,
  //     //         "Task Type": data.taskType,
  //     //         "Product": data.productName,
  //     //         "Project Name": data.projectName,
  //     //         "Assigned To": data.userName,
  //     //         "Created By": data.createdByName,
  //     //         "Priority": data.priority,
  //     //         "Status": data.status,
  //     //         "Start Date": this.convertDateDropdown(data.createdDate),
  //     //         "Due Date": this.convertDateDropdown(data.dueDate),
  //     //         "Last Updated": this.convertDateDropdown(data.updatedDate)
  //     //       })
  //     //   });
  //     // }
  //     import('xlsx').then(xlsx => {
  //       const worksheet = xlsx.utils.json_to_sheet(tableData);
  //       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //       const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //       this.saveAsExcelFile(excelBuffer, "Task_Report_");
  //     });
    
  // }
  // exportExcel() {
  //   let tableData = [];

  //   if(this.exportedData.length!=0){
  //     console.log("this.exportedDatathis.exportedData",this.exportedData);
  //     this.exportedData.forEach(data => {
  //       tableData.push(
  //         {
  //           "Task ID": data.taskId,
  //           "Task Name": data.taskName,
  //           "Task Type": data.taskType,
  //           "Product": data.productName,
  //           "Project Name": data.projectName,
  //           "Assigned To": data.userName,
  //           "Created By": data.createdByName,
  //           "Priority": data.priority,
  //           "Status": data.status,
  //           "Start Date": this.convertDateDropdown(data.createdDate),
  //           "Due Date": this.convertDateDropdown(data.dueDate),
  //           "Last Updated": this.convertDateDropdown(data.updatedDate),
  //           "Ticket Type":data.ticketType,
  //           "Additional Fields":data.AdditionalField
            
  //         })
  //     });
  //   }
  //   else{
  //     this.tasksExport.forEach(data => {
  //       tableData.push(
  //         {
  //           "Task ID": data.taskId,
  //           "Task Name": data.taskName,
  //           "Task Type": data.taskType,
  //           "Product": data.productName,
  //           "Project Name": data.projectName,
  //           "Assigned To": data.userName,
  //           "Created By": data.createdByName,
  //           "Priority": data.priority,
  //           "Status": data.status,
  //           "Start Date": this.convertDateDropdown(data.createdDate),
  //           "Due Date": this.convertDateDropdown(data.dueDate),
  //           "Last Updated": this.convertDateDropdown(data.updatedDate),
  //           "Ticket Type":data.ticketType,
  //           "Additional Fields":data.AdditionalField
  //         })
  //     });
  //   }
  //   import('xlsx').then(xlsx => {
  //     const worksheet = xlsx.utils.json_to_sheet(tableData);
  //     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //     const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //     this.saveAsExcelFile(excelBuffer, "Task_Report_");
  //   });
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   import("file-saver").then(FileSaver => {
  //     let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //     let EXCEL_EXTENSION = '.xlsx';
  //     const data: Blob = new Blob([buffer], {
  //       type: EXCEL_TYPE
  //     });
  //     FileSaver.saveAs(data, fileName + formatDate(new Date(), 'yyyy/MM/dd hh-mm-ss', 'en-US') + EXCEL_EXTENSION);
  //   });
  // }

  // Not getting used anywhere

  onAfterTogglePanel(event) {
    this.expand = !event.collapsed;
    this.toggle = !this.toggle;
    this.expand = this.toggle;
    if (event.collapsed && window.innerWidth >= 1536) {
      this.noOfRows = 20;
      this.noOfCards = 20;
    }
    else if (!event.collapsed && window.innerWidth >= 1536) {
      this.noOfRows = 15;
      this.noOfCards = 15;
    }
    else if (event.collapsed && window.innerWidth < 1536) {
      this.noOfRows = 20
      this.noOfCards = 20;
    }
    else if (!event.collapsed && window.innerWidth < 1536) {
      this.noOfRows = 15;
      this.noOfCards = 15;
    }
  }

  togglePanel() {
    this.toggle = !this.toggle;
    this.expand = this.toggle;
    
    if (!this.toggle && window.innerWidth >= 1536) {
      this.noOfRows = 20;
      this.noOfCards = 20;
    }
    else if (this.toggle && window.innerWidth >= 1536) {
      this.noOfRows = 15;
      this.noOfCards = 15;
    }
    else if (!this.toggle && window.innerWidth == 1366) {
      this.noOfRows = 20;
      this.noOfCards = 15;
    }
    else if (this.toggle && window.innerWidth == 1366) {
      this.noOfRows = 10;
      this.noOfCards = 10;
    }
    else if (!this.toggle && window.innerWidth < 1536) {
      this.noOfRows = 15
      this.noOfCards = 15;
    }
    else if (this.toggle && window.innerWidth < 1536) {
      this.noOfRows = 10;
      this.noOfCards = 10;
    }
  }

  closeIconClick(event){
    this.closeViewDialogBox=event;
  }

  closeAddTaskData(event) {
    if(this.closeViewDialogBox==true){
      if(this.dtWatchlist==null || this.dtWatchlist=="" || this.dtWatchlist==undefined){
        switch (this.selectedTab) {
          case 'My Active Task':
            this.newreq = {
              "userId": this.userData.userId,
              "status": "Assigned",
              "action": "My Active Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
         //   this.getDashboardDataExport(this.newreq);
            let req11 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Task Active"
            }
            this.getTaskPriorityWiseCount(req11);
            break;
          case 'Team Active Task':
            this.newreq = {
              "userId": this.userData.userId,
              "status": "Assigned",
              "action": "Team Active Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
           // this.getDashboardDataExport(this.newreq);
            let req22 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Team Active"
            }
            this.getTaskPriorityWiseCount(req22);
            break;
          case 'My Created Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "My Created Task",
              "createdById": this.userData.userId
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
         //   this.getDashboardDataExport(this.newreq);
            let req33 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Task Created",
              "createdById": this.userData.userId
            }
            this.getTaskPriorityWiseCount(req33);
            break;
          case 'Team Created Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "Team Created Task",
              "createdById": this.userData.userId
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
           // this.getDashboardDataExport(this.newreq);
            let req44 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Team Created",
              "createdById": this.userData.userId
            }
            this.getTaskPriorityWiseCount(req44);
            break;
          case 'My Overdue Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "My Overdue Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
            //this.getDashboardDataExport(this.newreq);
            let req77 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Task Overdue"
            }
            this.getTaskPriorityWiseCount(req77);
            break;
          case 'Team Overdue Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "Team Overdue Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
            //this.getDashboardDataExport(this.newreq);
            let req55 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Team Overdue"
            }
            this.getTaskPriorityWiseCount(req55);
            break;
          case 'My At Risk Task':
            this.newreq = {
              "userId": this.userData.userId,
              "status": "At Risk",
              "action": "My At Risk Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
           // this.getDashboardDataExport(this.newreq);
            let req88 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Task At Risk"
            }
            this.getTaskStatusWiseCount(req88);
            break;
          case 'Team At Risk Task':
            this.newreq = {
              "userId": this.userData.userId,
              "status": "At Risk",
              "action": "Team At Risk Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
           // this.getDashboardDataExport(this.newreq);
            let req66 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Team At Risk"
            }
            this.getTaskStatusWiseCount(req66);
            break;
          case 'My All Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "My All Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
           // this.getDashboardDataExport(this.newreq);
            let req99 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "All Task"
            }
            this.getTaskStatusWiseCount(req99);
            break;
          case 'Team All Task':
            this.newreq = {
              "userId": this.userData.userId,
              "action": "Team All Task"
            }
            this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
            //this.getDashboardDataExport(this.newreq);
            let req100 = {
              "userId": this.userData.userId,
              "basedOn": "Priority",
              "action": "Team All Task"
            }
            this.getTaskStatusWiseCount(req100);
            break;
          default:
            break;
        }
        this.getTaskCount();
       
      }
    }
    if(localStorage.getItem('first')){
      this.table.restoreState();
      this.first=Number(localStorage.getItem('first'));
    }
    else{
      this.table.restoreState();
      this.first=0;
    }
    if(localStorage.getItem('firstCardView')){
      this.cardTable.restoreState();
      this.firstCardView=Number(localStorage.getItem('firstCardView'));
    }
    else{
      this.cardTable.restoreState();
      this.firstCardView=0;
    }
    
    this.viewDialogueBox = event;
  }

  closeAddDataTask(event) {
    switch (this.selectedTab) {
      case 'My Active Task':

        this.newreq = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "My Active Task"
        }

        break;
      case 'Team Active Task':
        this.newreq = {
          "userId": this.userData.userId,
          "status": "Assigned",
          "action": "Team Active Task"
        }
        break;
      case 'My Created Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "My Created Task",
          "createdById": this.userData.userId
        }
        break;
      case 'Team Created Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "Team Created Task",
          "createdById": this.userData.userId
        }
        break;
      case 'My Overdue Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "My Overdue Task"
        }
        break;
      case 'Team Overdue Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "Team Overdue Task"
        }
        break;
      case 'My At Risk Task':
        this.newreq = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "My At Risk Task"
        }
        break;
      case 'Team At Risk Task':
        this.newreq = {
          "userId": this.userData.userId,
          "status": "At Risk",
          "action": "Team At Risk Task"
        }
        break;
      case 'My All Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "My All Task"
        }
        break;
      case 'Team All Task':
        this.newreq = {
          "userId": this.userData.userId,
          "action": "Team All Task"
        }
        break;
      default:
        break;
    }
    this.getTaskCount();
    this.getDashboardDataNew(this.newreq,this.pageNumber, this.noOfRows);
   // this.getDashboardDataExport(this.newreq);
    this.addTaskId = event;
  }

  openCardView() {
    if (window.innerWidth >= 1536) {
      this.noOfCards = 15;
      this.noOfRows = 15;
    }
    else if (window.innerWidth == 1366) {
      this.noOfCards = 10;
      this.noOfRows = 10;
    }
    else {
      this.noOfCards = 10;
      this.noOfRows = 10;
    }
    this.cardView = true;
    this.tableView = false;
   
  }

  openTableView() {
    this.cardView = false;
    this.tableView = true;
  }

  openViewTaskForm(taskId) {
    this.isUserAuthorised = true;
    this.viewDialogueBox = true;
    this.closeViewBox=true;
    this.viewTaskId = taskId;
    this.viewDialogueBoxData = "false";

  }

  openViewTaskFormEdit(taskId) {
    this.viewDialogueBoxData = "true";
    this.viewDialogueBox = true;
    this.viewTaskId = taskId;
  }

  writtenInitials(createdByName) {
    if (createdByName) {
      let str = createdByName;
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      let acronym = matches.join(''); // JSON
      return acronym;
    }
  }

  showTakeTourDialog(){
    let pdata:any=JSON.parse(localStorage.getItem('userPData'));
    if(pdata.tourFlag==true){
      this.dontshowagain=true;
      this.isDontShowAgain=true;
    }
    else{
      this.dontshowagain=false;
      this.isDontShowAgain=false;
    }
    this.ngxLoader.start();
    this.tourImages=JSON.parse(localStorage.getItem('productTourImages'));
    setTimeout(()=>{
      this.ngxLoader.stop();
    },1200)
    this.displayTakeTour=true;
  }

  getStarted() {
    if(this.dontshowagain==true){
      this.TaskService.updateDontShowProductTour(this.userData.emailId).subscribe(response=>{
      })
    }
    this.displayTakeTour=false;
  }

  
  onChangeTabs(event){
    if(event.index==0){
      if(this._selectedColumns.length==this.cols.length){
        for(let i=0;i<this.cols.length;i++){
          for(let j=0;j<this._selectedColumns.length;j++){
            if(this.cols[i].field==this._selectedColumns[j].field && this.cols[j].isHilighted==true){
              this._selectedColumns[i].isHilighted=true;
            }
          }
        }
      }
      else{
        this.getDefaultOptionsForDropdown();
      }
      this.showSearch1=false;
      this.isListView=true;
      this.isCardView=false;
      localStorage.setItem('ListView',"true");
      localStorage.setItem('CardView',"false");
    }
    else if(event.index==1){
      for(let i=0;i<this.cols.length;i++){
        for(let j=0;j<this._selectedColumns.length;j++){
          if(this.cols[i].field==this._selectedColumns[j].field && this._selectedColumns[j].isHilighted==true){
            this.cols[i].isHilighted=true;
          }
        }
      }
      this.showSearch1=true;
      this.isCardView=true;
      this.isListView=false;
      localStorage.setItem('CardView',"true");
      localStorage.setItem('ListView',"false");
    }
  }

  nextImage(){
    this.ngxLoader.start();
    setTimeout(()=>{
      this.ngxLoader.stop();
    },1600)
  }
  
  
  getDefaultOptionsForDropdown(){
    this.TaskService.getDefaultOptionForSettingsV1(this.userData.userId,localStorage.getItem('businessId')).subscribe(response=>{
      if(response.responseData==null){
        this.settingPresent=false;
        this._selectedColumns=[];
          if(localStorage.getItem('taskIds')!=null && JSON.parse(localStorage.getItem('taskIds')).length!=0) {
            this._selectedColumns.push({ field: 'taskId', header: 'Task ID', class: "taskId",isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskId'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'taskId', header: 'Task ID', class: "taskId",isHilighted:false });
          }
        
          if(localStorage.getItem('taskNames')!=null && JSON.parse(localStorage.getItem('taskNames')).length!=0) {
            this._selectedColumns.push({ field: 'taskName', header: 'Task Name', class: "taskName", isHilighted:true })
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'taskName', header: 'Task Name', class: "taskName", isHilighted:false })
          }
        
          if(localStorage.getItem('taskTypes')!=null && JSON.parse(localStorage.getItem('taskTypes')).length!=0) {
            this._selectedColumns.push( { field: 'taskType', header: 'Task Type', class: "taskType", isHilighted:true })
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskType'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push( { field: 'taskType', header: 'Task Type', class: "taskType", isHilighted:false })
          }
          if(localStorage.getItem('productsDd')!=null && JSON.parse(localStorage.getItem('productsDd')).length!=0) {
            this._selectedColumns.push({ field: 'productName', header: 'Product', class: "productName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='productName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'productName', header: 'Product', class: "productName", isHilighted:false });
          }
          if(localStorage.getItem('projectsDd')!=null && JSON.parse(localStorage.getItem('projectsDd')).length!=0) {
            this._selectedColumns.push({ field: 'projectName', header: 'Project Name', class: "projectName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='projectName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'projectName', header: 'Project Name', class: "projectName", isHilighted:false });
          }
          if(localStorage.getItem('userNamesDd')!=null && JSON.parse(localStorage.getItem('userNamesDd')).length!=0) {
            this._selectedColumns.push({ field: 'userName', header: 'Assigned To', class: "userName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='userName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'userName', header: 'Assigned To', class: "userName", isHilighted:false });
          }
          if(localStorage.getItem('createdByNamesDd')!=null && JSON.parse(localStorage.getItem('createdByNamesDd')).length!=0) {
            this._selectedColumns.push({ field: 'createdByName', header: 'Created By', class: "createdByName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdByName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'createdByName', header: 'Created By', class: "createdByName", isHilighted:false });
          }
        
          if(localStorage.getItem('priorityDd')!=null && JSON.parse(localStorage.getItem('priorityDd')).length!=0) {
            this._selectedColumns.push({ field: 'priority', header: 'Priority', class: "priority", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='priority'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'priority', header: 'Priority', class: "priority", isHilighted:false });
          }
          if(localStorage.getItem('statusDd')!=null && JSON.parse(localStorage.getItem('statusDd')).length!=0) {
            this._selectedColumns.push({ field: 'status', header: 'Status', class: "status", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='status'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'status', header: 'Status', class: "status", isHilighted:false });
          }
          if(localStorage.getItem('createdDateDd')!=null && JSON.parse(localStorage.getItem('createdDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'createdDate', header: 'Start Date', class: "createdDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'createdDate', header: 'Start Date', class: "createdDate", isHilighted:false });
          }
          if(localStorage.getItem('dueDateDd')!=null && JSON.parse(localStorage.getItem('dueDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'dueDate', header: 'Due Date', class: "dueDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='dueDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'dueDate', header: 'Due Date', class: "dueDate", isHilighted:false });
          }
          if(localStorage.getItem('updatedDateDd')!=null && JSON.parse(localStorage.getItem('updatedDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'updatedDate', header: 'Last Updated', class: "updatedDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='updatedDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'updatedDate', header: 'Last Updated', class: "updatedDate", isHilighted:false });
          }
          this._selectedColumns=[...this._selectedColumns];
          this.cols=[...this.cols]
      }
      else{
        
        let list=[];
        this.selectedColumns=[];
        list=response.responseData.taskColumns;
        if(list[0].taskId){
          if(localStorage.getItem('taskIds')!=null && JSON.parse(localStorage.getItem('taskIds')).length!=0) {
            this._selectedColumns.push({ field: 'taskId', header: 'Task ID', class: "taskId",isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskId'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'taskId', header: 'Task ID', class: "taskId",isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskId'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].taskName){
          if(localStorage.getItem('taskNames')!=null && JSON.parse(localStorage.getItem('taskNames')).length!=0) {
            this._selectedColumns.push({ field: 'taskName', header: 'Task Name', class: "taskName", isHilighted:true })
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'taskName', header: 'Task Name', class: "taskName", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskName'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].taskType) {
          if(localStorage.getItem('taskTypes')!=null && JSON.parse(localStorage.getItem('taskTypes')).length!=0) {
            this._selectedColumns.push( { field: 'taskType', header: 'Task Type', class: "taskType", isHilighted:true })
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskType'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push( { field: 'taskType', header: 'Task Type', class: "taskType", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='taskType'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].product){
          if(localStorage.getItem('productsDd')!=null && JSON.parse(localStorage.getItem('productsDd')).length!=0) {
            this._selectedColumns.push({ field: 'productName', header: 'Product', class: "productName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='productName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'productName', header: 'Product', class: "productName", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='productName'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].projectName){
          if(localStorage.getItem('projectsDd')!=null && JSON.parse(localStorage.getItem('projectsDd')).length!=0) {
            this._selectedColumns.push({ field: 'projectName', header: 'Project Name', class: "projectName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='projectName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'projectName', header: 'Project Name', class: "projectName", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='projectName'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].assignedTo){
          if(localStorage.getItem('userNamesDd')!=null && JSON.parse(localStorage.getItem('userNamesDd')).length!=0) {
            this._selectedColumns.push({ field: 'userName', header: 'Assigned To', class: "userName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='userName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'userName', header: 'Assigned To', class: "userName", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='userName'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].createdBy){
          if(localStorage.getItem('createdByNamesDd')!=null && JSON.parse(localStorage.getItem('createdByNamesDd')).length!=0) {
            this._selectedColumns.push({ field: 'createdByName', header: 'Created By', class: "createdByName", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdByName'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'createdByName', header: 'Created By', class: "createdByName", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdByName'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].priority){
          if(localStorage.getItem('priorityDd')!=null && JSON.parse(localStorage.getItem('priorityDd')).length!=0) {
            this._selectedColumns.push({ field: 'priority', header: 'Priority', class: "priority", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='priority'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'priority', header: 'Priority', class: "priority", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='priority'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].status){
          if(localStorage.getItem('statusDd')!=null && JSON.parse(localStorage.getItem('statusDd')).length!=0) {
            this._selectedColumns.push({ field: 'status', header: 'Status', class: "status", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='status'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'status', header: 'Status', class: "status", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='status'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].startDate){
          if(localStorage.getItem('createdDateDd')!=null && JSON.parse(localStorage.getItem('createdDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'createdDate', header: 'Start Date', class: "createdDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'createdDate', header: 'Start Date', class: "createdDate", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='createdDate'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].dueDate) {
          if(localStorage.getItem('dueDateDd')!=null && JSON.parse(localStorage.getItem('dueDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'dueDate', header: 'Due Date', class: "dueDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='dueDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          
          else{
            this._selectedColumns.push({ field: 'dueDate', header: 'Due Date', class: "dueDate", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='dueDate'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        if(list[0].lastUpdated){
          if(localStorage.getItem('updatedDateDd')!=null && JSON.parse(localStorage.getItem('updatedDateDd')).length!=0) {
            this._selectedColumns.push({ field: 'updatedDate', header: 'Last Updated', class: "updatedDate", isHilighted:true });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='updatedDate'){
                this.cols[i].isHilighted=true;
              }
            }
          }
          else{
            this._selectedColumns.push({ field: 'updatedDate', header: 'Last Updated', class: "updatedDate", isHilighted:false });
            for(let i=0;i<this.cols.length;i++){
              if(this.cols[i].field=='updatedDate'){
                this.cols[i].isHilighted=false;
              }
            }
          }
        }
        this._selectedColumns=[...this._selectedColumns];
        this.cols=[...this.cols];
        if(this._selectedColumns.length==12){
          this.settingPresent=false;
        }
        else{
          this.settingPresent=true;
        }
      }
    })
  }

  onClickMakeDefaultSettings(){
    this.ngxLoader.start();
    let isTaskId,isTaskName,isProjectName,isPriority,isCreatedBy,isStatus,isStartDate,isLastUpdated,isAssignedTo,isTaskType,isDueDate,isProduct;
    if(this._selectedColumns.length==0){
      isTaskId=false;
      isTaskName=false;
      isProjectName=false;
      isPriority=false;
      isCreatedBy=false;
      isStatus=false;
      isStartDate=false;
      isLastUpdated=false;
      isAssignedTo=false;
      isTaskType=false;
      isDueDate=false;
      isProduct=false;
    }
    else{
      for(let i=0;i<this._selectedColumns.length;i++){
        if(this._selectedColumns[i].field=="taskId"){
          isTaskId=true;
          break;
        }
        else{
          isTaskId=false;
        }
      }
     for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="taskName"){
        isTaskName=true;
        break;
      }
      else{
        isTaskName=false;
      }
     }
     for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="projectName"){
        isProjectName=true;
        break;
      }
      else{
        isProjectName=false;
      }
     }
     for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="priority"){
        isPriority=true;
        break;
      }
      else{
        isPriority=false;
      }
    }
    for(let i=0;i<this.selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="createdByName"){
        isCreatedBy=true;
        break;
      }
      else{
        isCreatedBy=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="status"){
        isStatus=true;
        break;
      }
      else{
        isStatus=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="createdDate"){
        isStartDate=true;
        break;
      }
      else{
        isStartDate=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="updatedDate"){
        isLastUpdated=true;
        break;
      }
      else{
        isLastUpdated=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="userName"){
        isAssignedTo=true;
        break;
      }
      else{
        isAssignedTo=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="taskType"){
        isTaskType=true;
        break;
      }
      else{
        isTaskType=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="dueDate"){
        isDueDate=true;
        break;
      }
      else{
        isDueDate=false;
      }
    }
    for(let i=0;i<this._selectedColumns.length;i++){
      if(this._selectedColumns[i].field=="productName"){
        isProduct=true;
        break;
      }
      else{
        isProduct=false;
      }
    }
    }
  let req={
    taskColumns:[
        { 
        taskId:isTaskId,
        taskName:isTaskName,
        projectName:isProjectName,
        priority :isPriority,
        createdBy:isCreatedBy,
        status :isStatus,
        startDate :isStartDate,
        lastUpdated:isLastUpdated,
        assignedTo :isAssignedTo,
        taskType :isTaskType,
        dueDate :isDueDate,
        product:isProduct
        }
  
    ],
    userId:this.userData.userId,
    businessId:localStorage.getItem('businessId')
  }
   this.TaskService.updateDefaultOptionForSettings(req).subscribe(response=>{
    if(response.result=="Success"){
      this.ngxLoader.stop();
      let msg1;
      this.translateservice.get('settingsupdatdsuccess').subscribe(txt=>{
        msg1=txt;
      })
      this.messageService.add({ severity: 'success', summary: msg1});
      this.getDefaultOptionsForDropdown();
    }
    else{
      this.ngxLoader.stop();
    }
   },
   error=>{
     this.ngxLoader.stop();
   })
  }

  onChangeTableFilter(col){
    switch (col.field) {
      case "taskId":
          localStorage.setItem('taskIds',JSON.stringify(this.dt22));
        break;
      case "taskName":
        localStorage.setItem('taskNames',JSON.stringify(this.dt23));
        break;
        case "taskType":
          localStorage.setItem('taskTypes',JSON.stringify(this.dt24));
        break;
        case "productName":
          localStorage.setItem('productsDd',JSON.stringify(this.dt25));
        break;
        case "projectName":
          localStorage.setItem('projectsDd',JSON.stringify(this.dt26));
        break;
        case "userName":
          localStorage.setItem('userNamesDd',JSON.stringify(this.dt27))
        break;
        case "createdByName":
          localStorage.setItem('createdByNamesDd',JSON.stringify(this.dt28));
        break;
        case "priority":
          localStorage.setItem('priorityDd',JSON.stringify(this.dt29));
        break;
        case "status":
          localStorage.setItem('statusDd',JSON.stringify(this.dt30));
        break;
        case "createdDate":
          localStorage.setItem('createdDateDd',JSON.stringify(this.dt31));
        break;
        case "dueDate":
          localStorage.setItem('dueDateDd',JSON.stringify(this.dt32));
        break;
        case "updatedDate":
          localStorage.setItem('updatedDateDd',JSON.stringify(this.dt33));
        break;

      default:
        break;
    }
  }

  onChangeDontShowTour(value){
    this.dontshowagain=value;
  }
  

  exportedData:any=[];
  getFilteredData(value){
    if(value==null){
      this.exportedData=this.tasksExport;
      
    }
    else{
      this.exportedData=value;
    } 
  }

  findPromocodeByBusinessId(){
    let expiryDate:any;
    this.paymentService.findPromocodeByIdV1(localStorage.getItem('businessId')).subscribe(response=>{
      if(response.responseData!=null){
        expiryDate=response.responseData.promoExpiryDate;
        let promoExpirydate= new Date(expiryDate)
        let currentDate=new Date();
        currentDate.setHours(0,0,0,0);
        if(expiryDate!=null){
          if(new Date(currentDate).getTime()>=new Date(promoExpirydate).getTime()){
            this.isPromocodeExpired=true;
            let m1= this.translateservice.instant('yoursubscriptionExpired');
           let m2= this.translateservice.instant('toRenewSubscription');
            this.messageService.add({key:'c',sticky: true,severity:'warn',summary:m1 ,detail:m2})
          }
          else{
            this.isPromocodeExpired=false;
          }
        }
        else{
          this.isPromocodeExpired=false;
        }
      }
    })
  }
  
  getTextValues() {
    // this.ngxService.start();
     let faqList=[];
     if(localStorage.getItem('helpFaqList')==null){
       this.TaskService.getHelpFAQ().subscribe(response=>{
         faqList=response.responseData.faqs;
         this.faqResponse=faqList;
         this.CopyfaqResponse=faqList;
         for(let i=0 ;i<this.faqResponse.length;i++){
               this.faqResponseNew.push(this.faqResponse[i]);
         }
         localStorage.setItem('helpFaqList',JSON.stringify(this.faqResponseNew))
       });
     }
     else{
       this.faqResponse=JSON.parse(localStorage.getItem('helpFaqList'));
       this.CopyfaqResponse=JSON.parse(localStorage.getItem('helpFaqList'));
         for(let i=0 ;i<this.faqResponse.length;i++){
             this.faqResponseNew.push(this.faqResponse[i]);
         }
     }
     let videoList=[];
     if(localStorage.getItem('helpVideosList')==null){
       this.TaskService.getHelpVideo().subscribe(response=>{
        // console.log(response)
         videoList=response.responseData.videos;
         this.videoResponse=videoList;
         this.CopyvideoResponse=videoList;
         for(let i=0 ;i<this.videoResponse.length;i++){
               this.videoResponseNew.push(this.videoResponse[i]);
         }
         localStorage.setItem('helpVideosList',JSON.stringify(this.videoResponseNew))
       });
     }
     else{
       this.videoResponse=JSON.parse(localStorage.getItem('helpVideosList'));
       this.CopyvideoResponse=JSON.parse(localStorage.getItem('helpVideosList'));
         for(let i=0 ;i<this.videoResponse.length;i++){
             this.videoResponseNew.push(this.videoResponse[i]);
         }
     }
     let indexList:any;
    // console.log(localStorage.getItem('indexList'))
     if(localStorage.getItem('indexList')==null){
       this.TaskService.getHelpIndex().subscribe(response=>{
        // console.log(response)
         indexList=response.responseData.indexData.headings[0];
         this.guideResponse=indexList.subHeadings;
         this.CopyguideResponse=indexList.subHeadings;
         for(let i=0 ;i<this.guideResponse.length;i++){
             this.guideResponseNew.push(this.guideResponse[i]);
         }
         localStorage.setItem('indexList',JSON.stringify(this.guideResponseNew))
         //console.log("guide res====",localStorage.getItem('indexList'))
       });
     }
     else{
       this.guideResponse=JSON.parse(localStorage.getItem('indexList'));
       this.CopyguideResponse=JSON.parse(localStorage.getItem('indexList'));
         for(let i=0 ;i<this.guideResponse.length;i++){
             this.guideResponseNew.push(this.guideResponse[i]);
         }
         //console.log("guide session====",this.guideResponseNew)
     }
   }
   first=0;
   firstCardView=0;
   onPageChange(event){
     localStorage.removeItem("first");
     this.first=event.first;
     localStorage.setItem("first",""+this.first+"")
   }

   onPageChangeCardView(event){
    localStorage.removeItem("firstCardView");
    this.firstCardView=event.first;
    localStorage.setItem("firstCardView",""+this.firstCardView+"")
   }

   changePageToFirst($event) {
    if (this.paginator !== undefined) {
      console.log("this.paginator---",this.paginator);
      this.paginator.changePageToFirst($event);
      this.paginator1.changePageToFirst($event);
    }
  }

   
  loadLazy(event) {
    //console.log(event);
    this.totalTask = event.first;
    this.noOfRows = event.rows;
    this.pageNumber = this.totalTask / this.noOfRows;
    this.pageClicked = true;
    this.lazyLoad = true;
    this.selectedTab=this.selectedTab;
    let req={
      "userId": this.userData.userId,
      "createdById": this.userData.userId,
      "action": this.selectedTab
    };
    let reportDetailsNew = {
  
      // "reportName": data.reportName,
      // "creatorsId": this.currentUser.userId,
      "productId": this.selectedProduct,
      // "userGroupId": this.selectedUserGropReport,
      "projectId": this.selectedProject,
      "userId": this.selectedUsersId,
      "createdById": this.selectedCreatedUser,
      "status": this.selectedStatusName,
      "priority": this.selectedListOfPriorityName,
      "fromDate": '',
      "toDate": '',
      "taskId": this.AdvancedSearchTaskId,
      "taskName": this.AdvancedSearchTaskName,
      "reportGeneratorId": this.currentUser.userId,
      "reportGeneratorName":this.currentUser.userName,
    }
    
if(this.globalFilterData==false && this.advanceSearchData==false){
  this.getDashboardDataNew(req,this.pageNumber, this.noOfRows);
 // this.getDashboardDataExport(req);
}
  else if(this.globalFilterData==true){
    this.globalFilter(this.globalSearchValue,this.pageNumber, this.noOfRows)
    this.searchtext=this.globalSearchValue;
  }
  else if(this.advanceSearchData==true) {
    this.advanceSearchList(reportDetailsNew,this.pageNumber,this.noOfRows);
  }
  this.newClearFunc();
  }



  onSelectProduct(event) {
    let temp: any[] = [];
    event.value.forEach(element => {
      temp.push(element.code);
      this.selectedProduct = temp;
    });
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }
  onSelectProject(event) {
    let temp: any[] = [];
    event.value.forEach(element => {
      temp.push(element.code);
      this.selectedProject = temp;
    });
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }

  onSelectCreatedUser(event) {
    let temp: any[] = [];
    event.value.forEach(element => {
      temp.push(element.code);
      this.selectedCreatedUser = temp;
    });
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }
  onSelectAssignTo(event){

      this.selectedUsersId = [event.value.code];

      this.selectedUsers = [event.value.name];
      this.advanceSearchFormClear=true;
      this.advanceSearchFormSubmit=true;
  }
  onSelectUser(event) {
    if (this.isAdmin) {
      let temp: any[] = [];
      event.value.forEach(element => {
        temp.push(element.code);
        this.selectedUsersId = temp;
      });
      this.isSlectedUsers = true;
    }
    else if (this.isManager && !this.isAdmin) {
      let temp: any[] = [];
      event.value.forEach(element => {
        temp.push(element.code);
        this.selectedUsersId = temp;
      });
      this.isSlectedUsers = true;
    }
    else {
      this.selectedUsersId = this.currentUser.userId;
    }
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }
  onSelectPriority(event) {
    let temp: any[] = [];
    let temp2: any[] = [];
    event.value.forEach(element => {
      temp.push(element.code);
      this.selectedListOfPriority = temp;

      temp2.push(element.name);
      this.selectedListOfPriorityName = temp2;
    });
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }

  onSelectStatus(event) {
    let temp: any[] = [];
    let temp2: any[] = [];
    event.value.forEach(element => {
      temp.push(element.code);
      this.selectedStatus = temp;

      temp2.push(element.name);
      this.selectedStatusName = temp2;
    });
    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
  }
  advanceSearch(){
    this.dashboardGlobalSearch=false;
    this.isAdvanceSearch=true;
    this.advanceSearchExport=false;
    this.displayflicker=true;
    this.tableTaskIdData = [];
    this.tableTaskNameData = [];
    this.tableTaskTypeData = [];
    this.tableProjectList = [];
    this.tableProductList = [];
    this.tableAssignToList = [];
    this.tableCreatedByList = [];
    this.tableStatusList = [];
    this.tableCreatedDate = [];
    this.tableDueDate = [];
    this.tableUpdatedDate = [];
    this.tablePriorityList = [];
    this.filterApplied = [];
    this.tasks=[];

  this.advancedSearch=true;
  this.table.reset();
  this.cardTable.reset();
  // this.clearState();
  this.advanced_Search_Div=true;
  this.dt22 = '';
  this.dt23 = '';
  this.dt24 = '';
  this.dt25 = '';
  this.dt26 = '';
  this.dt27 = '';
  this.dt28 = '';
  this.dt29 = '';
  this.dt30 = '';
  this.dt31 = '';
  this.dt32 = '';
  this.dt33 = '';
  this.dt34 = '';
  this.dt35 = '';
  this.dt36 = '';
  this.dt37 = '';
  this.dt38 = '';
  this.dt39 = ''; 
  this.dt40 = '';
  this.dt41 = '';
  this.dt42 = '';
  this.dt43 = '';
  this.dt44 = '';
  this.dt45 = '';
  localStorage.removeItem('userNamesDd');
  localStorage.removeItem('priorityDd');
  localStorage.removeItem('productsDd');
  localStorage.removeItem('createdByNamesDd');
  localStorage.removeItem('taskIds');
  localStorage.removeItem('createdDateDd');
  localStorage.removeItem('taskNames');
  localStorage.removeItem('statusDd');
  localStorage.removeItem('projectsDd');
  localStorage.removeItem('dueDateDd');
  localStorage.removeItem('updatedDateDd');
  localStorage.removeItem('taskTypes');
  
  
  this._selectedColumns.forEach(ele => {
    ele.isHilighted = false;
  })
  this.cols.forEach(ele => {
    ele.isHilighted = false;
  })


}


advanceSearchList(data,pageNumber,noOfRows){
  this.tasks=[];
  this.ngxLoader.start();
  this.advanceSearchData=true;
  this.advanceSearchFormSubmit=false;
  // this.advanceSearchFormClear=true;
  this.AdvancedSearchTaskId=data.taskId;
  this.AdvancedSearchTaskName=data.taskName;
  this.currentUser = JSON.parse(localStorage.getItem("userData"));
console.log(this.currentUser)
this.userIdAdvancedSearch=this.currentUser['userId'];           
console.log("this.userIdSelected-----",this.userIdAdvancedSearch);
  let reportDetails = {
  
    // "reportName": data.reportName,
    // "creatorsId": this.currentUser.userId,
    "productId": this.selectedProduct,
    // "userGroupId": this.selectedUserGropReport,
    "projectId": this.selectedProject,
    "userId": this.selectedUsersId,
    "createdById": this.selectedCreatedUser,
    "status": this.selectedStatusName,
    "priority": this.selectedListOfPriorityName,
    "fromDate": this.fromDate,
    "toDate": this.toDate,
    "taskId": data.taskId,
    "taskName": data.taskName,
    "reportGeneratorId": this.currentUser.userId,
    "reportGeneratorName":this.currentUser.userName,
  }

  this.advanceSearchArray=reportDetails;
  this.TaskService.advanceSearchList(reportDetails,pageNumber,noOfRows)
    .subscribe(res => {
      if (res['result'] == "Success") {
        this.displayflicker=false;
        this.advanceSearchExport =true;
        setTimeout(()=>{
          if(res['responseData']){
            if(res['responseData'].length<=0){
              this.isTasksNotPresent=true;
            }
            else{
              this.isTasksNotPresent=false;
            }
          this.ngxLoader.stop();
          }
        },500);
        if(res['responseMessage']=="No data found"){
          this.messageService.add({
            severity: "warn",
            summary: "No data found.",
            detail: "",
          });
          // this.getDashboardData();
          this.ngxLoader.stop();
        }
        this.tableTaskIdData = [];
        this.tableTaskNameData = [];
        this.tableTaskTypeData = [];
        this.tableProjectList = [];
        this.tableProductList = [];
        this.tableAssignToList = [];
        this.tableCreatedByList = [];
        this.tableStatusList = [];
        this.tableCreatedDate = [];
        this.tableDueDate = [];
        this.tableUpdatedDate = [];
        this.tablePriorityList = [];
        this.filterApplied = [];
        // this.tasks=res['responseData'];
        this.tasks = res['responseData']['content'];
        this.tasks.forEach(element => {
          element.createdDate=element?.startDate;
                });
        this.totalRecords=res['responseData']['totalElements'];
        // this.advanceSearchexportedData=res['responseData']['content'];
        this.advanced_Search_Div=false;
        this.ngxLoader.stop();
        // this.advanceSearchForm.reset();
        // this.totalRecords=res['responseData']['totalElements'];
        setTimeout(()=>{
          if(this.tasks){
            this.ngxLoader.stop();
          }
        },500)
        res.responseData['content'].forEach(element => {
          const key = 'label';
          if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
            let d = { label: element.taskId, value: element.taskId };
            this.tableTaskIdData.push(d);
            this.tableTaskIdData.sort(this.keysrt('label'));
            this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableTaskIdData.length; i++) {
              if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                this.tableTaskIdData.splice(i, 1);
              }
            }
          }

          if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
            let d = { label: element.taskName, value: element.taskName };
            this.tableTaskNameData.push(d);
            this.tableTaskNameData.sort(this.keysrt('label'));
            this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableTaskNameData.length; i++) {
              if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                this.tableTaskNameData.splice(i, 1);
              }
            }
          }

          if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
            let d = { label: element.taskType, value: element.taskType };
            this.tableTaskTypeData.push(d);
            this.tableTaskTypeData.sort(this.keysrt('label'));
            this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableTaskTypeData.length; i++) {
              if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                this.tableTaskTypeData.splice(i, 1);
              }
            }
          }

          if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
            let d = { label: element.projectName, value: element.projectName };
            this.tableProjectList.push(d);
            this.tableProjectList.sort(this.keysrt('label'));
            this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableProjectList.length; i++) {
              if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                this.tableProjectList.splice(i, 1);
              }
            }
          }

          if (element.productName != null || element.productName != "" || element.productName != undefined) {
            let d = { label: element.productName, value: element.productName };
            this.tableProductList.push(d);
            this.tableProductList.sort(this.keysrt('label'));
            this.tableProductList = [...new Map(this.tableProductList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableProductList.length; i++) {
              if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                this.tableProductList.splice(i, 1);
              }
            }
          }

          if (element.userName != null || element.userName != "" || element.userName != undefined) {
            let d = { label: element.userName, value: element.userName };
            this.tableAssignToList.push(d);
            this.tableAssignToList.sort(this.keysrt('label'));
            this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableAssignToList.length; i++) {
              if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                this.tableAssignToList.splice(i, 1);
              }
            }
          }

          if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
            let d = { label: element.createdByName, value: element.createdByName };
            this.tableCreatedByList.push(d);
            this.tableCreatedByList.sort(this.keysrt('label'));
            this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableCreatedByList.length; i++) {
              if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                this.tableCreatedByList.splice(i, 1);
              }
            }
          }

          if (element.priority != null || element.priority != "" || element.priority != undefined) {
            let d = { label: element.priority, value: element.priority };
            this.tablePriorityList.push(d);
            this.tablePriorityList.sort(this.keysrt('label'));
            this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tablePriorityList.length; i++) {
              if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                this.tablePriorityList.splice(i, 1);
              }
            }
          }

          if (element.status != null || element.status != "" || element.status != undefined) {
            let d = { label: element.status, value: element.status };
            this.tableStatusList.push(d);
            this.tableStatusList.sort(this.keysrt('label'));
            this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableStatusList.length; i++) {
              if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                this.tableStatusList.splice(i, 1);
              }
            }
          }

          if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
            let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
            this.tableCreatedDate.push(d);
            this.tableCreatedDate.sort(this.keysrt('label'));
            this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableCreatedDate.length; i++) {
              if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                this.tableCreatedDate.splice(i, 1);
              }
            }
            element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
          }

          if (element.dueDate != null) {
            let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
            this.tableDueDate.push(d);
            this.tableDueDate.sort(this.keysrt('label'));
            this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableDueDate.length; i++) {
              if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                this.tableDueDate.splice(i, 1);
              }
            }
            element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
          }

          if (element.updatedDate != null) {
            let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
            this.tableUpdatedDate.push(d);
            this.tableUpdatedDate.sort(this.keysrt('label'));
            this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
              [item[key], item])).values()];
            for (let i = 0; i < this.tableUpdatedDate.length; i++) {
              if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                this.tableUpdatedDate.splice(i, 1);
              }
            }
            element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
          }


        });


      }
      else{
        this.ngxLoader.stop();
      }
    },
    error => {
    });


    // this.TaskService.generateReport(reportDetails)
    // .subscribe(res => {
    //   this.advanceSearchexportedData=res.responseData;
    // });

  
}

// getAdvnaceSearchExport(){
//   console.log("advanceSearchArray***",this.advanceSearchArray);
//   this.TaskService.getAdvanceExcelData(this.advanceSearchArray)
//     .subscribe(res => {
//       console.log(res);
//       this.fileUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(res));
//       saveAs(res, 'taskReport.xlsx');
//       this.ngxLoader.stop();

//     });
// }



globalFilter(value,pageNumber,noOfRows) {
  this.selectedTab="";
  this.searchtext=value.trim();
  this.ngxLoader.start();
  // if (value == "") {
  //   this.clearState();
  // } else {
    this.lazyLoad = false;
    this.currentUser = JSON.parse(localStorage.getItem("userData"));
    console.log(this.currentUser)
    this.userIdAdvancedSearch=this.currentUser['userId'];   
    this.globalFilterData = true;
    this.globalSearchValue=value;
    this.TaskService.getGlobalFilterData(this.searchtext,pageNumber,noOfRows,this.userIdAdvancedSearch).subscribe(
      res => {
        if (res["result"] == "Success") {
          // this.pageClicked = true;
          this.tableTaskIdData = [];
    this.tableTaskNameData = [];
    this.tableTaskTypeData = [];
    this.tableProjectList = [];
    this.tableProductList = [];
    this.tableAssignToList = [];
    this.tableCreatedByList = [];
    this.tableStatusList = [];
    this.tableCreatedDate = [];
    this.tableDueDate = [];
    this.tableUpdatedDate = [];
    this.tablePriorityList = [];
    this.filterApplied = [];
          this.tasks = res['responseData'];
          this.totalRecords = res['totalRecords'];
          res['responseData'].forEach(element => {
            const key = 'label';
            if (element.taskId != null || element.taskId != "" || element.taskId != undefined) {
              let d = { label: element.taskId, value: element.taskId };
              this.tableTaskIdData.push(d);
              this.tableTaskIdData.sort(this.keysrt('label'));
              this.tableTaskIdData = [...new Map(this.tableTaskIdData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskIdData.length; i++) {
                if (this.tableTaskIdData[i].label == "" || this.tableTaskIdData[i].label == undefined || this.tableTaskIdData[i].label == null) {
                  this.tableTaskIdData.splice(i, 1);
                }
              }
            }
      
            if (element.taskName != null || element.taskName != "" || element.taskName != undefined) {
              let d = { label: element.taskName, value: element.taskName };
              this.tableTaskNameData.push(d);
              this.tableTaskNameData.sort(this.keysrt('label'));
              this.tableTaskNameData = [...new Map(this.tableTaskNameData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskNameData.length; i++) {
                if (this.tableTaskNameData[i].label == "" || this.tableTaskNameData[i].label == undefined || this.tableTaskNameData[i].label == null) {
                  this.tableTaskNameData.splice(i, 1);
                }
              }
            }
      
            if (element.taskType != null || element.taskType != "" || element.taskType != undefined) {
              let d = { label: element.taskType, value: element.taskType };
              this.tableTaskTypeData.push(d);
              this.tableTaskTypeData.sort(this.keysrt('label'));
              this.tableTaskTypeData = [...new Map(this.tableTaskTypeData.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableTaskTypeData.length; i++) {
                if (this.tableTaskTypeData[i].label == "" || this.tableTaskTypeData[i].label == undefined || this.tableTaskTypeData[i].label == null) {
                  this.tableTaskTypeData.splice(i, 1);
                }
              }
            }
      
            if (element.projectName != null || element.projectName != "" || element.projectName != undefined) {
              let d = { label: element.projectName, value: element.projectName };
              this.tableProjectList.push(d);
              this.tableProjectList.sort(this.keysrt('label'));
              this.tableProjectList = [...new Map(this.tableProjectList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProjectList.length; i++) {
                if (this.tableProjectList[i].label == "" || this.tableProjectList[i].label == undefined || this.tableProjectList[i].label == null) {
                  this.tableProjectList.splice(i, 1);
                }
              }
            }
      
            if (element.productName != null || element.productName != "" || element.productName != undefined) {
              let d = { label: element.productName, value: element.productName };
              this.tableProductList.push(d);
              this.tableProductList.sort(this.keysrt('label'));
              this.tableProductList = [...new Map(this.tableProductList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableProductList.length; i++) {
                if (this.tableProductList[i].label == "" || this.tableProductList[i].label == undefined || this.tableProductList[i].label == null) {
                  this.tableProductList.splice(i, 1);
                }
              }
            }
      
            if (element.userName != null || element.userName != "" || element.userName != undefined) {
              let d = { label: element.userName, value: element.userName };
              this.tableAssignToList.push(d);
              this.tableAssignToList.sort(this.keysrt('label'));
              this.tableAssignToList = [...new Map(this.tableAssignToList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableAssignToList.length; i++) {
                if (this.tableAssignToList[i].label == "" || this.tableAssignToList[i].label == undefined || this.tableAssignToList[i].label == null) {
                  this.tableAssignToList.splice(i, 1);
                }
              }
            }
      
            if (element.createdByName != null || element.createdByName != "" || element.createdByName != undefined) {
              let d = { label: element.createdByName, value: element.createdByName };
              this.tableCreatedByList.push(d);
              this.tableCreatedByList.sort(this.keysrt('label'));
              this.tableCreatedByList = [...new Map(this.tableCreatedByList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedByList.length; i++) {
                if (this.tableCreatedByList[i].label == "" || this.tableCreatedByList[i].label == undefined || this.tableCreatedByList[i].label == null) {
                  this.tableCreatedByList.splice(i, 1);
                }
              }
            }
      
            if (element.priority != null || element.priority != "" || element.priority != undefined) {
              let d = { label: element.priority, value: element.priority };
              this.tablePriorityList.push(d);
              this.tablePriorityList.sort(this.keysrt('label'));
              this.tablePriorityList = [...new Map(this.tablePriorityList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tablePriorityList.length; i++) {
                if (this.tablePriorityList[i].label == "" || this.tablePriorityList[i].label == undefined || this.tablePriorityList[i].label == null) {
                  this.tablePriorityList.splice(i, 1);
                }
              }
            }
      
            if (element.status != null || element.status != "" || element.status != undefined) {
              let d = { label: element.status, value: element.status };
              this.tableStatusList.push(d);
              this.tableStatusList.sort(this.keysrt('label'));
              this.tableStatusList = [...new Map(this.tableStatusList.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableStatusList.length; i++) {
                if (this.tableStatusList[i].label == "" || this.tableStatusList[i].label == undefined || this.tableStatusList[i].label == null) {
                  this.tableStatusList.splice(i, 1);
                }
              }
            }
      
            if (element.createdDate != null || element.createdDate != "" || element.createdDate != undefined) {
              let d = { label: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.createdDate, 'MM/dd/yyyy') };
              this.tableCreatedDate.push(d);
              this.tableCreatedDate.sort(this.keysrt('label'));
              this.tableCreatedDate = [...new Map(this.tableCreatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableCreatedDate.length; i++) {
                if (this.tableCreatedDate[i].label == "" || this.tableCreatedDate[i].label == undefined || this.tableCreatedDate[i].label == null) {
                  this.tableCreatedDate.splice(i, 1);
                }
              }
              element.createdDate = this.datepipe.transform(element.createdDate, 'MM/dd/yyyy');
            }
      
            if (element.dueDate != null) {
              let d = { label: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.dueDate, 'MM/dd/yyyy') };
              this.tableDueDate.push(d);
              this.tableDueDate.sort(this.keysrt('label'));
              this.tableDueDate = [...new Map(this.tableDueDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableDueDate.length; i++) {
                if (this.tableDueDate[i].label == "" || this.tableDueDate[i].label == undefined || this.tableDueDate[i].label == null) {
                  this.tableDueDate.splice(i, 1);
                }
              }
              element.dueDate = this.datepipe.transform(element.dueDate, 'MM/dd/yyyy');
            }
      
            if (element.updatedDate != null) {
              let d = { label: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy'), value: this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy') };
              this.tableUpdatedDate.push(d);
              this.tableUpdatedDate.sort(this.keysrt('label'));
              this.tableUpdatedDate = [...new Map(this.tableUpdatedDate.map(item =>
                [item[key], item])).values()];
              for (let i = 0; i < this.tableUpdatedDate.length; i++) {
                if (this.tableUpdatedDate[i].label == "" || this.tableUpdatedDate[i].label == undefined || this.tableUpdatedDate[i].label == null) {
                  this.tableUpdatedDate.splice(i, 1);
                }
              }
              element.updatedDate = this.datepipe.transform(element.updatedDate, 'MM/dd/yyyy');
            }
      
      
          });
          this.ngxLoader.stop();
         
          // this.extractCustomAttr();
        } else {
          this.messageService.add({ severity: 'error', detail: 'No data found' });
          // this.globalFilterData = false;
          this.ngxLoader.stop();
          // this.pageClicked = false;
        }
        // this.ngxLoader.stop();
      });
  // }
}
advanceSearchFormReset(){
   this.advanceSearchForm.reset();
  this.advanceSearchFormClear=false;
  this.advanceSearchFormSubmit=false;
  this.advanced_Search_Div =true;
  this.displayflicker=true;
  this.advanceSearchExport=false;
  this.advancedSearch=true;
  this.selectedProduct=[];
  this.selectedProject=[];
  this.selectedCreatedUser=[];
  this.selectedUsersId=[];
  this.selectedStatusName=[];
  this.selectedListOfPriorityName=[];
  this.selected = '';
  localStorage.removeItem('userNamesDd');
  localStorage.removeItem('priorityDd');
  localStorage.removeItem('productsDd');
  localStorage.removeItem('createdByNamesDd');
  localStorage.removeItem('taskIds');
  localStorage.removeItem('createdDateDd');
  localStorage.removeItem('taskNames');
  localStorage.removeItem('statusDd');
  localStorage.removeItem('projectsDd');
  localStorage.removeItem('dueDateDd');
  localStorage.removeItem('updatedDateDd');
  localStorage.removeItem('taskTypes');
  this.dt22 = '';
  this.dt23 = '';
  this.dt24 = '';
  this.dt25 = '';
  this.dt26 = '';
  this.dt27 = '';
  this.dt28 = '';
  this.dt29 = '';
  this.dt30 = '';
  this.dt31 = '';
  this.dt32 = '';
  this.dt33 = '';
  this.dt34 = '';
  this.dt35 = '';
  this.dt36 = '';
  this.dt37 = '';
  this.dt38 = '';
  this.dt39 = ''; 
  this.dt40 = '';
  this.dt41 = '';
  this.dt42 = '';
  this.dt43 = '';
  this.dt44 = '';
  this.dt45 = '';
   this.table.reset();
  this.cardTable.reset();
  this._selectedColumns.forEach(ele => {
    ele.isHilighted = false;
  })
  this.cols.forEach(ele => {
    ele.isHilighted = false;
  })
}
clearStateadvanceSearch(){
//   this.advanceSearchExport=false;
// this.displayflicker=true;
this.advanceSearchExport=true;
this.displayflicker=false;
  this.selectedProduct=[];
  this.selectedProject=[];
  this.selectedCreatedUser=[];
  this.selectedUsersId=[];
  this.selectedStatusName=[];
  this.selectedListOfPriorityName=[];
  this.selected = '';
  this.advanced_Search_Div=false;
  this.advancedSearch=true;
  localStorage.removeItem('userNamesDd');
  localStorage.removeItem('priorityDd');
  localStorage.removeItem('productsDd');
  localStorage.removeItem('createdByNamesDd');
  localStorage.removeItem('taskIds');
  localStorage.removeItem('createdDateDd');
  localStorage.removeItem('taskNames');
  localStorage.removeItem('statusDd');
  localStorage.removeItem('projectsDd');
  localStorage.removeItem('dueDateDd');
  localStorage.removeItem('updatedDateDd');
  localStorage.removeItem('taskTypes');
  this.dt22 = '';
  this.dt23 = '';
  this.dt24 = '';
  this.dt25 = '';
  this.dt26 = '';
  this.dt27 = '';
  this.dt28 = '';
  this.dt29 = '';
  this.dt30 = '';
  this.dt31 = '';
  this.dt32 = '';
  this.dt33 = '';
  this.dt34 = '';
  this.dt35 = '';
  this.dt36 = '';
  this.dt37 = '';
  this.dt38 = '';
  this.dt39 = ''; 
  this.dt40 = '';
  this.dt41 = '';
  this.dt42 = '';
  this.dt43 = '';
  this.dt44 = '';
  this.dt45 = '';
   this.table.reset();
  this.cardTable.reset();
  this._selectedColumns.forEach(ele => {
    ele.isHilighted = false;
  })
  this.cols.forEach(ele => {
    ele.isHilighted = false;
  })
}

cancelAdvanceSearch(){
this.tasks=[];
  this.dashboardGlobalSearch=true;
  this.isAdvanceSearch=false;
  this.displayflicker=false;
  this.selectedProduct=[];
  this.selectedProject=[];
  this.selectedCreatedUser=[];
  this.selectedUsersId=[];
  this.selectedStatusName=[];
  this.selectedListOfPriorityName=[];
  this.selected = '';
this.advanceSearchExport=false;
  this.advanceSearchForm.reset();
  // this.changePageToFirst(event);
  this.advanceSearchFormClear=false;
  this.advanceSearchFormSubmit=false;
  this.totalRecords=0;
  this.totalTask=0;
  this.pageNumber=0;
  this.advanceSearchData=false;
  this.advancedSearch=false;
this.clearState();
}
onKeydown() {

    this.advanceSearchFormClear=true;
    this.advanceSearchFormSubmit=true;
}




@HostListener('window:load',['$event'])

  onPageLoad(event: Event) {
    // this.ngxLoader.start();
     console.log('loaded',event);

    setTimeout(()=>{

       this.ngxLoader.stop();
      this.isLoaded=true;
    },5000)

  }


}