import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(json: { emailId: any; }) {
    return this.http.post<any>(environment.northstar_Loginurl+"user/loginByEmailId", json);
  }

  getAllProduct() {
    return this.http.get<any>(environment.northstar_Loginurl+"product/all");
  }

  startProvisioning (req) {
    return this.http.post<any>(environment.northstar_Loginurl + 'startProvisioning', req);
  }

  postData(url, data) {
    return this.http.post(`${environment.northstar_Loginurl}${url}`, data);
  }


  changePassword(url, data) {
    return this.http.post(`${environment.northstar_Loginurl}${url}`, data);
  }

  uploadUserProfile(req, mailId) {
    return this.http.post<any>(environment.northstar_Loginurl + 'profile/addUserLogo?userEmail='+ mailId, req);
  }

  sendFeedback(req) {
    return this.http.post<any>(environment.mainurl + 'feedback/createFeedback', req);
  }

  getSupportToken () {
    return this.http.get<any>(`${environment.northstar_Loginurl}support/getSupportToken`);
  }
//https://nsdev.straviso.net/tmg/ticket/core/ticket/create/support

  createTicket (req) {
    return this.http.post<any>(`${environment.ticketDahs_url}ticket/create/support`, req);
  }

  getBussinessData(){
    return this.http.get<any>(environment.northstar_Loginurl+'profile/getBusinessNameById');
  }

  OTPRequest(token) {
    return this.http.get<any>(environment.northstar_Loginurl+"verifyRegistrationLink?token="+ token);
  }

  verifyOTP (otp, mail) {
    return this.http.get<any>(environment.northstar_Loginurl+"verifyUserOTP?OTP="+ otp + "&userEmail=" + mail );
  }

  getData(url) {
    return this.http.get<any>(`${environment.northstar_Loginurl}${url}`);
  }
  // newForgetPassword(req){
  //   return this.http.post<any>(environment.northstar_Loginurl+"forgotPasswordRequest", req,{
  //     headers:{ 'Authorization': 'Bearer ' + localStorage.getItem('auth_token') }
  //   });
  // }
  newForgetPassword(req){
    return this.http.post<any>(environment.northstar_Loginurl+"forgotPasswordRequest", req);
  }

  signUp(req) {
    return this.http.post<any>(environment.northstar_Loginurl+"registerNewUser", req);
  }

  signIn(req) {
    return this.http.post<any>(environment.sign_in + "auth/login", req);
  }

  signInV1(req) {
    const req1 = { data: btoa(JSON.stringify(req))};
    return this.http.post<any>(environment.sign_in + "auth/loginV1", req1);
  }
  getUserData (mail, role) {
    return this.http.get<any>(environment.northstar_Loginurl + "profile/getUserProfile?userEmail="+ mail + "&userRole=" + role)
  }

  getUserDataV1 (mail, role) {
    return this.http.get<any>(environment.northstar_Loginurl + "profile/getUserProfileV1?userEmail="+ btoa(mail) + "&userRole=" +  btoa(role))
  }


  inviteUserSignUp (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'sendEmailLinkToInviteUser', req);
  }

  getProvisioningStatus (req) {
    return this.http.post<any>(environment.northstar_Loginurl + 'getProvisioningStatus', req)
  }
  
  

  
  lumen(req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardLumenUser', req);
  }

  sara(req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardLumenUserNlp', req)
  }

  Nexus (uData) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardNexusUser', uData);
  }

  Buzz (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardBuzzUser', req);
  }

  FieldServe (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardFieldServeUser', req);
  }

  bcsCommunicationDetail (req) {
    return this.http.post<any>(environment.northstar_Loginurl, req);
  }

  TaskEasy (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardTaskEasyUser', req);
  }

  Amtrack (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardAmtrackUser', req);
  }

  TicketEasy (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardTicketingUser', req);
  }

  KnowMe (req) {
    return this.http.post<any>(environment.northstar_Loginurl+'onBoardKnowMeUser', req);
  }

  Appointment(req) {
    return this.http.post<any>(`${environment.northstar_Loginurl}onBoardAppointmentUser`, req);
  }

  WorkOrder(req){
    return this.http.post<any>(`${environment.northstar_Loginurl}onBoardWorkOrderUser`, req);
  }

  getUserSubscribeProduct(businessId) {
    return this.http.get<any>(environment.northstar_Loginurl+'getBusinessSubScribeProducts?businessId='+businessId)
  }

  setPassword (req) {
    return this.http.post<any>(environment.northstar_Loginurl+"setUpPasswordForUser", req);
  }
  // setPassword (value) {
  //   let req = { data: btoa(JSON.stringify(value))};
  //   return this.http.post<any>(environment.northstar_Loginurl+"setUpPasswordForUserV1", req);
  // }
  setForgotPassword (value,emailToken) {
    let req = { data: btoa(JSON.stringify(value)),emailToken};
    return this.http.post<any>(environment.northstar_Loginurl+"setUpPasswordForUserV1", req);
  }
  
  applyPromo(req) {
    return this.http.post<any>(environment.northstar_Loginurl+"promoCode/apply", req);
  }
  
  addBillingAddress(req) {
    return this.http.post<any>(environment.northstar_Loginurl+"billingAddress/save", req);
  }
  uploadBusinessLogo (formData, Bid) {
    return this.http.post<any>(environment.northstar_Loginurl+"profile/addBusinessLogo?businessId="+ Bid, formData);
  }
  deleteBusinessLogo(email){
    return this.http.delete(environment.northstar_Loginurl+"profile/deleteBusinessLogo?userEmail="+ email);
  }

  deleteUserLogo(email){
    return this.http.delete(environment.northstar_Loginurl+"profile/deleteUserLogo?userEmail="+ email);
  }
  logoutUser (req) {
    return this.http.post(environment.logOutUrl+'logoutUser', req)
  }

  trackUserLogin(req){
    return this.http.post<any>(environment.trackUserLoginUrl, req);
  }
  trackUserLoginV1(req){
    const req1 = { data: btoa(JSON.stringify(req))};
    return this.http.post<any>(environment.trackUserLoginUrlV1, req1);
  }
  backupBusiness(req){
    return this.http.post(environment.deleteBusinessUrl+'saveBusinessDeleteConfirmationDetails', req);
  }

  updateUserProfile(req){
    return this.http.post(environment.updateUserProfileUrl+'profile/updateUserProfile',req);
  }
}



// https://taskeasydev.straviso.net:7083/NorthStar/Core/registerNewUser - signup

// {"accActivationDate":"","accNumber":"","accPrimaryEmail":"saurabh.totewar@straviso.com","accPrimaryFirstName":"Saurabh","accPrimaryLastName":"Totewar","accPrimaryPhone":null,"accStatus":"","businessAddress1":"","businessAddress2":"","businessId":"","businessIndustry":"Accounting","businessLogoId":"","businessName":"Saurabh","businessNoofEmployees":"11-20","businessWebsite":"","city":"","country":"India","createdBy":"","createdDate":"2019-12-12T07:19:26.369Z","id":0,"state":"","updatedBy":"","updatedDate":"","userJobTitle":null,"zip":""}


// https://taskeasydev.straviso.net:7083/NorthStar/Core/verifyUserOTP?OTP=37494&userEmail=saurabh.totewar@straviso.com - verify


// https://taskeasydev.straviso.net:7083/NorthStar/Core/setUpPasswordForUser - create password/setup
// {"emailId":"saurabh.totewar@straviso.com","userPassword":"Straviso@123"}


// https://taskeasydev.straviso.net:7083/AuthServer/auth/login - login
// {"emailId":"saurabh.totewar@straviso.com","password":"Straviso@123"}

// https://taskeasydev.straviso.net:7083/NorthStar/Core/forgotPassword?userEmail=saurabh.totewar@straviso.com - forgot

// url=https://mytaskeasy.straviso.net/#/reset-password