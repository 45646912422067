import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TasksComponent } from './components/dashboard/tasks/tasks.component';
import { PrimeNgModule } from './modules/prime-ng/prime-ng.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { HttpIntercepter } from './interceptors/http.intercepter';
import {RatingModule} from 'primeng/rating';
import {AccordionModule} from 'primeng/accordion';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';

import { PasswordExpiredComponent } from './components/authentication/password-expired/password-expired.component';

import { VerifyComponent } from './components/authentication/verify/verify.component';
import { NgxUiLoaderConfig, POSITION, SPINNER, PB_DIRECTION, NgxUiLoaderModule, NgxUiLoaderRouterModule, } from 'ngx-ui-loader';
import {EditorModule} from 'primeng/editor';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {CarouselModule} from 'primeng/carousel';
import { CarouselDirective } from './shared/coursel.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { UserIdleModule } from 'angular-user-idle';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import {HighchartsChartModule} from 'highcharts-angular';
import { SafePipe } from './shared/safe.pipe';
import { environment } from '../environments/environment';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.fadingCircle, // background spinner type
  fgsType: SPINNER.fadingCircle, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  text: 'Please wait...',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

@NgModule({
  declarations: [
    AppComponent, 
     HeaderComponent,
     FooterComponent,
    ResetPasswordComponent,
    PasswordExpiredComponent,
    VerifyComponent,
   // CardViewComponent,
    DashboardComponent,
    TasksComponent,
    CarouselDirective,
    SafePipe
  ],
  imports: [
   PrimeNgModule,
    BrowserModule,
    RatingModule,
    AppRoutingModule,
    HttpClientModule,
    EditorModule,
    CalendarModule,
    NgxDaterangepickerMd.forRoot(),
    ConfirmDialogModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    ScrollPanelModule,
    FormsModule,
    HighchartsChartModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  UserIdleModule.forRoot({idle: 3600, timeout: 3600})
  ],
  providers: [ConfirmationService,AccordionModule,
    MessageService, 
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpIntercepter,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
