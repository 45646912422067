import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CardModule} from 'primeng/card';
import {EditorModule} from 'primeng/editor';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
import {FileUploadModule} from 'primeng/fileupload';
import {SidebarModule} from 'primeng/sidebar';
import {TableModule} from 'primeng/table';
import {DropdownModule} from 'primeng/dropdown';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {TabMenuModule} from 'primeng/tabmenu';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MultiSelectModule} from 'primeng/multiselect';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AccordionModule} from 'primeng/accordion'; 
import {StepsModule} from 'primeng/steps';
import {MenuItem} from 'primeng/api';
import {PanelModule} from 'primeng/panel';
import {DialogModule } from 'primeng/dialog';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TabViewModule} from 'primeng/tabview';
import {MatInputModule} from '@angular/material/input';
import {RadioButtonModule} from 'primeng/radiobutton';
import { TabsModule } from 'ngx-bootstrap/tabs';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { PaginatorModule } from 'primeng/paginator';
@NgModule({
  declarations: [],
  imports: [
    CardModule,
    FormsModule,
    FileUploadModule,
    SidebarModule,
    TableModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    MessageModule,
    CheckboxModule,
    TabViewModule,
    ButtonModule,
    PaginatorModule,
    TabMenuModule,
    InputSwitchModule,
    InputTextModule,
    TooltipModule,
    MultiSelectModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    InputTextareaModule,
    CommonModule,
    CardModule,
    EditorModule,
    FormsModule,
    CalendarModule,
    FileUploadModule,
    SidebarModule,
    TableModule,
    DropdownModule,
    MessageModule,
    CheckboxModule,
    ButtonModule,
    TabMenuModule,
    InputSwitchModule,
    InputTextModule,
    TooltipModule,
    MultiSelectModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    AccordionModule,
    StepsModule,
    PanelModule,
    DialogModule,
    InputTextareaModule,
    BreadcrumbModule,
    MatInputModule,
    RadioButtonModule,
    ScrollPanelModule,
    TabsModule.forRoot()
  ],
  exports: [
    AccordionModule,
    CardModule,
    EditorModule,
    FormsModule,
    FileUploadModule,
    TabViewModule,
    SidebarModule,
    TableModule,
    DropdownModule,
    ReactiveFormsModule,
    ToastModule,
    MessageModule,
    CheckboxModule,
    ButtonModule,
    TabMenuModule,
    PaginatorModule,
    InputSwitchModule,
    InputTextModule,
    TooltipModule,
    MultiSelectModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    InputTextareaModule,
    CommonModule,
    CardModule,
    FormsModule,
    CalendarModule,
    FileUploadModule,
    SidebarModule,
    TableModule,
    DropdownModule,
    MessageModule,
    CheckboxModule,
    ButtonModule,
    TabsModule,
    TabMenuModule,
    InputSwitchModule,
    InputTextModule,
    TooltipModule,
    MultiSelectModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    StepsModule,
    PanelModule,
    DialogModule,
    InputTextareaModule,
    BreadcrumbModule,
    MatInputModule,
    RadioButtonModule
  ],
  providers:[ConfirmationService, MessageService]
})
export class PrimeNgModule { }
