import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskDataService {

  constructor(private http: HttpClient) { }

  getUserGroupList() {
    return this.http.get<any>(environment.mainurl + "usergroup/findAll");
  }

  getUserGroupListActiveAndEnabled() {
    return this.http.get<any>(environment.mainurl + "usergroup/findAllEnableAndActive");
  }

  getAllNotification(requestJson) {
    return this.http.post<any>(environment.mainurl+"notification/findAll",requestJson);
  }

  getAllNotificationClear(requestJson ){
    return this.http.post<any>(environment.mainurl+"notification/clearAllNotifications",requestJson);
  }

  getAllNotificationCount(requestJson) {
    return this.http.post<any>(environment.mainurl+"notification/findcounts",requestJson);
  }

  getAllNotificationLastseen(requestJson) {
    return this.http.post<any>(environment.mainurl+"user/lastSeenUpdate",requestJson);
  }

  getAllProductList() {
    return this.http.get<any>(environment.mainurl + "product/findAllActive");
  }

  getUserList() {
    return this.http.get<any>(environment.mainurl + "user/findAllEnabledAndActive");
  }

  getEmployeeList(url) {
    return this.http.get<any>(url);
  }

  getUserDetails() {
    return this.http.get<any>(environment.mainurl + "user/getUserInfo");
  }

  getTaskStatusList() {
    return this.http.get<any>(environment.mainurl + "taskStatus/findAll");
  }
  getTaskStatusListActiveAndEnabled() {
    return this.http.get<any>(environment.mainurl + "taskStatus/findAllEnableAndActive");
  }

  getAllProjectList() {
    return this.http.get<any>(environment.mainurl + "project/findAllActive");
  }

  getProjectList(){
    return this.http.get<any>(environment.mainurl+'project/findAll');
  }

  getProjectListActiveAndEnabled(){
    return this.http.get<any>(environment.mainurl+'project/findAllEnableAndActive');
  }

  getProductList(){
    return this.http.get<any>(environment.mainurl+'product/findAll');
  }
  getProductListActiveAndEnabled(){
    return this.http.get<any>(environment.mainurl+'product/findAllEnableAndActive');
  }

  getAllTasktype(){
    return this.http.get<any>(environment.mainurl + "taskType/findAll");
  }

  getAllTasktypeActiveAndEnabled(){
    return this.http.get<any>(environment.mainurl + "taskType/findAllEnableAndActive");
  }

  getAllStatusList(){
    return this.http.get<any>(environment.mainurl + "taskStatus/findAllActive");
  }

  getAllProjectListByProductID(json) {
    return this.http.post<any>(environment.mainurl + "project/findByProductId",json);
  }
  getAllUserListByProductID(json) {
    return this.http.post<any>(environment.mainurl + "user/findByProductId",json);
  }

  getTaskStatusHistory(json) {
    return this.http.post<any>(environment.mainurl + "task/findTaskStatusHistory", json);
  }

  getBussinessDetail() {
    return this.http.get<any>(environment.mainurl + "utility/getBusinessDetail");
  }

  getTaskCount(json) {
    return this.http.post<any>(environment.reporturl + "taskReport/findCounts", json);
  }
 
 
  addWatchlist(json) {
    return this.http.post<any>(environment.mainurl + "watchlist/create", json);
  }

  submitWatchlist(json) {
    return this.http.post<any>(environment.reporturl + "watchlist/findTasks", json);
  }

  setAsDefault(json) {
    return this.http.post<any>(environment.mainurl + "watchlist/setDefault", json);
  }

checkWatchlsit(json){
  return this.http.post<any>(environment.mainurl + "watchlist/checkIfWatchlistNameExists", json);
 
}  

  deleteDefault(json) {
    return this.http.post<any>(environment.mainurl + "watchlist/delete", json);
  }

  getDashboardTaskData(json) {
    return this.http.post<any>(environment.reporturl + "taskReport/findTasks", json);
  }


  getDashboardTaskDataByPagination(json,pageNumber, noofrows1) {
    return this.http.post<any>(environment.reporturl + 'taskReport/findTasksByPagination?page='+pageNumber+'&size='+noofrows1, json);
  }


  getTasksList(json) {
    return this.http.post<any>(environment.reporturl + "taskReport/findGroupedTasks", json);
  }

  getAllUserListByUserGroup(json) {
    return this.http.post<any>(environment.mainurl + "user/findByMultipleUserGroup",json);
  }

  getAllUserList() {
    return this.http.get<any>(environment.mainurl + "user/findAllEnabledAndActive");
  }

  getAllCreatedByList() {
    return this.http.get<any>(environment.mainurl + "user/findAllEnabledAndActive");
  }

  createTicket(requestJson) {
    return this.http.post<any>(environment.mainurl+"task/create",requestJson);
  }

  helpMenu(requestJson) {
    return this.http.post<any>(environment.help_url,requestJson);
  }

  updateTicket (requestJson) {
    return this.http.post<any>(environment.mainurl+"task/update",requestJson);
  }

  getMyReportsList(requestJson) {
    return this.http.post<any>(environment.reporturl+"fetchByUserId",requestJson);
  }

  deleteMyReportById(requestJson) {
    return this.http.post<any>(environment.reporturl+"delete",requestJson);
  }


  generateReport(requestJson) {
    return this.http.post<any>(environment.reporturl+"generate",requestJson);
  }

  generateReportNew(requestJson,pageNumber, noofrows1) {
    return this.http.post<any>(environment.reporturl+'generateByPagination?page='+pageNumber+'&size='+noofrows1,requestJson);
  }
  
 

  saveGenerateReport(requestJson) {
    return this.http.post<any>(environment.reporturl+"save",requestJson);
  }

  generateStdReport(requestJson) {
    return this.http.post<any>(environment.reporturl+"standard",requestJson);
  }

  getTaskByAction(requestJson) {
    return this.http.post<any>(environment.reporturl+"taskReport/findTasks",requestJson);
  }

  getMyTaskCount() {
    return this.http.get<any>(environment.reporturl + "taskReport/findCounts");
  }

  getTaskDetailById(requestJson) {
    // const req = { data: btoa(JSON.stringify(requestJson)) }
    return this.http.post<any>(environment.mainurl+"task/findByTaskId",requestJson);
  }
  // getTaskDetailByIdForDashboard(requestJson) {
  //   const req = { data: btoa(JSON.stringify(requestJson)) }
  //   return this.http.post<any>(environment.mainurl+"task/findByTaskId",requestJson)
  // }

  addComment(requestJson) {
    return this.http.post<any>(environment.mainurl+"task/addComment",requestJson);
  }

  getHistory(requestJson) {
    return this.http.post<any>(environment.mainurl+"task/fetchTaskHistory",requestJson);
  }

  

  downloadFile(res:any): Observable<any>{
    return this.http.post('https://taskeasydev.straviso.net:9091/task/attachment/download',res);
   }

   getUserSubscribeProduct(businessId,productId) {
    return this.http.get<any>(environment.northstar_url+'getBusinessSubScribeProducts?businessId='+businessId+'&productId='+productId);
  }

  getTaskStatusWiseCount(data) {
    return this.http.post<any>(environment.reporturl+'dashboard/statusWiseCount',data);
  }

  getTaskPriorityWiseCount(data) {
    return this.http.post<any>(environment.reporturl + "taskReport/fetchGraphData",data);
  }

  logout() {
    return this.http.post(`${environment.logout_url}logoutUser`, {})
  }



  getWatchlistsByTaskId(requestJson) {	
    return this.http.post<any>(environment.mainurl+"watchlist/findByTaskId",requestJson);	
  }	
  addTaskToWatchlist(requestJson) {	
    return this.http.post<any>(environment.mainurl+"watchlist/addTaskId",requestJson);	
  }	
  updateTaskToWatchlist(requestJson) {	
    return this.http.post<any>(environment.mainurl+"watchlist/updateTaskId",requestJson);	
  }	

  downloadAttachment(id) {
    return this.http.get<any>(`${environment.mainurl}task/attachment/download/${id}`, {responseType: 'blob' as 'json'});
  }

  getRecurrenceById(req){
     return this.http.post<any>(environment.mainurl+"task/recurrence/findByTaskId",req);
   }

  updateRecurrence(req){
    return this.http.post<any>(environment.mainurl+'task/recurrence/update',req);
  }

  deleteRecurrence(req){
    return this.http.post<any>(environment.mainurl+'task/recurrence/remove',req)
  }
 
  getProductTourImages(req){
    return this.http.get<any>(environment.supportToken+"productTour/findAllImages?userorAdminFlag="+req);
  }

  getProductTourImagesV1(req){
    return this.http.get<any>(environment.supportToken+"productTour/findAllImagesV1?userorAdminFlag="+btoa(req));
  }

  findQuickRefPdf(){
    return this.http.get<any>(environment.mainurl+'taskType/findQuickReferenceUrl');
  }

  removeTaskAttachment(req){
    return this.http.post<any>(environment.mainurl+'task/attachment/remove',req);
  }

  removeNotificationById(req){
    return this.http.post<any>(environment.mainurl+'notification/deleteNotificationById',req);
  }

  checkReportNameExist(req) {
    return this.http.post<any>(environment.reporturl+'checkIfReportNameExists',req);
  }

  getDefaultOptionForSettings(userId,businessId){
    return this.http.get<any>(environment.northstar_Loginurl+'setting/getDefaultSettingById?userId='+userId+'&businessId='+businessId);
  }

  getDefaultOptionForSettingsV1(userId,businessId){
    let json={
        "userId": userId,
        "businessId": businessId
    }
    const req = { data: btoa(JSON.stringify(json))};
    return this.http.post<any>(environment.northstar_Loginurl+'setting/getDefaultSettingByIdV1',req);
  }

  updateDefaultOptionForSettings(req){
    return this.http.post<any>(environment.northstar_Loginurl+'setting/updateDefaultSettingById',req);
  }

 
  updateDontShowProductTour(email) {
    return this.http.get<any>(environment.northstar_Loginurl+'productTour/updateProductTourFlag?email='+email);
  }

  getHelpIndex(){
    return this.http.get<any>(environment.northstar_Loginurl+'help/findByServiceAndContent?serviceName=MyTaskEasy&contentType=Index')
  }

  getHelpFAQ(){
    return this.http.get<any>(environment.northstar_Loginurl+'help/findByServiceAndContent?serviceName=MyTaskEasy&contentType=FAQ')
  }

  getHelpVideo(){
    return this.http.get<any>(environment.northstar_Loginurl+'help/findByServiceAndContent?serviceName=MyTaskEasy&contentType=Video')
  }

  sendMailIdToHotreads(req){
    return this.http.post<any>(environment.hotReadsUrl,req);
  }
  getMultiLangLabels(){
    return this.http.get<any>(environment.northstar_Loginurl+'i18n/findAll');
  }
  getMultiLangLabelsByLang(req){
    return this.http.get<any>(environment.northstar_Loginurl+'i18n/findByLanguage?lang='+req);
  }
  updatePreferredLanguage(req){
    return this.http.get<any>(environment.northstar_Loginurl+'profile/updatePreferredLanguage?lang='+req);
  }
  getAllLanguages(){
    return this.http.get<any>(environment.northstar_Loginurl+'i18n/getAllLanguages');
  }

  getAllPriorities(){
    return this.http.get<any>(environment.mainurl+'priority/findAllEnableAndActive');
  }

  getTeamCount(json) {
    return this.http.post<any>(environment.reporturl + "taskReport/findTeamCounts", json);
  }

  getMyCount(json) {
    return this.http.post<any>(environment.reporturl + "taskReport/findMyCounts", json);
  }

  removeAttachment(req){
    return this.http.post<any>(environment.mainurl+"task/attachment/remove",req);
  }

 advanceSearchList(requestJson,pageNumber, noofrows1) {
    return this.http.post<any>(environment.reporturl+'advanceSearch?page='+pageNumber+'&size='+noofrows1, requestJson);
  }
  getGlobalFilterData(value,pageNumber, noofrows1,userId) {
    return this.http.get(environment.reporturl+'globalSearch?search=' +value+'&page='+pageNumber+'&size='+noofrows1+'&userId='+userId);
}
 
//Template TaskEasyBOP
getTemplateListData() {
  return this.http.get(environment.template_base_url + 'getAllTaskTemplates');
}
getTemplateListByBusinessId(businessId){
  return this.http.get(environment.template_base_url + 'getTemplateDetailsByBusinessId?businessId='+businessId);
}
getTemplateListByBusinessIdClone(businessId){
  return this.http.get(environment.template_base_url + 'getTemplateDetailsByBusinessId?businessId='+businessId);
}

getTemplateListByBusinessIdV1(businessId){
  return this.http.get(environment.template_base_url + 'getTemplateDetailsByBusinessIdV1?businessId='+btoa(businessId));
}
getTemplateListByBusinessIdCloneV1(businessId){
  return this.http.get(environment.template_base_url + 'getTemplateDetailsByBusinessIdV1?businessId='+ btoa(businessId));
}
getTemplatebyId(name) {
  return this.http.get(environment.template_base_url + 'fetchTemplate?templateName='+name);
}
getTemplate() {
  return this.http.get(environment.template_base_url + 'fetchTemplate');
}
getExcelData(json){
  return this.http.post<any>(environment.reporturl + "taskReport/exportTicketsInfo", json, {responseType: 'blob' as 'json'});
}
getExcelDynamicData(json){
  return this.http.post<any>(environment.reporturl + "taskReport/exportTicketsInfoV1", json, {responseType: 'blob' as 'json'});
}

getAdvanceExcelData(json){
  return this.http.post<any>(environment.reporturl + "exportAdvanceSearchInfo", json, {responseType: 'blob' as 'json'});
}

getDynamicAdvanceExcelData(json){
  return this.http.post<any>(environment.reporturl + "exportAdvanceSearchInfoV1", json, {responseType: 'blob' as 'json'});
}



// createTemplateMTE(requestJson){
//   return this.http.post<any>(environment.templateMTE+"create",requestJson);
//   }
  // getTemplateMTEById(requestJson) {
  //   return this.http.post<any>(environment.templateMTE+"findByTaskId",requestJson);
  // }
  // updateTemplateMTE (requestJson) {
  //   return this.http.post<any>(environment.templateMTE+"update",requestJson);
  // }
  // generateTemplateMTEReport(requestJson) {
  //   return this.http.post<any>(environment.templateReportMTE+"generate",requestJson);
  // }
  // getDashboardTemplateDataExport(json) {
  //   return this.http.post<any>(environment.templateMTE + "findTasks", json);
  // }
  // saveGenerateTemplateReport(requestJson) {
  //   return this.http.post<any>(environment.templateReportMTE+"save",requestJson);
  // }
  // getDashboardTaskExportTemplateData(json) {
  //   return this.http.post<any>(environment.templateReportMTE + "taskReport/findTasks", json);
  // }
  // getMyReportsTemplateList(requestJson) {
  //   return this.http.post<any>(environment.templateReportMTE+"fetchByUserId",requestJson);
  // }

}
